import React from 'react';
import styled from 'styled-components';
import { Typography, Paper, Grid, Container, Card, CardContent, Divider } from '@mui/material';
import { Add, Replay, Balance } from '@mui/icons-material';
import liver from '../../../asset/img/liver .png';
import kidney from '../../../asset/img/kidney.png';
import heart from '../../../asset/img/heart2.png';

const MainContent = styled.div`
  padding: 2rem;
  height: 100%;
  gap: 20px;
  background-color: white;
  font-family: 'Noto Sans KR', sans-serif;
`;

const Title = styled(Typography)`
  color: #2c3e50;
  margin-bottom: 30px !important;
  text-align: center;
  font-weight: bold !important;
  font-family: 'Noto Sans KR', sans-serif !important;
`;

const InfoCard = styled(Paper)`
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  }
`;

const IconWrapper = styled.div`
  background-color: #0f67fe;
  color: white;
  border-radius: 50%;
  padding: 15px;
  margin-bottom: 15px;
`;

const Image = styled.img`
  width: 25%;
  height: auto;
  padding: 1rem;
`;

const CardTitle = styled(Typography)`
  color: #34495e;
  margin-bottom: 10px !important;
  font-weight: bold !important;
  font-family: 'Noto Sans KR', sans-serif !important;
`;

const CardInnerContent = styled(Typography)`
  color: val(text.secondary);
  font-family: 'Noto Sans KR', sans-serif !important;
`;

const SectionTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 10px;
`;

const Text = styled.p`
  line-height: 1.6;
  margin-bottom: 20px;

  .highlight {
    color: #0f67fe;
    font-size: 1.2em;
    font-weight: bold;
  }
`;

const Footer = styled.div`
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 12px;
  margin-top: 5rem;
  color: #666;

  a {
    color: #666;
    text-decoration: none;
  }

  @media (max-width: 991px) {
    margin-top: 3rem;
  }
`;

const cards = [
  {
    title: '건강상태',
    description: '내 몸의 에너지가 잘 생성되고 순환하며 균형 조절되는 상태를 말합니다.',
  },
  {
    title: '질병상태',
    description: '잘못된 생활습관으로 몸 에너지 기능에 문제가 생기면 질병이 발생하게 됩니다.',
  },
];

const NobinoHealthComponent = () => {
  return (
    <MainContent>
      <>
        <SectionTitle>노비노 건강법</SectionTitle>
        <Text>
          노비노건강법은 내 몸 에너지 맞춤 치료법으로 <span className="highlight">NO비만</span> .{' '}
          <span className="highlight">NO질병</span> . <span className="highlight">NO肥老</span>의 세상을 추구합니다.
        </Text>

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <InfoCard elevation={3}>
              <CardTitle variant="h6">{cards[0].title}</CardTitle>
              <CardInnerContent>{cards[0].description}</CardInnerContent>
            </InfoCard>
          </Grid>
          <Grid item xs={12} md={6}>
            <InfoCard elevation={3}>
              <CardTitle variant="h6">{cards[1].title}</CardTitle>
              <CardInnerContent>{cards[1].description}</CardInnerContent>
            </InfoCard>
          </Grid>
        </Grid>

        <Divider sx={{ my: 4, opacity: 0 }} />
        <Text>일상 생명활동에 필요한 에너지는 아래와 같이 생성 순환 균형 조절됩니다.</Text>

        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <InfoCard elevation={3}>
              <Image src={liver} alt="liver" />
              <CardTitle variant="h6">에너지 생성</CardTitle>
              <CardInnerContent>비, 위 소화기능과 우리가 먹는 음식에 의해 생성됩니다</CardInnerContent>
            </InfoCard>
          </Grid>
          <Grid item xs={12} md={4}>
            <InfoCard elevation={3}>
              <Image src={heart} alt="heart" />
              <CardTitle variant="h6">에너지 순환</CardTitle>
              <CardInnerContent>
                심, 폐 순환기능과 일상의 움직임과 운동에 의해 경락을 통하여 머리끝부터 발끝까지 우리 몸 모든 기관조직에
                순환 공급됩니다
              </CardInnerContent>
            </InfoCard>
          </Grid>
          <Grid item xs={12} md={4}>
            <InfoCard elevation={3}>
              <Image src={kidney} alt="kidney" />
              <CardTitle variant="h6">에너지 균형</CardTitle>
              <CardInnerContent>
                간, 신 비뇨생식기능과 수면 및 정신 활동에 의해 일정한 리듬을 가지고 균형 조절됩니다.
              </CardInnerContent>
            </InfoCard>
          </Grid>
        </Grid>
      </>
      <Footer>
        <a href="https://www.flaticon.com/free-icons/liver" title="liver icons">
          Liver, &nbsp;
        </a>
        <a href="https://www.flaticon.com/free-icons/kidney" title="kidney icons">
          Kidney, &nbsp;
        </a>
        <a href="https://www.flaticon.com/free-icons/heart" title="heart icons">
          Heart &nbsp;
        </a>
        icons created by pojok d - Flaticon
      </Footer>
    </MainContent>
  );
};

export default NobinoHealthComponent;
