import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SignUpPresenter from './SignUpPresenter';
import { useHeaderTextChange } from '../../../lib/useHeader';
import { authApi } from '../../../api/api-auth';

export default function SignUpContainer() {
  useHeaderTextChange('sign-up');

  const [submitForm, setSubmitForm] = useState({
    email: '',
    name: '',
    hint: '',
    answer: '',
    password: '',
    rePassword: '',
  });

  const [validForm, setValidForm] = useState({
    emailValid: false,
    nameValid: false,
    hintValid: false,
    answerValid: false,
    passwordValid: false,
    rePasswordValid: false,
  });

  const [validErrMessages, setValidErrMessages] = useState({
    emailErrMessage: '',
    nameErrMessage: '',
    hintErrMessage: '',
    answerErrMessage: '',
    passwordErrMessage: '',
    rePasswordErrMessage: '',
  });

  const navigate = useNavigate();

  const onClickSubmit = async () => {
    if (!validForm.emailValid) {
      return;
    }
    if (!validForm.nameValid) {
      return;
    }
    if (!validForm.hintValid) {
      return;
    }
    if (!validForm.answerValid) {
      return;
    }
    if (!validForm.passwordValid) {
      return;
    }
    if (!validForm.rePasswordValid) {
      return;
    }

    const loginForm = {
      email: submitForm.email,
      name: submitForm.name,
      password: submitForm.password,
      hint: submitForm.hint,
      answer: submitForm.answer,
    };

    let res;
    try {
      res = await authApi.createUser(loginForm);
    } catch (e) {
      console.log('create err');
    } finally {
      if (res) {
        if (res.data.user) {
          navigate('/signin');
        } else {
          console.log('create err');
        }
      }
    }
  };

  /* 이름 확인 */
  useEffect(() => {
    if (submitForm.name.length >= 2) {
      setValidForm({
        ...validForm,
        nameValid: true,
      });
      setValidErrMessages({
        ...validErrMessages,
        nameErrMessage: '',
      });
    } else {
      setValidForm({
        ...validForm,
        nameValid: false,
      });
      setValidErrMessages({
        ...validErrMessages,
        nameErrMessage: '이름을 정확히 입력해 주세요',
      });
    }
  }, [submitForm.name]);

  /* 아이디 확인 */
  useEffect(() => {
    if (submitForm.email.length >= 4) {
      emailExist(submitForm.email);
    } else {
      setValidForm({
        ...validForm,
        emailValid: false,
      });
      setValidErrMessages({
        ...validErrMessages,
        emailErrMessage: '아이디는 4자리 이상이어야 합니다.',
      });
    }
  }, [submitForm.email]);

  /* 비밀번호 확인 */
  useEffect(() => {
    if (submitForm.password.length > 7) {
      setValidForm({
        ...validForm,
        passwordValid: true,
      });
      setValidErrMessages({
        ...validErrMessages,
        passwordErrMessage: '',
      });
      if (submitForm.rePassword.length > 1) {
        if (submitForm.rePassword === submitForm.password) {
          setValidForm({
            ...validForm,
            rePasswordValid: true,
          });
          setValidErrMessages({
            ...validErrMessages,
            rePasswordErrMessage: '',
          });
        } else {
          setValidForm({
            ...validForm,
            rePasswordValid: false,
          });
          setValidErrMessages({
            ...validErrMessages,
            rePasswordErrMessage: '비밀번호가 일치하지 않습니다.',
          });
        }
      }
    } else {
      setValidForm({
        ...validForm,
        passwordValid: false,
      });
      setValidErrMessages({
        ...validErrMessages,
        passwordErrMessage: '비밀번호 8자리 이상 입력해주세요.',
      });
    }
  }, [submitForm.password]);

  /* 2차 비밀번호 확인 */
  useEffect(() => {
    if (submitForm.rePassword === submitForm.password) {
      setValidForm({
        ...validForm,
        rePasswordValid: true,
      });
      setValidErrMessages({
        ...validErrMessages,
        rePasswordErrMessage: '',
      });
    } else {
      setValidForm({
        ...validForm,
        rePasswordValid: false,
      });
      setValidErrMessages({
        ...validErrMessages,
        rePasswordErrMessage: '비밀번호가 일치하지 않습니다.',
      });
    }
  }, [submitForm.rePassword]);

  /* 힌트 확인 */
  useEffect(() => {
    if (submitForm.hint.length > 0) {
      setValidForm({
        ...validForm,
        hintValid: true,
      });
      setValidErrMessages({
        ...validErrMessages,
        hintErrMessage: '',
      });
    } else {
      setValidForm({
        ...validForm,
        hintValid: false,
      });
      setValidErrMessages({
        ...validErrMessages,
        hintErrMessage: '비밀번호 찾기 힌트를 입력해주세요.',
      });
    }
  }, [submitForm.hint]);

  /* 힌트 정답 확인 */
  useEffect(() => {
    if (submitForm.answer.length > 0) {
      setValidForm({
        ...validForm,
        answerValid: true,
      });
      setValidErrMessages({
        ...validErrMessages,
        answerErrMessage: '',
      });
    } else {
      setValidForm({
        ...validForm,
        answerValid: false,
      });
      setValidErrMessages({
        ...validErrMessages,
        answerErrMessage: '비밀번호 찾기 힌트에 대한 답을 입력해주세요.',
      });
    }
  }, [submitForm.answer]);

  useEffect(() => {
    setValidForm({
      emailValid: false,
      nameValid: false,
      hintValid: false,
      answerValid: false,
      passwordValid: false,
      rePasswordValid: false,
    });
    setValidErrMessages({
      emailErrMessage: '',
      nameErrMessage: '',
      hintErrMessage: '',
      answerErrMessage: '',
      passwordErrMessage: '',
      rePasswordErrMessage: '',
    });
  }, []);

  /* 이메일 중복 확인 */
  const emailExist = async (email: string) => {
    const result = await authApi.checkExistEmail(email);
    if (!result.data.exist) {
      setValidForm({
        ...validForm,
        emailValid: true,
      });
      setValidErrMessages({
        ...validErrMessages,
        emailErrMessage: '',
      });
    } else {
      setValidForm({
        ...validForm,
        emailValid: false,
      });
      setValidErrMessages({
        ...validErrMessages,
        emailErrMessage: '중복되는 아이디가 존재합니다.',
      });
    }
  };

  return (
    <SignUpPresenter
      submitForm={submitForm}
      validForm={validForm}
      setSubmitForm={setSubmitForm}
      onClickSubmit={onClickSubmit}
      validErrMessages={validErrMessages}
    />
  );
}
