import styled from '@emotion/styled';
import { Container, TextField, Typography, Box, Button, Link } from '@mui/material';

export const StyledPasswordFindPage = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px;
`;

export const StyledTextField = styled(TextField)`
  width: 80%;
  input {
    padding: 1rem;
  }
`;

export const CenterText = styled(Typography)`
  font-weight: 500;
  text-align: center;
`;

export const LogoBox = styled(Box)`
  display: flex;
  flex-direction: column;
  margin: 0 auto 5rem;
`;

export const WichuIcon = styled.img`
  width: 120px;
  margin: 0 auto;
`;

export const StyledButton = styled(Button)`
  && {
    width: 60%;
    height: 30px;
    background-color: #2cbc88;
  }
`;

export const WithoutDecoLink = styled(Link)`
  color: 'inherit';
  text-decoration: none;
`;

export const LinkBox = styled(Box)`
  display: flex;
  flex-direction: row;
`;
