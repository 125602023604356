import React from 'react';
import styled from 'styled-components';
import Header from './HomeHeader';
import HomeSection from './HomeSection';
import IntroduceSection from './IntroduceSection';
import DiagnosisSection from './DiagnosisSection';
import KaidocSection from './KaidocSection';
import LogoSection from './LogoSection';
import SideMenu from './SideMenu';

const HomepagePresenter: React.FC = () => {
  return (
    <PageWrapper>
      <SideMenu />
      <ContentWrapper>
        {/* <Header /> */}
        {/* <IntroduceSection />
      <DiagnosisSection /> */}
        <HomeSection />
        <KaidocSection />
        {/* <LogoSection /> */}
      </ContentWrapper>
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  width: 100%;
  height: 100%;
  min-height: 100vh;
`;

const ContentWrapper = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  align-items: center;
`;

export default HomepagePresenter;