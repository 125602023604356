import React, { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  SurveyTitle,
  ControlledAccordions,
  AccordionItem,
  CheckBoxWithTextField,
  SurveySingleSelect,
  SurveyMultiSelect,
  CheckBoxFlexDirecRowForOne,
  StyledButton,
  BtnPreNext,
  ComboBox,
  SurveyMultiSelectVer2,
} from '../common/Survey';
import { ISurveyDictType } from '../common/SurveyTypes';
import '../common/Survey.scss';

interface IFavFodPageProps {
  dictObject: ISurveyDictType;
  validForm: IValidData;
  validErrMessages: IValidErrMessage;
  defaultValueForm: IDefaultValue;
  expanded: string;
  handleChange: (panel: any) => (event: any, isExpanded: boolean) => void;
  autoCompleteContent: any;
  setAutoCompleteContent: Dispatch<SetStateAction<any>>;
}

interface IValidErrMessage {
  etcDiseaseErrMessage: string;
  etcReasonErrMessage: string;
}

interface IValidData {
  etcDisease: boolean;
  etcReason: boolean;
  diseaseGenerateTime: boolean;
}

interface IDefaultValue {
  body_symptom: boolean[];
  autoimmune: boolean[];
  nervous_system: boolean[];
  psychiatry: boolean[];
  gynecological: boolean[];
  pediatric: boolean[];
  otorhinolaryngology: boolean[];
  skin: boolean[];
  cancer: boolean[];
}

export default function SurveyDgnsisPresenter(Props: IFavFodPageProps) {
  const {
    validForm,
    dictObject,
    validErrMessages,
    defaultValueForm,
    expanded,
    handleChange,
    autoCompleteContent,
    setAutoCompleteContent,
  } = Props;

  return (
    <>
      <SurveyTitle title="질환관련 정보(증상 질병명) " />
      <div className="title-container">
        <div className="title-text">질환관련 정보를 선택해주세요.</div>
      </div>

      <div className="autoCompleteSearch-container">
        <div className="autoComplete">
          <ComboBox autoCompleteContent={autoCompleteContent} setAutoCompleteContent={setAutoCompleteContent} />
        </div>
        {/* <div className="searchIcon" /> */}
      </div>

      <div className="accordion-container">
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
            <Typography sx={{ flexShrink: 0 }}>전신 증상</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="accordion-container">
              {[
                { qTitle: 'body_symptom_1_1', title: '만성피로' },
                { qTitle: 'body_symptom_1_2', title: '비만' },
                { qTitle: 'body_symptom_1_3', title: '다한증' },
                { qTitle: 'body_symptom_1_4', title: '고혈압' },
                { qTitle: 'body_symptom_1_5', title: '당뇨' },
                { qTitle: 'body_symptom_1_6', title: '고지혈증' },
              ].map((item, index) => (
                <AccordionItem
                  qTitle={item.qTitle}
                  title={item.title}
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  defaultValue={defaultValueForm.body_symptom[index]}
                />
              ))}
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
            <Typography sx={{ flexShrink: 0 }}>내과 질환</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="accordion-container">
              <ControlledAccordions
                autoCompleteContent={autoCompleteContent}
                title="소화기 질환"
                items={[
                  { qTitle: 'internal_1_1', title: '역류성 식도염' },
                  { qTitle: 'internal_1_2', title: '기능성 소화불량' },
                  { qTitle: 'internal_1_3', title: '위궤양' },
                  { qTitle: 'internal_1_4', title: '과민성 대장 증후군' },
                  { qTitle: 'internal_1_5', title: '만성 변비' },
                  { qTitle: 'internal_1_6', title: '설사' },
                ]}
                defaultValues={[
                  dictObject.internal_1_1,
                  dictObject.internal_1_2,
                  dictObject.internal_1_3,
                  dictObject.internal_1_4,
                  dictObject.internal_1_5,
                  dictObject.internal_1_6,
                ]}
              />
              <ControlledAccordions
                autoCompleteContent={autoCompleteContent}
                title="호흡기 질환"
                items={[
                  { qTitle: 'internal_2_1', title: '만성기침' },
                  { qTitle: 'internal_2_2', title: '알러지' },
                  { qTitle: 'internal_2_3', title: '천식' },
                ]}
                defaultValues={[dictObject.internal_2_1, dictObject.internal_2_2, dictObject.internal_2_3]}
              />
              <ControlledAccordions
                autoCompleteContent={autoCompleteContent}
                title="비뇨생식기 질환"
                items={[
                  { qTitle: 'internal_3_1', title: '만성 전립선염' },
                  { qTitle: 'internal_3_2', title: '소변실금' },
                ]}
                defaultValues={[dictObject.internal_3_1, dictObject.internal_3_2]}
              />
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3bh-content" id="panel3bh-header">
            <Typography sx={{ flexShrink: 0 }}>근골격계 질환</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="accordion-container">
              {/* <ControlledAccordions
                title="근골격계 질환"
                items={[
                  { qTitle: 'muscle_physique_1_1', title: '교통사고 후유증' },
                  { qTitle: 'muscle_physique_1_2', title: '턱 관절통' },
                  { qTitle: 'muscle_physique_1_3', title: '골다공증' },
                ]}
                defaultValues={[
                  dictObject.muscle_physique_1_1,
                  dictObject.muscle_physique_1_2,
                  dictObject.muscle_physique_1_3,
                ]}
              /> */}
              <ControlledAccordions
                autoCompleteContent={autoCompleteContent}
                title="목.허리 척추질환"
                items={[
                  { qTitle: 'muscle_physique_2_1', title: '목통증(항강)' },
                  { qTitle: 'muscle_physique_2_2', title: '목 디스크' },
                  { qTitle: 'muscle_physique_2_3', title: '허리통증' },
                  { qTitle: 'muscle_physique_2_4', title: '허리디스크' },
                  { qTitle: 'muscle_physique_2_5', title: '척추관협착증' },
                  { qTitle: 'muscle_physique_2_6', title: '척추측만증' },
                  { qTitle: 'muscle_physique_2_7', title: '압박골절' },
                ]}
                defaultValues={[
                  dictObject.muscle_physique_2_1,
                  dictObject.muscle_physique_2_2,
                  dictObject.muscle_physique_2_3,
                  dictObject.muscle_physique_2_4,
                  dictObject.muscle_physique_2_5,
                  dictObject.muscle_physique_2_6,
                  dictObject.muscle_physique_2_7,
                ]}
              />
              <ControlledAccordions
                autoCompleteContent={autoCompleteContent}
                title="팔 다리 관절질환"
                items={[
                  { qTitle: 'muscle_physique_3_1', title: '어깨통증(오십견)' },
                  { qTitle: 'muscle_physique_3_2', title: '손목통증' },
                  { qTitle: 'muscle_physique_3_3', title: '손목터널증후군' },
                  { qTitle: 'muscle_physique_3_4', title: '손가락 통증' },
                  { qTitle: 'muscle_physique_3_5', title: '수족냉증' },
                  { qTitle: 'muscle_physique_3_6', title: '골반통' },
                  { qTitle: 'muscle_physique_3_7', title: '엉덩이 뼈괴사' },
                  { qTitle: 'muscle_physique_3_8', title: '무릎 퇴행성 관절염' },
                  { qTitle: 'muscle_physique_3_9', title: '만성발목염좌' },
                  { qTitle: 'muscle_physique_3_10', title: '족저근막염' },
                ]}
                defaultValues={[
                  dictObject.muscle_physique_3_1,
                  dictObject.muscle_physique_3_2,
                  dictObject.muscle_physique_3_3,
                  dictObject.muscle_physique_3_4,
                  dictObject.muscle_physique_3_5,
                  dictObject.muscle_physique_3_6,
                  dictObject.muscle_physique_3_7,
                  dictObject.muscle_physique_3_8,
                  dictObject.muscle_physique_3_9,
                  dictObject.muscle_physique_3_10,
                ]}
              />
              <SurveyMultiSelectVer2
                surveyItem={{
                  qTitles: ['muscle_physique_1_1', 'muscle_physique_1_2', 'muscle_physique_1_3'],
                  title: '',
                  questions: ['교통사고 후유증', '턱 관절통', '골다공증'],
                }}
                defaultValues={[
                  dictObject.muscle_physique_1_1,
                  dictObject.muscle_physique_1_2,
                  dictObject.muscle_physique_1_3,
                ]}
              />
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4bh-content" id="panel4bh-header">
            <Typography sx={{ flexShrink: 0 }}>자가면역질환</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="accordion-container">
              {[
                { qTitle: 'autoimmune_1', title: '류마티스' },
                { qTitle: 'autoimmune_2', title: '강직성척추염' },
                { qTitle: 'autoimmune_3', title: '섬유근통' },
                { qTitle: 'autoimmune_4', title: '루프스' },
                { qTitle: 'autoimmune_5', title: '통풍' },
              ].map((item, index) => (
                <AccordionItem
                  qTitle={item.qTitle}
                  title={item.title}
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  defaultValue={defaultValueForm.autoimmune[index]}
                />
              ))}
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel5bh-content" id="panel5bh-header">
            <Typography sx={{ flexShrink: 0 }}>신경계질환</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="accordion-container">
              {[
                { qTitle: 'nervous_system_1', title: '두통(긴장성두통, 편두통)' },
                { qTitle: 'nervous_system_2', title: '어지러움' },
                { qTitle: 'nervous_system_3', title: '중풍' },
                { qTitle: 'nervous_system_4', title: '파킨슨질환' },
                { qTitle: 'nervous_system_5', title: '치매' },
                { qTitle: 'nervous_system_6', title: '안면신경마비' },
                { qTitle: 'nervous_system_7', title: '안면경련' },
              ].map((item, index) => (
                <AccordionItem
                  qTitle={item.qTitle}
                  title={item.title}
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  defaultValue={defaultValueForm.nervous_system[index]}
                />
              ))}
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel6bh-content" id="panel6bh-header">
            <Typography sx={{ flexShrink: 0 }}>정신과질환</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="accordion-container">
              {[
                { qTitle: 'psychiatry_1', title: '불면증' },
                { qTitle: 'psychiatry_2', title: '우울증' },
                { qTitle: 'psychiatry_3', title: '공황장애' },
                { qTitle: 'psychiatry_4', title: '외상후스트레스(PTSD)' },
              ].map((item, index) => (
                <AccordionItem
                  qTitle={item.qTitle}
                  title={item.title}
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  defaultValue={defaultValueForm.psychiatry[index]}
                />
              ))}
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel7bh-content" id="panel7bh-header">
            <Typography sx={{ flexShrink: 0 }}>부인과질환</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="accordion-container">
              {[
                { qTitle: 'gynecological_1', title: '생리통' },
                { qTitle: 'gynecological_2', title: '기능성 자궁출혈' },
                { qTitle: 'gynecological_3', title: '난임증' },
                { qTitle: 'gynecological_4', title: '산후질환' },
                { qTitle: 'gynecological_5', title: '유산후유증' },
                { qTitle: 'gynecological_6', title: '자궁질환' },
              ].map((item, index) => (
                <AccordionItem
                  qTitle={item.qTitle}
                  title={item.title}
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  defaultValue={defaultValueForm.gynecological[index]}
                />
              ))}
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel8bh-content" id="panel8bh-header">
            <Typography sx={{ flexShrink: 0 }}>소아과질환</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="accordion-container">
              {[
                { qTitle: 'pediatric_1', title: '소아 성장' },
                { qTitle: 'pediatric_2', title: '소아비만' },
                { qTitle: 'pediatric_3', title: '자페스펙트럼' },
                { qTitle: 'pediatric_4', title: '코피' },
                { qTitle: 'pediatric_5', title: '야제증(밤에 우는것)' },
              ].map((item, index) => (
                <AccordionItem
                  qTitle={item.qTitle}
                  title={item.title}
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  defaultValue={defaultValueForm.pediatric[index]}
                />
              ))}
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel9bh-content" id="panel9bh-header">
            <Typography sx={{ flexShrink: 0 }}>안이비인후과 질환</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="accordion-container">
              {[
                { qTitle: 'otorhinolaryngology_1', title: '안구건조증' },
                { qTitle: 'otorhinolaryngology_2', title: `이명(메니에르) 및 난청` },
                { qTitle: 'otorhinolaryngology_3', title: `만성중이염` },
                { qTitle: 'otorhinolaryngology_4', title: '만성비염(알러지)' },
                { qTitle: 'otorhinolaryngology_5', title: '축농증' },
                { qTitle: 'otorhinolaryngology_6', title: '만성구내염' },
                { qTitle: 'otorhinolaryngology_7', title: '구강건조(쇼그렌증후군)' },
              ].map((item, index) => (
                <AccordionItem
                  qTitle={item.qTitle}
                  title={item.title}
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  defaultValue={defaultValueForm.otorhinolaryngology[index]}
                />
              ))}
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel10bh-content" id="panel10bh-header">
            <Typography sx={{ flexShrink: 0 }}>피부질환</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="accordion-container">
              {[
                { qTitle: 'skin_1', title: '아토피피부염' },
                { qTitle: 'skin_2', title: '건선' },
                { qTitle: 'skin_3', title: '두드러기' },
                { qTitle: 'skin_4', title: '탈모' },
              ].map((item, index) => (
                <AccordionItem
                  qTitle={item.qTitle}
                  title={item.title}
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  defaultValue={defaultValueForm.skin[index]}
                />
              ))}
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel11bh-content" id="panel11bh-header">
            <Typography sx={{ flexShrink: 0 }}>암질환</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="accordion-container">
              {[
                { qTitle: 'cancer_1', title: '유방암' },
                { qTitle: 'cancer_2', title: '난소암' },
                { qTitle: 'cancer_3', title: '갑상선암' },
                { qTitle: 'cancer_4', title: '전립선암' },
                { qTitle: 'cancer_5', title: '간암' },
                { qTitle: 'cancer_6', title: '폐암' },
                { qTitle: 'cancer_7', title: '위암' },
                { qTitle: 'cancer_8', title: '대장암' },
                { qTitle: 'cancer_9', title: '췌장암' },
                { qTitle: 'cancer_10', title: '뇌종양' },
                { qTitle: 'cancer_11', title: '두경부암' },
              ].map((item, index) => (
                <AccordionItem
                  qTitle={item.qTitle}
                  title={item.title}
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  defaultValue={defaultValueForm.cancer[index]}
                />
              ))}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>

      <div className="for-1rem-padding" />
      <div className="checkBoxFlexDirecRowOuter-container">
        <CheckBoxWithTextField
          qTitle="etc_disease"
          title="기타질환(ex, 코로나 후유증)"
          defaultCheckValue={dictObject.etc_disease}
          defaultTextValue={dictObject.etc_disease_content}
          placeholder="명칭"
          helperText={validErrMessages.etcDiseaseErrMessage}
          error={!validForm.etcDisease && validErrMessages.etcDiseaseErrMessage !== ''}
        />
      </div>

      <SurveyTitle title="발병 시기 (증상발현이나 진단시기)" />
      <SurveySingleSelect
        surveyItem={{
          qTitle: 'disease_generate_time',
          title: '',
          questions: ['1개월이내', '1개월-6개월', '6개월 이상'],
        }}
        defaultValue={dictObject.disease_generate_time}
      />
      <SurveyTitle title="발병 원인 (몸이 안 좋아진 계기)" />
      <div className="title-container">
        <div className="title-text">몸이 안 좋아진 계기를 선택해주세요.</div>
      </div>
      <CheckBoxFlexDirecRowForOne
        qTitle="reason_no_reason"
        title="특별한 이유 없음"
        defaultValue={dictObject.reason_no_reason}
      />
      {!dictObject.reason_no_reason && (
        <>
          <SurveyMultiSelect
            surveyItem={{
              qTitles: ['reason_food_habbit_1', 'reason_food_habbit_2', 'reason_food_habbit_3', 'reason_food_habbit_4'],
              title: '음식 습관',
              questions: ['과식', '폭식', '급하게 먹음', '상한 음식'],
            }}
            defaultValues={[
              dictObject.reason_food_habbit_1,
              dictObject.reason_food_habbit_2,
              dictObject.reason_food_habbit_3,
              dictObject.reason_food_habbit_4,
            ]}
            disabled={typeof dictObject.reason_no_reason === 'boolean' ? dictObject.reason_no_reason : false}
          />
          <SurveyMultiSelect
            surveyItem={{
              qTitles: [
                'reason_life_habbit_1',
                'reason_life_habbit_2',
                'reason_life_habbit_3',
                'reason_life_habbit_4',
                'reason_life_habbit_5',
              ],
              title: '생활 습관',
              questions: ['과로', '야간 근무', '책상 근무', '잘 눕는 습관', '과도한 성생활'],
            }}
            defaultValues={[
              dictObject.reason_life_habbit_1,
              dictObject.reason_life_habbit_2,
              dictObject.reason_life_habbit_3,
              dictObject.reason_life_habbit_4,
              dictObject.reason_life_habbit_5,
            ]}
          />
          <SurveyMultiSelect
            surveyItem={{
              qTitles: ['reason_stress_1', 'reason_stress_2', 'reason_stress_3', 'reason_stress_4'],
              title: '정신적 스트레스',
              questions: ['화냄', '슬픔', '우울함', '일상 스트레스'],
            }}
            defaultValues={[
              dictObject.reason_stress_1,
              dictObject.reason_stress_2,
              dictObject.reason_stress_3,
              dictObject.reason_stress_4,
            ]}
          />
          <SurveyMultiSelect
            surveyItem={{
              qTitles: ['reason_weather_1', 'reason_weather_2', 'reason_weather_3', 'reason_weather_4'],
              title: '날씨 기후 영향',
              questions: ['찬바람', '무더위', '습한 곳', '건조한 곳에 노출'],
            }}
            defaultValues={[
              dictObject.reason_weather_1,
              dictObject.reason_weather_2,
              dictObject.reason_weather_3,
              dictObject.reason_weather_4,
            ]}
          />
          <SurveyMultiSelect
            surveyItem={{
              qTitles: ['reason_external_1', 'reason_external_2', 'reason_external_3', 'reason_external_4'],
              title: '외상',
              questions: ['교통사고', '낙상', '외상', '세균 감염'],
            }}
            defaultValues={[
              dictObject.reason_external_1,
              dictObject.reason_external_2,
              dictObject.reason_external_3,
              dictObject.reason_external_4,
            ]}
          />
          <div className="for-1rem-padding" />
          <div className="checkBoxFlexDirecRowOuter-container">
            <CheckBoxWithTextField
              qTitle="etc_reason"
              title="기타"
              defaultCheckValue={dictObject.etc_reason}
              defaultTextValue={dictObject.etc_reason_content}
              placeholder="원인"
              helperText={validErrMessages.etcReasonErrMessage}
              error={!validForm.etcReason && validErrMessages.etcReasonErrMessage !== ''}
            />
          </div>
        </>
      )}

      <BtnPreNext
        linkPre="../survey-basic"
        linkNex="../survey-favfod"
        disabled={!(validForm.etcDisease && validForm.etcReason && validForm.diseaseGenerateTime)}
      />
    </>
  );
}
