import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SurveyResultPresenter from './SurveyResultPresenter';
import { useHeaderTextChange } from '../../../../lib/useHeader';
import { rootReducers, RootState } from '../../../../modules';
import { surveyApi } from '../../../../api/api-survey';
import { restSurveyStore } from '../../../../modules/survey';
import * as t from './ResultType';
import * as c from './GuideCards';
import circulatorySystemImg from '../../../../asset/img/circulatory-system.png';
import imgfood from '../../../../asset/img/food.png'
import imgsleep from '../../../../asset/img/sleep.png'
import imgexercise from '../../../../asset/img/exercise.png'
import { newsurveyApi } from '../../../../api/api-newsurvey';

const dummy: t.SurveyResultType = {
    "id": "",
    "phone": "01024339301",
    "question": {
        "q1": 0,
        "q2": 0,
        "q3": 1,
        "q4": 0,
        "q5": 1,
        "q6": 2,
        "q7": 0,
        "q8": 2,
        "q9": 0,
        "q10": 2,
        "q11": 1,
        "q12": 0,
        "q13": 0,
        "q14": {
            "s1": 0,
            "s2": 1,
            "s3": 1,
            "s4": 0
        },
        "q15": {
            "s1": 0,
            "s2": 1,
            "s3": 1,
            "s4": 0,
            "s5": 0,
            "s6": 0
        },
        "q16": {
            "s1": 0,
            "s2": 1,
            "s3": 1,
            "s4": 0
        },
        "q17": {
            "s1": 0,
            "s2": 1,
            "s3": 0
        },
        "q18": 0,
        "q19": {
            "s1": 0,
            "s2": 1,
            "s3": 0
        }
    },
    "pastMedicalHistory": {
        "reason": [0,3,5],
        "reasonEtc": true,
        "pastHistory": [1,3,4],
        "surgery": true,
        "surgeryDesc": "example",
        "reasonEtcDesc": "example",
        "pastHistoryEtc": true,
        "pastHistoryEtcDesc": "example"
    },
    "medicine": {
        "diet": true,
        "kmedicine": {
            "value": true,
            "month": 12
        },
        "wmedicine": {
            "value": true,
            "month": 12
        }
    },
    "favorateFood": {
        "cigarette": true,
        "cigaretteAmount": 3,
        "alcohol": true,
        "alcoholAmount1": 3,
        "alcoholAmount2": 2,
        "caffeine": true,
        "caffeineAmount": 3
    },
    "disease":[{"id": "12312151", "name": "exaple"}],
    "diseaseGenerateTime": 2,
    "AScore": 46,
    "BScore": 78,
    "CScore": 91,
    "basicOld": null,
    "name": "rrr",
    "age": 24
}


const dummyRaw: t.SurveyResultRawType = {
    "phone": null,
    "question": {
        "q1": 0,
        "q2": 0,
        "q3": 1,
        "q4": 0,
        "q5": 1,
        "q6": 2,
        "q7": 0,
        "q8": 2,
        "q9": 0,
        "q10": 2,
        "q11": 1,
        "q12": 0,
        "q13": 0,
        "q14": {
            "s1": 0,
            "s2": 1,
            "s3": 1,
            "s4": 0
        },
        "q15": {
            "s1": 0,
            "s2": 1,
            "s3": 1,
            "s4": 0,
            "s5": 0,
            "s6": 0
        },
        "q16": {
            "s1": 0,
            "s2": 1,
            "s3": 1,
            "s4": 0
        },
        "q17": {
            "s1": 0,
            "s2": 1,
            "s3": 0
        },
        "q18": 0,
        "q19": {
            "s1": 0,
            "s2": 1,
            "s3": 0
        }
    },
    "pastMedicalHistory": {
        "reason": [0,3,5],
        "reasonEtc": true,
        "pastHistory": [1,3,4],
        "surgery": true,
        "surgeryDesc": "example",
        "reasonEtcDesc": "example",
        "pastHistoryEtc": true,
        "pastHistoryEtcDesc": "example"
    },
    "medicine": {
        "diet": true,
        "kmedicine": {
            "value": true,
            "month": 12
        },
        "wmedicine": {
            "value": true,
            "month": 12
        }
    },
    "favorateFood": {
        "cigarette": true,
        "cigaretteAmount": 3,
        "alcohol": true,
        "alcoholAmount1": 3,
        "alcoholAmount2": 2,
        "caffeine": true,
        "caffeineAmount": 3
    },
    "disease":[{"id": "12312151", "name": "exaple"}],
    "diseaseGenerateTime": 2,
    "basicOld": {
        "name":null,
        "height": 166,
        "weight": 77,
        "phone":null,
        "birth":1999,
        "gender":1,
        "systolic": 134,
        "diastolic": 89
    },
    "name": "rrr",
    "age": 24
}

const dummyEmpty: t.SurveyResultType = {
    "id": "",
    "phone": "",
    "question": {
        "q1": 0,
        "q2": 0,
        "q3": 1,
        "q4": 0,
        "q5": 1,
        "q6": 2,
        "q7": 0,
        "q8": 2,
        "q9": 0,
        "q10": 2,
        "q11": 1,
        "q12": 0,
        "q13": 0,
        "q14": {
            "s1": 0,
            "s2": 1,
            "s3": 1,
            "s4": 0
        },
        "q15": {
            "s1": 0,
            "s2": 1,
            "s3": 1,
            "s4": 0,
            "s5": 0,
            "s6": 0
        },
        "q16": {
            "s1": 0,
            "s2": 1,
            "s3": 1,
            "s4": 0
        },
        "q17": {
            "s1": 0,
            "s2": 1,
            "s3": 0
        },
        "q18": 0,
        "q19": {
            "s1": 0,
            "s2": 1,
            "s3": 0
        }
    },
    "pastMedicalHistory": {
        "reason": [0,3,5],
        "reasonEtc": true,
        "pastHistory": [1,3,4],
        "surgery": true,
        "surgeryDesc": "example",
        "reasonEtcDesc": "example",
        "pastHistoryEtc": true,
        "pastHistoryEtcDesc": "example"
    },
    "medicine": {
        "diet": true,
        "kmedicine": {
            "value": true,
            "month": 12
        },
        "wmedicine": {
            "value": true,
            "month": 12
        }
    },
    "favorateFood": {
        "cigarette": true,
        "cigaretteAmount": 3,
        "alcohol": true,
        "alcoholAmount1": 3,
        "alcoholAmount2": 2,
        "caffeine": true,
        "caffeineAmount": 3
    },
    "disease":[],
    "diseaseGenerateTime": 2,
    "AScore": 100,
    "BScore": 100,
    "CScore": 100,
    "basicOld": null,
    "name": "rrr",
    "age": 24
}

// A 순환, B 생성, C 균형
export default function SurveyPasHisContainer() {
    const newsurveydata = useSelector((state: RootState)=> state.newsurvey)
    const [survey, setSurvey] = useState<t.SurveyResultType>(dummyEmpty);
    const [loadingState, setLoadingState] = useState<number>(0); // 0로딩, 1성공, 2실패
    const { t, i18n } = useTranslation();

    const dietCreateCards:t.GuideCardType[] = [
        {'title':t('result.cont.diet_recommend'), 'image':circulatorySystemImg, 'renderComponent':c.dietCreateRecommand},
        {'title':t('result.cont.diet_avoid'), 'image':circulatorySystemImg, 'renderComponent':c.dietCreateAvoid},
    ]
    
    const exerciseCreateCards:t.GuideCardType[] = [
        {'title':t('result.cont.exercise_recommend'), 'image':circulatorySystemImg, 'renderComponent':c.exerciseCreateRecommand},
        {'title':t('result.cont.exercise_avoid'), 'image':circulatorySystemImg, 'renderComponent':c.exerciseCreateAvoid},
    ]
    
    const dietCycleCards:t.GuideCardType[] = [
        {'title':t('result.cont.diet_recommend'), 'image':circulatorySystemImg, 'renderComponent':c.dietCycleRecommand},
        {'title':t('result.cont.diet_avoid'), 'image':circulatorySystemImg, 'renderComponent':c.dietCycleAvoid},
    ]
    
    const exerciseCycleCards:t.GuideCardType[] = [
        {'title':t('result.cont.exercise_recommend'), 'image':circulatorySystemImg, 'renderComponent':c.exerciseCycleRecommand},
        {'title':t('result.cont.exercise_avoid'), 'image':circulatorySystemImg, 'renderComponent':c.exerciseCycleAvoid},
    ]
    
    const dietBalanceCards:t.GuideCardType[] = [
        {'title':t('result.cont.diet_recommend'), 'image':circulatorySystemImg, 'renderComponent':c.dietBalanceRecommand},
        {'title':t('result.cont.diet_avoid'), 'image':circulatorySystemImg, 'renderComponent':c.dietBalanceAvoid},
    ]
    
    const exerciseBalanceCards:t.GuideCardType[] = [
        {'title':t('result.cont.exercise_recommend'), 'image':circulatorySystemImg, 'renderComponent':c.exerciseBalanceRecommand},
        {'title':t('result.cont.exercise_avoid'), 'image':circulatorySystemImg, 'renderComponent':c.exerciseBalanceAvoid},
    ]
    
    const sleepCreateCards:t.GuideCardType[] = [
        {'title':t('result.cont.sleep_recommend'), 'image':circulatorySystemImg, 'renderComponent':c.sleepRecommand},
        {'title':t('result.cont.sleep_avoid'), 'image':circulatorySystemImg, 'renderComponent':c.sleepAvoid},
    ]
    
    const kmedicineCards:t.GuideCardType[] = [
        {'title':t('result.cont.k_medical_therapy'), 'image':circulatorySystemImg, 'renderComponent':c.kMedicineDesc},
        {'title':t('result.cont.bee_venom_therapy'), 'image':circulatorySystemImg, 'renderComponent':c.bongdockDesc},
    ]

    const transformData = () =>
        new Promise((resolve) => {
          const s = newsurveydata;
          console.log(s)
          const currentDate = new Date();
          const genderCode:Record<string,number> = {"male": 0, "female": 1};
          const diseaseCode:Record<string,number> = {
            "해당사항없음": 0,
            "고혈압": 1,
            "당뇨": 2,
            "고지혈증": 3,
            "중풍": 4,
            "암": 5,
            "심장질환": 6,
            "폐질환": 7,
            "소화기질환": 8,
            "간질환": 9,
            "신장질환": 10,
            "알러지": 11,
            "자가면역질환": 12
          };
          const reasonCode:Record<string,number> = {
            "특별한이유없음": 0,
            "과식": 1,
            "폭식": 2,
            "급하게먹음": 3,
            "상한음식": 4,
            "과로": 5,
            "야간근무": 6,
            "책상근무": 7,
            "잘눕는습관": 8,
            "과도한성생활": 9,
            "화냄": 10,
            "슬픔": 11,
            "우울함": 12,
            "일상스트레스": 13,
            "찬바람": 14,
            "무더위": 15,
            "습한": 16,
            "건조한": 17,
            "교통사고": 18,
            "낙상": 19,
            "외상": 20,
            "세균감염": 21
          };

          const generateDiseaseArray = (history:Record<string, boolean>, code:Record<string, number>) => {
            const rlist: number[] = [];
            Object.entries(history).forEach(([key, value]) =>{
                if (code[key] !== undefined) {
                    if(history[key]){
                      rlist.push(code[key]);
                    }
                  }
            });
            return rlist;
          };

          const r:t.SurveyResultRawType = {
            phone: s.privacy_info.phoneNumber,
            diseaseGenerateTime: Number(s.healthcare_info.disease_generate_time),
            name: s.privacy_info.name === "" ? t('result.cont.default_name') : s.privacy_info.name,
            age: s.basic_info.age,
            disease: s.healthcare_info.disease,
            medicine: Object.keys(s.healthcare_info).length === 0 ?{
                diet: false,
                kmedicine: {value: false, month: 0} ,
                wmedicine:{value: false, month: 0}
            } : {
                diet: s.healthcare_info.diet_med['해당사항없음'] === undefined ? false : !s.healthcare_info.diet_med['해당사항없음'],
                kmedicine: s.healthcare_info.diet_med['한약'] === undefined ? 
                {value: false, month: 0} : {value: s.healthcare_info.diet_med['한약'], month: s.healthcare_info.korMed},
                wmedicine: s.healthcare_info.diet_med['양약'] === undefined ? 
                {value: false, month: 0} : {value: s.healthcare_info.diet_med['양약'], month: s.healthcare_info.westernMed}
            },
            pastMedicalHistory: Object.keys(s.healthcare_info).length === 0 ? {
                reason: [],
                reasonEtc: false,
                pastHistory: [],
                surgery: false,
                surgeryDesc: "",
                reasonEtcDesc: "",
                pastHistoryEtc: false,
                pastHistoryEtcDesc: ""
            } : {
                reason: generateDiseaseArray(s.healthcare_info.trigger, reasonCode),
                reasonEtc: s.healthcare_info.trigger['기타'] === undefined ? false : s.healthcare_info.trigger['기타'],
                pastHistory: generateDiseaseArray(s.healthcare_info.past_history, diseaseCode),
                surgery:s.healthcare_info.past_history['수술유무'] === undefined ? false : s.healthcare_info.past_history['수술유무'],
                surgeryDesc:s.healthcare_info.surgery,
                reasonEtcDesc:s.healthcare_info.otherReason,
                pastHistoryEtc: s.healthcare_info.past_history['기타'] === undefined ? false : s.healthcare_info.past_history['기타'],
                pastHistoryEtcDesc: s.healthcare_info.otherReasonP
            },
            basicOld: s.privacy_info.phoneNumber === "" ? {
                phone: null,
                name: t('result.cont.default_name'),
                height: Number(s.basic_info.height),
                weight: Number(s.basic_info.weight),
                birth: currentDate.getFullYear() - Number(s.basic_info.age) + 1,
                gender: genderCode[s.basic_info.gender],
                systolic: Number(s.basic_info.systolic),
                diastolic: Number(s.basic_info.diastolic)
            } : null,
            favorateFood: Object.keys(s.healthcare_info).length === 0 ?{
                cigarette: false,
                alcohol: false,
                caffeine: false,
                cigaretteAmount:0,
                alcoholAmount1: 0,
                alcoholAmount2: 0,
                caffeineAmount: 0,
            }:{
                cigarette: s.healthcare_info.favorate_food.question1 === 'yes',
                alcohol: s.healthcare_info.favorate_food.question2 === 'yes',
                caffeine: s.healthcare_info.favorate_food.question3 === 'yes',
                cigaretteAmount: Number(s.healthcare_info.favorate_food.question1Amount),
                alcoholAmount1: Number(s.healthcare_info.favorate_food.question2Amount1),
                alcoholAmount2: Number(s.healthcare_info.favorate_food.question2Amount2),
                caffeineAmount: Number(s.healthcare_info.favorate_food.question3Amount),
            },
            question:{
                q1: Number(s.single_selected_option["목둘레"]),
                q2: Number(s.single_selected_option["상하체발달"]),
                q3: Number(s.single_selected_option["종아리둘레"]),
                q4: Number(s.single_selected_option["늑골각"]),
                q5: Number(s.single_selected_option["맥박수"]),
                q6: Number(s.single_selected_option["식욕"]),
                q7: Number(s.single_selected_option["식사횟수"]),
                q8: Number(s.single_selected_option["1회식사량"]),
                q9: Number(s.single_selected_option["소화불량"]),
                q10: Number(s.single_selected_option["충분한수면"]),
                q11: s.single_selected_option["입면시간"] === "2" ? 1 : 0,
                q12: s.single_selected_option["규칙적인입출면시간"] === "2" ? 1 : 0,
                q13: s.single_selected_option["규칙적인총수면시간"] === "2" ? 1 : 0,
                q14: {s1: s.selected_option["기상시상태와평소활동량"].includes("1")?1:0, 
                    s2:s.selected_option["기상시상태와평소활동량"].includes("2")?1:0, 
                    s3:s.selected_option["기상시상태와평소활동량"].includes("3")?1:0,
                    s4:s.selected_option["기상시상태와평소활동량"].includes("4")?1:0},
                q15: {s1: s.selected_option["성격"].includes("1")?1:0, 
                    s2:s.selected_option["성격"].includes("2")?1:0, 
                    s3:s.selected_option["성격"].includes("3")?1:0,
                    s4:s.selected_option["성격"].includes("4")?1:0,
                    s5:s.selected_option["성격"].includes("5")?1:0,
                    s6:s.selected_option["성격"].includes("6")?1:0},
                q16: {s1: s.selected_option["한열상태"].includes("1")?1:0, 
                    s2:s.selected_option["한열상태"].includes("2")?1:0, 
                    s3:s.selected_option["한열상태"].includes("3")?1:0,
                    s4:s.selected_option["한열상태"].includes("4")?1:0,
                    s5:s.selected_option["한열상태"].includes("5")?1:0,
                    s6:s.selected_option["한열상태"].includes("6")?1:0},
                q17: {s1: s.selected_option["한열상태(얼굴무릎)"].includes("1")?1:0, 
                    s2:s.selected_option["한열상태(얼굴무릎)"].includes("2")?1:0, 
                    s3:s.selected_option["한열상태(얼굴무릎)"].includes("3")?1:0,
                    },
                q18: Number(s.single_selected_option["대변"]),
                q19: {s1: s.selected_option["소변"].includes("1")?1:0, 
                    s2:s.selected_option["소변"].includes("2")?1:0, 
                    s3:s.selected_option["소변"].includes("3")?1:0},
            }
          }
          resolve(r)
        }
    );

    const dispatch = useDispatch();

    // console.log(newsurveydata)

    useEffect(()=>{
        const Submit = async () => {
            const data = await transformData() as t.SurveyResultRawType;
            // const data = dummyRaw;
            // console.log(data)
            // send server with data
            let res;
            try {
              res = await newsurveyApi.createSurvey(data);
            } catch (e) {
              console.log(e);
              setLoadingState(2);
            } finally {
              if (res) {
                // console.log(res.data)
                setSurvey(res.data);
                setLoadingState(1);
              }
            }
        
            // reset survey store
            // dispatch(restSurveyStore());
        };
        // setSurvey(dummy)
        // setLoadingState(1);
        Submit();
    }, []);

    const calcDiagStatus = () => {
        // 0: 정상, 1: 생성, 2: 순환, 3: 균형
        const minscore = Math.min(survey.AScore, survey.BScore, survey.CScore)
        if(survey.BScore < 85 && survey.BScore === minscore)
            return 1;
        if(survey.CScore < 85 && survey.CScore === minscore)
            return 3;
        if(survey.AScore < 85 && survey.AScore === minscore)
            return 2;
        return 0;
    };

    const diagStatus = calcDiagStatus();

    const createDietGuide = () => {
        let cards = dietCreateCards;
        let content = '';

        if(diagStatus === 0){
            content = ``;
        }
            
        if(diagStatus === 1){
            content = t('result.cont.diet_guide_create');
            cards = dietCreateCards;
        }
            
        if(diagStatus === 2){
            content = t('result.cont.diet_guide_circulation');
            cards = dietCycleCards;
        }
            
        if(diagStatus === 3){
            content = t('result.cont.diet_guide_balance');
            cards = dietBalanceCards;
        }
            

        const guide : t.ManagementGuidType = {
            name: survey.name,
            image: imgfood,
            title: t('result.cont.diet_title'),
            content,
            subtitle: t('result.cont.diet_subtitle'),
            subcomment: t('result.cont.diet_subcomment'),
            cards: [...cards]
        }
        return guide;
    }

    const createExercieseGuide = () => {
        let cards = exerciseCreateCards;
        let content = '';

        if(diagStatus === 0){
            content = ``;
        }
            
        if(diagStatus === 1){
            content = t('result.cont.exercise_guide_create');
            cards = exerciseCreateCards;
        }
            
        if(diagStatus === 2){
            content = t('result.cont.exercise_guide_circulation');
            cards = exerciseCycleCards;
        }
            
        if(diagStatus === 3){
            content = t('result.cont.exercise_guide_balance');
            cards = exerciseBalanceCards;
        }
        const guide : t.ManagementGuidType = {
            name: survey.name,
            image: imgexercise,
            title: t('result.cont.exercise_title'),
            content,
            subtitle: t('result.cont.exercise_subtitle'),
            subcomment: t('result.cont.exercise_subcomment'),
            cards: [...cards]
        }
        return guide;
    }

    const createSleepGuide = () => {
        const cards = sleepCreateCards;
        let content = '';

        if(diagStatus === 0){
            content = ``;
        }
            
        if(diagStatus === 1){
            content = t('result.cont.sleep_guide_create');
        }
            
        if(diagStatus === 2){
            content = t('result.cont.sleep_guide_circulation');
        }
            
        if(diagStatus === 3){
            content = t('result.cont.sleep_guide_balance');
        }
        const guide : t.ManagementGuidType = {
            name: survey.name,
            image: imgsleep,
            title: t('result.cont.sleep_title'),
            content,
            subtitle: t('result.cont.sleep_subtitle'),
            subcomment: t('result.cont.sleep_subcomment'),
            cards: [...cards]
        }
        return guide;
    }

    const createDiagSummary = () => {
        const diseaseList = (dCategory : string, maxIdx : number) => {
            const dlist = []
            for(let i = 1; i <= maxIdx; i += 1){
                dlist.push(t(`result.cont.${dCategory}.d${i}`));
            }
            return dlist;
        }
        if(diagStatus === 0)
            return {
                summary: t('result.cont.diag_summary_normal'),
                disease:[],
                care:``
        };
        if(diagStatus === 1)
            return {
                summary: t('result.cont.diag_summary_create'),
                disease:diseaseList('disease_create', 14),
                care: t('result.cont.care_create')
        };
        if(diagStatus === 2)
            return {
                summary: t('result.cont.diag_summary_circulation'),
                disease:diseaseList('disease_circulation', 9),
                care:t('result.cont.care_circulation')
        };
        if(diagStatus === 3)
            return {
                summary: t('result.cont.diag_summary_balance'),
                disease:diseaseList('disease_balance', 13),
                care:t('result.cont.care_balance')
        };
        return {
            summary: ``,
            disease:[],
            care:``
        };

    }

    const guides : t.AllManagementGuidType = {
        diet: createDietGuide(),
        exercise: createExercieseGuide(),
        sleep: createSleepGuide(),
        diagSummary: createDiagSummary().summary,
        predictedDisease: createDiagSummary().disease,
        care: createDiagSummary().care,
        kmedicine: [...kmedicineCards],
        status: diagStatus
    }

    return (
        <SurveyResultPresenter result={survey} guides={guides} loadingState={loadingState}/>
    );

}