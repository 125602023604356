// export const profile = 'server';
export const profile = "local";

// server
/*
export const API_SERVER = 'https://api.oriental-silo.com';
export const BASE_URL = 'https://oriental-silo.com';
*/
export const API_SERVER = 'https://ljdacu.gabia.io/';

// local
// export const API_SERVER = "http://211.47.75.53";
// export const API_SERVER = "http://localhost:8080";
export const BASE_URL = "http://localhost:8080";
