import React from 'react';
import { useTranslation } from 'react-i18next';
import { blue } from '@mui/material/colors';
import Divider from '@mui/material/Divider';
import * as s from './NewSurveyStyled';
import breakfast from '../../../asset/img/survey/breakfast.png';

interface AppetiteProps {
  onNext: () => void;
  onScore: (key: string, score: number) => void;
  selectedOption: string;
  onOptionSelect: (option: string) => void;
}

const Appetite: React.FC<AppetiteProps> = ({ onNext, onScore, selectedOption, onOptionSelect }) => {
  const { t } = useTranslation();
  
  return (
    <s.SurveyContainer>
      <s.Question>{t('survey.appetite.question')}</s.Question>
      <s.PictureContainer>
        <s.Picture src={breakfast} style={{width: "clamp(20rem, 25vw, 25rem)", marginBottom: "1rem"}} alt="종아리둘레" />
      </s.PictureContainer>
      <s.OptionContainer className="OptionContainer">
        <s.Options>
          <s.OptionRadioContainer>
            <s.OptionRadio
              value={1}
              onChange={() => {
                onOptionSelect('1');
                onScore('ebProduce', -1);
              }}
              checked={selectedOption === '1'}
              sx={{
                color: blue[300],
              }}
            />
            <s.OptionTitle>{t('survey.appetite.option1')}</s.OptionTitle>
          </s.OptionRadioContainer>
          <Divider orientation="vertical" flexItem />
          <s.OptionRadioContainer>
            <s.OptionRadio
              value={2}
              onChange={() => {
                onOptionSelect('2');
                onNext();
              }}
              checked={selectedOption === '2'}
              sx={{
                color: blue[600],
              }}
            />
            <s.OptionTitle>{t('survey.appetite.option2')}</s.OptionTitle>
          </s.OptionRadioContainer>
          <Divider orientation="vertical" flexItem />
          <s.OptionRadioContainer>
            <s.OptionRadio
              value={3}
              onChange={() => {
                onOptionSelect('3');
                onNext();
              }}
              checked={selectedOption === '3'}
              sx={{
                color: blue[900],
              }}
            />
            <s.OptionTitle>{t('survey.appetite.option3')}</s.OptionTitle>
          </s.OptionRadioContainer>
        </s.Options>
      </s.OptionContainer>
    </s.SurveyContainer>
  );
};

export default Appetite;
