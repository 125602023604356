import React from 'react';
import { useTranslation } from 'react-i18next';
import * as s from './NewSurveyStyled';
import clock from '../../../asset/img/survey/clock.png';

interface SleepAmountProps {
  onNext: () => void;
  onScore: (key: string, score: number) => void;
  selectedOption: string;
  onOptionSelect: (option: string) => void;
}

const SleepAmount: React.FC<SleepAmountProps> = ({ onNext, onScore, selectedOption, onOptionSelect }) => {
  const { t } = useTranslation();
  
  return (
    <s.SurveyContainer>
      <s.Question>{t('survey.sleep_amount.question')}</s.Question>
      <s.PictureContainer>
        <s.Picture src={clock} />
      </s.PictureContainer>
      <s.OptionContainer>
        <s.Options>
          <s.OptionButton
            type="button"
            variant={selectedOption === '1' ? 'contained' : 'outlined'}
            onClick={() => {
              onOptionSelect('1');
              onScore('ebBalanceControl', -1);
            }}
            style={{ height: '100px' }}
          >
            <s.OptionTitle>{t('survey.sleep_amount.option1')}</s.OptionTitle>
            <s.OptionSubtitle>{t('survey.sleep_amount.sub_option1')}</s.OptionSubtitle>
          </s.OptionButton>
          <s.OptionButton
            type="button"
            variant={selectedOption === '2' ? 'contained' : 'outlined'}
            onClick={() => {
              onOptionSelect('2');
              onNext();
            }}
            style={{ height: '100px' }}
          >
            <s.OptionTitle>{t('survey.sleep_amount.option2')}</s.OptionTitle>
            <s.OptionSubtitle>{t('survey.sleep_amount.sub_option2')}</s.OptionSubtitle>
          </s.OptionButton>
        </s.Options>
      </s.OptionContainer>
    </s.SurveyContainer>
  );
};

export default SleepAmount;
