import React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Paper,
  Divider,
  Button,
  List,
  ListItem,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ListItemAvatar,
  Avatar,
  Link,
} from '@mui/material';
import LocalPharmacyIcon from '@mui/icons-material/LocalPharmacy';
import BalanceIcon from '@mui/icons-material/Balance';
import MedicationIcon from '@mui/icons-material/Medication';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ResponsiveTypography from './ResponsiveTypography';
import ingredient1 from '../../../asset/img/사향.png';
import ingredient2 from '../../../asset/img/녹용.png';
import ingredient3 from '../../../asset/img/산수유.png';
import ingredient4 from '../../../asset/img/당귀.png';
import ingredient5 from '../../../asset/img/공진단.png';
import ingredient6 from '../../../asset/img/감비산.png';
import needle from '../../../asset/img/needle.png';

const ContentWrapper = styled.div`
  background-color: white;
  padding: 2rem;
`;

const SectionTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 10px;
`;

const Text = styled.p`
  line-height: 1.6;
  margin-bottom: 20px;
`;

const ButtonGroup = styled.div`
  display: flex;
  margin: 2rem 0 1rem;
`;

const TabButton = styled(Button)<{ active: boolean }>`
  && {
    font-family: 'Noto Sans KR', sans-serif;
    font-size: 1rem;
    margin: 0 0.5rem;
    background-color: ${(props) => (props.active ? '#0f67fe' : 'white')};
    color: ${(props) => (props.active ? 'white' : '#0f67fe')};
  }

  @media (max-width: 991px) {
    && {
      font-size: 0.8rem;
    }
  }
`;

const StyledCard = styled(Card)`
  max-width: 800px;
  margin: 32px auto;
  background-color: #f5f5f5;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
`;

const StyledPaper = styled(Paper)`
  padding: 24px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  align-items: center;
`;

const CustomListItem = styled(ListItem)`
  && {
    @media (max-width: 991px) {
      flex-direction: column;
    }
  }
`;

const Title = styled(Typography)`
  && {
    font-family: 'Noto Sans KR', sans-serif;
    font-weight: bold;
    font-size: 1.5rem;
    margin-bottom: 16px;
  }
`;

const Image = styled.img`
  width: 25%;
  height: 25%;
  padding-bottom: 1rem;

  @media (max-width: 991px) {
    width: 50%;
    height: 50%;
  }
`;

const Image2 = styled.img`
  width: 50%;
  height: 50%;
  padding-bottom: 1rem;

  @media (max-width: 991px) {
    width: 70%;
    height: 70%;
  }
`;

const ImageContentWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  @media (max-width: 991px) {
    flex-direction: column;
  }
`;

const OrientalSection: React.FC = () => {
  const [activeTab, setActiveTab] = useState<'한약치료법' | '봉독약침요법'>('한약치료법');

  return (
    <ContentWrapper>
      <SectionTitle>한의학이란?</SectionTitle>
      <Text>
        한의학은 기혈(氣血)이라는 일상 생명활동에 필요한 에너지를 조절하여 질병을 치료하는 학문입니다.
        <br />
        <Typography fontSize="0.9rem" color="text.secondary">
          ※ 기혈 에너지는 몸 속에 혈액, 신경, 호르몬, 림프액, 전기와 자기장이 기능을 포괄하는 개념입니다.
        </Typography>
      </Text>
      <ButtonGroup>
        <TabButton
          active={activeTab === '한약치료법'}
          onClick={() => setActiveTab('한약치료법')}
          variant="contained"
          style={{ margin: '0 0.5rem 0 0' }}
        >
          한약치료법
        </TabButton>
        <TabButton
          active={activeTab === '봉독약침요법'}
          onClick={() => setActiveTab('봉독약침요법')}
          variant="contained"
        >
          봉독약침요법
        </TabButton>
      </ButtonGroup>
      {activeTab === '한약치료법' && (
        <StyledCard>
          <CardContent>
            <Title variant="h4" align="center" gutterBottom>
              한약치료법
            </Title>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <StyledPaper elevation={3}>
                  <IconWrapper>
                    <LocalPharmacyIcon style={{ fontSize: 40, color: '#4caf50' }} />
                  </IconWrapper>
                  <Typography variant="h6" gutterBottom align="center" fontWeight="700">
                    표증치료약
                  </Typography>
                  <Typography variant="body2" align="center" color="text.secondary">
                    질병이나 증상 중심의 치료약으로, 특정 증상에 대응하는 한약입니다. 예: 두통약, 요통약, 관절염약 등
                  </Typography>
                </StyledPaper>
              </Grid>
              <Grid item xs={12} md={6}>
                <StyledPaper elevation={3}>
                  <IconWrapper>
                    <BalanceIcon style={{ fontSize: 40, color: '#2196f3' }} />
                  </IconWrapper>
                  <Typography variant="h6" gutterBottom align="center" fontWeight="700">
                    본증치료약
                  </Typography>
                  <Typography variant="body2" align="center" color="text.secondary">
                    몸중심의 치료약으로, 몸 에너지의 생성과 순환, 균형 기능을 근본적으로 개선하여 질병을 치료하는
                    한약입니다. 예: 개인별 에너지 맞춤 탕약
                  </Typography>
                </StyledPaper>
              </Grid>
            </Grid>
            <Divider sx={{ my: 4, opacity: 0 }} />
            <StyledPaper elevation={3}>
              {/* <IconWrapper>
                <MedicationIcon style={{ fontSize: 40, color: '#ff9800' }} />
              </IconWrapper> */}
              <Image src={ingredient6} alt="감비산" />
              <Typography variant="h6" gutterBottom align="center" fontWeight="700">
                노비노 탕약
              </Typography>
              <Typography variant="body2" align="center" color="text.secondary">
                경희 의료원 한방 병원에서 700만 건 이상 처방된{' '}
                <Link
                  href="https://m.post.naver.com/viewer/postView.nhn?volumeNo=27449510&memberNo=15001358"
                  underline="none"
                >
                  감비산
                </Link>
                (한슬림: 특허 제 10-1071294)을 기반으로 개인의 에너지 상태에 따라 처방된 본증치료 한약입니다.
              </Typography>
            </StyledPaper>

            <Divider sx={{ my: 3, opacity: 0 }} />

            <Title variant="h4" align="center">
              한약의 대표 보약 : 공진단
            </Title>
            <StyledPaper elevation={3}>
              <Typography variant="body2" color="textSecondary">
                공진단은 예로부터 황실에서 사용해오던 대표적인 처방으로 최고의 에너지가 함유된 약재로 구성되어 있습니다.
                특히, 사향은 막힌 경락을 뚫어주는 작용이 탁월하여 모든 기관에 에너지 공급을 원활하게 해줍니다.
              </Typography>
              <Typography variant="subtitle1" style={{ marginTop: '16px', fontWeight: 'bold' }}>
                주요 성분 및 효능:
              </Typography>
              <List>
                <CustomListItem>
                  <ListItemAvatar>
                    <Avatar alt="사향" src={ingredient1} sx={{ width: 80, height: 80, marginRight: '1rem' }} />
                  </ListItemAvatar>
                  <ListItemText
                    primary="사향"
                    secondary="대표 성분인 무스콘은 중추신경 기능을 강화하며, 질환에 대한 저항력을 높이고 정신을 맑게 합니다."
                  />
                </CustomListItem>
                <CustomListItem>
                  <ListItemAvatar>
                    <Avatar alt="녹용" src={ingredient2} sx={{ width: 80, height: 80, marginRight: '1rem' }} />
                  </ListItemAvatar>
                  <ListItemText
                    primary="녹용"
                    secondary="구성 성분 중 판토크린과 프로테오리피드는 면역력을 증강시킵니다."
                  />
                </CustomListItem>
                <CustomListItem>
                  <ListItemAvatar>
                    <Avatar alt="산수유" src={ingredient3} sx={{ width: 80, height: 80, marginRight: '1rem' }} />
                  </ListItemAvatar>
                  <ListItemText
                    primary="산수유"
                    secondary="비타민, 유기산이 풍부하며 부교감신경을 활성화하고 생리 대사를 촉진합니다. 해독, 살균, 지혈, 항암, 항염증, 항스트레스 효과가 있습니다."
                  />
                </CustomListItem>
                <CustomListItem>
                  <ListItemAvatar>
                    <Avatar alt="당귀" src={ingredient4} sx={{ width: 80, height: 80, marginRight: '1rem' }} />
                  </ListItemAvatar>
                  <ListItemText
                    primary="당귀"
                    secondary="데쿠르신(decursin) 성분으로 심혈관 순환계 증상을 개선하고 콜레스테롤 흡수를 억제하여 중풍, 고혈압 등 성인병 예방에 도움이 됩니다."
                  />
                </CustomListItem>
              </List>
              <Typography variant="subtitle1" style={{ marginTop: '16px', fontWeight: 'bold' }}>
                공진단의 종류:
              </Typography>
              <ImageContentWrapper>
                <Image src={ingredient5} alt="공진단" />
                <List>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Link href="https://naver.me/x4a6VP8K" underline="none">
                          보골공진단
                        </Link>
                      }
                      secondary="근육과 뼈를 강화시켜 주는 약재들이 보강되어 허리통증이나 관절염 등의 근골격계 질환에 효과적입니다."
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="보위공진단"
                      secondary="비위를 강화시키고 소화를 돕는 약재를 추가하여 소화불량과 식욕부진 등의 소화기 증상에 효과적입니다."
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="청뇌공진단"
                      secondary="머리 쪽의 에너지 순환을 도와주는 약재를 추가하여 집중력 저하, 두통, 어지럼증, 불안, 학습능력 저하 등의 증상에 효과적입니다."
                    />
                  </ListItem>
                </List>
              </ImageContentWrapper>
            </StyledPaper>
          </CardContent>
        </StyledCard>
      )}
      {activeTab === '봉독약침요법' && (
        <StyledCard>
          <CardContent>
            <Title variant="h5" align="center">
              봉독약침요법
            </Title>
            <StyledPaper elevation={3}>
              {/* <IconWrapper>
                <LocalHospitalIcon style={{ fontSize: 40, color: '#4caf50' }} />
              </IconWrapper> */}
              <Image2 src={needle} alt="needle" />
              <Typography variant="body2" color="textSecondary">
                봉독약침은 예로부터 민간요법으로 사용해오던 벌침을 과학적인 방법으로 안전하고 효과적으로 발전시킨
                약침요법입니다.
              </Typography>
              <Typography variant="body2" color="textSecondary" style={{ margin: '16px 0' }}>
                한의학적 작용기전: 침의 효과, 뜸의 효과, 부항의 효과를 동시에 가지고 있으며, 특허(특허 제 10-1146718)
                개발된 약침입니다.
              </Typography>

              <Accordion style={{width: '100%'}}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                    봉독의 효능
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2" color="textSecondary">
                    아파민, 멜리틴 등 40여 가지의 인체에 유익한 성분으로 구성되어 있으며, 다음과 같은 효과가 있습니다:
                  </Typography>
                  <List>
                    <ListItem>
                      <ListItemText primary="항염증작용" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="면역기능 조절" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="신경장애 개선" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="혈액순환 개선" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="호르몬 분비 촉진" />
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>

              <Accordion style={{width: '100%'}}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                    치료방법
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2" color="textSecondary">
                    - 일주일에 1-2회 시술
                    <br />
                    - 15회 치료를 1단계로 시행
                    <br />
                    - 1단계 치료효과 평가를 진행한 후 2, 3단계 시술을 진행합니다.
                    <br />- 2단계, 3단계 시술 가능
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion style={{width: '100%'}}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                    적응질환
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2" color="textSecondary">
                    봉독은 일종의 천연 소염진통제로 다음과 같은 질환에 효과적입니다:
                  </Typography>
                  <List>
                    <ListItem>
                      <ListItemText primary="목, 허리의 디스크와 협착증 등 모든 척추질환" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="무릎, 어깨 통증 및 관절염" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="류마티스를 비롯한 각종 자가면역 질환" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="암" />
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>
            </StyledPaper>
          </CardContent>
        </StyledCard>
      )}
    </ContentWrapper>
  );
};

export default OrientalSection;
