import React from 'react';
import SurveyForm from '../common/SurveyForm';
import WaistCircumference from '../WaistCircumference';
import BodyDevelopment from '../BodyDevelopment';
import CalfCircumference from '../CalfCircumference';
import RibAngle from '../RibAngle';
import PulseRate from '../PulseRate';
import Appetite from '../Appetite';
import NumberOfMeals from '../NumberOfMeals';
import AmountPerMeal from '../AmountPerMeal';
import Indigestion from '../Indigestion';
import EnoughSleep from '../EnoughSleep';
import SleepTime from '../SleepTime';
import RegularSleepTime from '../RegularSleepTime';
import SleepAmount from '../SleepAmount';
import Condition from '../Condition';
import Personality from '../Personality';
import HeatCondition from '../HeatCondition';
import HeatConditionDetail from '../HeatConditionDetail';
import Poop from '../Poop';
import Pee from '../Pee';

type StepType =
  | '목둘레'
  | '상하체발달'
  | '종아리둘레'
  | '늑골각'
  | '맥박수'
  | '식욕'
  | '식사횟수'
  | '1회식사량'
  | '소화불량'
  | '충분한수면'
  | '입면시간'
  | '규칙적인입출면시간'
  | '규칙적인총수면시간'
  | '기상시상태와평소활동량'
  | '성격'
  | '한열상태'
  | '한열상태(얼굴무릎)'
  | '대변'
  | '소변'
  | '질환및증상'
  | '발병시기및계기'
  | '과거력'
  | '비만약'
  | '기호식품';

type NewSurveyPresenterProps = {
  step: StepType;
  totalSteps: number;
  currentStepIndex: number;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
  goToPath: (path: string) => () => void;
  privacyData: any;
  data: any;
  handleScore: (key: string, score: number) => void;
  selectedOptions: string[];
  singleSelectedOption: string;
  handleMultipleOptionSelect: (option: string) => void;
  handleSingleOptionSelect: (option: string) => void;
  isSelected: (selectedOptionss: string[], option: string) => boolean;
  isAllSelected: () => boolean;
};

export default function NewSurveyPresenter({
  step,
  totalSteps,
  currentStepIndex,
  goToNextStep,
  goToPreviousStep,
  goToPath,
  privacyData,
  data,
  handleScore,
  selectedOptions,
  singleSelectedOption,
  handleMultipleOptionSelect,
  handleSingleOptionSelect,
  isSelected,
  isAllSelected,
}: NewSurveyPresenterProps) {
  // const controlProps = () => ({
  //   onNext: goToNextStep,
  //   onScore: handleScore,
  //   selectedOptions: {selectedOptions},
  //   onOptionSelect: handleOptionSelect,
  // });

  const add = data.isHealthCare;

  return (
    <main>
      <SurveyForm
        progress={(currentStepIndex + 1) / totalSteps}
        totalSteps={totalSteps}
        onNext={goToNextStep}
        onPrev={goToPreviousStep}
      >
        {step === '목둘레' && (
          <WaistCircumference
            onNext={goToNextStep}
            onScore={handleScore}
            gender={data.gender}
            selectedOption={singleSelectedOption}
            onOptionSelect={handleSingleOptionSelect}
          />
        )}
        {step === '상하체발달' && (
          <BodyDevelopment
            onNext={goToNextStep}
            onScore={handleScore}
            selectedOption={singleSelectedOption}
            onOptionSelect={handleSingleOptionSelect}
          />
        )}
        {step === '종아리둘레' && (
          <CalfCircumference
            onNext={goToNextStep}
            onScore={handleScore}
            selectedOption={singleSelectedOption}
            onOptionSelect={handleSingleOptionSelect}
          />
        )}
        {step === '늑골각' && (
          <RibAngle
            onNext={goToNextStep}
            onScore={handleScore}
            selectedOption={singleSelectedOption}
            onOptionSelect={handleSingleOptionSelect}
          />
        )}
        {step === '맥박수' && (
          <PulseRate
            onNext={goToNextStep}
            onScore={handleScore}
            selectedOption={singleSelectedOption}
            onOptionSelect={handleSingleOptionSelect}
          />
        )}
        {step === '식욕' && (
          <Appetite
            onNext={goToNextStep}
            onScore={handleScore}
            selectedOption={singleSelectedOption}
            onOptionSelect={handleSingleOptionSelect}
          />
        )}
        {step === '식사횟수' && (
          <NumberOfMeals
            onNext={goToNextStep}
            onScore={handleScore}
            selectedOption={singleSelectedOption}
            onOptionSelect={handleSingleOptionSelect}
          />
        )}
        {step === '1회식사량' && (
          <AmountPerMeal
            onNext={goToNextStep}
            onScore={handleScore}
            selectedOption={singleSelectedOption}
            onOptionSelect={handleSingleOptionSelect}
          />
        )}
        {step === '소화불량' && (
          <Indigestion
            onNext={goToNextStep}
            onScore={handleScore}
            selectedOption={singleSelectedOption}
            onOptionSelect={handleSingleOptionSelect}
          />
        )}
        {step === '충분한수면' && (
          <EnoughSleep
            onNext={goToNextStep}
            onScore={handleScore}
            selectedOption={singleSelectedOption}
            onOptionSelect={handleSingleOptionSelect}
          />
        )}
        {step === '입면시간' && (
          <SleepTime
            onNext={goToNextStep}
            onScore={handleScore}
            selectedOption={singleSelectedOption}
            onOptionSelect={handleSingleOptionSelect}
          />
        )}
        {step === '규칙적인입출면시간' && (
          <RegularSleepTime
            onNext={goToNextStep}
            onScore={handleScore}
            selectedOption={singleSelectedOption}
            onOptionSelect={handleSingleOptionSelect}
          />
        )}
        {step === '규칙적인총수면시간' && (
          <SleepAmount
            onNext={goToNextStep}
            onScore={handleScore}
            selectedOption={singleSelectedOption}
            onOptionSelect={handleSingleOptionSelect}
          />
        )}
        {step === '기상시상태와평소활동량' && (
          <Condition
            onNext={goToNextStep}
            onScore={handleScore}
            selectedOption={selectedOptions}
            onOptionSelect={handleMultipleOptionSelect}
            isSelected={isSelected}
          />
        )}
        {step === '성격' && (
          <Personality
            onNext={goToNextStep}
            onScore={handleScore}
            selectedOption={selectedOptions}
            onOptionSelect={handleMultipleOptionSelect}
            isSelected={isSelected}
          />
        )}
        {step === '한열상태' && (
          <HeatCondition
            onNext={goToNextStep}
            onScore={handleScore}
            selectedOption={selectedOptions}
            onOptionSelect={handleMultipleOptionSelect}
            isSelected={isSelected}
          />
        )}
        {step === '한열상태(얼굴무릎)' && (
          <HeatConditionDetail
            onNext={goToNextStep}
            onScore={handleScore}
            selectedOption={selectedOptions}
            onOptionSelect={handleMultipleOptionSelect}
            isSelected={isSelected}
          />
        )}
        {step === '대변' && (
          <Poop
            onNext={goToNextStep}
            onScore={handleScore}
            selectedOption={singleSelectedOption}
            onOptionSelect={handleSingleOptionSelect}
          />
        )}
        {step === '소변' && (
          <Pee
            onNext={add ? goToPath('/health-care') : goToPath('/survey-user-result')}
            onScore={handleScore}
            selectedOption={selectedOptions}
            onOptionSelect={handleMultipleOptionSelect}
            isSelected={isSelected}
            isAllSelected={isAllSelected}
            isHealthCare={add}
          />
        )}
      </SurveyForm>
    </main>
  );
}
