import React, { useEffect } from 'react';
import { SurveyTitle, BtnPreNext, SelectAndAnswer, SelectAndAnswerDrink } from '../common/Survey';
import { ISurveyDictType } from '../common/SurveyTypes';
import '../common/Survey.scss';

interface IFavFodPageProps {
  dictObject: ISurveyDictType;
  validForm: IValidData;
}

interface IValidData {
  favorite_food_cigarette: boolean;
  favorite_food_drink: boolean;
  favorite_food_caffeine: boolean;
}

export default function SurveyFavFodPresenter(Props: IFavFodPageProps) {
  const { validForm, dictObject } = Props;

  return (
    <>
      <SurveyTitle title="※ 기호식품" />
      <div className="title-container">
        <div className="title-text">해당사항을 체크하고, 해당한다면 양을 적어주세요.</div>
      </div>
      <SelectAndAnswer
        qTitle="favorite_food_cigarette"
        title="담배를 피운다. *"
        content="흡연량"
        unit="개비/1일"
        questions={['아니다', '피운다']}
        defaultValue={dictObject.favorite_food_cigarette}
        defaultValueText={dictObject.favorite_food_cigarette_1}
      />
      <SelectAndAnswerDrink
        defaultValue={dictObject.favorite_food_drink}
        defaultValueText={dictObject.favorite_food_drink_1}
        defaultValueText2={dictObject.favorite_food_drink_2}
      />
      <SelectAndAnswer
        qTitle="favorite_food_caffeine"
        title="커피, 녹차를 마신다. *"
        content="섭취량"
        unit="잔/1일"
        questions={['아니다', '마신다']}
        defaultValue={dictObject.favorite_food_caffeine}
        defaultValueText={dictObject.favorite_food_caffeine_1}
      />
      <BtnPreNext
        linkPre="../survey-dgnsis"
        linkNex="../survey-bdyshp"
        disabled={
          !(validForm.favorite_food_caffeine && validForm.favorite_food_cigarette && validForm.favorite_food_drink)
        }
      />
    </>
  );
}
