import React from 'react';
import { useTranslation } from 'react-i18next';
import { blue } from '@mui/material/colors';
import Divider from '@mui/material/Divider';
import * as s from './NewSurveyStyled';
import digestion from '../../../asset/img/survey/digestion2.png';

interface IndigestionProps {
  onNext: () => void;
  onScore: (key: string, score: number) => void;
  selectedOption: string;
  onOptionSelect: (option: string) => void;
}

const Indigestion: React.FC<IndigestionProps> = ({ onNext, onScore, selectedOption, onOptionSelect }) => {
  const { t } = useTranslation();
  
  return (
    <s.SurveyContainer>
      <s.Question>{t('survey.indigestion.question')}</s.Question>
      <s.PictureContainer>
        <s.Picture src={digestion} alt="digestion" />
      </s.PictureContainer>
      <s.OptionContainer className="OptionContainer">
        <s.Options>
          <s.OptionRadioContainer>
            <s.OptionRadio
              value={1}
              onChange={() => {
                onOptionSelect('1');
                onScore('ebProduce', -3);
              }}
              checked={selectedOption === '1'}
              sx={{
                color: blue[300],
              }}
            />
            <s.OptionTitle>{t('survey.indigestion.option1')}</s.OptionTitle>
          </s.OptionRadioContainer>
          {/* <Divider orientation="vertical" flexItem /> */}
          <s.OptionRadioContainer>
            <s.OptionRadio
              value={2}
              onChange={() => {
                onOptionSelect('2');
                onScore('ebProduce', -2);;
              }}
              checked={selectedOption === '2'}
              sx={{
                color: blue[500],
              }}
            />
            <s.OptionTitle>{t('survey.indigestion.option2')}</s.OptionTitle>
          </s.OptionRadioContainer>
          {/* <Divider orientation="vertical" flexItem /> */}
          <s.OptionRadioContainer>
            <s.OptionRadio
              value={3}
              onChange={() => {
                onOptionSelect('3');
                onScore('ebProduce', -1);
              }}
              checked={selectedOption === '3'}
              sx={{
                color: blue[700],
              }}
            />
            <s.OptionTitle>{t('survey.indigestion.option3')}</s.OptionTitle>
          </s.OptionRadioContainer>
          {/* <Divider orientation="vertical" flexItem /> */}
          <s.OptionRadioContainer>
            <s.OptionRadio
              value={4}
              onChange={() => {
                onOptionSelect('4');
                onNext();
              }}
              checked={selectedOption === '4'}
              sx={{
                color: blue[900],
              }}
            />
            <s.OptionTitle>{t('survey.indigestion.option4')}</s.OptionTitle>
          </s.OptionRadioContainer>
        </s.Options>
      </s.OptionContainer>
    </s.SurveyContainer>
  );
};

export default Indigestion;
