import React from 'react';
import { useTranslation } from 'react-i18next';
import * as s from './GuideCardsStyle';
import imgbean from '../../../../asset/img/bean.png'
import imgbicycle from '../../../../asset/img/bicycle.png'
import imgbread from '../../../../asset/img/bread.png'
import imgbreathing from '../../../../asset/img/breathing.png'
import imgchicken from '../../../../asset/img/chicken.png'
import imgdrink from '../../../../asset/img/drink.png'
import imgfilates from '../../../../asset/img/filates.png'
import imgfish from '../../../../asset/img/fish.png'
import imgflour from '../../../../asset/img/flour.png'
import imgfoot from '../../../../asset/img/foot.png'
import imgfruit from '../../../../asset/img/fruit.png'
import imghiking from '../../../../asset/img/hiking.png'
import imgjogging from '../../../../asset/img/jogging.png'
import imgkick from '../../../../asset/img/kick.png'
import imgmeat from '../../../../asset/img/meat.png'
import imgnoodle from '../../../../asset/img/noodle.png'
import imgmilk from '../../../../asset/img/milk.png'
import imgpork from '../../../../asset/img/pork.png'
import imgramen from '../../../../asset/img/ramen.png'
import imgseaweed from '../../../../asset/img/seaweed.png'
import imgsquart from '../../../../asset/img/squart.png'
import imgstairs from '../../../../asset/img/stairs.png'
import imgstrecing from '../../../../asset/img/streching.png'
import imgswim from '../../../../asset/img/swim.png'
import imgtofu from '../../../../asset/img/tofu.png'
import imgtteokbokki from '../../../../asset/img/tteokbokki.png'
import imgwalking from '../../../../asset/img/walking.png'
import imgyoga from '../../../../asset/img/yoga.png'
import imgrice from '../../../../asset/img/rice.png'
import imgricecake from '../../../../asset/img/ricecake.png'
import imgfry from '../../../../asset/img/fry.png'
import imgcoffee from '../../../../asset/img/coffee.png'
import imgtea from '../../../../asset/img/tea.png'


// import  from '../../../../asset/img'

export function dietEnergyCreate1(){
    return(
        <s.PageContainer>
            <s.TitleWrapper style={{marginTop: '0px'}}>
                <s.TitleGood>권장 음식</s.TitleGood>
            </s.TitleWrapper>
            
            <s.TitleWrapper>
                <s.TitleBad>피할 음식</s.TitleBad>
            </s.TitleWrapper>
            <div>
                <s.ContentCard>
                    <s.ContentCardImage src={imgbread}/>
                    <s.ContentCardText>밀가루 음식</s.ContentCardText>
                </s.ContentCard>
            </div>
        </s.PageContainer>
    );
}

export function dietCreateRecommand(){
    const { t, i18n } = useTranslation();
    return(
        <s.PageContainer>
            <s.TitleWrapper style={{marginTop: '0px'}}>
                <s.TitleGood>{t('result.card.diet_create.g_t1')}</s.TitleGood>
            </s.TitleWrapper>
            <s.ContentTextContainer>
                <s.ContentText>{t('result.card.diet_create.g_c1')}</s.ContentText>
            </s.ContentTextContainer>
            {/* <s.TitleWrapper>
                <s.TitleGood>단백질 위주의 음식</s.TitleGood>
            </s.TitleWrapper>          
            <s.ContentCardContainer>
                <s.ContentCard>
                    <s.ContentCardImage src={imgmeat}/>
                    <s.ContentCardText> </s.ContentCardText>
                </s.ContentCard>
                <s.ContentCard>
                    <s.ContentCardImage src={imgfish}/>
                    <s.ContentCardText> </s.ContentCardText>
                </s.ContentCard>
                <s.ContentCard>
                    <s.ContentCardImage src={imgbean}/>
                    <s.ContentCardText> </s.ContentCardText>
                </s.ContentCard>
            </s.ContentCardContainer> */}
        </s.PageContainer>
    );
}

export function dietCreateAvoid(){
    const { t, i18n } = useTranslation();
    return(
        <s.PageContainer>
            <s.TitleWrapper style={{marginTop: '0px'}}>
                <s.TitleBad>{t('result.card.diet_create.b_t1')}</s.TitleBad>
            </s.TitleWrapper>
            <s.ContentCardContainer>
                <s.ContentCardImgOnly>
                    <s.ContentCardImage src={imgbread}/>
                    {/* <s.ContentCardText> </s.ContentCardText> */}
                </s.ContentCardImgOnly>
                <s.ContentCardImgOnly>
                    <s.ContentCardImage src={imgflour}/>
                    {/* <s.ContentCardText> </s.ContentCardText> */}
                </s.ContentCardImgOnly>
                <s.ContentCardImgOnly>
                    <s.ContentCardImage src={imgnoodle}/>
                    {/* <s.ContentCardText> </s.ContentCardText> */}
                </s.ContentCardImgOnly>
            </s.ContentCardContainer>
            <s.TitleWrapper>
                <s.TitleBad>{t('result.card.diet_create.b_t2')}</s.TitleBad>
            </s.TitleWrapper>
            <s.ContentCardContainer>
                <s.ContentCard>
                    <s.ContentCardImage src={imgfruit}/>
                    <s.ContentCardText>{t('result.card.diet_create.b_c2')}</s.ContentCardText>
                </s.ContentCard>
            </s.ContentCardContainer>
            <s.TitleWrapper>
                <s.TitleBad>{t('result.card.diet_create.b_t3')}</s.TitleBad>
            </s.TitleWrapper>
            <s.ContentCardContainer>
                <s.ContentCardImgOnly>
                    <s.ContentCardImage src={imgdrink}/>
                    {/* <s.ContentCardText> </s.ContentCardText> */}
                </s.ContentCardImgOnly>
            </s.ContentCardContainer>
        </s.PageContainer>
    );
}

export function dietCycleRecommand(){
    const { t, i18n } = useTranslation();
    return(
        <s.PageContainer>
            <s.TitleWrapper style={{marginTop: '0px'}}>
                <s.TitleGood>{t('result.card.diet_cycle.g_t1')}</s.TitleGood>
            </s.TitleWrapper>
            <s.ContentCardContainer>
                <s.ContentCard>
                    <s.ContentCardImage src={imgtofu}/>
                    <s.ContentCardText>{t('result.card.diet_cycle.g_c11')}</s.ContentCardText>
                </s.ContentCard>
                <s.ContentCard>
                    <s.ContentCardImage src={imgbean}/>
                    <s.ContentCardText>{t('result.card.diet_cycle.g_c12')}</s.ContentCardText>
                </s.ContentCard>
                <s.ContentCard>
                    <s.ContentCardImage src={imgfish}/>
                    <s.ContentCardText>{t('result.card.diet_cycle.g_c13')}</s.ContentCardText>
                </s.ContentCard>
                <s.ContentCard>
                    <s.ContentCardImage src={imgmeat}/>
                    <s.ContentCardText>{t('result.card.diet_cycle.g_c14')}</s.ContentCardText>
                </s.ContentCard>
            </s.ContentCardContainer>            
        </s.PageContainer>
    );
}

export function dietCycleAvoid(){
    const { t, i18n } = useTranslation();
    return(
        <s.PageContainer>
            <s.TitleWrapper style={{marginTop: '0px'}}>
                <s.TitleBad>{t('result.card.diet_cycle.b_t1')}</s.TitleBad>
            </s.TitleWrapper>
            <s.ContentCardContainer>
                <s.ContentCard>
                    <s.ContentCardImage src={imgrice}/>
                    <s.ContentCardText>{t('result.card.diet_cycle.b_c11')}</s.ContentCardText>
                </s.ContentCard>
                <s.ContentCard>
                    <s.ContentCardImage src={imgnoodle}/>
                    <s.ContentCardText>{t('result.card.diet_cycle.b_c12')}</s.ContentCardText>
                </s.ContentCard>
                <s.ContentCard>
                    <s.ContentCardImage src={imgricecake}/>
                    <s.ContentCardText>{t('result.card.diet_cycle.b_c13')}</s.ContentCardText>
                </s.ContentCard>
                <s.ContentCard>
                    <s.ContentCardImage src={imgfry}/>
                    <s.ContentCardText>{t('result.card.diet_cycle.b_c14')}</s.ContentCardText>
                </s.ContentCard>
                <s.ContentCard>
                    <s.ContentCardImage src={imgfruit}/>
                    <s.ContentCardText>{t('result.card.diet_cycle.b_c15')}</s.ContentCardText>
                </s.ContentCard>
            </s.ContentCardContainer>
        </s.PageContainer>
    );
}

export function dietBalanceRecommand(){
    const { t, i18n } = useTranslation();
    return(
        <s.PageContainer>
            <s.TitleWrapper style={{marginTop: '0px'}}>
                <s.TitleGood>{t('result.card.diet_balance.g_t1')}</s.TitleGood>
            </s.TitleWrapper>
            <s.ContentCardContainer>
                <s.ContentCard>
                    <s.ContentCardImage src={imgpork}/>
                    <s.ContentCardText>{t('result.card.diet_balance.g_c11')}</s.ContentCardText>
                </s.ContentCard>
                <s.ContentCard>
                    <s.ContentCardImage src={imgfish}/>
                    <s.ContentCardText>{t('result.card.diet_balance.g_c12')}</s.ContentCardText>
                </s.ContentCard>
                <s.ContentCard>
                    <s.ContentCardImage src={imgseaweed}/>
                    <s.ContentCardText>{t('result.card.diet_balance.g_c13')}</s.ContentCardText>
                </s.ContentCard>
            </s.ContentCardContainer>            
        </s.PageContainer>
    );
}

export function dietBalanceAvoid(){
    const { t, i18n } = useTranslation();
    return(
        <s.PageContainer>
            <s.TitleWrapper style={{marginTop: '0px'}}>
                <s.TitleBad>{t('result.card.diet_balance.b_t1')}</s.TitleBad>
            </s.TitleWrapper>
            <s.ContentCardContainer>
                <s.ContentCardImgOnly>
                    <s.ContentCardImage src={imgtteokbokki}/>
                    {/* <s.ContentCardText> </s.ContentCardText> */}
                </s.ContentCardImgOnly>
                <s.ContentCardImgOnly>
                    <s.ContentCardImage src={imgramen}/>
                    {/* <s.ContentCardText> </s.ContentCardText> */}
                </s.ContentCardImgOnly>
            </s.ContentCardContainer>
            <s.TitleWrapper>
                <s.TitleBad>{t('result.card.diet_balance.b_t2')}</s.TitleBad>
            </s.TitleWrapper>
            <s.ContentTextContainer>
                <s.ContentText>{t('result.card.diet_balance.b_c2')}</s.ContentText>
            </s.ContentTextContainer>
            <s.ContentCardContainer>
                <s.ContentCard>
                    <s.ContentCardImage src={imgcoffee}/>
                    <s.ContentCardText>{t('result.card.diet_balance.b_c21')}</s.ContentCardText>
                </s.ContentCard>
                <s.ContentCard>
                    <s.ContentCardImage src={imgtea}/>
                    <s.ContentCardText>{t('result.card.diet_balance.b_c22')}</s.ContentCardText>
                </s.ContentCard>
            </s.ContentCardContainer>           
        </s.PageContainer>
    );
}

export function exerciseCreateRecommand(){
    const { t, i18n } = useTranslation();
    return(
        <s.PageContainer>
            <s.TitleWrapper style={{marginTop: '0px'}}>
                <s.TitleGood>{t('result.card.exercise_create.g_t1')}</s.TitleGood>
            </s.TitleWrapper>
            <s.ContentTextContainer>
                <s.ContentText>{t('result.card.exercise_create.g_c1')}</s.ContentText>
            </s.ContentTextContainer>
            <s.TitleWrapper>
                <s.TitleGood>{t('result.card.exercise_create.g_t2')}</s.TitleGood>
            </s.TitleWrapper>
            <s.ContentTextContainer>
                <s.ContentText>{t('result.card.exercise_create.g_c2')}</s.ContentText>
            </s.ContentTextContainer>
            <s.TitleWrapper>
                <s.TitleGood>{t('result.card.exercise_create.g_t3')}</s.TitleGood>
            </s.TitleWrapper>
            <s.ContentCardContainer>
                <s.ContentCard>
                    <s.ContentCardImage src={imgwalking}/>
                    <s.ContentCardText>{t('result.card.exercise_create.g_c31')}</s.ContentCardText>
                </s.ContentCard>
                <s.ContentCard>
                    <s.ContentCardImage src={imgbreathing}/>
                    <s.ContentCardText>{t('result.card.exercise_create.g_c32')}</s.ContentCardText>
                </s.ContentCard>
                <s.ContentCard>
                    <s.ContentCardImage src={imgstrecing}/>
                    <s.ContentCardText>{t('result.card.exercise_create.g_c33')}</s.ContentCardText>
                </s.ContentCard>
                <s.ContentCard>
                    <s.ContentCardImage src={imgyoga}/>
                    <s.ContentCardText>{t('result.card.exercise_create.g_c34')}</s.ContentCardText>
                </s.ContentCard>
                <s.ContentCard>
                    <s.ContentCardImage src={imgfilates}/>
                    <s.ContentCardText>{t('result.card.exercise_create.g_c35')}</s.ContentCardText>
                </s.ContentCard>
            </s.ContentCardContainer>
        </s.PageContainer>
    );
}

export function exerciseCreateAvoid(){
    const { t, i18n } = useTranslation();
    return(
        <s.PageContainer>
            <s.TitleWrapper style={{marginTop: '0px'}}>
                <s.TitleBad>{t('result.card.exercise_create.b_t1')}</s.TitleBad>
            </s.TitleWrapper>
            <s.ContentTextContainer>
                <s.ContentText>{t('result.card.exercise_create.b_c11')}</s.ContentText>
            </s.ContentTextContainer>
            <s.ContentCardContainer>
                <s.ContentCard>
                    <s.ContentCardImage src={imgswim}/>
                    <s.ContentCardText>{t('result.card.exercise_create.b_c12')}</s.ContentCardText>
                </s.ContentCard>
            </s.ContentCardContainer>
        </s.PageContainer>
    );
}

export function exerciseCycleRecommand(){
    const { t, i18n } = useTranslation();
    return(
        <s.PageContainer>
            <s.TitleWrapper style={{marginTop: '0px'}}>
                <s.TitleGood>{t('result.card.exercise_cycle.g_t1')}</s.TitleGood>
            </s.TitleWrapper>
            <s.ContentTextContainer>
                <s.ContentText>{t('result.card.exercise_cycle.g_c1')}</s.ContentText>
            </s.ContentTextContainer>
            <s.TitleWrapper>
                <s.TitleGood>{t('result.card.exercise_cycle.g_t2')}</s.TitleGood>
            </s.TitleWrapper>
            <s.ContentTextContainer>
                <s.ContentText>{t('result.card.exercise_cycle.g_c21')}</s.ContentText>
                <s.ContentText>{t('result.card.exercise_cycle.g_c22')}</s.ContentText>
            </s.ContentTextContainer>
            <s.TitleWrapper>
                <s.TitleGood>{t('result.card.exercise_cycle.g_t3')}</s.TitleGood>
            </s.TitleWrapper>
            <s.ContentCardContainer>
                <s.ContentCard>
                    <s.ContentCardImage src={imgjogging}/>
                    <s.ContentCardText>{t('result.card.exercise_cycle.g_c31')}</s.ContentCardText>
                </s.ContentCard>
                <s.ContentCard>
                    <s.ContentCardImage src={imghiking}/>
                    <s.ContentCardText>{t('result.card.exercise_cycle.g_c32')}</s.ContentCardText>
                </s.ContentCard>
                <s.ContentCard>
                    <s.ContentCardImage src={imgswim}/>
                    <s.ContentCardText>{t('result.card.exercise_cycle.g_c33')}</s.ContentCardText>
                </s.ContentCard>
            </s.ContentCardContainer>
        </s.PageContainer>
    );
}

export function exerciseCycleAvoid(){
    const { t, i18n } = useTranslation();
    return(
        <s.PageContainer>
            <s.TitleWrapper style={{marginTop: '0px'}}>
                <s.TitleBad>{t('result.card.exercise_cycle.b_t1')}</s.TitleBad>
            </s.TitleWrapper>
            <s.ContentTextContainer>
                <s.ContentText>{t('result.card.exercise_cycle.b_c1')}</s.ContentText>
            </s.ContentTextContainer>
            <s.TitleWrapper>
                <s.TitleBad>{t('result.card.exercise_cycle.b_t2')}</s.TitleBad>
            </s.TitleWrapper>
            <s.ContentTextContainer>
                <s.ContentText>{t('result.card.exercise_cycle.b_c2')}</s.ContentText>
            </s.ContentTextContainer>
        </s.PageContainer>
    );
}

export function exerciseBalanceRecommand(){
    const { t, i18n } = useTranslation();
    return(
        <s.PageContainer>
            <s.TitleWrapper style={{marginTop: '0px'}}>
                <s.TitleGood>{t('result.card.exercise_balance.g_t1')}</s.TitleGood>
            </s.TitleWrapper>
            <s.ContentTextContainer>
                <s.ContentText>{t('result.card.exercise_balance.g_c1')}</s.ContentText>
            </s.ContentTextContainer>
            <s.TitleWrapper>
                <s.TitleGood>{t('result.card.exercise_balance.g_t2')}</s.TitleGood>
            </s.TitleWrapper>
            <s.ContentTextContainer>
                <s.ContentText>{t('result.card.exercise_balance.g_c21')}</s.ContentText>
                <s.ContentText>{t('result.card.exercise_balance.g_c22')}</s.ContentText>
            </s.ContentTextContainer>
            <s.TitleWrapper>
                <s.TitleGood>{t('result.card.exercise_balance.g_t3')}</s.TitleGood>
            </s.TitleWrapper>
            <s.ContentCardContainer>
                <s.ContentCard>
                    <s.ContentCardImage src={imgstairs}/>
                    <s.ContentCardText>{t('result.card.exercise_balance.g_c31')}</s.ContentCardText>
                </s.ContentCard>
                <s.ContentCard>
                    <s.ContentCardImage src={imgbicycle}/>
                    <s.ContentCardText>{t('result.card.exercise_balance.g_c32')}</s.ContentCardText>
                </s.ContentCard>
                <s.ContentCard>
                    <s.ContentCardImage src={imgfoot}/>
                    <s.ContentCardText>{t('result.card.exercise_balance.g_c33')}</s.ContentCardText>
                </s.ContentCard>
                <s.ContentCard>
                    <s.ContentCardImage src={imgkick}/>
                    <s.ContentCardText>{t('result.card.exercise_balance.g_c34')}</s.ContentCardText>
                </s.ContentCard>
                <s.ContentCard>
                    <s.ContentCardImage src={imgsquart}/>
                    <s.ContentCardText>{t('result.card.exercise_balance.g_c35')}</s.ContentCardText>
                </s.ContentCard>
                {/* <s.ContentCard>
                    <s.ContentCardImage src='https://cdn-icons-png.flaticon.com/512/3014/3014502.png'/>
                    <s.ContentCardText>런지</s.ContentCardText>
                </s.ContentCard> */}
            </s.ContentCardContainer>
        </s.PageContainer>
    );
}

export function exerciseBalanceAvoid(){
    const { t, i18n } = useTranslation();
    return(
        <s.PageContainer>
            <s.TitleWrapper style={{marginTop: '0px'}}>
                <s.TitleBad>{t('result.card.exercise_balance.b_t1')}</s.TitleBad>
            </s.TitleWrapper>
            <s.ContentTextContainer>
                <s.ContentText>{t('result.card.exercise_balance.b_c1')}</s.ContentText>
            </s.ContentTextContainer>
        </s.PageContainer>
    );
}

export function sleepRecommand(){
    const { t, i18n } = useTranslation();
    return(
        <s.PageContainer>
            <s.TitleWrapper style={{marginTop: '0px'}}>
                <s.Title>{t('result.card.sleep_create.g_t1')}</s.Title>
            </s.TitleWrapper>
            <s.ContentTextContainer>
                <s.ContentList>{t('result.card.sleep_create.g_c1')}</s.ContentList>
                <s.ContentList>{t('result.card.sleep_create.g_c2')}</s.ContentList>
                <s.ContentList>{t('result.card.sleep_create.g_c3')}</s.ContentList>
                <s.ContentList>{t('result.card.sleep_create.g_c4')}</s.ContentList>
                <s.ContentList>{t('result.card.sleep_create.g_c5')}</s.ContentList>
                <s.ContentList>{t('result.card.sleep_create.g_c6')}</s.ContentList>
                <s.ContentList>{t('result.card.sleep_create.g_c7')}</s.ContentList>
            </s.ContentTextContainer>
        </s.PageContainer>
    );
}

export function sleepAvoid(){
    const { t, i18n } = useTranslation();
    return(
        <s.PageContainer>
            <s.TitleWrapper style={{marginTop: '0px'}}>
                <s.Title>{t('result.card.sleep_create.b_t1')}</s.Title>
            </s.TitleWrapper>
            <s.ContentTextContainer>
                <s.ContentList>{t('result.card.sleep_create.b_c1')}</s.ContentList>
                <s.ContentList>{t('result.card.sleep_create.b_c2')}</s.ContentList>
                <s.ContentList>{t('result.card.sleep_create.b_c3')}</s.ContentList>
                <s.ContentList>{t('result.card.sleep_create.b_c4')}</s.ContentList>
            </s.ContentTextContainer>
        </s.PageContainer>
    );
}

export function kMedicineDesc(){
    const { t, i18n } = useTranslation();
    return(
        <s.PageContainer>
            <s.TitleWrapper style={{marginTop: '0px'}}>
                <s.Title>{t('result.card.han.m_t')}</s.Title>
            </s.TitleWrapper>
            <s.ContentTextContainer>
                <s.ContentText>
                {t('result.card.han.m_c')}
                </s.ContentText>
            </s.ContentTextContainer>
        </s.PageContainer>
    );
}

export function bongdockDesc(){
    const { t, i18n } = useTranslation();
    return(
        <s.PageContainer>
            <s.TitleWrapper style={{marginTop: '0px'}}>
                <s.Title>{t('result.card.han.b_t')}</s.Title>
            </s.TitleWrapper>
            <s.ContentTextContainer>
                <s.ContentText>
                {t('result.card.han.b_c')}
                </s.ContentText>
            </s.ContentTextContainer>
        </s.PageContainer>
    );
}