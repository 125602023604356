import React, { useEffect } from 'react';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import styled from 'styled-components';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import EmojiFoodBeverageIcon from '@mui/icons-material/EmojiFoodBeverage';
import YouTubeIcon from '@mui/icons-material/YouTube';
import PersonIcon from '@mui/icons-material/Person';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import logo from '../../../asset/img/nobino.png';
import smlogo from '../../../asset/img/nobino_small.png';

const SideMenu = () => {
  const [toggled, setToggled] = React.useState(false);
  const [isMobile, setIsMobile] = React.useState(false);

  useEffect(() => {
    const mobileMaxWidth = 768;
    const isMobileSize = window.innerWidth <= mobileMaxWidth;

    if (isMobileSize) {
      setIsMobile(true);
    }

    const checkIfDesktop = () => {
      if (window.innerWidth > mobileMaxWidth) {
        setIsMobile(false);
      }
    };

    window.addEventListener('resize', checkIfDesktop);

    return () => window.removeEventListener('resize', checkIfDesktop);
  }, []);

  return (
    <>
      {!isMobile && (
        <Sidebar>
          <Link to="/home">
            <Logo src={logo} alt="Kaidoc logo" />
          </Link>
          <Menu
            closeOnClick
            menuItemStyles={{
              button: {
                [`&.active`]: {
                  backgroundColor: '#13395e',
                  color: '#b6c8d9',
                },
                '&:hover': {
                  backgroundColor: 'rgba(24, 112, 251, 0.2)',
                  fontWeight: '700',
                },
              },
            }}
          >
            <MenuItem icon={<MedicalInformationIcon color="primary" />} component={<Link to="/nobino-health" />}>
              {' '}
              노비노 건강법
            </MenuItem>
            <MenuItem icon={<EmojiFoodBeverageIcon color="primary" />} component={<Link to="/oriental" />}>
              {' '}
              한방 치료법
            </MenuItem>
            <MenuItem icon={<YouTubeIcon color="primary" />} component={<Link to="/social" />}>
              {' '}
              소셜
            </MenuItem>
            <MenuItem icon={<PersonIcon color="primary" />} component={<Link to="/introduce" />}>
              {' '}
              소개글
            </MenuItem>
          </Menu>
        </Sidebar>
      )}
      {isMobile && (
        <>
          <MenuIcon
            onClick={() => setToggled(!toggled)}
            style={{ position: 'fixed', top: '10px', left: '10px', zIndex: 1000 }}
          />
          <Sidebar
            width="50%"
            backgroundColor="#FBFBFB"
            onBackdropClick={() => setToggled(false)}
            toggled={toggled}
            breakPoint="always"
          >
            <Link to="/home">
              <Logo src={logo} alt="Kaidoc logo" />
            </Link>
            <Menu
              closeOnClick
              menuItemStyles={{
                button: {
                  [`&.active`]: {
                    backgroundColor: '#13395e',
                    color: '#b6c8d9',
                  },
                  '&:hover': {
                    backgroundColor: 'rgba(24, 112, 251, 0.2)',
                    fontWeight: '700',
                  },
                },
              }}
            >
              <MenuItem icon={<MedicalInformationIcon color="primary" />} component={<Link to="/nobino-health" />}>
                {' '}
                노비노 건강법
              </MenuItem>
              <MenuItem icon={<EmojiFoodBeverageIcon color="primary" />} component={<Link to="/oriental" />}>
                {' '}
                한방 치료법
              </MenuItem>
              <MenuItem icon={<YouTubeIcon color="primary" />} component={<Link to="/social" />}>
                {' '}
                소셜
              </MenuItem>
              <MenuItem icon={<PersonIcon color="primary" />} component={<Link to="/introduce" />}>
                {' '}
                소개글
              </MenuItem>
            </Menu>
          </Sidebar>
        </>
      )}
    </>
  );
};

const Logo = styled.img`
  padding: 2rem 20px 1rem;
  width: 150px;

  @media (max-width: 991px) {
    width: 100px;
    padding: 3rem 20px 1rem;
  }
`;

const toggleButton = styled.button`
  background-color: #fff;
  border: none;
  cursor: pointer;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  color: #000;
`;

export default SideMenu;
