import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoginPresenter from './LoginPresenter';
import { useHeaderTextChange, useHeaderToggle } from '../../../lib/useHeader';
import { authApi } from '../../../api/api-auth';

export default function LogintContainer() {
  useHeaderToggle();
  useHeaderTextChange('');

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const onSubmit = async () => {
    let res;
    try {
      res = await authApi.login({ email, password });
    } catch (e) {
      setErrorMessage('존재하지 않는 아이디 또는 비밀번호가 일치하지 않습니다.');
    } finally {
      if (res) {
        // save tokens
        localStorage.setItem('tokens', JSON.stringify(res.data.tokens));

        // save userInfo
        localStorage.setItem('userInfo', JSON.stringify(res.data.user));

        setErrorMessage('');
        navigate('/main');
      }
    }
  };

  return (
    <LoginPresenter
      email={email}
      errorMessage={errorMessage}
      onChangeEmail={onChangeEmail}
      onChangePassword={onChangePassword}
      onSubmit={onSubmit}
    />
  );
}
