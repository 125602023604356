import React from 'react';
import { useTranslation } from 'react-i18next';
import * as s from './NewSurveyStyled';
import hot from '../../../asset/img/survey/hot.png';
import cold from '../../../asset/img/survey/cold.png';
import coffee from '../../../asset/img/survey/coffee.png';
import ice from '../../../asset/img/survey/ice.png';
import no from '../../../asset/img/survey/no.png';

interface HeatConditionProps {
  onNext: () => void;
  onScore: (key: string, score: number) => void;
  selectedOption: string[];
  onOptionSelect: (option: string) => void;
  isSelected: (selectedOptions: string[], option: string) => boolean;
}

const HeatCondition: React.FC<HeatConditionProps> = ({ onNext, onScore, selectedOption, onOptionSelect, isSelected }) => {
  const calcScore = (selectedOptions: string[]) => {
    if (selectedOptions.includes('2')) {
      onScore('ebProduce', -1);
    }
    if (selectedOptions.includes('3')) {
      onScore('ebProduce', -1);
    }
  };

  const { t } = useTranslation();

  return (
    <s.SurveyContainer>
      <s.Question>{t('survey.heat_condition.question')}</s.Question>
      <s.InnerOptionContainer style={{ margin: '1rem 0' }}>
        <s.OptionContainer>
          <s.Options>
            <s.OptionButton
              type="button"
              variant={isSelected(selectedOption, '1') ? 'contained' : 'outlined'}
              onClick={() => {
                onOptionSelect('1');
              }}
            >
              <s.ThreeOptionImage src={hot} alt="대체 이미지" />
              <s.OptionSubtitle>{t('survey.heat_condition.option1')}</s.OptionSubtitle>
            </s.OptionButton>
            <s.OptionButton
              type="button"
              variant={isSelected(selectedOption, '2') ? 'contained' : 'outlined'}
              onClick={() => {
                onOptionSelect('2');
              }}
            >
              <s.ThreeOptionImage src={cold} alt="대체 이미지" />
              <s.OptionSubtitle>{t('survey.heat_condition.option2')}</s.OptionSubtitle>
            </s.OptionButton>
          </s.Options>
        </s.OptionContainer>
        <s.OptionContainer >
          <s.Options style={{ margin: '0' }}>
            <s.OptionButton
              type="button"
              variant={isSelected(selectedOption, '3') ? 'contained' : 'outlined'}
              onClick={() => {
                onOptionSelect('3');
              }}
            >
              <s.ThreeOptionImage src={coffee} alt="대체 이미지" />
              <s.OptionSubtitle>{t('survey.heat_condition.option3')}</s.OptionSubtitle>
            </s.OptionButton>
            <s.OptionButton
              type="button"
              variant={isSelected(selectedOption, '4') ? 'contained' : 'outlined'}
              onClick={() => {
                onOptionSelect('4');
              }}
            >
              <s.ThreeOptionImage src={ice} alt="대체 이미지" />
              <s.OptionSubtitle>{t('survey.heat_condition.option4')}</s.OptionSubtitle>
            </s.OptionButton>
            <s.OptionButton
              type="button"
              variant={isSelected(selectedOption, '5') ? 'contained' : 'outlined'}
              onClick={() => {
                onOptionSelect('5');
              }}
            >
              <s.ThreeOptionImage src={no} alt="대체 이미지" />
              <s.OptionSubtitle>{t('survey.heat_condition.option5')}</s.OptionSubtitle>
            </s.OptionButton>
          </s.Options>
        </s.OptionContainer>
      </s.InnerOptionContainer>
      <s.NextButton
        variant="contained"
        color="primary"
        onClick={() => {
          onNext();
          calcScore(selectedOption);
        }}
      >
        다음
      </s.NextButton>
    </s.SurveyContainer>
  );
};

export default HeatCondition;
