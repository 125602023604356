import React, { Dispatch, SetStateAction } from 'react';
import { Link } from 'react-router-dom';
import siloIcon from '../../../../asset/icon/silo-icon.png';
import { AlertDialogPreSurvey } from '../common/Survey';
import * as s from './SurveyMainStlyed';

interface SurveyMainProps {
  onClickSurveyStart: () => void;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export default function SurveyMainPresenter(props: SurveyMainProps) {
  const { onClickSurveyStart, open, setOpen } = props;

  return (
    <s.StyledSurveyStartPage maxWidth="sm">
      <s.LogoBox>
        <s.SiloIcon src={siloIcon} />
        <s.CenterText variant="h4">oriental silo</s.CenterText>
        <s.CenterText variant="h6">설문조사</s.CenterText>
      </s.LogoBox>

      <s.StyledButton
        variant="contained"
        sx={{ marginTop: '5rem', marginBottom: '1rem' }}
        onClick={() => setOpen(true)}
      >
        설문시작
      </s.StyledButton>

      <AlertDialogPreSurvey open={open} setOpen={setOpen} onClickConfirm={onClickSurveyStart} />
    </s.StyledSurveyStartPage>
  );
}
