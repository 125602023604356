import { loginedApi, notLoginedApi } from './api-base';

interface LoginData {
  email: string;
  password: string;
}

interface RegisterData {
  name: string;
  email: string;
  password: string;
  hint: string;
  answer: string;
}

interface GetResetPasswordTokenByHintData {
  email: string;
  answer: string;
}

export const authApi = {
  login: (Data: LoginData) => notLoginedApi.post('/v1/auth/login', Data),
  logout: (refreshToken: string) => notLoginedApi.post('/v1/auth/logout', { refreshToken }),
  createUser: (Data: RegisterData) => notLoginedApi.post('v1/auth/register', Data),
  checkExistEmail: (email: string) => notLoginedApi.post('v1/auth/email-exist', { email }),
  getHint: (email: string) => notLoginedApi.get(`v1/auth/hint?email=${email}`),
  getResetPasswordTokenByHint: (Data: GetResetPasswordTokenByHintData) =>
    notLoginedApi.post('v1/auth/reset-password-token-hint', Data),
  resetPassword: (password: string, token: string) =>
    notLoginedApi.post(`v1/auth/reset-password?token=${token}`, { password }),
  refreshToken: (refreshToken: string) => notLoginedApi.post('v1/auth/refresh-tokens', { refreshToken }),
};
