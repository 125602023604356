/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { TextField, Button, List, ListItem, ListItemText, IconButton, Container, Alert } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import * as s from './NewSurveyStyled';

const StyledForm = styled.form`
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
`;

const StyledListItem = styled(ListItem)`
  margin-bottom: 8px;
  background-color: #f5f5f5;
`;

const ErrorMessage = styled(Alert)`
  margin-top: 100px;
  font-family: 'Noto Sans KR', sans-serif;
  margin-bottom: 16px;
`;

interface DiseaseProps {
  onNext: () => void;
  onSpecific: (step: any) => void;
  diseases: { id: string; name: string }[];
  newDisease: string;
  onNewDiseaseChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleAddDisease: (event: { preventDefault: () => void }) => void;
  handleRemoveDisease: (id: string) => void;
  onDragEnd: (result: any) => void;
}

const Disease: React.FC<DiseaseProps> = ({
  onNext,
  onSpecific,
  diseases,
  newDisease,
  onNewDiseaseChange,
  handleAddDisease,
  handleRemoveDisease,
  onDragEnd,
}) => {
  const [showError, setShowError] = useState(false);
  const location = useLocation();
  useEffect(() => {
    setShowError(false);
  }, [location]);

  const { t } = useTranslation();

  return (
    <s.SurveyContainer style={{ justifyContent: 'space-between' }}>
      <Container style={{ padding: '0' }}>
        <s.Question style={{ paddingBottom: '1rem' }}>
          {t('survey.disease.question')}
        </s.Question>
        <StyledForm onSubmit={handleAddDisease}>
          <TextField
            fullWidth
            variant="outlined"
            value={newDisease}
            onChange={onNewDiseaseChange}
            placeholder="질환 또는 증상 명"
            color="primary"
          />
          <Button type="submit" variant="contained" color="primary">
            {t('button.add')}
          </Button>
        </StyledForm>
        <s.OptionSubtitle style={{ paddingBottom: '1rem', color: '#0f67fe' }}>
          {t('survey.disease.subtitle')}
        </s.OptionSubtitle>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="diseases">
            {(provided) => (
              <List {...provided.droppableProps} ref={provided.innerRef}>
                {diseases.map((disease, index) => (
                  <Draggable key={disease.id} draggableId={disease.id} index={index}>
                    {(provided) => (
                      <StyledListItem
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <ListItemText primary={disease.name} />
                        <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveDisease(disease.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </StyledListItem>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
      </Container>
      <div style={{ width: '100%' }}>
        {showError && <ErrorMessage severity="error">질환 및 증상을 작성해주세요.</ErrorMessage>}
        <s.NextButton
          onClick={() => {
            onSpecific('과거력');
          }}
          variant="contained" // 왜 outlined가 적용이 안되는건데?
          color="primary"
        >
          {t('button.skip')}
        </s.NextButton>
        <s.NextButton
          variant="contained"
          color="primary"
          onClick={() => {
            if (diseases.length === 0) {
              setShowError(true);
            } else {
              onNext();
            }
          }}
        >
          {t('button.next')}
        </s.NextButton>
      </div>
    </s.SurveyContainer>
  );
};

export default Disease;
