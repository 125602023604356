import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, To } from 'react-router-dom';
import NewSurveyPresenter from './NewSurveyPresenter';
import { IBasicInfo, IPrivacyInfo, setBasicInfo, setPrivacyInfo, setSelectedOptionInfo, setSingleSelectedOptionInfo } from "../../../../modules/newsurvey";

type StepType =
  | '목둘레'
  | '상하체발달'
  | '종아리둘레'
  | '늑골각'
  | '맥박수'
  | '식욕'
  | '식사횟수'
  | '1회식사량'
  | '소화불량'
  | '충분한수면'
  | '입면시간'
  | '규칙적인입출면시간'
  | '규칙적인총수면시간'
  | '기상시상태와평소활동량'
  | '성격'
  | '한열상태'
  | '한열상태(얼굴무릎)'
  | '대변'
  | '소변';
  // | '질환및증상'
  // | '발병시기및계기'
  // | '과거력'
  // | '비만약'
  // | '기호식품';

const stepOrder: StepType[] = [
  '목둘레',
  '상하체발달',
  '종아리둘레',
  '늑골각',
  '맥박수',
  '식욕',
  '식사횟수',
  '1회식사량',
  '소화불량',
  '충분한수면',
  '입면시간',
  '규칙적인입출면시간',
  '규칙적인총수면시간',
  '기상시상태와평소활동량',
  '성격',
  '한열상태',
  '한열상태(얼굴무릎)',
  '대변',
  '소변',
  // '질환및증상',
  // '발병시기및계기',
  // '과거력',
  // '비만약',
  // '기호식품',
];

export default function NewSurveyContainer() {
  const navigate = useNavigate();
  const location = useLocation();
  const privacyData = location.state as { isHealthCare: boolean };
  const [data, setData] = useState(
    typeof location.state === 'object' && location.state !== null ? { ...location.state } : {},
  );
  const [step, setStep] = useState<StepType>('목둘레');
  const [selectedOptions, setSelectedOptions] = useState<Record<StepType, string[]>>({} as Record<StepType, string[]>);
  const [singleSelectedOptions, setSingleSelectedOptions] = useState<Record<StepType, string>>(
    {} as Record<StepType, string>,
  );
  const dispatch = useDispatch();

  const totalSteps = stepOrder.length;

  const currentStepIndex = stepOrder.indexOf(step);

  const goToNextStep = () => {
    if (currentStepIndex < stepOrder.length - 1) {
      setStep(stepOrder[currentStepIndex + 1]);
    }
  };

  const goToPreviousStep = () => {
    if (currentStepIndex > 0) {
      setStep(stepOrder[currentStepIndex - 1]);
    }
  };

  // const goToSpecificStep = (step: StepType) => {
  //   setStep(step);
  // };

  const goToPath = (path: To) => () => {
    dispatch(setSelectedOptionInfo(selectedOptions));
    dispatch(setSingleSelectedOptionInfo(singleSelectedOptions));
    navigate(path);
    // console.log(path);
  };

  const handleScore = (key: string, score: number) => {
    setData((prevData) => ({
      ...prevData,
      [key]: (prevData[key as keyof typeof prevData] || 0) + score,
    }));
    goToNextStep();
  };

  const handleMultipleOptionSelect = (option: string) => {
    setSelectedOptions((prevOptions) => {
      const currentOptions = prevOptions[step] || [];
      const newOptions = currentOptions.includes(option)
        ? currentOptions.filter((item) => item !== option)
        : [...currentOptions, option];

      return {
        ...prevOptions,
        [step]: newOptions,
      };
    });
  };

  const handleSingleOptionSelect = (option: string) => {
    setSingleSelectedOptions((prevOptions) => ({
      ...prevOptions,
      [step]: option,
    }));
  };

  const isSelected = (selectedOptions: string[], option: string) => {
    return selectedOptions.includes(option);
  };

  const isAllSelected = () => {
    return Object.keys(singleSelectedOptions).length >= 11 && Object.keys(selectedOptions).length === 5;
  } 

  return (
    <NewSurveyPresenter
      step={step}
      totalSteps={totalSteps}
      currentStepIndex={currentStepIndex}
      goToNextStep={goToNextStep}
      goToPreviousStep={goToPreviousStep}
      goToPath={goToPath}
      privacyData={privacyData}
      data={data}
      handleScore={handleScore}
      selectedOptions={selectedOptions[step] || []}
      singleSelectedOption={singleSelectedOptions[step] || ''}
      handleMultipleOptionSelect={handleMultipleOptionSelect}
      handleSingleOptionSelect={handleSingleOptionSelect}
      isSelected={isSelected}
      isAllSelected={isAllSelected}
    />
  );
}
