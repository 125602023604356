import React from 'react';
import { useTranslation } from 'react-i18next';
import * as s from './NewSurveyStyled';
import heat from '../../../asset/img/survey/heat.png';
import knee from '../../../asset/img/survey/knee2.png';
import no from '../../../asset/img/survey/no.png';

interface HeatConditionDetailProps {
  onNext: () => void;
  onScore: (key: string, score: number) => void;
  selectedOption: string[];
  onOptionSelect: (option: string) => void;
  isSelected: (selectedOptions: string[], option: string) => boolean;
}

const HeatConditionDetail: React.FC<HeatConditionDetailProps> = ({ onNext, onScore, selectedOption, onOptionSelect, isSelected }) => {
  const calcScore = (selectedOptions: string[]) => {
    if (selectedOptions.includes('1')) {
      onScore('ebBalanceControl', -1);
    }
    if (selectedOptions.includes('2')) {
      onScore('ebBalanceControl', -1);
    }
  };

  const { t } = useTranslation();

  return (
    <s.SurveyContainer>
      <s.Question>{t('survey.heat_condition_detail.question')}</s.Question>
      <s.InnerOptionContainer style={{ marginTop: '1rem' }}>
        <s.OptionContainer>
          <s.Options>
            <s.OptionButton
              type="button"
              variant={isSelected(selectedOption, '1') ? 'contained' : 'outlined'}
              onClick={() => {
                onOptionSelect('1');
              }}
            >
              <s.ThreeOptionImage src={heat} alt="대체 이미지" />
              <s.OptionSubtitle>{t('survey.heat_condition_detail.option1')}</s.OptionSubtitle>
            </s.OptionButton>
            <s.OptionButton
              type="button"
              variant={isSelected(selectedOption, '2') ? 'contained' : 'outlined'}
              onClick={() => {
                onOptionSelect('2');
              }}
            >
              <s.ThreeOptionImage src={knee} alt="대체 이미지" />
              <s.OptionSubtitle>{t('survey.heat_condition_detail.option2')}</s.OptionSubtitle>
            </s.OptionButton>
            <s.OptionButton
              type="button"
              variant={isSelected(selectedOption, '3') ? 'contained' : 'outlined'}
              onClick={() => {
                onOptionSelect('3');
              }}
            >
              <s.ThreeOptionImage src={no} alt="대체 이미지" />
              <s.OptionSubtitle>{t('survey.heat_condition_detail.option3')}</s.OptionSubtitle>
            </s.OptionButton>
          </s.Options>
        </s.OptionContainer>
      </s.InnerOptionContainer>
      <s.NextButton
        variant="contained"
        color="primary"
        onClick={() => {
          onNext();
          calcScore(selectedOption);
        }}
      >
        다음
      </s.NextButton>
    </s.SurveyContainer>
  );
};

export default HeatConditionDetail;
