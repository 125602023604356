import React from 'react';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import { Button, Pagination, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import moment from 'moment';
import siloIcon from '../../../asset/img/logo.png';
import * as s from './UserListStlyed';


interface MainProps {
  userInfo: UserInfoData;
  pageInfo: PageInfoData;
  userList: Array<UserListData>;
  onClickLogout: () => void;
  onClickSearch: () => void;
  onClickUserCheck: (name: string, phone: string, age: string) => void;
  onChangeTempSearchText: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handlePageChange: (event: React.ChangeEvent<unknown>, value: number) => void;
}

interface UserInfoData {
  name: string;
  role: string;
  email: string;
}

interface UserListData {
  phone: string;
  name: string;
  age: string;
  birth: string;
  recentSurveyDate: string;
}

interface PageInfoData {
  nowSeletedPage: number;
  totalPages: number;
  totalResults: number;
}

export default function UserListPresenter(props: MainProps) {
  const {
    userInfo,
    pageInfo,
    userList,
    onClickLogout,
    onClickSearch,
    onClickUserCheck,
    onChangeTempSearchText,
    handlePageChange,
  } = props;
  const { t, i18n } = useTranslation();

  const changeLanguage = (event: SelectChangeEvent<string>) => {
    i18n.changeLanguage(event.target.value);
  };

  return (
    <s.StyledUserListPage maxWidth="sm">
      <s.LanguageSelector>
          <Select
            value={i18n.language}
            onChange={changeLanguage}
            defaultValue='ko'
            variant="outlined"
            size="small"
            style={{fontFamily: 'Noto Sans KR'}}
          >
            <MenuItem value="ko" style={{fontFamily: 'Noto Sans KR'}}>한국어</MenuItem>
            <MenuItem value="en" style={{fontFamily: 'Noto Sans KR'}}>English</MenuItem>
          </Select>
        </s.LanguageSelector>
      <s.LogoBox>
        <s.SiloIcon src={siloIcon} />
        <s.CenterText variant="h4">KAIDOC</s.CenterText>
      </s.LogoBox>
      {userInfo.role === 'admin' ? (
        <s.InfoBox>
          <s.LeftText variant="body2">{t('manage.admin')}</s.LeftText>
        </s.InfoBox>
      ) : (
        <s.InfoBox>
          <s.LeftText variant="body2">{t('manage.list.id')} : {userInfo.email}</s.LeftText>
          <s.LeftText variant="body2">{t('manage.list.name')} : {userInfo.name}</s.LeftText>
        </s.InfoBox>
      )}

      {userList.length === 0 ? (
        <s.CenterText sx={{ marginTop: 10, marginBottom: 10 }}>{t('manage.list.search_not_found')}</s.CenterText>
      ) : (
        <s.UserList sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
          {userList.map((value: UserListData, index: number) => (
            <s.UserListItem
              key={value.name + value.phone}
              disableGutters
              secondaryAction={
                <s.UserListButtonBox>
                  <s.StyledButton
                    variant="contained"
                    sx={{ marginTop: '1rem', marginBottom: '1rem' }}
                    onClick={() => onClickUserCheck(value.name, value.phone, String(new Date().getFullYear() - Number(value.birth) + 1))}
                  >
                    {t('manage.list.check')}
                  </s.StyledButton>
                </s.UserListButtonBox>
              }
            >
              <s.LeftText sx={{ marginRight: '20px', paddingBottom: '25px' }}>
                {pageInfo.totalResults - index}
              </s.LeftText>
              <s.SurveyListItemInfo>
                <s.LeftText>
                  {value.name}({new Date().getFullYear() - Number(value.birth) + 1}) {value.phone}
                </s.LeftText>
                <s.LeftText>{t('manage.list.recent')} : {`${moment(value.recentSurveyDate).format('yyyy.MM.DD HH:mm')}`}</s.LeftText>
              </s.SurveyListItemInfo>
            </s.UserListItem>
          ))}
        </s.UserList>
      )}

      <Pagination count={pageInfo.totalPages} page={pageInfo.nowSeletedPage} onChange={handlePageChange} />
      <s.SerachBox>
        <s.StyledTextField
          variant="standard"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            onChangeTempSearchText(e);
          }}
        />
        <Button
          variant="text"
          sx={{ justifyContent: 'flex-start', paddingLeft: 0, marginLeft: 0 }}
          onClick={onClickSearch}
        >
          <SearchIcon sx={{ marginTop: 3 }} />
        </Button>
      </s.SerachBox>
    </s.StyledUserListPage>
  );
}
