import React, { useEffect } from 'react';
import { SurveyTitle, TextFieldBox, BtnPreNext, SurveySingleSelect, TextFieldBoxWithHelper } from '../common/Survey';
import '../common/Survey.scss';
import { ISurveyDictType } from '../common/SurveyTypes';

interface IBdyShpPageProps {
  dictObject: ISurveyDictType;
  validForm: IValidData;
}

interface IValidData {
  q11: boolean;
}

export default function SurveyBodyShapePresenter(Props: IBdyShpPageProps) {
  const { validForm, dictObject } = Props;

  const q11 = {
    qTitle: 'q11',
    title: '1-1. 당신의 체형에서 상하체의 관계는? *',
    questions: ['상체가 발달 (하체에 비해)', '하체가 발달 (상체에 비해)', '상하체가 비슷'],
  };
  return (
    <>
      <SurveyTitle title="1. 체형" />
      <SurveySingleSelect surveyItem={q11} defaultValue={dictObject.q11} />
      {/* <SurveySingleSelect surveyItem={q12} defaultValue={dictObject.q12} /> */}
      <div className="dropBoxWithBr-container">
        <div className="dropBoxWithBr-title">1-2. 줄자를 이용하여 해당 둘레를 측정(cm)</div>
      </div>
      <TextFieldBoxWithHelper
        textFieldBoxItem={{
          qTitle: 'q12_neck',
          title: `목둘레`,
          placeholder: '42',
          type: 'number',
          require: false,
          defaultValue: dictObject.q12_neck,
          helperTitle: '목 둘레 측정 방법',
          helperText: '목젖 1cm 아래에서 잰다.',
          helperText2: '(측정방법 : 목젖 1cm 아래에서 잰다.)',
        }}
      />
      <TextFieldBoxWithHelper
        textFieldBoxItem={{
          qTitle: 'q12_waist',
          title: '허리둘레',
          placeholder: '65',
          type: 'number',
          require: false,
          defaultValue: dictObject.q12_waist,
          helperTitle: '허리 둘레 측정 방법',
          helperText: '양발을 어깨 넓이로 벌리고 배꼽 2cm 위에서 잰다.',
          helperText2: '(측정방법 : 양발을 어깨 넓이로 벌리고 배꼽 2cm 위에서 잰다.)',
        }}
      />
      <TextFieldBoxWithHelper
        textFieldBoxItem={{
          qTitle: 'q12_calf',
          title: '종아리둘레',
          placeholder: '32',
          type: 'number',
          require: false,
          defaultValue: dictObject.q12_calf,
          helperTitle: '종아리 둘레 측정 방법',
          helperText: '무릎아래 종아리의 가장 굵은 부분을 잰다.',
          helperText2: '(측정방법 : 무릎아래 종아리의 가장 굵은 부분을 잰다.)',
        }}
      />
      <BtnPreNext linkPre="../survey-favfod" linkNex="../survey-digslp" disabled={!validForm.q11} />
    </>
  );
}
