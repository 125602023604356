import React, { useEffect } from 'react';
import {
  SurveyTitle,
  BtnPreNext,
  SurveyMultiSelect,
  SurveyMultiSelectWithNoProblem,
  SurveySingleSelectVer3,
} from '../common/Survey';
import abdominalImg from '../../../../asset/img/abdominal-img.png';
import { ISurveyDictType } from '../common/SurveyTypes';
import '../common/Survey.scss';

interface IAdbTmpPageProps {
  dictObject: ISurveyDictType;
  validForm: IValidData;
}

interface IValidData {
  q81: boolean;
  q9: boolean;
}

export default function SurveyAbdtmpPresenter(Props: IAdbTmpPageProps) {
  const { validForm, dictObject } = Props;

  const q81 = {
    qTitle: 'q81',
    title: '',
    questions: ['1', '2', '3'],
  };

  const q9 = {
    qTitles: ['q91', 'q92', 'q93', 'q94', 'q95', 'q96', 'q97'],
    title: '',
    questions: [
      '평소 손과 발이 참',
      '평소 손발이 따뜻함',
      '평소 따뜻한 것을 좋아함',
      '평소 시원한 것을 좋아함',
      '평소 얼굴 쪽으로 열이 남',
      '평소 무릎 아래가 시림',
      '해당사항 없음.',
    ],
  };

  return (
    <>
      <SurveyTitle title="8. 당신의 복부형태" />
      <div className="title-container">
        <div className="title-text">해당되는 곳에 체크하세요</div>
      </div>
      <div className="image-container">
        <img className="img" src={abdominalImg} alt="abdominalImage" />
      </div>
      <SurveySingleSelectVer3 surveyItem={q81} defaultValue={dictObject.q81} />
      {/* <SurveySingleSelect surveyItem={q62} defaultValue={dictObject.q62} /> */}
      <div className="for-1rem-padding" />
      <SurveyTitle title="9. 한열 상태 (해당사항을 모두 체크해주세요)" />
      <SurveyMultiSelectWithNoProblem
        surveyItem={q9}
        defaultValues={[
          dictObject.q91,
          dictObject.q92,
          dictObject.q93,
          dictObject.q94,
          dictObject.q95,
          dictObject.q96,
          dictObject.q97,
        ]}
      />
      <div className="for-1rem-padding" />
      <BtnPreNext linkPre="../survey-hbipsn" linkNex="../survey-pashis" disabled={!(validForm.q81 && validForm.q9)} />
    </>
  );
}
