/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Container, Paper, Typography, FormGroup, FormControlLabel, Checkbox, TextField } from '@mui/material';
import * as s from './NewSurveyStyled';

const StyledPaper = styled(Paper)`
  padding: 0 1.5rem 1.5rem 1.5rem;
  margin: 1.5rem 0;
  overflow-y: auto;
  max-height: 50vh;
  width: 100%;
  box-sizing: border-box;
`;

const StyledFormGroup = styled(FormGroup)`
  margin-top: 16px;
`;

const CategoryTitle = styled(Typography)`
  margin-top: 16px;
  margin-bottom: 8px;
  font-weight: bold;
  font-family: 'Noto Sans KR', sans-serif;
`;

const SubCategory = styled.div`
  display: flex;
  margin-left: 24px;
  font-family: 'Noto Sans KR', sans-serif;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const LabelText = styled(Typography)`
  font-family: 'Noto Sans KR', sans-serif;
`;

const StyledTextField = styled(TextField)`
  margin-top: 8px;
  margin-left: 24px;
  width: 80%;
`;

const FormControlLabelCustom = styled(FormControlLabel)`
  width: 100%;
`;

interface PastHistoryTriggerProps {
  onNext: () => void;
  pastHistory: { [key: string]: boolean };
  otherReason: string;
  surgery: string;
  isNextDisabled: boolean;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleOtherReasonChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSurgeryChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const PastHistoryTrigger: React.FC<PastHistoryTriggerProps> = ({
  onNext,
  pastHistory,
  otherReason,
  surgery,
  isNextDisabled,
  handleChange,
  handleOtherReasonChange,
  handleSurgeryChange,
}) => {
  const { t } = useTranslation();

  return (
    <s.SurveyContainer>
      <Container style={{ padding: '0' }}>
        <s.Question style={{ padding: ' 0 1.5rem 0 1.5rem' }}>{t('survey.past_history.question')}</s.Question>
        <s.QuestionSubtitle style={{ padding: '0 1.5rem', color: '#0f67fe' }}>
          {t('survey.past_history.subtitle')}
        </s.QuestionSubtitle>
        <s.OptionContainer>
          <StyledPaper>
            <StyledFormGroup>
              <FormControlLabelCustom
                control={<Checkbox checked={pastHistory.해당사항없음} onChange={handleChange} name="해당사항없음" />}
                label={<LabelText>{t('survey.past_history.option1')}</LabelText>}
              />
              <SubCategory>
                <FormControlLabelCustom
                  control={<Checkbox checked={pastHistory.고혈압} onChange={handleChange} name="고혈압" />}
                  label={<LabelText>{t('survey.past_history.option2')}</LabelText>}
                />
                <FormControlLabelCustom
                  control={<Checkbox checked={pastHistory.당뇨} onChange={handleChange} name="당뇨" />}
                  label={<LabelText>{t('survey.past_history.option3')}</LabelText>}
                />
              </SubCategory>
              <SubCategory>
                <FormControlLabelCustom
                  control={<Checkbox checked={pastHistory.고지혈증} onChange={handleChange} name="고지혈증" />}
                  label={<LabelText>{t('survey.past_history.option4')}</LabelText>}
                />
                <FormControlLabelCustom
                  control={<Checkbox checked={pastHistory.중풍} onChange={handleChange} name="중풍" />}
                  label={<LabelText>{t('survey.past_history.option5')}</LabelText>}
                />
              </SubCategory>
              <SubCategory>
                <FormControlLabelCustom
                  control={<Checkbox checked={pastHistory.암} onChange={handleChange} name="암" />}
                  label={<LabelText>{t('survey.past_history.option6')}</LabelText>}
                />
                <FormControlLabelCustom
                  control={<Checkbox checked={pastHistory.심장질환} onChange={handleChange} name="심장질환" />}
                  label={<LabelText>{t('survey.past_history.option7')}</LabelText>}
                />
              </SubCategory>
              <SubCategory>
                <FormControlLabelCustom
                  control={<Checkbox checked={pastHistory.폐질환} onChange={handleChange} name="폐질환" />}
                  label={<LabelText>{t('survey.past_history.option8')}</LabelText>}
                />
                <FormControlLabelCustom
                  control={<Checkbox checked={pastHistory.소화기질환} onChange={handleChange} name="소화기질환" />}
                  label={<LabelText>{t('survey.past_history.option9')}</LabelText>}
                />
              </SubCategory>
              <SubCategory>
                <FormControlLabelCustom
                  control={<Checkbox checked={pastHistory.간질환} onChange={handleChange} name="간질환" />}
                  label={<LabelText>{t('survey.past_history.option10')}</LabelText>}
                />
                <FormControlLabelCustom
                  control={<Checkbox checked={pastHistory.신장질환} onChange={handleChange} name="신장질환" />}
                  label={<LabelText>{t('survey.past_history.option11')}</LabelText>}
                />
              </SubCategory>
              <SubCategory>
                <FormControlLabelCustom
                  control={<Checkbox checked={pastHistory.알러지} onChange={handleChange} name="알러지" />}
                  label={<LabelText>{t('survey.past_history.option12')}</LabelText>}
                />
                <FormControlLabelCustom
                  control={<Checkbox checked={pastHistory.자가면역질환} onChange={handleChange} name="자가면역질환" />}
                  label={<LabelText>{t('survey.past_history.option13')}</LabelText>}
                />
              </SubCategory>
              <FormControlLabelCustom
                control={<Checkbox checked={pastHistory.기타} onChange={handleChange} name="기타" />}
                label={t('survey.past_history.option14')}
              />
              {pastHistory.기타 && (
                <StyledTextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  placeholder={t('survey.past_history.other_placeholder')}
                  value={otherReason}
                  onChange={handleOtherReasonChange}
                />
              )}
              <FormControlLabelCustom
                control={<Checkbox checked={pastHistory.수술유무} onChange={handleChange} name="수술유무" />}
                label={t('survey.past_history.option15')}
              />
              {pastHistory.수술유무 && (
                <StyledTextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  placeholder={t('survey.past_history.surgery_placeholder')}
                  value={surgery}
                  onChange={handleSurgeryChange}
                />
              )}
            </StyledFormGroup>
          </StyledPaper>
        </s.OptionContainer>
      </Container>
      <s.NextButton
        variant="contained"
        color="primary"
        onClick={() => {
          onNext();
        }}
        style={{ marginTop: 'auto' }}
        disabled={isNextDisabled}
      >
        {t('button.next')}
      </s.NextButton>
    </s.SurveyContainer>
  );
};

export default PastHistoryTrigger;
