import React from 'react';
import { useTranslation } from 'react-i18next';
import * as s from './NewSurveyStyled';
// import eat from '../../../asset/img/survey/eat.png';
import less from '../../../asset/img/survey/less.png';
import regular from '../../../asset/img/survey/regular.png';
import more from '../../../asset/img/survey/more.png';

interface AmountPerMealProps {
  onNext: () => void;
  onScore: (key: string, score: number) => void;
  selectedOption: string;
  onOptionSelect: (option: string) => void;
}

const AmountPerMeal: React.FC<AmountPerMealProps> = ({ onNext, onScore, selectedOption, onOptionSelect }) => {
  const { t } = useTranslation();
  
  return (
    <s.SurveyContainer>
      <s.Question>{t('survey.amount_per_meal.question')}</s.Question>
      {/* <s.PictureContainer>
        <s.Picture src={eat} alt="대체 이미지" />
      </s.PictureContainer> */}
      <s.OptionContainer>
        <s.Options>
          <s.OptionButton
            type="button"
            variant={selectedOption === '1' ? 'contained' : 'outlined'}
            onClick={() => {
              onOptionSelect('1');
              onScore('ebProduce', -1);
            }}
          >
            <s.ThreeOptionImage src={less} alt="인분" />
            <s.OptionTitle>{t('survey.amount_per_meal.option1')}</s.OptionTitle>
          </s.OptionButton>
          <s.OptionButton
            type="button"
            variant={selectedOption === '2' ? 'contained' : 'outlined'}
            onClick={() => {
              onOptionSelect('2');
              onNext();
            }}
          >
            <s.ThreeOptionImage src={regular} alt="인분" />
            <s.OptionTitle>{t('survey.amount_per_meal.option2')}</s.OptionTitle>
          </s.OptionButton>
          <s.OptionButton
            type="button"
            variant={selectedOption === '3' ? 'contained' : 'outlined'}
            onClick={() => {
              onOptionSelect('3');
              onNext();
            }}
          >
            <s.ThreeOptionImage src={more} alt="인분" />
            <s.OptionTitle>{t('survey.amount_per_meal.option3')}</s.OptionTitle>
          </s.OptionButton>
        </s.Options>
      </s.OptionContainer>
    </s.SurveyContainer>
  );
};

export default AmountPerMeal;
