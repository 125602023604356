import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Select, MenuItem, SelectChangeEvent } from '@mui/material';
import * as s from './WelcomeStyled';
import welcomeImg from '../../../asset/img/welcome.png';
import logo from '../../../asset/img/logo.png';

export default function WelcomePresenter() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const changeLanguage = (event: SelectChangeEvent<string>) => {
    i18n.changeLanguage(event.target.value);
  };

  return (
    <s.WelcomePage>
      <s.WelcomeContainer>
        <s.LanguageSelector>
          <Select
            value={i18n.language}
            onChange={changeLanguage}
            defaultValue='ko'
            variant="outlined"
            size="small"
            style={{fontFamily: 'Noto Sans KR'}}
          >
            <MenuItem value="ko" style={{fontFamily: 'Noto Sans KR'}}>한국어</MenuItem>
            <MenuItem value="en" style={{fontFamily: 'Noto Sans KR'}}>English</MenuItem>
          </Select>
        </s.LanguageSelector>
        <s.WelcomeLogo src={logo} alt="logo" />
        <s.WelcomeTitle>{t('welcome.title')}</s.WelcomeTitle>
        <s.WelcomeSubtitle>{t('welcome.subtitle')}</s.WelcomeSubtitle>
        <s.WelcomeContent>{t('welcome.content')}</s.WelcomeContent>
        <s.WelcomeImage src={welcomeImg} alt="welcome" />
        <s.StartButton variant="contained" onClick={() => navigate('/privacy')}>
          {t('welcome.button')}
        </s.StartButton>
        <s.ImageLink href="https://storyset.com/people">
          People illustrations by Storyset
        </s.ImageLink>
      </s.WelcomeContainer>
    </s.WelcomePage>
  );
}