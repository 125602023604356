import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Global } from '@emotion/react';
import Header from './components/common/Header';
import { MainRouter } from './mainRouter';
import { globalStyles } from './globalStyles';

function App() {
  return (
    <BrowserRouter>
      <Global styles={globalStyles} />
      {/* <Header /> */}
      <MainRouter />
    </BrowserRouter>
  );
}

export default App;
