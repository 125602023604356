import { Button, TextField } from '@mui/material';
import styled from '@emotion/styled';
import * as i from './SurveyTypes';

export const StyledBtn = styled(Button)`
  && {
    width: 80%;
    height: 50px;
    background-color: #2cbc88;
  }
`;
export const StyledBtnHalf = styled(Button)<i.IBtnHalfProps>`
  && {
    width: 38%;
    height: 50px;
    /* background-color: #2cbc88; */
    background-color: ${(props) => props.colorbackground};
  }
`;

export const StyledTextFieldHalf = styled(TextField)`
  && {
    width: 38%;
    height: 50px;
    padding: 10, 10, 10, 10;
  }
`;
