import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { adminApi } from '../../../api/api-admin';
import { authApi } from '../../../api/api-auth';
import { surveyApi } from '../../../api/api-survey';
import { useHeaderTextChange } from '../../../lib/useHeader';
import { localTokenCheck } from '../../../utils/util';
import UserListPresenter from './UserListPresenter';
import { newsurveyApi } from '../../../api/api-newsurvey';
import { patientApi } from '../../../api/api-patient';

export default function UserListContainer() {
  useHeaderTextChange('유저목록');
  const [userInfo, setUserInfo] = useState({
    email: '',
    name: '',
    role: '',
  });

  const [userList, setUserList] = useState([]);
  const [pageInfo, setPageInfo] = useState({
    nowSeletedPage: 0,
    totalPages: 0,
    totalResults: 0,
  });

  const [searchText, setSearchText] = useState('');
  const [tempSearchText, setTempSearchText] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    localTokenCheck();

    const userInfoJSON = localStorage.getItem('userInfo');

    if (userInfoJSON !== null && userInfoJSON !== '') {
      const userEmail = JSON.parse(userInfoJSON).email;
      const userName = JSON.parse(userInfoJSON).name;
      const userRole = JSON.parse(userInfoJSON).role;
      setUserInfo({
        email: userEmail,
        name: userName,
        role: userRole,
      });
      getUserListByPage(1);
    } else {
      localStorage.clear();
      navigate('/signin');
    }
  }, []);

  const getUserListByPage = async (page: number) => {
    let res;

    try {
      const regex = /^[0-9]/g;
      if (searchText === '') res = await patientApi.getAllPatients(page);
      else if (regex.test(searchText)) res = await patientApi.getPatient(searchText);
      else res = await patientApi.getPateintsWithName(page, searchText);
    } catch (e) {
      console.log(e);
    } finally {
      if (res) {
        setPageInfo({
          nowSeletedPage: res.data.page,
          totalPages: res.data.limit,
          totalResults: res.data.totalResults,
        });
        setUserList(res.data.results);
      }
    }
  };

  const onClickLogout = async () => {
    const tokenJSON = localStorage.getItem('tokens');

    if (tokenJSON !== null && tokenJSON !== '') {
      await authApi.logout(JSON.parse(tokenJSON).refresh.token);
    }
    localStorage.clear();
    navigate('/signin');
  };

  const handlePageChange = async (event: React.ChangeEvent<unknown>, value: number) => {
    getUserListByPage(value);
  };

  const onClickUserCheck = (name: string, phone: string, age: string) => {
    navigate('/survey-list', { state: { name, phone, age } });
  };

  const onChangeTempSearchText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTempSearchText(e.target.value);
  };

  const onClickSearch = () => {
    setSearchText(tempSearchText);
  };

  useEffect(() => {
    getUserListByPage(1);
  }, [searchText]);

  return (
    <UserListPresenter
      userInfo={userInfo}
      userList={userList}
      pageInfo={pageInfo}
      onClickLogout={onClickLogout}
      onClickUserCheck={onClickUserCheck}
      onClickSearch={onClickSearch}
      onChangeTempSearchText={onChangeTempSearchText}
      handlePageChange={handlePageChange}
    />
  );
}
