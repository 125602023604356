import React from 'react';
import styled from 'styled-components';
import SideMenu from './SideMenu';
import IntroduceSection from './IntroduceSection';

const HomepagePresenter: React.FC = () => {
  return (
    <PageWrapper>
      <SideMenu />
      <IntroduceSection />
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  border-radius: 32px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: 100%;
  min-height: 100vh;
`;

export default HomepagePresenter;
