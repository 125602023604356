import styled from '@emotion/styled';
import { Container, TextField, Typography, Box, Button } from '@mui/material';

export const StyledMainPage = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px;
`;

export const StyledTextField = styled(TextField)`
  width: 80%;
  input {
    padding: 1rem;
  }
`;

export const CenterText = styled(Typography)`
  font-weight: 500;
  text-align: center;
`;
export const LeftText = styled(Typography)`
  font-weight: 300;
  text-align: Left;
`;

export const LogoBox = styled(Box)`
  display: flex;
  flex-direction: column;
  margin: 0 auto 1rem;
`;

export const InfoBox = styled(Box)`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

export const SilouIcon = styled.img`
  width: 120px;
  margin: 0 auto;
`;

export const StyledButton = styled(Button)`
  && {
    width: 60%;
    height: 30px;
    background-color: #0f67fe;
  }
`;

export const LanguageSelector = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
  background-color: white;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;