/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Container, Paper, Typography, FormGroup, FormControlLabel, Checkbox, TextField } from '@mui/material';
import * as s from './NewSurveyStyled';

const StyledPaper = styled(Paper)`
  padding: 0 1.5rem 1.5rem 1.5rem;
  margin: 1.5rem 0;
  overflow-y: auto;
  max-height: 50vh;
  width: 100%;
  box-sizing: border-box;
`;

const StyledFormGroup = styled(FormGroup)`
  margin-top: 16px;
`;

const LabelText = styled(Typography)`
  font-family: 'Noto Sans KR', sans-serif;
`;

const StyledTextField = styled(TextField)`
  margin-top: 8px;
  margin-left: 24px;
  width: 80%;
`;

const FormControlLabelCustom = styled(FormControlLabel)`
  width: 50%;
`;

interface ObeseMedProps {
  onNext: () => void;
  meds: { [key: string]: boolean };
  korMed: string;
  westernMed: string;
  isNextDisabled: boolean;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleKorMedChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleWesternMedChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const ObeseMed: React.FC<ObeseMedProps> = ({
  onNext,
  meds,
  korMed,
  westernMed,
  isNextDisabled,
  handleChange,
  handleKorMedChange,
  handleWesternMedChange,
}) => {
  const { t } = useTranslation();

  return (
    <s.SurveyContainer>
      <Container style={{ padding: '0' }}>
        <s.Question style={{ padding: ' 0 1.5rem 0 1.5rem' }}>{t('survey.obese_med.question')}</s.Question>
        <s.QuestionSubtitle style={{ padding: '0 1.5rem', color: '#0f67fe' }}>
          {t('survey.obese_med.subtitle')}
        </s.QuestionSubtitle>
        <s.OptionContainer>
          <StyledPaper>
            <StyledFormGroup>
              <FormControlLabelCustom
                control={<Checkbox checked={meds.해당사항없음} onChange={handleChange} name="해당사항없음" />}
                label={<LabelText>{t('survey.obese_med.option1')}</LabelText>}
              />

              <FormControlLabelCustom
                control={<Checkbox checked={meds.한약} onChange={handleChange} name="한약" />}
                label={t('survey.obese_med.option2')}
              />
              {meds.한약 && (
                <StyledTextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  placeholder={t('survey.obese_med.placeholder')}
                  value={korMed}
                  onChange={handleKorMedChange}
                />
              )}
              <FormControlLabelCustom
                control={<Checkbox checked={meds.양약} onChange={handleChange} name="양약" />}
                label={t('survey.obese_med.option3')}
              />
              {meds.양약 && (
                <StyledTextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  placeholder={t('survey.obese_med.placeholder')}
                  value={westernMed}
                  onChange={handleWesternMedChange}
                />
              )}
            </StyledFormGroup>
          </StyledPaper>
        </s.OptionContainer>
      </Container>
      <s.NextButton
        variant="contained"
        color="primary"
        onClick={() => {
          onNext();
        }}
        style={{ marginTop: 'auto' }}
        disabled={isNextDisabled}
      >
        {t('button.next')}
      </s.NextButton>
    </s.SurveyContainer>
  );
};

export default ObeseMed;
