import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import SurveyUrsPresenter from './SurveyUrsPresenter';
import { useHeaderTextChange } from '../../../../lib/useHeader';
import { RootState } from '../../../../modules';

export default function SurveyUrsContainer() {
  // header text change
  useHeaderTextChange('사전문진');

  // get store object(survey)
  const dictObject = useSelector((state: RootState) => state.survey);

  // validation for survey-hbipsn
  const [validForm, setValidForm] = useState({
    q41: false,
    q51: false,
  });

  // validation check for q4
  useEffect(() => {
    if (dictObject.q41 === -1) {
      setValidForm({
        ...validForm,
        q41: false,
      });
    } else {
      setValidForm({
        ...validForm,
        q41: true,
      });
    }
  }, [dictObject.q41]);

  // validation check for q5
  useEffect(() => {
    if (dictObject.q51 === -1) {
      setValidForm({
        ...validForm,
        q51: false,
      });
    } else {
      setValidForm({
        ...validForm,
        q51: true,
      });
    }
  }, [dictObject.q51]);

  // checking for button disable
  useEffect(() => {
    if (dictObject.q41 !== -1 && dictObject.q51 !== -1) {
      setValidForm({
        q41: true,
        q51: true,
      });
    }
  }, []);

  return <SurveyUrsPresenter dictObject={dictObject} validForm={validForm} />;
}
