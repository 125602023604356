import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
	palette: {
      primary: {
        main: "#0F67FE",
      },
      secondary: {
        main: "#3ab1f1",
      },
    },
    typography: {
      fontFamily: 'Noto Sans KR, sans-serif',
    },
});