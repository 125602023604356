import React from 'react';
import { useTranslation } from 'react-i18next';
import * as s from './NewSurveyStyled';
import calf1 from '../../../asset/img/survey/calf1.png';
import calf2 from '../../../asset/img/survey/calf2.png';
import calf3 from '../../../asset/img/survey/calf3.png';
import calf4 from '../../../asset/img/survey/calf4.png';

interface CalfCircumferenceProps {
  onNext: () => void;
  onScore: (key: string, score: number) => void;
  selectedOption: string;
  onOptionSelect: (option: string) => void;
}

const CalfCircumference: React.FC<CalfCircumferenceProps> = ({ onNext, onScore, selectedOption, onOptionSelect }) => {
  const { t } = useTranslation();
  
  return (
    <s.SurveyContainer>
      <s.Question>{t('survey.calf_circumference.question')}</s.Question>
      <s.PictureContainer>
        <s.Picture src={calf1} alt="종아리둘레" />
      </s.PictureContainer>
      <s.QuestionSubtitle>{t('survey.calf_circumference.sub_question')}</s.QuestionSubtitle>
      <s.Options>
        <s.OptionButton
          type="button"
          variant={selectedOption === '1' ? 'contained' : 'outlined'}
          onClick={() => {
            onOptionSelect('1');
            onNext();
          }}
        >
          <s.OptionTitle>{t('survey.calf_circumference.option1')}</s.OptionTitle>
          <s.ThreeOptionImage src={calf2} alt="상체" />
        </s.OptionButton>
        <s.OptionButton
          type="button"
          variant={selectedOption === '2' ? 'contained' : 'outlined'}
          onClick={() => {
            onOptionSelect('2');
            onNext();
          }}
        >
          <s.OptionTitle>{t('survey.calf_circumference.option2')}</s.OptionTitle>
          <s.ThreeOptionImage src={calf3} alt="하체" />
        </s.OptionButton>
        <s.OptionButton
          type="button"
          variant={selectedOption === '3' ? 'contained' : 'outlined'}
          onClick={() => {
            onOptionSelect('3');
            onScore('ebProduce', -1);
          }}
        >
          <s.OptionTitle>{t('survey.calf_circumference.option3')}</s.OptionTitle>
          <s.ThreeOptionImage src={calf4} alt="상하체 비슷함" />
        </s.OptionButton>
      </s.Options>
    </s.SurveyContainer>
  );
};

export default CalfCircumference;
