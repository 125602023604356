import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { authApi } from '../../../api/api-auth';
import { surveyApi } from '../../../api/api-survey';
import { useHeaderTextChange, useHeaderToggle } from '../../../lib/useHeader';
import { localTokenCheck } from '../../../utils/util';
import SurveyResultPresenter from './SurveyResultPresenter';
import { newsurveyApi } from '../../../api/api-newsurvey';
import { SurveyResultType } from '../surveys/result/ResultType';


interface stateType {
  surveyId: string;
  name: string;
  phone: string;
}

export default function SurveyResultContainer() {
  const { t, i18n } = useTranslation();
  useHeaderTextChange('사전문진 결과');

  const [survey, setSurvey] = useState({} as SurveyResultType);
  const [role, setRole] = useState('');

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    localTokenCheck();

    const userInfoJSON = localStorage.getItem('userInfo');
    if (userInfoJSON !== null && userInfoJSON !== '') {
      const userRole = JSON.parse(userInfoJSON).role;
      setRole(userRole);

      const state = location.state as stateType;
      getSurveyById(state.surveyId);
    } else {
      localStorage.clear();
    }
  }, []);

  const getSurveyById = async (id: string) => {
    let res;
    try {
      res = await newsurveyApi.getSurveyById(id);
    } catch (e) {
      console.log(e);
    } finally {
      if (res) {
        // console.log(res.data)
        // const decoded:SurveyResultType = {
        //   id: res.data.id,
        //   phone: res.data.phone,
        //   question: JSON.parse(res.data.question),
        //   disease: JSON.parse(res.data.disease),
        //   diseaseGenerateTime: res.data.diseaseGenerateTime,
        //   pastMedicalHistory: JSON.parse(res.data.pastMedicalHistory),
        //   medicine: JSON.parse(res.data.medicine),
        //   favorateFood: JSON.parse(res.data.favorateFood),
        //   basicOld: JSON.parse(res.data.basicOld),
        //   AScore: res.data.AScore,
        //   BScore: res.data.BScore,
        //   CScore: res.data.CScore,
        //   name: res.data.name,
        //   age: res.data.age
        // }
        const decoded:SurveyResultType = {
          id: res.data.id,
          phone: res.data.phone,
          question: res.data.question,
          disease: res.data.disease,
          diseaseGenerateTime: res.data.diseaseGenerateTime,
          pastMedicalHistory: res.data.pastMedicalHistory,
          medicine: res.data.medicine,
          favorateFood: res.data.favorateFood,
          basicOld: res.data.basicOld,
          AScore: res.data.AScore,
          BScore: res.data.BScore,
          CScore: res.data.CScore,
          name: res.data.name,
          age: res.data.age
        }
        // console.log(decoded);
        setSurvey(decoded);
      }
    }
  };

  const onClickLogout = async () => {
    const tokenJSON = localStorage.getItem('tokens');

    if (tokenJSON !== null && tokenJSON !== '') {
      await authApi.logout(JSON.parse(tokenJSON).refresh.token);
    }
    localStorage.clear();
    navigate('/signin');
  };

  const onClickDelete = async (surveyId: string, name: string, phone: string, age: string) => {
    let res;
    try {
      res = await newsurveyApi.deleteSurveyById(surveyId);
    } catch (e) {
      console.log(e);
    } finally {
      if (res) {
        if (res.status === 200 || res.status === 204) {
          navigate('/survey-list', { state: { name, phone, age } });
        }
      }
    }
  };

  return (
    <SurveyResultPresenter survey={survey} onClickLogout={onClickLogout} role={role} onClickDelete={onClickDelete} />
  );
}
