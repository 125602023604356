import Axios, { AxiosRequestConfig } from 'axios';
import { API_SERVER } from '../config/config';

import * as util from '../utils/util';

export const notLoginedApi = Axios.create({
  baseURL: `${API_SERVER}`,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

export const loginedApi = Axios.create({
  baseURL: `${API_SERVER}`,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

notLoginedApi.interceptors.response.use(
  function (response) {
    console.log(response);
    return response;
  },

  function (error) {
    return Promise.reject(error);
  },
);

loginedApi.interceptors.request.use(
  (config: any) => {
    if (util.localTokenCheck()) {
      const newConfig = config;

      const tokens = JSON.parse(localStorage.getItem('tokens') || 'null');
      if (newConfig !== undefined) {
        newConfig.headers.authorization = `Bearer ${tokens.access.token}`;
        newConfig.headers['X-Auth-Token'] = tokens.access.token;
      }

      return newConfig;
    }

    window.location.href = '/signin';
    return null;
  },
  function (error) {
    return Promise.reject(error);
  },
);

loginedApi.interceptors.response.use(
  function (response) {
    return response;
  },

  function (error) {
    return Promise.reject(error);
  },
);
