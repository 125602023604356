import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, To } from 'react-router-dom';
import PrivacyPresenter from './PrivacyPresenter';
import { IPrivacyInfo, setPrivacyInfo } from '../../../modules/newsurvey';
import { patientApi } from '../../../api/api-patient';


export default function PrivacyContainer() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modal, setmodal] = useState(false);
  const [privacyData, setPrivacyData] = useState({
    name: '',
    phoneNumber: '',
    exist: false,
  });


  const openModal = () => {
    setmodal(true);
  }
  const closeModal = () => {
    setmodal(false);
  }

  const onClickModalNochan = () => {
    // dispatch(setPrivacyInfo(privacyData));

    navigate('/new-surveys'
      , {
        state: {
          name: privacyData.name,
          phoneNumber: privacyData.phoneNumber,
          isHealthCare: true,
        },
      }
    );
  }

  const onClickModalChan = () => {
    // dispatch(setPrivacyInfo(privacyData));
    navigate('/info', {
          state: {
            name: privacyData.name,
            phoneNumber: privacyData.phoneNumber,
            isHealthCare: true,
          },
        });
  }

  const handleInputChange = (event: { target: { name: any; value: any } }) => {
    const { name, value } = event.target;
    setPrivacyData((prevData) => {
      const updatedData = { ...prevData, [name]: value.toString() };
      return updatedData;
    });
  };

  const handleNext = async () => {
    // 1. 이름과 전화번호가 DB에 저장되어 있는지 확인
    // 2. 저장되어 있지 않다면 DB에 저장
    // 3. 저장되어 있다면 기존에 저장된 정보가 있다고 알림 -> 기존 정보를 사용할 것인지 물어봄
    // 4-1. 기존 정보를 사용한다면 'new-surveys'로 이동
    // 4-2. 기존 정보를 사용하지 않는다면 '/info'로 이동

    let isExist = false;
    let res
    try{
      res = await patientApi.getPatientExistByPhone(privacyData.phoneNumber)
    } catch(e){
      console.log(e);
    }
    finally{
      if (res){
        if(res.data.exist){
          isExist = true;
          // 왜 set코드 작동 안하지?
          setPrivacyData((prevData) => {
            const updatedData = { ...prevData, exist: isExist };
            return updatedData;
          });
        }
      }
    }

    // console.log({...privacyData, exist: isExist});
    dispatch(setPrivacyInfo({...privacyData, exist: isExist}));

    navigate('/info', {
      state: {
        name: privacyData.name,
        phoneNumber: privacyData.phoneNumber,
        isHealthCare: true,
      },
    });

    // if (isExist) {
    //   // 정보 받아오기 -> new-surveys로 이동
    //   openModal();
    //   // navigate('new-surveys');
    // } else {
    //   navigate('/info', {
    //     state: {
    //       name: privacyData.name,
    //       phoneNumber: privacyData.phoneNumber,
    //       isHealthCare: true,
    //     },
    //   });
    // }
  };

  return <PrivacyPresenter privacyData={privacyData} handleInputChange={handleInputChange} handleNext={handleNext} 
  modal={modal} openModal={openModal} closeModal={closeModal} onClickModalChan={onClickModalChan} 
  onClickModalNochan={onClickModalNochan} />;
}
