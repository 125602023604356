import { css } from '@emotion/react';
import '@fontsource/noto-sans-kr/400.css';
import '@fontsource/noto-sans-kr/700.css';

export const globalStyles = css`
  html {
    font-family: 'Noto Sans KR', sans-serif;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: 'Noto Sans KR', sans-serif;
  }

  @font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: local('Noto Sans KR'), local('NotoSansKR-Regular'),
      url('@fontsource/noto-sans-kr/files/noto-sans-kr-korean-400-normal.woff2') format('woff2');
    unicoderange: U+1F00-1FFF;
  }
  @font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: local('Noto Sans KR Bold'), local('NotoSansKR-Bold'),
      url('@fontsource/noto-sans-kr/files/noto-sans-kr-korean-700-normal.woff2') format('woff2');
    unicoderange: U+1F00-1FFF;
  }
`;
