import React from 'react';
import { useTranslation } from 'react-i18next';

function EnergyGraph({energyBalanceScore, energyCreateScore, energyCirculationScore}:
    {energyBalanceScore:number, energyCreateScore:number, energyCirculationScore:number}
) {
    const { t, i18n } = useTranslation();
    const center = {x: 149.5, y: 120.6};
    const energyBalanceAxis = {x:149.5, y:21};
    const energyCreateAxis = {x:63.384 ,y:170.5};
    const energyCirculationAxis = {x:235.853 ,y:170.5};

    const calcDiagStatus = () => {
        // 0: 정상, 1: 생성, 2: 순환, 3: 균형
        const minscore = Math.min(energyCreateScore, energyCirculationScore, energyBalanceScore)
        if(energyCreateScore < 70 && energyCreateScore === minscore)
            return 1;
        if(energyBalanceScore < 70 && energyBalanceScore === minscore)
            return 3;
        if(energyCirculationScore < 70 && energyCirculationScore === minscore)
            return 2;
        return 0;
    };

    const diagStatus = calcDiagStatus();
    const createColor = diagStatus === 1 ? "#FF6E6E" : "#B8B8B8"
    const circulationColor = diagStatus === 2 ? "#FF6E6E" : "#B8B8B8"
    const balanceColor = diagStatus === 3 ? "#FF6E6E" : "#B8B8B8"

    const calcNode = (score:number, axis:{x:number, y:number}) =>{
        return {x: center.x + (axis.x - center.x)*score/100, y: center.y + (axis.y - center.y)*score/100}
    };

    const energyBalanceNode = calcNode(energyBalanceScore,energyBalanceAxis);
    const energyCreateNode = calcNode(energyCreateScore,energyCreateAxis);
    const energyCirculationNode = calcNode(energyCirculationScore,energyCirculationAxis);

    const trianglePoints = `${energyBalanceNode.x},${energyBalanceNode.y} ${energyCreateNode.x},${energyCreateNode.y} ${energyCirculationNode.x},${energyCirculationNode.y}`;
    // <svg width="100%" height="100%" viewBox="0 0 299 221" fill="none" xmlns="http://www.w3.org/2000/svg">
    return(
        <svg width="100%" height="100%" viewBox="0 0 299 231" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="150" cy="121" r="99.5" fill="#EAF2FF" stroke="#B8B8B8"/>
        <line x1="149.5" y1="121" x2="149.5" y2="21" stroke="#B8B8B8"/>
        <line x1="149.25" y1="120.567" x2="235.853" y2="170.567" stroke="#B8B8B8"/>
        <line x1="149.987" y1="120.433" x2="63.384" y2="170.433" stroke="#B8B8B8"/>
        <circle cx="150" cy="121" r="32.5" stroke="#B8B8B8"/>
        <circle cx="149.5" cy="120.5" r="66" stroke="#B8B8B8"/>
        <text x="149.5" y="10" fontSize="12" textAnchor='middle' fill={balanceColor}>
            {t('result.main.energy_balance_text')}
        </text>
        <text x="58.384" y="185.567" fontSize="12" textAnchor='end' fill={createColor}>
            {t('result.main.energy_create_text')}
        </text>
        <text x="240.853" y="185.567" fontSize="12" textAnchor='start' fill={circulationColor}>
            {t('result.main.energy_circulation_text')}
        </text>
        <polygon points={trianglePoints} fill="#0F67FE" fillOpacity="0.76" stroke="#0F67FE" />
        </svg>
    );
};

export default EnergyGraph;