import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import HealthCarePresenter from './HealthCarePresenter';
import { IHealthcareInfo, setHealthcareInfo } from '../../../modules/newsurvey';


type StepType =
  | '질환및증상'
  | '발병시기및계기'
  | '과거력'
  | '비만약'
  | '기호식품';

const stepOrder: StepType[] = [
  '질환및증상',
  '발병시기및계기',
  '과거력',
  '비만약',
  '기호식품',
];

export default function HealthCareContainer() {
  const location = useLocation();
  const [data, setData] = useState(
    typeof location.state === 'object' && location.state !== null ? { ...location.state } : {},
  );

  const dispatch = useDispatch();

  const [step, setStep] = useState<StepType>('질환및증상');

  const totalSteps = stepOrder.length;

  const currentStepIndex = stepOrder.indexOf(step);

  const navigate = useNavigate();

  const goToNextStep = () => {
    if (currentStepIndex < stepOrder.length - 1) {
      setStep(stepOrder[currentStepIndex + 1]);
    }
  };

  const goToPreviousStep = () => {
    if (currentStepIndex > 0) {
      setStep(stepOrder[currentStepIndex - 1]);
    }
  };

  const goToSpecificStep = (step: StepType) => {
    setStep(step);
  };

  // Disease
  const [diseases, setDiseases] = useState<{ id: string; name: string }[]>([]);
  const [newDisease, setNewDisease] = useState('');

  const handleNewDiseaseChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewDisease(e.target.value);
  };

  const handleAddDisease = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    if (newDisease.trim() !== '') {
      setDiseases([...diseases, { id: Date.now().toString(), name: newDisease }]);
      setNewDisease('');
    }
  };

  const handleRemoveDisease = (id: string) => {
    setDiseases(diseases.filter((disease) => disease.id !== id));
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) return;
    const items = Array.from(diseases);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setDiseases(items);
  };

  // DiseaseTimeTrigger
  const [symptoms, setSymptoms] = useState({
    특별한이유없음: false,
    과식: false,
    폭식: false,
    급하게먹음: false,
    상한음식: false,
    과로: false,
    야간근무: false,
    책상근무: false,
    잘눕는습관: false,
    과도한성생활: false,
    화냄: false,
    슬픔: false,
    우울함: false,
    일상스트레스: false,
    찬바람: false,
    무더위: false,
    습한: false,
    건조한: false,
    교통사고: false,
    낙상: false,
    외상: false,
    세균감염: false,
    기타: false,
  });

  const [selectedOption, setSelectedOption] = useState('');
  const [otherReason, setOtherReason] = useState('');
  const [isNextDisabled, setIsNextDisabled] = useState(true);

  const handleSelect = (option: string) => {
    setSelectedOption(option);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSymptoms({ ...symptoms, [event.target.name]: event.target.checked });
  };

  const handleOtherReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOtherReason(event.target.value);
  };

  useEffect(() => {
    const hasSelectedSymptom = Object.values(symptoms).some((value) => value);
    const isOtherValid = symptoms.기타 ? otherReason.trim() !== '' : true;
    setIsNextDisabled(!hasSelectedSymptom || !isOtherValid || (!symptoms.특별한이유없음 && !selectedOption));
  }, [symptoms, otherReason, selectedOption]);

  // PastHistory
  const [pastHistory, setPastHistory] = useState({
    해당사항없음: false,
    고혈압: false,
    당뇨: false,
    고지혈증: false,
    중풍: false,
    암: false,
    심장질환: false,
    폐질환: false,
    소화기질환: false,
    간질환: false,
    신장질환: false,
    알러지: false,
    자가면역질환: false,
    기타: false,
    수술유무: false,
  });

  const [otherReasonP, setOtherReasonP] = useState('');
  const [surgery, setSurgery] = useState('');
  const [isNextDisabledP, setIsNextDisabledP] = useState(true);

  const handleChangeP = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPastHistory({ ...pastHistory, [event.target.name]: event.target.checked });
  };

  const handleOtherReasonChangeP = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOtherReasonP(event.target.value);
  };

  const handleSurgeryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSurgery(event.target.value);
  };

  useEffect(() => {
    const hasSelectedPastHistory = Object.values(pastHistory).some((value) => value);
    const isOtherValid = pastHistory.기타 ? otherReasonP.trim() !== '' : true;
    const isSurgeryValid = pastHistory.수술유무 ? surgery.trim() !== '' : true;
    setIsNextDisabledP(!hasSelectedPastHistory || !isOtherValid || !isSurgeryValid);
  }, [pastHistory, otherReasonP, surgery]);

  // ObeseMed
  const [meds, setMeds] = useState({
    해당사항없음: false,
    한약: false,
    양약: false,
  });

  const [korMed, setKorMed] = useState('');
  const [westernMed, setWesternMed] = useState('');
  const [isNextDisabledM, setIsNextDisabledM] = useState(true);

  const handleChangeM = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMeds({ ...meds, [event.target.name]: event.target.checked });
  };

  const handleKorMedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKorMed(event.target.value);
  };

  const handleWesternMedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWesternMed(event.target.value);
  };

  useEffect(() => {
    const hasSelectedMed = Object.values(meds).some((value) => value);
    const isKorMedValid = meds.한약 ? korMed.trim() !== '' : true;
    const isWesternMedValid = meds.양약 ? westernMed.trim() !== '' : true;
    setIsNextDisabledM(!hasSelectedMed || !isKorMedValid || !isWesternMedValid);
  }, [meds, korMed, westernMed]);

  // FavoriteFood
  const [formData, setFormData] = useState({
    question1: 'no',
    question1Amount: '',
    question2: 'no',
    question2Amount1: '',
    question2Amount2: '',
    question3: 'no',
    question3Amount: '',
  });

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
      ...(value === 'no' && name === 'question1' && { question1Amount: '' }),
      ...(value === 'no' && name === 'question2' && { question2Amount1: '', question2Amount2: '' }),
      ...(value === 'no' && name === 'question3' && { question3Amount: '' }),
    }));
  };

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const isValid = () => {
    if (formData.question1 === 'yes' && formData.question1Amount === '') return false;
    if (formData.question2 === 'yes' && (formData.question2Amount1 === '' || formData.question2Amount2 === ''))
      return false;
    if (formData.question3 === 'yes' && formData.question3Amount === '') return false;
    return true;
  };

  const sendDatas = () => {
    // Send data to API
    const hinfo : IHealthcareInfo = {
      disease: diseases,
      trigger: symptoms,
      disease_generate_time: selectedOption,
      past_history: pastHistory,
      otherReason,
      otherReasonP,
      surgery,
      isNextDisabledP,
      diet_med: meds,
      korMed,
      westernMed,
      isNextDisabledM,
      favorate_food: formData
    };
    
    dispatch(setHealthcareInfo(hinfo))

    navigate('/survey-user-result');
  }

  return (
    <HealthCarePresenter
      step={step}
      totalSteps={totalSteps}
      currentStepIndex={currentStepIndex}
      goToNextStep={goToNextStep}
      goToPreviousStep={goToPreviousStep}
      goToSpecificStep={goToSpecificStep}
      // Disease
      diseases={diseases}
      newDisease={newDisease}
      handleNewDiseaseChange={handleNewDiseaseChange}
      handleAddDisease={handleAddDisease}
      handleRemoveDisease={handleRemoveDisease}
      onDragEnd={onDragEnd}
      // DiseaseTimeTrigger
      symptoms={symptoms}
      selectedOption={selectedOption}
      otherReason={otherReason}
      isNextDisabled={isNextDisabled}
      handleSelect={handleSelect}
      handleChange={handleChange}
      handleOtherReasonChange={handleOtherReasonChange}
      // PastHistory
      pastHistory={pastHistory}
      otherReasonP={otherReasonP}
      surgery={surgery}
      isNextDisabledP={isNextDisabledP}
      handleChangeP={handleChangeP}
      handleOtherReasonChangeP={handleOtherReasonChangeP}
      handleSurgeryChange={handleSurgeryChange}
      // ObeseMed
      meds={meds}
      korMed={korMed}
      westernMed={westernMed}
      isNextDisabledM={isNextDisabledM}
      handleChangeM={handleChangeM}
      handleKorMedChange={handleKorMedChange}
      handleWesternMedChange={handleWesternMedChange}
      // FavoriteFood
      formData={formData}
      handleRadioChange={handleRadioChange}
      handleTextChange={handleTextChange}
      isValid={isValid}
      // API
      sendDatas={sendDatas}
    />
  );
}
