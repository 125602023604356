import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Select, MenuItem, SelectChangeEvent } from '@mui/material';
import siloIcon from '../../../asset/img/logo.png';
import * as s from './MainStlyed';

interface MainProps {
  userInfo: UserInfoData;
  onClickLogout: () => void;
  onClickViewResult: () => void;
  onClickViewUserResult: () => void;
  onClickSurvey: () => void;
}

interface UserInfoData {
  name: string;
  role: string;
  email: string;
  id: string;
}
export default function MainPresenter(props: MainProps) {
  const { userInfo, onClickLogout, onClickViewResult, onClickViewUserResult, onClickSurvey } = props;
  const { t, i18n } = useTranslation();

  const changeLanguage = (event: SelectChangeEvent<string>) => {
    i18n.changeLanguage(event.target.value);
  };

  return (
    <s.StyledMainPage maxWidth="sm">
      <s.LanguageSelector>
          <Select
            value={i18n.language}
            onChange={changeLanguage}
            defaultValue='ko'
            variant="outlined"
            size="small"
            style={{fontFamily: 'Noto Sans KR'}}
          >
            <MenuItem value="ko" style={{fontFamily: 'Noto Sans KR'}}>한국어</MenuItem>
            <MenuItem value="en" style={{fontFamily: 'Noto Sans KR'}}>English</MenuItem>
          </Select>
        </s.LanguageSelector>
      <s.LogoBox>
        <s.SilouIcon src={siloIcon} />
        <s.CenterText variant="h4">KAIDOC</s.CenterText>
      </s.LogoBox>
      {userInfo.role === 'admin' ? (
        <s.InfoBox>
          <s.LeftText variant="body2">{t('manage.admin')}</s.LeftText>
        </s.InfoBox>
      ) : (
        <s.InfoBox>
          <s.LeftText variant="body2">{t('manage.list.id')} : {userInfo.email}</s.LeftText>
          <s.LeftText variant="body2">{t('manage.list.name')} : {userInfo.name}</s.LeftText>
        </s.InfoBox>
      )}

      {/*

      <s.StyledButton
        variant="contained"
        sx={{ marginTop: '2rem', marginBottom: '1rem' }}
        onClick={() => onClickSurvey()}
      >
        설문하기
      </s.StyledButton>
      */}
      {/*

      <s.StyledButton
        variant="contained"
        sx={{ marginTop: '1rem', marginBottom: '1rem' }}
        onClick={() => onClickViewResult()}
      >
        설문 결과 리스트 보기
      </s.StyledButton>
      */}

      {userInfo.role === 'admin' ? (
        <s.StyledButton
          variant="contained"
          sx={{ marginTop: '1rem', marginBottom: '1rem' }}
          onClick={() => onClickViewUserResult()}
        >
          {t('manage.see_result')}
        </s.StyledButton>
      ) : (
        ''
      )}
      <s.StyledButton
        variant="contained"
        sx={{ marginTop: '1rem', marginBottom: '1rem' }}
        onClick={() => onClickLogout()}
      >
        {t('manage.logout')}
      </s.StyledButton>
    </s.StyledMainPage>
  );
}
