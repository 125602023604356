import React from 'react';
import { useTranslation } from 'react-i18next';
import * as s from './NewSurveyStyled';
import dream from '../../../asset/img/survey/dream.png';

interface EnoughSleepProps {
  onNext: () => void;
  onScore: (key: string, score: number) => void;
  selectedOption: string;
  onOptionSelect: (option: string) => void;
}

const EnoughSleep: React.FC<EnoughSleepProps> = ({ onNext, onScore, selectedOption, onOptionSelect }) => {
  const { t } = useTranslation();
  
  return (
    <s.SurveyContainer>
      <s.Question>{t('survey.enough_sleep.question')}</s.Question>
      <s.PictureContainer>
        <s.Picture src={dream} alt="대체 이미지" />
        <div>
          <s.QuestionSubtitle style={{fontWeight: "700", marginBottom: "0.5rem"}}>{t('survey.enough_sleep.sub_question1')}</s.QuestionSubtitle>
          <s.QuestionSubtitle style={{fontWeight: "700"}}>{t('survey.enough_sleep.sub_question2')}</s.QuestionSubtitle>
        </div>
      </s.PictureContainer>
      <s.InnerOptionContainer>
        <s.OptionContainer>
          <s.Options>
            <s.OptionButton
              type="button"
              variant={selectedOption === '1' ? 'contained' : 'outlined'}
              onClick={() => {
                onOptionSelect('1');
                onNext();
              }}
            >
              <s.OptionTitle>{t('survey.enough_sleep.option1')}</s.OptionTitle>
              <s.OptionSubtitle>{t('survey.enough_sleep.sub_option1')}</s.OptionSubtitle>
            </s.OptionButton>
            <s.OptionButton
              type="button"
              variant={selectedOption === '2' ? 'contained' : 'outlined'}
              onClick={() => {
                onOptionSelect('2');
                onScore('ebBalanceControl', -2);
              }}
            >
              <s.OptionTitle>{t('survey.enough_sleep.option2')}</s.OptionTitle>
              <s.OptionTitle>{t('survey.enough_sleep.sub_option2')}</s.OptionTitle>
            </s.OptionButton>
          </s.Options>
        </s.OptionContainer>
        <s.OptionContainer>
          <s.Options>
            <s.OptionButton
              type="button"
              variant={selectedOption === '3' ? 'contained' : 'outlined'}
              onClick={() => {
                onOptionSelect('3');
                onScore('ebBalanceControl', -4);
              }}
            >
              <s.OptionTitle>{t('survey.enough_sleep.option3')}</s.OptionTitle>
              <s.OptionTitle>{t('survey.enough_sleep.sub_option3')}</s.OptionTitle>
            </s.OptionButton>
            <s.OptionButton
              type="button"
              variant={selectedOption === '4' ? 'contained' : 'outlined'}
              onClick={() => {
                onOptionSelect('4');
                onScore('ebBalanceControl', -6);
              }}
            >
              <s.OptionTitle>{t('survey.enough_sleep.option4')}</s.OptionTitle>
              <s.OptionTitle>{t('survey.enough_sleep.sub_option4')}</s.OptionTitle>
            </s.OptionButton>
          </s.Options>
        </s.OptionContainer>
      </s.InnerOptionContainer>
    </s.SurveyContainer>
  );
};

export default EnoughSleep;
