import React from 'react';
import SurveyForm from '../new_surveys/common/SurveyForm';
import Disease from '../new_surveys/Disease';
import DiseaseTimeTrigger from '../new_surveys/DiseaseTimeTrigger';
import PastHistory from '../new_surveys/PastHistory';
import ObeseMed from '../new_surveys/ObeseMed';
import FavoriteFood from '../new_surveys/FavoriteFood';

type StepType = '질환및증상' | '발병시기및계기' | '과거력' | '비만약' | '기호식품';

type HealthCarePresenterProps = {
  step: StepType;
  totalSteps: number;
  currentStepIndex: number;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
  goToSpecificStep: (step: StepType) => void;
  // Disease
  diseases: { id: string; name: string }[];
  newDisease: string;
  handleNewDiseaseChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleAddDisease: (event: { preventDefault: () => void }) => void;
  handleRemoveDisease: (id: string) => void;
  onDragEnd: (result: any) => void;
  // DiseaseTimeTrigger
  symptoms: { [key: string]: boolean };
  selectedOption: string;
  otherReason: string;
  isNextDisabled: boolean;
  handleSelect: (option: string) => void;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleOtherReasonChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  // PastHistory
  pastHistory: { [key: string]: boolean };
  otherReasonP: string;
  surgery: string;
  isNextDisabledP: boolean;
  handleChangeP: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleOtherReasonChangeP: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSurgeryChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  // ObeseMed
  meds: { [key: string]: boolean };
  korMed: string;
  westernMed: string;
  isNextDisabledM: boolean;
  handleChangeM: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleKorMedChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleWesternMedChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  // FavoriteFood
  formData: {
    question1: string;
    question1Amount: string;
    question2: string;
    question2Amount1: string;
    question2Amount2: string;
    question3: string;
    question3Amount: string;
  };
  handleRadioChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleTextChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isValid: () => boolean;
  // API
  sendDatas: () => void;
};

export default function HealthCarePresenter({
  step,
  totalSteps,
  currentStepIndex,
  goToNextStep,
  goToPreviousStep,
  goToSpecificStep,
  // Disease
  diseases,
  newDisease,
  handleNewDiseaseChange,
  handleAddDisease,
  handleRemoveDisease,
  onDragEnd,
  // DiseaseTimeTrigger
  symptoms,
  selectedOption,
  otherReason,
  isNextDisabled,
  handleSelect,
  handleChange,
  handleOtherReasonChange,
  // PastHistory
  pastHistory,
  otherReasonP,
  surgery,
  isNextDisabledP,
  handleChangeP,
  handleOtherReasonChangeP,
  handleSurgeryChange,
  // ObeseMed
  meds,
  korMed,
  westernMed,
  isNextDisabledM,
  handleChangeM,
  handleKorMedChange,
  handleWesternMedChange,
  // FavoriteFood
  formData,
  handleRadioChange,
  handleTextChange,
  isValid,
  // API
  sendDatas,
}: HealthCarePresenterProps) {
  // const controlProps = () => ({
  //   onNext: goToNextStep,
  //   onScore: handleScore,
  //   selectedOptions: {selectedOptions},
  //   onOptionSelect: handleOptionSelect,
  // });

  return (
    <main>
      <SurveyForm
        progress={(currentStepIndex + 1) / totalSteps}
        totalSteps={totalSteps}
        onNext={goToNextStep}
        onPrev={goToPreviousStep}
      >
        {step === '질환및증상' && (
          <Disease
            onNext={goToNextStep}
            onSpecific={goToSpecificStep}
            diseases={diseases}
            newDisease={newDisease}
            onNewDiseaseChange={handleNewDiseaseChange}
            handleAddDisease={handleAddDisease}
            handleRemoveDisease={handleRemoveDisease}
            onDragEnd={onDragEnd}
          />
        )}
        {step === '발병시기및계기' && (
          <DiseaseTimeTrigger
            onNext={goToNextStep}
            symptoms={symptoms}
            selectedOption={selectedOption}
            otherReason={otherReason}
            isNextDisabled={isNextDisabled}
            handleSelect={handleSelect}
            handleChange={handleChange}
            handleOtherReasonChange={handleOtherReasonChange}
          />
        )}
        {step === '과거력' && (
          <PastHistory
            onNext={goToNextStep}
            pastHistory={pastHistory}
            otherReason={otherReasonP}
            surgery={surgery}
            isNextDisabled={isNextDisabledP}
            handleChange={handleChangeP}
            handleOtherReasonChange={handleOtherReasonChangeP}
            handleSurgeryChange={handleSurgeryChange}
          />
        )}
        {step === '비만약' && (
          <ObeseMed
            onNext={goToNextStep}
            meds={meds}
            korMed={korMed}
            westernMed={westernMed}
            isNextDisabled={isNextDisabledM}
            handleChange={handleChangeM}
            handleKorMedChange={handleKorMedChange}
            handleWesternMedChange={handleWesternMedChange}
          />
        )}
        {step === '기호식품' && (
          <FavoriteFood
            onNext={goToNextStep}
            formData={formData}
            handleRadioChange={handleRadioChange}
            handleTextChange={handleTextChange}
            isValid={isValid}
            sendDatas={sendDatas}
          />
        )}
      </SurveyForm>
    </main>
  );
}
