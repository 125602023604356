import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SurveyPasHisPresenter from './SurveyPasHisPresenter';
import { useHeaderTextChange } from '../../../../lib/useHeader';
import { RootState } from '../../../../modules';
import { surveyApi } from '../../../../api/api-survey';
import { restSurveyStore } from '../../../../modules/survey';

export default function SurveyPasHisContainer() {
  // header text change
  useHeaderTextChange('사전문진');

  // get store object(survey)
  const dictObject = useSelector((state: RootState) => state.survey);
  const [survey, setSurvey] = useState<any>('');

  // validation for survey-pashis
  const [validForm, setValidForm] = useState({
    name: false,
    etc: true,
    pasSurgery: true,
  });

  const [validErrMessages, setErrMessages] = useState({
    etcErrMessage: '',
    pasSurgeryErrMessage: '',
  });

  // validation for etc
  useEffect(() => {
    if (dictObject.past_medical_history_13 && dictObject.past_medical_history_13_content.length === 0) {
      setValidForm({
        ...validForm,
        etc: false,
      });
      setErrMessages({
        ...validErrMessages,
        etcErrMessage: '내용을 입력해주세요.',
      });
    } else {
      setValidForm({
        ...validForm,
        etc: true,
      });
      setErrMessages({
        ...validErrMessages,
        etcErrMessage: '',
      });
    }
  }, [dictObject.past_medical_history_13, dictObject.past_medical_history_13_content]);

  // validation for pasSurgery
  useEffect(() => {
    if (dictObject.past_medical_history_14 && dictObject.past_medical_history_14_content.length === 0) {
      setValidForm({
        ...validForm,
        pasSurgery: false,
      });
      setErrMessages({
        ...validErrMessages,
        pasSurgeryErrMessage: '내용을 입력해주세요.',
      });
    } else {
      setValidForm({
        ...validForm,
        pasSurgery: true,
      });
      setErrMessages({
        ...validErrMessages,
        pasSurgeryErrMessage: '',
      });
    }
  }, [dictObject.past_medical_history_14, dictObject.past_medical_history_14_content]);

  // initial validation
  useEffect(() => {
    if (dictObject.name.length === 0) {
      setValidForm({
        ...validForm,
        name: false,
      });
    } else {
      setValidForm({
        ...validForm,
        name: true,
      });
    }
  }, []);

  function toDate(dateStr: string) {
    const yyyyMMdd = String(dateStr);
    const sYear = yyyyMMdd.substring(0, 4);
    const sMonth = yyyyMMdd.substring(4, 6);
    const sDate = yyyyMMdd.substring(6, 8);

    return new Date(Number(sYear), Number(sMonth) - 1, Number(sDate));
  }

  // transform data for server side
  const transformData = () =>
    new Promise((resolve) => {
      const surveyContent = dictObject;
      resolve({
        bodySympton: {
          a1: surveyContent.body_symptom_1_1 === true ? 1 : 0,
          a2: surveyContent.body_symptom_1_2 === true ? 1 : 0,
          a3: surveyContent.body_symptom_1_3 === true ? 1 : 0,
          a4: surveyContent.body_symptom_1_4 === true ? 1 : 0,
          a5: surveyContent.body_symptom_1_5 === true ? 1 : 0,
          a6: surveyContent.body_symptom_1_6 === true ? 1 : 0,
        },
        internal: {
          q1: {
            a1: surveyContent.internal_1_1 === true ? 1 : 0,
            a2: surveyContent.internal_1_2 === true ? 1 : 0,
            a3: surveyContent.internal_1_3 === true ? 1 : 0,
            a4: surveyContent.internal_1_4 === true ? 1 : 0,
            a5: surveyContent.internal_1_5 === true ? 1 : 0,
            a6: surveyContent.internal_1_6 === true ? 1 : 0,
          },
          q2: {
            a1: surveyContent.internal_2_1 === true ? 1 : 0,
            a2: surveyContent.internal_2_2 === true ? 1 : 0,
            a3: surveyContent.internal_2_3 === true ? 1 : 0,
          },
          q3: {
            a1: surveyContent.internal_3_1 === true ? 1 : 0,
            a2: surveyContent.internal_3_2 === true ? 1 : 0,
          },
        },
        musclePhysique: {
          q1: {
            a1: surveyContent.muscle_physique_1_1 === true ? 1 : 0,
            a2: surveyContent.muscle_physique_1_2 === true ? 1 : 0,
            a3: surveyContent.muscle_physique_1_3 === true ? 1 : 0,
          },
          q2: {
            a1: surveyContent.muscle_physique_2_1 === true ? 1 : 0,
            a2: surveyContent.muscle_physique_2_2 === true ? 1 : 0,
            a3: surveyContent.muscle_physique_2_3 === true ? 1 : 0,
            a4: surveyContent.muscle_physique_2_4 === true ? 1 : 0,
            a5: surveyContent.muscle_physique_2_5 === true ? 1 : 0,
            a6: surveyContent.muscle_physique_2_6 === true ? 1 : 0,
            a7: surveyContent.muscle_physique_2_7 === true ? 1 : 0,
          },
          q3: {
            a1: surveyContent.muscle_physique_3_1 === true ? 1 : 0,
            a2: surveyContent.muscle_physique_3_2 === true ? 1 : 0,
            a3: surveyContent.muscle_physique_3_3 === true ? 1 : 0,
            a4: surveyContent.muscle_physique_3_4 === true ? 1 : 0,
            a5: surveyContent.muscle_physique_3_5 === true ? 1 : 0,
            a6: surveyContent.muscle_physique_3_6 === true ? 1 : 0,
            a7: surveyContent.muscle_physique_3_7 === true ? 1 : 0,
            a8: surveyContent.muscle_physique_3_8 === true ? 1 : 0,
            a9: surveyContent.muscle_physique_3_9 === true ? 1 : 0,
            a10: surveyContent.muscle_physique_3_10 === true ? 1 : 0,
          },
        },
        autoimmune: {
          a1: surveyContent.autoimmune_1 === true ? 1 : 0,
          a2: surveyContent.autoimmune_2 === true ? 1 : 0,
          a3: surveyContent.autoimmune_3 === true ? 1 : 0,
          a4: surveyContent.autoimmune_4 === true ? 1 : 0,
          a5: surveyContent.autoimmune_5 === true ? 1 : 0,
        },
        nervousSystem: {
          a1: surveyContent.nervous_system_1 === true ? 1 : 0,
          a2: surveyContent.nervous_system_2 === true ? 1 : 0,
          a3: surveyContent.nervous_system_3 === true ? 1 : 0,
          a4: surveyContent.nervous_system_4 === true ? 1 : 0,
          a5: surveyContent.nervous_system_5 === true ? 1 : 0,
          a6: surveyContent.nervous_system_6 === true ? 1 : 0,
          a7: surveyContent.nervous_system_7 === true ? 1 : 0,
        },
        psychiatry: {
          a1: surveyContent.psychiatry_1 === true ? 1 : 0,
          a2: surveyContent.psychiatry_2 === true ? 1 : 0,
          a3: surveyContent.psychiatry_3 === true ? 1 : 0,
          a4: surveyContent.psychiatry_4 === true ? 1 : 0,
        },
        gynecological: {
          a1: surveyContent.gynecological_1 === true ? 1 : 0,
          a2: surveyContent.gynecological_2 === true ? 1 : 0,
          a3: surveyContent.gynecological_3 === true ? 1 : 0,
          a4: surveyContent.gynecological_4 === true ? 1 : 0,
          a5: surveyContent.gynecological_5 === true ? 1 : 0,
          a6: surveyContent.gynecological_6 === true ? 1 : 0,
        },
        pediatric: {
          a1: surveyContent.pediatric_1 === true ? 1 : 0,
          a2: surveyContent.pediatric_2 === true ? 1 : 0,
          a3: surveyContent.pediatric_3 === true ? 1 : 0,
          a4: surveyContent.pediatric_4 === true ? 1 : 0,
          a5: surveyContent.pediatric_5 === true ? 1 : 0,
        },
        otorhinolaryngology: {
          a1: surveyContent.otorhinolaryngology_1 === true ? 1 : 0,
          a2: surveyContent.otorhinolaryngology_2 === true ? 1 : 0,
          a3: surveyContent.otorhinolaryngology_3 === true ? 1 : 0,
          a4: surveyContent.otorhinolaryngology_4 === true ? 1 : 0,
          a5: surveyContent.otorhinolaryngology_5 === true ? 1 : 0,
          a6: surveyContent.otorhinolaryngology_6 === true ? 1 : 0,
          a7: surveyContent.otorhinolaryngology_7 === true ? 1 : 0,
        },
        skin: {
          a1: surveyContent.skin_1 === true ? 1 : 0,
          a2: surveyContent.skin_2 === true ? 1 : 0,
          a3: surveyContent.skin_3 === true ? 1 : 0,
          a4: surveyContent.skin_4 === true ? 1 : 0,
        },
        cancer: {
          a1: surveyContent.cancer_1 === true ? 1 : 0,
          a2: surveyContent.cancer_2 === true ? 1 : 0,
          a3: surveyContent.cancer_3 === true ? 1 : 0,
          a4: surveyContent.cancer_4 === true ? 1 : 0,
          a5: surveyContent.cancer_5 === true ? 1 : 0,
          a6: surveyContent.cancer_6 === true ? 1 : 0,
          a7: surveyContent.cancer_7 === true ? 1 : 0,
          a8: surveyContent.cancer_8 === true ? 1 : 0,
          a9: surveyContent.cancer_9 === true ? 1 : 0,
          a10: surveyContent.cancer_10 === true ? 1 : 0,
          a11: surveyContent.cancer_11 === true ? 1 : 0,
        },
        etc: {
          disease: surveyContent.etc_disease === true ? 1 : 0,
          diseaseContent: surveyContent.etc_disease_content,
          reason: surveyContent.etc_reason === true ? 1 : 0,
          reasonContent: surveyContent.etc_reason_content,
        },
        diseaseGenerateTime:
          typeof surveyContent.disease_generate_time === 'string' && surveyContent.height !== ''
            ? parseInt(surveyContent.disease_generate_time, 10)
            : 0,
        reason: {
          noReason: surveyContent.reason_no_reason === true ? 1 : 0,
          foodHabbit: {
            a1: surveyContent.reason_food_habbit_1 === true ? 1 : 0,
            a2: surveyContent.reason_food_habbit_2 === true ? 1 : 0,
            a3: surveyContent.reason_food_habbit_3 === true ? 1 : 0,
            a4: surveyContent.reason_food_habbit_4 === true ? 1 : 0,
          },
          lifeHabbit: {
            a1: surveyContent.reason_life_habbit_1 === true ? 1 : 0,
            a2: surveyContent.reason_life_habbit_2 === true ? 1 : 0,
            a3: surveyContent.reason_life_habbit_3 === true ? 1 : 0,
            a4: surveyContent.reason_life_habbit_4 === true ? 1 : 0,
            a5: surveyContent.reason_life_habbit_5 === true ? 1 : 0,
          },
          stress: {
            a1: surveyContent.reason_stress_1 === true ? 1 : 0,
            a2: surveyContent.reason_stress_2 === true ? 1 : 0,
            a3: surveyContent.reason_stress_3 === true ? 1 : 0,
            a4: surveyContent.reason_stress_4 === true ? 1 : 0,
          },
          weather: {
            a1: surveyContent.reason_weather_1 === true ? 1 : 0,
            a2: surveyContent.reason_weather_2 === true ? 1 : 0,
            a3: surveyContent.reason_weather_3 === true ? 1 : 0,
            a4: surveyContent.reason_weather_4 === true ? 1 : 0,
          },
          external: {
            a1: surveyContent.reason_external_1 === true ? 1 : 0,
            a2: surveyContent.reason_external_2 === true ? 1 : 0,
            a3: surveyContent.reason_external_3 === true ? 1 : 0,
            a4: surveyContent.reason_external_4 === true ? 1 : 0,
          },
        },
        basicInfo: {
          name: surveyContent.name,
          gender: surveyContent.gender === '남자' ? 0 : 1,
          age: typeof surveyContent.age === 'string' && surveyContent.age !== '' ? parseInt(surveyContent.age, 10) : 0,
          phone: surveyContent.phone,
          height:
            typeof surveyContent.height === 'string' && surveyContent.height !== ''
              ? parseInt(surveyContent.height, 10)
              : 0,
          weight:
            typeof surveyContent.weight === 'string' && surveyContent.weight !== ''
              ? parseInt(surveyContent.weight, 10)
              : 0,
          heartRate:
            typeof surveyContent.heart_rate === 'string' && surveyContent.heart_rate !== ''
              ? parseInt(surveyContent.heart_rate, 10)
              : 0,
          bloodPressureMin:
            typeof surveyContent.blood_pressure_min === 'string' && surveyContent.blood_pressure_min !== ''
              ? parseInt(surveyContent.blood_pressure_min, 10)
              : 0,
          bloodPressureMax:
            typeof surveyContent.blood_pressure_max === 'string' && surveyContent.blood_pressure_max !== ''
              ? parseInt(surveyContent.blood_pressure_max, 10)
              : 0,
        },
        q1: {
          a1: typeof surveyContent.q11 === 'string' ? parseInt(surveyContent.q11, 10) : 0,
          neck: typeof surveyContent.q12_neck === 'string' ? parseInt(surveyContent.q12_neck, 10) : 0,
          waist: typeof surveyContent.q12_waist === 'string' ? parseInt(surveyContent.q12_waist, 10) : 0,
          calf: typeof surveyContent.q12_calf === 'string' ? parseInt(surveyContent.q12_calf, 10) : 0,
        },
        q2: {
          a1: typeof surveyContent.q21 === 'string' ? parseInt(surveyContent.q21, 10) : 0,
          a2: surveyContent.q22 === true ? 1 : 0,
          a3: surveyContent.q23 === true ? 1 : 0,
          a4: surveyContent.q24 === true ? 1 : 0,
          a5: surveyContent.q25 === true ? 1 : 0,
        },
        q3: {
          a1: typeof surveyContent.q31 === 'string' ? parseInt(surveyContent.q31, 10) : 0,
          a2: surveyContent.q32 === true ? 1 : 0,
          a3: surveyContent.q33 === true ? 1 : 0,
          a4: surveyContent.q34 === true ? 1 : 0,
          a5: surveyContent.q35 === true ? 1 : 0,
        },
        q4: {
          a1: typeof surveyContent.q41 === 'string' ? parseInt(surveyContent.q41, 10) : 0,
        },
        q5: {
          a1: typeof surveyContent.q51 === 'string' ? parseInt(surveyContent.q51, 10) : 0,
        },
        q6: {
          a1: surveyContent.q61 === true ? 1 : 0,
          a2: surveyContent.q62 === true ? 1 : 0,
          a3: surveyContent.q63 === true ? 1 : 0,
          a4: typeof surveyContent.q64 === 'string' ? parseInt(surveyContent.q64, 10) : 0,
        },
        q7: {
          a1: surveyContent.q71 === true ? 1 : 0,
          a2: surveyContent.q72 === true ? 1 : 0,
          a3: surveyContent.q73 === true ? 1 : 0,
          a4: surveyContent.q74 === true ? 1 : 0,
          a5: surveyContent.q75 === true ? 1 : 0,
          a6: surveyContent.q76 === true ? 1 : 0,
        },
        q8: {
          a1: typeof surveyContent.q81 === 'string' ? parseInt(surveyContent.q81, 10) : 0,
        },
        q9: {
          a1: surveyContent.q91 === true ? 1 : 0,
          a2: surveyContent.q92 === true ? 1 : 0,
          a3: surveyContent.q93 === true ? 1 : 0,
          a4: surveyContent.q94 === true ? 1 : 0,
          a5: surveyContent.q95 === true ? 1 : 0,
          a6: surveyContent.q96 === true ? 1 : 0,
          a7: surveyContent.q97 === true ? 1 : 0,
        },
        favoriteFood: {
          cigarette: {
            do: surveyContent.favorite_food_cigarette === '0' ? 0 : 1,
            numOfDay:
              typeof surveyContent.favorite_food_cigarette_1 === 'string' &&
              surveyContent.favorite_food_cigarette === '1'
                ? parseInt(surveyContent.favorite_food_cigarette_1, 10)
                : 0,
          },
          drink: {
            do: surveyContent.favorite_food_drink === '0' ? 0 : 1,
            numOfWeek:
              typeof surveyContent.favorite_food_drink_1 === 'string' && surveyContent.favorite_food_drink === '1'
                ? parseInt(surveyContent.favorite_food_drink_1, 10)
                : 0,
            numOfCup:
              typeof surveyContent.favorite_food_drink_2 === 'string' && surveyContent.favorite_food_drink === '1'
                ? parseInt(surveyContent.favorite_food_drink_2, 10)
                : 0,
          },
          caffein: {
            do: surveyContent.favorite_food_caffeine === '0' ? 0 : 1,
            numOfCup:
              typeof surveyContent.favorite_food_caffeine_1 === 'string' && surveyContent.favorite_food_caffeine === '1'
                ? parseInt(surveyContent.favorite_food_caffeine_1, 10)
                : 0,
          },
        },
        pastMedicalHistory: {
          a1: {
            exist: surveyContent.past_medical_history_1 === true ? 1 : 0,
          },
          a2: {
            exist: surveyContent.past_medical_history_2 === true ? 1 : 0,
          },
          a3: {
            exist: surveyContent.past_medical_history_3 === true ? 1 : 0,
          },
          a4: {
            exist: surveyContent.past_medical_history_4 === true ? 1 : 0,
          },
          a5: {
            exist: surveyContent.past_medical_history_5 === true ? 1 : 0,
          },
          a6: {
            exist: surveyContent.past_medical_history_6 === true ? 1 : 0,
          },
          a7: {
            exist: surveyContent.past_medical_history_7 === true ? 1 : 0,
          },
          a8: {
            exist: surveyContent.past_medical_history_8 === true ? 1 : 0,
          },
          a9: {
            exist: surveyContent.past_medical_history_9 === true ? 1 : 0,
          },
          a10: {
            exist: surveyContent.past_medical_history_10 === true ? 1 : 0,
          },
          a11: {
            exist: surveyContent.past_medical_history_11 === true ? 1 : 0,
          },
          a12: {
            exist: surveyContent.past_medical_history_12 === true ? 1 : 0,
          },
          a13: {
            exist: surveyContent.past_medical_history_13 === true ? 1 : 0,
            content: surveyContent.past_medical_history_13_content,
          },
          a14: {
            exist: surveyContent.past_medical_history_14 === true ? 1 : 0,
            content: surveyContent.past_medical_history_14_content,
          },
          a15: {
            exist: surveyContent.past_medical_history_15 === true ? 1 : 0,
          },
        },
      });
    });

  const dispatch = useDispatch();

  const onClickSubmit = async () => {
    const data = await transformData();

    // send server with data
    let res;
    try {
      res = await surveyApi.postSurvey(data);
    } catch (e) {
      console.log(e);
    } finally {
      if (res) {
        setSurvey(res.data);
      }
    }

    // reset survey store
    dispatch(restSurveyStore());
  };

  return (
    <SurveyPasHisPresenter
      dictObject={dictObject}
      survey={survey}
      validForm={validForm}
      validErrMessages={validErrMessages}
      onClickSubmit={onClickSubmit}
    />
  );
}
