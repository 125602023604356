import {
  FormControl,
  Radio,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Autocomplete,
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import './Survey.scss';
import React, { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSurveyChange } from '../../../../lib/useSurvey';
import * as i from './SurveyTypes';
import * as s from './SurveyStyled';

export function SurveyTitle({ title }: i.ISurveyTitleProps) {
  return (
    <div>
      <div className="subTitle">{title}</div>
      <div className="divLineUpper" />
      <div className="divLineLower" />
    </div>
  );
}

export function SurveySingleSelect(Props: i.ISurveyProps) {
  // eslint-disable-next-line react/destructuring-assignment
  const { qTitle, title, questions } = Props.surveyItem;
  const { defaultValue, disabled } = Props;
  const [selectedValue, onSelectedChange] = useState<string | number>(-1);

  useEffect(() => {
    onSelectedChange(parseInt(defaultValue, 10));
  }, []);

  return (
    <div className="container">
      {title !== '' ? <div className="subTitle">{title}</div> : ''}
      <div className="items">
        {questions.map((question, index) => (
          <RadioItem
            disabled={disabled}
            content={question}
            selectedValue={selectedValue}
            onSelectedChange={onSelectedChange}
            value={index}
            qTitle={qTitle}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
          />
        ))}
      </div>
    </div>
  );
}

export function SurveySingleSelectVer2(Props: i.ISurveyProps) {
  // eslint-disable-next-line react/destructuring-assignment
  const { qTitle, title, questions } = Props.surveyItem;
  const { defaultValue } = Props;
  const [selectedValue, onSelectedChange] = useState<string | number>(-1);

  useEffect(() => {
    onSelectedChange(parseInt(defaultValue, 10));
  }, []);

  return (
    <div className="containerVer2">
      <div className="subTitleVer2">{title}</div>
      <div className="itemsVer2">
        {questions.map((question, index) => (
          <RadioItem
            content={question}
            selectedValue={selectedValue}
            onSelectedChange={onSelectedChange}
            value={index}
            qTitle={qTitle}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
          />
        ))}
      </div>
    </div>
  );
}

export function SurveySingleSelectVer3(Props: i.ISurveyPropsRow) {
  // eslint-disable-next-line react/destructuring-assignment
  const { qTitle, questions } = Props.surveyItem;
  const { defaultValue, disabled } = Props;
  const [selectedValue, onSelectedChange] = useState<string | number>(-1);

  useEffect(() => {
    onSelectedChange(parseInt(defaultValue, 10));
  }, []);

  return (
    <div className="containerVer3">
      <div className="itemsVer3">
        {questions.map((question, index) => (
          <RadioItem
            disabled={disabled}
            content=""
            selectedValue={selectedValue}
            onSelectedChange={onSelectedChange}
            value={index}
            qTitle={qTitle}
            key={question}
          />
        ))}
      </div>
    </div>
  );
}

function RadioItem(Props: i.IRadioItemProps) {
  const { content, selectedValue, onSelectedChange, value, qTitle, disabled } = Props;
  const dispatch = useDispatch();
  return (
    <div className="item">
      <div className="text">{content}</div>
      <Radio
        disabled={disabled}
        checked={selectedValue === value}
        onChange={(e) => {
          onSelectedChange(parseInt(e.target.value, 10));
          useSurveyChange(dispatch, qTitle, e.target.value);
        }}
        value={value}
        color="success"
        size="small"
      />
    </div>
  );
}

export function SurveyMultiSelect(Props: i.ISurveyCheckBoxProps) {
  // eslint-disable-next-line react/destructuring-assignment
  const { qTitles, title, questions } = Props.surveyItem;
  const { defaultValues, disabled } = Props;
  const dispatch = useDispatch();

  return (
    <div className="checkbox-container">
      <div className="subTitle">{title}</div>
      <div className="items">
        {questions.map((question, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className="item" key={index}>
            <div className="text">{question}</div>
            <div>
              <Checkbox
                disabled={disabled}
                checked={
                  defaultValues === undefined || defaultValues[index] === undefined ? false : defaultValues[index]
                }
                color="success"
                size="small"
                onChange={(e) => {
                  useSurveyChange(dispatch, qTitles[index], e.target.checked);
                }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export function SurveyMultiSelectVer2(Props: i.ISurveyCheckBoxProps) {
  // eslint-disable-next-line react/destructuring-assignment
  const { qTitles, title, questions } = Props.surveyItem;
  const { defaultValues, disabled } = Props;
  const dispatch = useDispatch();

  return (
    <div className="checkboxVer2-container">
      <div className="subTitle">{title}</div>
      <div className="items">
        {questions.map((question, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className="item" key={index}>
            <div className="text">{question}</div>
            <div>
              <Checkbox
                disabled={disabled}
                checked={
                  defaultValues === undefined || defaultValues[index] === undefined ? false : defaultValues[index]
                }
                color="success"
                size="small"
                onChange={(e) => {
                  useSurveyChange(dispatch, qTitles[index], e.target.checked);
                }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export function SurveyMultiSelectWithNoProblem(Props: i.ISurveyCheckBoxProps) {
  // eslint-disable-next-line react/destructuring-assignment
  const { qTitles, title, questions, noProblem } = Props.surveyItem;
  const { defaultValues, disabled } = Props;
  const dispatch = useDispatch();
  return (
    <div className="checkbox-container">
      <div className="subTitle">{title}</div>
      <div className="items">
        {questions.map((question, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className="item" key={index}>
            <div className="text">{question}</div>
            <div>
              <Checkbox
                disabled={disabled}
                checked={
                  defaultValues === undefined || defaultValues[index] === undefined ? false : defaultValues[index]
                }
                color="success"
                size="small"
                onChange={(e) => {
                  useSurveyChange(dispatch, qTitles[index], e.target.checked);
                  if (e.target.checked) {
                    if (index === qTitles.length - 1) {
                      let i = 0;
                      for (i; i < defaultValues.length - 1; i += 1) {
                        useSurveyChange(dispatch, qTitles[i], false);
                      }
                    } else {
                      useSurveyChange(dispatch, qTitles[qTitles.length - 1], false);
                    }
                  }
                }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export function DropBox(Props: i.IDropBoxProps) {
  // eslint-disable-next-line react/destructuring-assignment
  const { qTitle, title, questions, defaultValue, disabled, size } = Props.dropBoxItem;
  const [value, setValue] = useState<number | string | boolean | null>('');
  const dispatch = useDispatch();
  useEffect(() => {
    setValue(defaultValue);
  }, []);
  return (
    <div className="dropBox-container">
      <div className="dropBox-title">{title}</div>
      <div className="dropBox-select">
        <FormControl fullWidth required>
          <InputLabel>{title}</InputLabel>
          <Select
            disabled={disabled}
            size={size}
            value={value}
            onChange={(event) => {
              setValue(event.target.value);
              useSurveyChange(dispatch, qTitle, event.target.value);
            }}
          >
            {questions.map((question) => (
              <MenuItem key={question} value={question}>
                {question}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
}

export function DropBoxWithBr(Props: i.IDropBoxProps) {
  // eslint-disable-next-line react/destructuring-assignment
  const { qTitle, title, questions } = Props.dropBoxItem;
  const [value, setValue] = useState<string>('');
  const dispatch = useDispatch();
  return (
    <div className="dropBoxWithBr-container">
      <div className="dropBoxWithBr-title">{title}</div>
      <div className="dropBoxWithBr-select">
        <FormControl fullWidth required>
          <InputLabel>{title}</InputLabel>
          <Select
            value={value}
            label="value"
            onChange={(event) => {
              setValue(event.target.value);
              // useSurveyChange(dispatch, qTitle, event.target.value);
            }}
          >
            {questions.map((question) => (
              <MenuItem key={question} value={question}>
                {question}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
}

export function TextFieldBox(Props: i.ITextFieldBoxProps) {
  const { qTitle, title, placeholder, disabled, type, require, defaultValue, helperText, error } =
    // eslint-disable-next-line react/destructuring-assignment
    Props.textFieldBoxItem;
  const dispatch = useDispatch();
  return (
    <div className="textFieldBox-container">
      <div className="textFieldBox-title">{title}</div>

      <div className="textFieldBox-textField">
        <TextField
          fullWidth
          label={title}
          disabled={disabled}
          required={require}
          placeholder={placeholder}
          type={type}
          defaultValue={defaultValue}
          helperText={helperText}
          error={error}
          onChange={(e) => {
            useSurveyChange(dispatch, qTitle, e.target.value);
          }}
        />
      </div>
    </div>
  );
}

export function TextFieldBoxWithHelper(Props: i.ITextFieldBoxProps) {
  const {
    qTitle,
    title,
    placeholder,
    disabled,
    type,
    require,
    defaultValue,
    helperText,
    helperTitle,
    error,
    helperText2,
  } =
    // eslint-disable-next-line react/destructuring-assignment
    Props.textFieldBoxItem;

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();
  return (
    <>
      <div className="textFieldBox-container">
        <div className="textFieldBox-title">
          {title}
          {/*
          <div
            onClick={() => {
              setOpen(true);
            }}
            role="alert"
            onKeyPress={(e) => {
              console.log(e);
            }}
          >
            <HelpOutlineIcon className="questionMark" />
          </div>
           */}
        </div>
        <div className="textFieldBox-textField">
          <TextField
            fullWidth
            label={title}
            disabled={disabled}
            required={require}
            placeholder={placeholder}
            type={type}
            defaultValue={defaultValue}
            error={error}
            helperText={helperText2}
            onChange={(e) => {
              useSurveyChange(dispatch, qTitle, e.target.value);
            }}
          />
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{helperTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{helperText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>닫기</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export function StyledButton(Props: i.IBtnProps) {
  // eslint-disable-next-line react/destructuring-assignment
  const { text, link, disabled } = Props.btnItem;
  const { onClickSubmit } = Props;
  const navigate = useNavigate();

  return (
    <div className="btnContainer">
      <s.StyledBtn
        variant="contained"
        disabled={disabled}
        onClick={() => {
          if (link) navigate(link);
          if (onClickSubmit !== undefined) onClickSubmit();
        }}
      >
        {text}
      </s.StyledBtn>
    </div>
  );
}

function StyledButtonHalf(Props: i.IBtnProps) {
  const [open, setOpen] = React.useState<boolean>(false);
  // eslint-disable-next-line react/destructuring-assignment
  const { text, color, link, disabled } = Props.btnItem;
  const { onClickSubmit } = Props;
  const navigate = useNavigate();

  return (
    <>
      <s.StyledBtnHalf
        colorbackground={color}
        variant="contained"
        disabled={disabled}
        onClick={() => {
          if (link) navigate(link);
          if (onClickSubmit) onClickSubmit();
        }}
      >
        {text}
      </s.StyledBtnHalf>
      <AlertDialog open={open} setOpen={setOpen} />
    </>
  );
}

export function AlertDialog(Props: i.IAlertDialog) {
  const { open, setOpen } = Props;
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open alert dialog
      </Button> */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">필수 항목을 모두 선택해 주세요.</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description"> 선택 항목을 다시 확인해주세요.</DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>Disagree</Button> */}
          <Button onClick={handleClose} autoFocus>
            닫기
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export function AlertDialogPreSurvey(Props: i.IConfirmAlertDialog) {
  const { open, setOpen, onClickConfirm } = Props;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">※ 유의사항 ※</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{`설문은 하루 1회 가능하며,\n 최종 제출 시 수정이 불가능합니다.`}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>Disagree</Button> */}
          <Button onClick={onClickConfirm} autoFocus>
            확인
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export function BtnPreNext(Props: {
  linkPre: string;
  linkNex: string;
  disabled?: boolean;
  onClickSubmit?: () => void;
}) {
  const { linkPre, linkNex, disabled, onClickSubmit } = Props;
  return (
    <div className="btnContainerWithRowDirection">
      <StyledButtonHalf btnItem={{ text: '이전', color: '#DADADA', link: linkPre, disabled: false }} />
      <StyledButtonHalf
        btnItem={{ text: '다음', color: '#2cbc88', link: linkNex, disabled }}
        onClickSubmit={onClickSubmit}
      />
    </div>
  );
}

export function BtnPreEnd(Props: { linkPre: string; linkNex: string; disabled?: boolean; onClickSubmit?: () => void }) {
  const { linkPre, linkNex, disabled, onClickSubmit } = Props;
  return (
    <div className="btnContainerWithRowDirection">
      <StyledButtonHalf btnItem={{ text: '이전', color: '#DADADA', link: linkPre, disabled: false }} />
      <StyledButtonHalf
        btnItem={{ text: '설문 종료', color: '#2cbc88', link: linkNex, disabled }}
        onClickSubmit={onClickSubmit}
      />
    </div>
  );
}

export function SelectAndAnswer(Props: i.ISelectAnswerProps) {
  const dispatch = useDispatch();
  // const questions = ['아니다', '그렇다'];
  // eslint-disable-next-line react/destructuring-assignment
  const { qTitle, title, content, unit, questions } = Props;
  const { defaultValue, defaultValueText, disabled } = Props;
  const [selectedValue, onSelectedChange] = useState<string | number>(-1);

  useEffect(() => {
    onSelectedChange(parseInt(defaultValue, 10));
  }, []);

  return (
    <div className="containerVerFavFod">
      <div className="subTitle">{title}</div>
      <div className="items">
        {questions.map((question, index) => (
          <RadioItem
            disabled={disabled}
            content={question}
            selectedValue={selectedValue}
            onSelectedChange={onSelectedChange}
            value={index}
            qTitle={qTitle}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
          />
        ))}
        <div className="content-container">
          <div className="content-content">{content}</div>
          <s.StyledTextFieldHalf
            hiddenLabel
            size="small"
            disabled={selectedValue !== 1 || disabled}
            type="number"
            defaultValue={defaultValueText}
            onChange={(e) => {
              useSurveyChange(dispatch, qTitle.concat('_1'), e.target.value);
            }}
          />
          <div className="content-unit">{unit}</div>
        </div>
      </div>
    </div>
  );
}

export function SelectAndAnswerDrink(Props: i.ISelectAnswerPropsVer2) {
  // const { reduxValue } = Props;
  const questions = ['아니다', '마신다'];
  // eslint-disable-next-line react/destructuring-assignment
  const [selectedValue, onSelectedChange] = useState<string | number>(-1);
  const { defaultValue, defaultValueText, defaultValueText2, disabled } = Props;

  useEffect(() => {
    onSelectedChange(parseInt(defaultValue, 10));
  }, []);

  const dispatch = useDispatch();

  return (
    <div className="containerVerFavFodDrink">
      <div className="subTitle">술을 주 1회 이상 마신다 *</div>
      <div className="items">
        {questions.map((question, index) => (
          <RadioItem
            disabled={disabled}
            content={question}
            selectedValue={selectedValue}
            onSelectedChange={onSelectedChange}
            value={index}
            qTitle="favorite_food_drink"
            // eslint-disable-next-line react/no-array-index-key
            key={index}
          />
        ))}

        <div className="content-container">
          <div className="inner-container">
            <div className="content-content">음주량</div>
            <s.StyledTextFieldHalf
              hiddenLabel
              size="small"
              disabled={selectedValue !== 1 || disabled}
              type="number"
              defaultValue={defaultValueText}
              onChange={(e) => {
                useSurveyChange(dispatch, 'favorite_food_drink_1', e.target.value);
              }}
            />
            <div className="content-unit">회/1주</div>
          </div>
          <div className="inner-container">
            <div className="content-content">{'　　　'}</div>
            <s.StyledTextFieldHalf
              hiddenLabel
              size="small"
              disabled={selectedValue !== 1 || disabled}
              type="number"
              defaultValue={defaultValueText2}
              onChange={(e) => {
                useSurveyChange(dispatch, 'favorite_food_drink_2', e.target.value);
              }}
            />
            <div className="content-unit">잔/1회</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function CheckBoxWithDropBox(Props: i.ICheckBoxWithDropBoxProps) {
  const { qTitle, title, defaultValue, disabled } = Props;
  const [valueCbx, setValueCbx] = useState<boolean>(false);
  const [valueDigYear, setValuDigYear] = useState<string>('');
  const [valueMed, setValueMed] = useState<string>('');
  const [dates, setDates] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const today = new Date();
    const date = parseInt(String(today.getFullYear()), 10);
    let step;
    for (step = -50; step <= 3; step += 1) {
      dates.push(String(date + step));
    }
    setDates(dates);
    setLoading(true);
    setValueCbx(defaultValue[0]);
    setValuDigYear(defaultValue[1]);
    setValueMed(defaultValue[2]);
  }, []);

  return (
    <div className="checkBoxWithDropBox-container">
      <div className="items">
        <div className="title">{title}</div>
        <div className="checkBox">
          <Checkbox
            disabled={disabled}
            checked={valueCbx}
            color="success"
            size="small"
            onChange={(e) => {
              setValueCbx(e.target.checked);
              useSurveyChange(dispatch, qTitle, e.target.checked);
            }}
          />
        </div>
        <div className="digYear">
          {loading && (
            <FormControl sx={{ width: '6rem' }} required size="small" disabled={!valueCbx}>
              <InputLabel>연도</InputLabel>
              <Select
                disabled={disabled}
                value={valueDigYear}
                label="value"
                onChange={(event) => {
                  setValuDigYear(event.target.value);
                  useSurveyChange(dispatch, qTitle.concat('_year'), event.target.value);
                }}
              >
                {dates.map((date: string, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <MenuItem key={`${date}-${index}`} value={date}>
                    {date}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </div>
        <div className="medication">
          {loading && (
            <FormControl sx={{ width: '6rem' }} required size="small" disabled={!valueCbx}>
              <InputLabel>여부</InputLabel>
              <Select
                disabled={disabled}
                value={valueMed}
                label="value"
                onChange={(event) => {
                  setValueMed(event.target.value);
                  useSurveyChange(dispatch, qTitle.concat('_medi'), event.target.value);
                }}
              >
                {['O', 'X'].map((OorX: string) => (
                  <MenuItem key={OorX} value={OorX}>
                    {OorX}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </div>
      </div>
    </div>
  );
}

export function CheckBoxFlexDirecRow(Props: i.ICheckBoxFlexDirecRowProps) {
  const { qTitle, title, defaultValue, disabled } = Props;
  const [valueCbx, setValueCbx] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setValueCbx(defaultValue);
  }, []);

  return (
    <div className="checkBoxFlexDirecRow-container">
      <div className="items">
        <div className="title">{title}</div>
        <div className="checkBox">
          <Checkbox
            disabled={disabled}
            checked={valueCbx}
            color="success"
            size="small"
            onChange={(e) => {
              setValueCbx(e.target.checked);
              useSurveyChange(dispatch, qTitle, e.target.checked);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export function CheckBoxFlexDirecRowForOne(Props: i.ICheckBoxFlexDirecRowProps) {
  const { qTitle, title, defaultValue, disabled } = Props;
  const [valueCbx, setValueCbx] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setValueCbx(defaultValue);
  }, []);

  return (
    <div className="checkBoxFlexDirecRowForOne-container">
      <div className="items">
        <div className="title">{title}</div>
        <div className="checkBox">
          <Checkbox
            disabled={disabled}
            checked={valueCbx}
            color="success"
            size="small"
            onChange={(e) => {
              setValueCbx(e.target.checked);
              useSurveyChange(dispatch, qTitle, e.target.checked);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export function CheckBoxWithTextField(Props: i.ICheckBoxWithTextFieldProps) {
  const { qTitle, title, defaultCheckValue, defaultTextValue, disabled, helperText, placeholder, error } = Props;
  const [valueCbx, setValueCbx] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setValueCbx(defaultCheckValue);
  }, []);

  return (
    <>
      <div className="checkBoxFlexDirecRow-container">
        <div className="items">
          <div className="title">{title}</div>
          <div className="checkBox">
            <Checkbox
              disabled={disabled}
              checked={valueCbx}
              color="success"
              size="small"
              onChange={(e) => {
                setValueCbx(e.target.checked);
                useSurveyChange(dispatch, qTitle, e.target.checked);
              }}
            />
          </div>
        </div>
      </div>
      <div className="checkBoxFlexDirecRow-container">
        <div className="items">
          <div className="textField">
            <TextField
              fullWidth
              size="small"
              disabled={!valueCbx || disabled}
              required
              placeholder={placeholder}
              defaultValue={defaultTextValue}
              helperText={helperText}
              error={error}
              onBlur={(e) => {
                useSurveyChange(dispatch, qTitle.concat('_content'), e.target.value);
              }}
            />
          </div>
          <div className="checkBox"> </div>
        </div>
      </div>
    </>
  );
}

export function CheckBoxWithBlank() {
  return <div className="check-blank" />;
}

export function ControlledAccordions(Props: i.IControlledAccordionsProps) {
  const { title, items, defaultValues, disabled, expand, autoCompleteContent } = Props;

  const [expanded, setExpanded] = React.useState('');

  const handleChange = (panel: any) => (event: any, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    if (expand) {
      setExpanded(title);
    }

    if (autoCompleteContent && title === autoCompleteContent.category.split(' > ')[1]) {
      setExpanded(title);
    }
  }, [autoCompleteContent]);

  return (
    <Accordion expanded={expanded === title} onChange={handleChange(title)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
        <Typography sx={{ flexShrink: 0 }}>{title}</Typography>
        {/* <Typography sx={{ color: 'text.secondary' }}>I am an accordion</Typography> */}
      </AccordionSummary>
      <AccordionDetails>
        {items.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <AccordionItem
            qTitle={item.qTitle}
            title={item.title}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            defaultValue={
              defaultValues === undefined || defaultValues[index] === undefined ? false : defaultValues[index]
            }
            disabled={disabled}
          />
        ))}
      </AccordionDetails>
    </Accordion>
  );
}

export function AccordionItem(Props: i.IAccordianItemCheckBoxProps) {
  const dispatch = useDispatch();
  const { disabled, qTitle, defaultValue, title } = Props;

  return (
    <>
      <div className="accordianItem-container">
        <p className="title">{title}</p>
        <div className="checkBox">
          <Checkbox
            disabled={disabled}
            checked={defaultValue}
            color="success"
            size="small"
            onChange={(e) => {
              useSurveyChange(dispatch, qTitle, e.target.checked);
            }}
          />
        </div>
      </div>
      <div />
    </>
  );
}

export function ComboBox(Props: { autoCompleteContent: any; setAutoCompleteContent: Dispatch<SetStateAction<any>> }) {
  const { autoCompleteContent, setAutoCompleteContent } = Props;

  return (
    <Autocomplete
      id="grouped-autocomplete"
      options={items}
      groupBy={(option) => option.category}
      getOptionLabel={(option) => option.label}
      onChange={(event, newValue) => {
        setAutoCompleteContent(newValue);
      }}
      // size="small"
      fullWidth
      // eslint-disable-next-line react/jsx-props-no-spreading
      renderInput={(params) => <TextField {...params} label="질환명 검색" />}
    />
  );
}

const items = [
  { label: '만성피로', category: '전신 증상' },
  { label: '비만', category: '전신 증상' },
  { label: '다한증', category: '전신 증상' },
  { label: '고혈압', category: '전신 증상' },
  { label: '당뇨', category: '전신 증상' },
  { label: '고지혈증', category: '전신 증상' },
  { label: '역류성 식도염', category: '내과 질환 > 소화기 질환' },
  { label: '기능성 소화불량', category: '내과 질환 > 소화기 질환' },
  { label: '위궤양', category: '내과 질환 > 소화기 질환' },
  { label: '과민성 대장 증후군', category: '내과 질환 > 소화기 질환' },
  { label: '만성 변비', category: '내과 질환 > 소화기 질환' },
  { label: '설사', category: '내과 질환 > 소화기 질환' },
  { label: '만성기침', category: '내과 질환 > 호흡기 질환' },
  { label: '알러지', category: '내과 질환 > 호흡기 질환' },
  { label: '천식', category: '내과 질환 > 호흡기 질환' },
  { label: '만성 전립선염', category: '내과 질환 > 비뇨생식기 질환' },
  { label: '소변실금', category: '내과 질환 > 비뇨생식기 질환' },
  { label: '교통사고 후유증', category: '근 골격계 질환' },
  { label: '턱 관절통', category: '근 골격계 질환' },
  { label: '골다공증', category: '근 골격계 질환' },
  { label: '목통증(항강)', category: '근 골격계 질환 > 목.허리 척추질환' },
  { label: '목 디스크', category: '근 골격계 질환 > 목.허리 척추질환' },
  { label: '허리통증', category: '근 골격계 질환 > 목.허리 척추질환' },
  { label: '허리 디스크', category: '근 골격계 질환 > 목.허리 척추질환' },
  { label: '척추관협착증', category: '근 골격계 질환 > 목.허리 척추질환' },
  { label: '척추측만증', category: '근 골격계 질환 > 목.허리 척추질환' },
  { label: '압박골절', category: '근 골격계 질환 > 목.허리 척추질환' },
  { label: '어깨통증(오십견)', category: '근 골격계 질환 > 팔 다리 관절질환' },
  { label: '손목통증', category: '근 골격계 질환 > 팔 다리 관절질환' },
  { label: '손목터널증후군', category: '근 골격계 질환 > 팔 다리 관절질환' },
  { label: '손가락 통증', category: '근 골격계 질환 > 팔 다리 관절질환' },
  { label: '수족냉증', category: '근 골격계 질환 > 팔 다리 관절질환' },
  { label: '골반통', category: '근 골격계 질환 > 팔 다리 관절질환' },
  { label: '엉덩이 뼈괴사', category: '근 골격계 질환 > 팔 다리 관절질환' },
  { label: '무릎 퇴행성 관절염', category: '근 골격계 질환 > 팔 다리 관절질환' },
  { label: '만성발목염좌', category: '근 골격계 질환 > 팔 다리 관절질환' },
  { label: '족저근막염', category: '근 골격계 질환 > 팔 다리 관절질환' },
  { label: '류마티스', category: '자가면역질환' },
  { label: '강직성척추염', category: '자가면역질환' },
  { label: '섬유근통', category: '자가면역질환' },
  { label: '루프스', category: '자가면역질환' },
  { label: '통풍', category: '자가면역질환' },
  { label: '두통(긴장성두통, 편두통)', category: '신경계질환' },
  { label: '어지러움', category: '신경계질환' },
  { label: '중풍', category: '신경계질환' },
  { label: '파킨슨질환', category: '신경계질환' },
  { label: '치매', category: '신경계질환' },
  { label: '안면신경마비', category: '신경계질환' },
  { label: '안면경련', category: '신경계질환' },
  { label: '불면증', category: '정신과질환' },
  { label: '우울증', category: '정신과질환' },
  { label: '공황장애', category: '정신과질환' },
  { label: '외상후스트레스(PTSD)', category: '정신과질환' },
  { label: '생리통', category: '부인과질환' },
  { label: '기능성 자궁출혈', category: '부인과질환' },
  { label: '난임증', category: '부인과질환' },
  { label: '산후질환', category: '부인과질환' },
  { label: '유산후유증', category: '부인과질환' },
  { label: '자궁질환', category: '부인과질환' },
  { label: '소아 성장', category: '소아과질환' },
  { label: '소아비만', category: '소아과질환' },
  { label: '자페스펙트럼', category: '소아과질환' },
  { label: '코피', category: '소아과질환' },
  { label: '야제증(밤에 우는것)', category: '소아과질환' },
  { label: '안구건조증', category: '안이비인후과 질환' },
  { label: '이명(메니에르) 및 난청', category: '안이비인후과 질환' },
  { label: '만성중이염', category: '안이비인후과 질환' },
  { label: '만성비염(알러지)', category: '안이비인후과 질환' },
  { label: '축농증', category: '안이비인후과 질환' },
  { label: '만성구내염', category: '안이비인후과 질환' },
  { label: '구강건조(쇼그렌증후군)', category: '안이비인후과 질환' },
  { label: '아토피피부염', category: '피부질환' },
  { label: '건선', category: '피부질환' },
  { label: '두드러기', category: '피부질환' },
  { label: '탈모', category: '피부질환' },
  { label: '유방암', category: '암질환' },
  { label: '난소암', category: '암질환' },
  { label: '갑상선암', category: '암질환' },
  { label: '전립선암', category: '암질환' },
  { label: '간암', category: '암질환' },
  { label: '폐암', category: '암질환' },
  { label: '위암', category: '암질환' },
  { label: '대장암', category: '암질환' },
  { label: '췌장암', category: '암질환' },
  { label: '뇌종양', category: '암질환' },
  { label: '두경부암', category: '암질환' },
];
