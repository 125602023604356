/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Container, Paper, Typography, FormGroup, FormControlLabel, Checkbox, TextField } from '@mui/material';
import * as s from './NewSurveyStyled';

const StyledPaper = styled(Paper)`
  padding: 0 1.5rem 1.5rem 1.5rem;
  margin: 1.5rem 0;
  width: 100%;
  box-sizing: border-box;
`;

const StyledFormGroup = styled(FormGroup)`
  margin-top: 16px;
`;

const CategoryTitle = styled(Typography)`
  margin-top: 16px;
  margin-bottom: 8px;
  font-weight: bold;
  font-family: 'Noto Sans KR', sans-serif;
`;

const SubCategory = styled.div`
  display: flex;
  margin-left: 24px;
  font-family: 'Noto Sans KR', sans-serif;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const LabelText = styled(Typography)`
  font-family: 'Noto Sans KR', sans-serif;
`;

const StyledTextField = styled(TextField)`
  margin-top: 8px;
  margin-left: 24px;
  width: 80%;
`;

const FormControlLabelCustom = styled(FormControlLabel)`
  width: 100%;
`;

interface PastHistoryTriggerProps {
  pastHistory: number[];
  otherReason: string;
  surgery: string;
  otherReasonB: boolean;
  surgeryB: boolean;
}

const PastHistoryTriggerHistory: React.FC<PastHistoryTriggerProps> = ({
  pastHistory,
  otherReason,
  surgery,
  otherReasonB,
  surgeryB,
}) => {
  const { t } = useTranslation();

  return (
    <s.SurveyContainer>
      <Container style={{ padding: '0' }}>
        <s.OptionContainer style={{ padding: '0' }}>
          <StyledPaper>
            <StyledFormGroup>
              <FormControlLabelCustom
                control={<Checkbox checked={pastHistory.includes(0)} name="해당사항없음" />}
                label={<LabelText>{t('survey.past_history.option1')}</LabelText>}
              />
              <SubCategory>
                <FormControlLabelCustom
                  control={<Checkbox checked={pastHistory.includes(1)} name="고혈압" />}
                  label={<LabelText>{t('survey.past_history.option2')}</LabelText>}
                />
                <FormControlLabelCustom
                  control={<Checkbox checked={pastHistory.includes(2)} name="당뇨" />}
                  label={<LabelText>{t('survey.past_history.option3')}</LabelText>}
                />
              </SubCategory>
              <SubCategory>
                <FormControlLabelCustom
                  control={<Checkbox checked={pastHistory.includes(3)} name="고지혈증" />}
                  label={<LabelText>{t('survey.past_history.option4')}</LabelText>}
                />
                <FormControlLabelCustom
                  control={<Checkbox checked={pastHistory.includes(4)} name="중풍" />}
                  label={<LabelText>{t('survey.past_history.option5')}</LabelText>}
                />
              </SubCategory>
              <SubCategory>
                <FormControlLabelCustom
                  control={<Checkbox checked={pastHistory.includes(5)} name="암" />}
                  label={<LabelText>{t('survey.past_history.option6')}</LabelText>}
                />
                <FormControlLabelCustom
                  control={<Checkbox checked={pastHistory.includes(6)} name="심장질환" />}
                  label={<LabelText>{t('survey.past_history.option7')}</LabelText>}
                />
              </SubCategory>
              <SubCategory>
                <FormControlLabelCustom
                  control={<Checkbox checked={pastHistory.includes(7)} name="폐질환" />}
                  label={<LabelText>{t('survey.past_history.option8')}</LabelText>}
                />
                <FormControlLabelCustom
                  control={<Checkbox checked={pastHistory.includes(8)} name="소화기질환" />}
                  label={<LabelText>{t('survey.past_history.option9')}</LabelText>}
                />
              </SubCategory>
              <SubCategory>
                <FormControlLabelCustom
                  control={<Checkbox checked={pastHistory.includes(9)} name="간질환" />}
                  label={<LabelText>{t('survey.past_history.option10')}</LabelText>}
                />
                <FormControlLabelCustom
                  control={<Checkbox checked={pastHistory.includes(10)} name="신장질환" />}
                  label={<LabelText>{t('survey.past_history.option11')}</LabelText>}
                />
              </SubCategory>
              <SubCategory>
                <FormControlLabelCustom
                  control={<Checkbox checked={pastHistory.includes(11)} name="알러지" />}
                  label={<LabelText>{t('survey.past_history.option12')}</LabelText>}
                />
                <FormControlLabelCustom
                  control={<Checkbox checked={pastHistory.includes(12)} name="자가면역질환" />}
                  label={<LabelText>{t('survey.past_history.option13')}</LabelText>}
                />
              </SubCategory>
              <FormControlLabelCustom
                control={<Checkbox checked={otherReasonB} name="기타" />}
                label={t('survey.past_history.option14')}
              />
                <StyledTextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  placeholder={t('manage.health.etc')}
                  value={otherReason}
                />
              <FormControlLabelCustom
                control={<Checkbox checked={surgeryB} name="수술유무" />}
                label={t('survey.past_history.option15')}
              />
                <StyledTextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  placeholder={t('manage.health.etc')}
                  value={surgery}
                />
            </StyledFormGroup>
          </StyledPaper>
        </s.OptionContainer>
      </Container>
    </s.SurveyContainer>
  );
};

export default PastHistoryTriggerHistory;
