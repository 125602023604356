import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { authApi } from '../../../api/api-auth';
import { useHeaderTextChange, useHeaderToggle } from '../../../lib/useHeader';
import { localTokenCheck } from '../../../utils/util';
import MainPresenter from './MainPresenter';

export default function MainContainer() {
  useHeaderTextChange('Silo');
  useHeaderToggle();

  const [userInfo, setUserInfo] = useState({
    email: '',
    name: '',
    role: '',
    id: '',
  });
  const navigate = useNavigate();
  useEffect(() => {
    localTokenCheck();

    const userInfoJSON = localStorage.getItem('userInfo');
    if (userInfoJSON !== null && userInfoJSON !== '') {
      const { id, email, name, role } = JSON.parse(userInfoJSON);
      setUserInfo({
        email,
        name,
        role,
        id,
      });
    } else {
      localStorage.clear();
      navigate('/signin');
    }
  }, []);

  const onClickLogout = async () => {
    const tokenJSON = localStorage.getItem('tokens');

    if (tokenJSON !== null && tokenJSON !== '') {
      await authApi.logout(JSON.parse(tokenJSON).refresh.token);
    }
    localStorage.clear();
    navigate('/signin');
  };

  const onClickViewResult = async () => {
    navigate('/survey-list', { state: { id: userInfo.id, name: userInfo.name, email: userInfo.email } });
  };

  const onClickViewUserResult = async () => {
    navigate('/user-list');
  };

  const onClickSurvey = async () => {
    navigate('/survey-basic');
  };
  return (
    <MainPresenter
      userInfo={userInfo}
      onClickLogout={onClickLogout}
      onClickViewResult={onClickViewResult}
      onClickViewUserResult={onClickViewUserResult}
      onClickSurvey={onClickSurvey}
    />
  );
}
