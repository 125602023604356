import React, { useState, useEffect } from 'react';
import { Route, Routes, useNavigate, To, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ArrowBack } from '@mui/icons-material';
import FemaleIcon from '@mui/icons-material/Female';
import MaleIcon from '@mui/icons-material/Male';
import * as s from './InfoStyled';
import loadingIcon from '../../../asset/icon/loading.png';

function FormStep({
  title,
  children,
  progress,
  onNext,
  isValid,
  totalSteps,
  goToPreviousStep,
  currentStepIndex,
}: {
  title: string;
  children: React.ReactNode;
  progress: number;
  onNext: React.FormEventHandler<HTMLFormElement>;
  isValid: boolean;
  totalSteps: number;
  goToPreviousStep: () => void;
  currentStepIndex: number;
  // eslint-disable-next-line react/require-default-props
}) {
  const [showError, setShowError] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setShowError(false);
  }, [location]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isValid) {
      onNext(event);
    } else {
      setShowError(true);
    }
  };

  const handleBack = () => {
    if (currentStepIndex === 0) {
      navigate(-1);
    } else {
      goToPreviousStep();
    }
  };

  const { t } = useTranslation();

  return (
    <s.PageContainer>
      <div style={{ width: '100%', marginTop: '20px' }}>
        <s.HeaderContainer>
          <s.NavButton onClick={handleBack} aria-label="Go back">
            <ArrowBack />
          </s.NavButton>
          <s.ProgressBarContainer>
            <s.ProgressBar variant="determinate" value={(progress / totalSteps) * 100} />
          </s.ProgressBarContainer>
          <s.ProgressText>
            {progress}/{totalSteps}
          </s.ProgressText>
        </s.HeaderContainer>
        <s.InfoTitle>{title}</s.InfoTitle>
        {progress === 3 && <s.PrivacyText>{t('info.subtitle_bp')}</s.PrivacyText>}
      </div>
      <s.Form onSubmit={handleSubmit}>
        <div style={{ width: '100%' }}>
          {children}
          {showError && <s.ErrorMessage severity="error">{t('inputError')}</s.ErrorMessage>}
        </div>
        <div style={{ width: '100%' }}>
          {progress === 3 && (
            <s.NextButton type="submit" variant="outlined" color="primary">
              {t('button.skip')}
            </s.NextButton>
          )}
          <s.NextButton type="submit" variant="contained" color="primary">
            {t('button.next')}
          </s.NextButton>
        </div>
      </s.Form>
    </s.PageContainer>
  );
}

function LoadingPage() {
  // const navigate = useNavigate();

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     navigate('/new-surveys', {
  //       state: {
  //         gender: `${formData.gender}`,
  //         ebCirculation: `${formData.ebCirculation}`,
  //         ebProduce: `${formData.ebProduce}`,
  //         ebBalanceControl: `${formData.ebBalanceControl}`,
  //       },
  //     });
  //   }, 3000);

  //   return () => clearTimeout(timer);
  // }, [navigate]);

  const { t } = useTranslation();

  return (
    <s.LoadingContainer>
      <s.LoadingIcon src={loadingIcon} alt="로딩 중 아이콘" />
      <s.LoadingText>{t('loading')}</s.LoadingText>
    </s.LoadingContainer>
  );
}

interface InfoPresenterProps {
  formData: {
    gender: string;
    age: string;
    systolicBloodPressure: string;
    diastolicBloodPressure: string;
    weight: string;
    height: string;
    ebCirculation: number;
    ebProduce: number;
    ebBalanceControl: number;
  };
  step: string;
  totalSteps: number;
  currentStepIndex: number;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
  handleInputChange: (event: { target: { name: any; value: any } }) => void;
  handleNext: (path: To) => (event: { preventDefault: () => void }) => void;
  handleResults: (path: To) => (event: { preventDefault: () => void }) => void;
  loading: boolean;
  setFormData: React.Dispatch<
    React.SetStateAction<{
      gender: string;
      age: string;
      systolicBloodPressure: string;
      diastolicBloodPressure: string;
      weight: string;
      height: string;
      ebCirculation: number;
      ebProduce: number;
      ebBalanceControl: number;
    }>
  >;
}

interface NumericInputProps {
  value: string;
  onChange: (value: string) => void;
  onSliderChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  min: number;
  max: number;
  suffix: string;
  name: string;
}

const NumericInput: React.FC<NumericInputProps> = ({ value, onChange, onSliderChange, min, max, suffix, name }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value.replace(/[^0-9]/g, '');
    if (newValue === '' || (parseInt(newValue, 10) >= min && parseInt(newValue, 10) <= max)) {
      onChange(newValue);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'];
    if (!/[0-9]/.test(event.key) && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  };

  const handleBlur = () => {
    if (value === '') onChange(min.toString());
    if (parseInt(value, 10) > max) onChange(max.toString());
  };

  return (
    <div>
      <s.SliderTextContainer>
        <s.NumericInput
          type="text"
          inputMode="numeric"
          pattern="[0-9]*"
          min={min}
          max={max}
          value={value}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
        />
        <s.SliderTextSuffix>{suffix}</s.SliderTextSuffix>
      </s.SliderTextContainer>
      <s.Slider type="range" min={min} max={max} name={name} value={value} onChange={onSliderChange} />
    </div>
  );
};

export default function InfoPresenter({
  formData,
  handleInputChange,
  handleNext,
  handleResults,
  setFormData,
  step,
  totalSteps,
  currentStepIndex,
  goToNextStep,
  goToPreviousStep,
  loading,
}: InfoPresenterProps) {
  const handleNumericChange =
    (field: 'age' | 'systolicBloodPressure' | 'diastolicBloodPressure' | 'weight' | 'height') => (value: string) => {
      setFormData((prev) => ({ ...prev, [field]: value }));
    };

  const { t } = useTranslation();

  return (
    <s.PageContainerNoPadding>
      {step === '성별' && (
        <FormStep
          title={t('info.title_gender')}
          progress={currentStepIndex + 1}
          onNext={goToNextStep}
          isValid={formData.gender !== ''}
          totalSteps={totalSteps}
          goToPreviousStep={goToPreviousStep}
          currentStepIndex={currentStepIndex}
        >
          <s.Option>
            <s.OptionButton
              variant={formData.gender === 'male' ? 'contained' : 'outlined'}
              onClick={() => setFormData((prev) => ({ ...prev, gender: 'male' }))}
            >
              <MaleIcon style={{ fontSize: '120px', color: formData.gender === 'male' ? '#fff' : '#0f67fe' }} />
              <s.OptionText>{t('info.male')}</s.OptionText>
            </s.OptionButton>
            <s.OptionButton
              variant={formData.gender === 'female' ? 'contained' : 'outlined'}
              onClick={() => setFormData((prev) => ({ ...prev, gender: 'female' }))}
            >
              <FemaleIcon style={{ fontSize: '120px', color: formData.gender === 'female' ? '#fff' : '#0f67fe' }} />
              <s.OptionText>{t('info.female')}</s.OptionText>
            </s.OptionButton>
          </s.Option>
        </FormStep>
      )}
      {step === '나이' && (
        <FormStep
          title={t('info.title_age')}
          progress={currentStepIndex + 1}
          onNext={goToNextStep}
          isValid={formData.age !== ''}
          totalSteps={totalSteps}
          goToPreviousStep={goToPreviousStep}
          currentStepIndex={currentStepIndex}
        >
          <NumericInput
            value={formData.age}
            onChange={handleNumericChange('age')}
            onSliderChange={handleInputChange}
            min={1}
            max={120}
            suffix={t('info.age')}
            name="age"
          />
        </FormStep>
      )}
      {step === '혈압' && (
        <FormStep
          title={t('info.title_bp')}
          progress={currentStepIndex + 1}
          onNext={goToNextStep}
          isValid={formData.age !== ''}
          totalSteps={totalSteps}
          goToPreviousStep={goToPreviousStep}
          currentStepIndex={currentStepIndex}
        >
          <s.BpContainer>
            <div>
              <s.InfoSubtitle style={{ textAlign: 'center' }}>{t('info.systolic')}</s.InfoSubtitle>
              <NumericInput
                value={formData.systolicBloodPressure}
                onChange={handleNumericChange('systolicBloodPressure')}
                onSliderChange={handleInputChange}
                min={1}
                max={240}
                suffix="mmHg"
                name="systolicBloodPressure"
              />
            </div>
            <div>
              <s.InfoSubtitle style={{ textAlign: 'center' }}>{t('info.diastolic')}</s.InfoSubtitle>
              <NumericInput
                value={formData.diastolicBloodPressure}
                onChange={handleNumericChange('diastolicBloodPressure')}
                onSliderChange={handleInputChange}
                min={1}
                max={160}
                suffix="mmHg"
                name="diastolicBloodPressure"
              />
            </div>
          </s.BpContainer>
        </FormStep>
      )}
      {step === '체중' && (
        <FormStep
          title={t('info.title_weight')}
          progress={currentStepIndex + 1}
          onNext={goToNextStep}
          isValid={formData.weight !== ''}
          totalSteps={totalSteps}
          goToPreviousStep={goToPreviousStep}
          currentStepIndex={currentStepIndex}
        >
          <NumericInput
            value={formData.weight}
            onChange={handleNumericChange('weight')}
            onSliderChange={handleInputChange}
            min={1}
            max={150}
            suffix="kg"
            name="weight"
          />
        </FormStep>
      )}
      {step === '키' && (
        <FormStep
          title={t('info.title_height')}
          progress={currentStepIndex + 1}
          onNext={handleResults('/new-surveys')}
          isValid={formData.height !== ''}
          totalSteps={totalSteps}
          goToPreviousStep={goToPreviousStep}
          currentStepIndex={currentStepIndex}
        >
          <NumericInput
            value={formData.height}
            onChange={handleNumericChange('height')}
            onSliderChange={handleInputChange}
            min={1}
            max={200}
            suffix="cm"
            name="height"
          />
        </FormStep>
      )}
      {loading === true && <LoadingPage />}
    </s.PageContainerNoPadding>
  );
}
