import React from 'react';
import { useTranslation } from 'react-i18next';
import * as s from './NewSurveyStyled';
import rib1 from '../../../asset/img/survey/rib1.png';
import rib2 from '../../../asset/img/survey/rib2.png';
import rib3 from '../../../asset/img/survey/rib3.png';
import question from '../../../asset/img/survey/question.png';

interface RibAngleProps {
  onNext: () => void;
  onScore: (key: string, score: number) => void;
  selectedOption: string;
  onOptionSelect: (option: string) => void;
}

const RibAngle: React.FC<RibAngleProps> = ({ onNext, onScore, selectedOption, onOptionSelect }) => {
  const { t } = useTranslation();

  return (
    <s.SurveyContainer>
      <s.Question>{t('survey.rib_angle.question')}</s.Question>
      <s.QuestionSubtitle>{t('survey.rib_angle.sub_question')}</s.QuestionSubtitle>
      <s.OptionContainer>
        <s.Options>
          <s.OptionButton
            type="button"
            variant={selectedOption === '1' ? 'contained' : 'outlined'}
            onClick={() => {
              onOptionSelect('1');
              onScore('ebCirculation', -1);
            }}
          >
            <s.OptionTitle>{t('survey.rib_angle.option1')}</s.OptionTitle>
            <s.ThreeOptionImage src={rib1} alt="상체" />
          </s.OptionButton>
          <s.OptionButton
            type="button"
            variant={selectedOption === '2' ? 'contained' : 'outlined'}
            onClick={() => {
              onOptionSelect('2');
              onNext();
            }}
          >
            <s.OptionTitle>{t('survey.rib_angle.option2')}</s.OptionTitle>
            <s.ThreeOptionImage src={rib2} alt="상체" />
          </s.OptionButton>
          <s.OptionButton
            type="button"
            variant={selectedOption === '3' ? 'contained' : 'outlined'}
            onClick={() => {
              onOptionSelect('3');
              onScore('ebProduce', -1);
              onScore('ebBalanceControl', -1);
            }}
          >
            <s.OptionTitle>{t('survey.rib_angle.option3')}</s.OptionTitle>
            <s.ThreeOptionImage src={rib3} alt="상체" />
          </s.OptionButton>
          <s.OptionButton
            type="button"
            variant={selectedOption === '4' ? 'contained' : 'outlined'}
            onClick={() => {
              onOptionSelect('4');
              onNext();
            }}
          >
            <s.OptionTitle>{t('survey.rib_angle.option4')}</s.OptionTitle>
            <s.ThreeOptionImage src={question} alt="상체" />
          </s.OptionButton>
        </s.Options>
      </s.OptionContainer>
      {/* <s.NextButton type="button" onClick={() => onNext()} variant="contained" color="primary">
        {t('button.skip')}
      </s.NextButton> */}
    </s.SurveyContainer>
  );
};

export default RibAngle;
