import React from 'react';
import { useTranslation } from 'react-i18next';
import * as s from './NewSurveyStyled';
import puffy from '../../../asset/img/survey/puffy.png';
import heavy from '../../../asset/img/survey/heavy2.png';
import lie from '../../../asset/img/survey/lie.png';
import no from '../../../asset/img/survey/no.png';

interface ConditionProps {
  onNext: () => void;
  onScore: (key: string, score: number) => void;
  selectedOption: string[];
  onOptionSelect: (option: string) => void;
  isSelected: (selectedOptions: string[], option: string) => boolean;
}

const Condition: React.FC<ConditionProps> = ({ onNext, onScore, selectedOption, onOptionSelect, isSelected }) => {
  const calcScore = (selectedOptions: string[]) => {
    if (selectedOptions.includes('1')) {
      onScore('ebCirculation', -1);
    }
    if (selectedOptions.includes('2')) {
      onScore('ebCirculation', -1);
    }
    if (selectedOptions.includes('3')) {
      onScore('ebCirculation', -1);
    }
  }

  const { t } = useTranslation();

  return (
    <s.SurveyContainer>
      <s.Question>{t('survey.condition.question')}</s.Question>
      <s.InnerOptionContainer style={{ marginTop: '2rem' }}>
        <s.OptionContainer>
          <s.Options>
            <s.OptionButton
              type="button"
              variant={isSelected(selectedOption, '1') ? 'contained' : 'outlined'}
              onClick={() => {
                onOptionSelect('1');
              }}
            >
              <s.ThreeOptionImage src={puffy} alt="대체 이미지" />
              <s.OptionSubtitle>{t('survey.condition.option1')}</s.OptionSubtitle>
            </s.OptionButton>
            <s.OptionButton
              type="button"
              variant={isSelected(selectedOption, '2')  ? 'contained' : 'outlined'}
              onClick={() => {
                onOptionSelect('2');
              }}
            >
              <s.ThreeOptionImage src={heavy} alt="대체 이미지" />
              <s.OptionSubtitle>{t('survey.condition.option2')}</s.OptionSubtitle>
            </s.OptionButton>
          </s.Options>
        </s.OptionContainer>
        <s.OptionContainer>
          <s.Options style={{ margin: '0' }}>
            <s.OptionButton
              type="button"
              variant={isSelected(selectedOption, '3')  ? 'contained' : 'outlined'}
              onClick={() => {
                onOptionSelect('3');
              }}
            >
              <s.ThreeOptionImage src={lie} alt="대체 이미지" />
              <s.OptionSubtitle>{t('survey.condition.option3')}</s.OptionSubtitle>
            </s.OptionButton>
            <s.OptionButton
              type="button"
              variant={isSelected(selectedOption, '4') ? 'contained' : 'outlined'}
              onClick={() => {
                onOptionSelect('4');
              }}
            >
              <s.ThreeOptionImage src={no} alt="대체 이미지" />
              <s.OptionSubtitle>{t('survey.condition.option4')}</s.OptionSubtitle>
            </s.OptionButton>
          </s.Options>
        </s.OptionContainer>
      </s.InnerOptionContainer>
      <s.NextButton variant="contained" color="primary" onClick={() => {
        onNext();
        calcScore(selectedOption)}}>
        다음
      </s.NextButton>
    </s.SurveyContainer>
  );
};

export default Condition;
