import React from 'react';
import { useTranslation } from 'react-i18next';
import * as s from './NewSurveyStyled';
import toilet from '../../../asset/img/survey/toilet.png';

interface PoopProps {
  onNext: () => void;
  onScore: (key: string, score: number) => void;
  selectedOption: string;
  onOptionSelect: (option: string) => void;
}

const Poop: React.FC<PoopProps> = ({ onNext, onScore, selectedOption, onOptionSelect }) => {
  const { t } = useTranslation();
  
  return (
    <s.SurveyContainer>
      <s.Question>{t('survey.poop.question')}</s.Question>
      <s.PictureContainer>
        <s.Picture src={toilet} alt="대체 이미지" />
      </s.PictureContainer>
      <s.OptionContainer className="OptionContainer">
        <s.Options>
          <s.OptionButton
            type="button"
            variant={selectedOption === '1' ? 'contained' : 'outlined'}
            onClick={() => {
              onOptionSelect('1');
              onNext();
            }}
          >
            <s.OptionTitle>{t('survey.poop.option1')}</s.OptionTitle>
            <s.OptionSubtitle>{t('survey.poop.sub_option1')}</s.OptionSubtitle>
          </s.OptionButton>
          <s.OptionButton
            type="button"
            variant={selectedOption === '2' ? 'contained' : 'outlined'}
            onClick={() => {
              onOptionSelect('2');
              onScore('ebCirculation', -1);
            }}
          >
            <s.OptionTitle>{t('survey.poop.option2')}</s.OptionTitle>
            <s.OptionSubtitle>{t('survey.poop.sub_option2')}</s.OptionSubtitle>
          </s.OptionButton>
          <s.OptionButton
            type="button"
            variant={selectedOption === '3' ? 'contained' : 'outlined'}
            onClick={() => {
              onOptionSelect('3');
              onScore('ebProduce', -1);
            }}
          >
            <s.OptionTitle>{t('survey.poop.option3')}</s.OptionTitle>
            <s.OptionSubtitle>{t('survey.poop.sub_option3')}</s.OptionSubtitle>
          </s.OptionButton>
        </s.Options>
      </s.OptionContainer>
    </s.SurveyContainer>
  );
};

export default Poop;
