import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoginPresenter from './PasswordFindPresenter';
import { useHeaderTextChange, useHeaderToggle } from '../../../lib/useHeader';
import { authApi } from '../../../api/api-auth';
import { useLocalStorage } from '../../../utils/useLocalStorage';

export default function PasswordFindContainer() {
  useHeaderToggle();
  useHeaderTextChange('pw-find');

  const [email, setEmail] = useState('');

  const [rePassword, setRePassword] = useState('');
  const [password, setPassword] = useState('');

  const [errorMessage, setErrorMessage] = useState('');
  const [hint, setHint] = useState('');
  const [answer, setAnswer] = useState('');
  const [resetPasswordToken, setResetPasswordToken] = useState('');

  const navigate = useNavigate();

  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const onChangeAnswer = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAnswer(e.target.value);
  };

  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const onChangeRePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRePassword(e.target.value);
  };

  const onSubmitEmail = async () => {
    let res;
    try {
      res = await authApi.getHint(email);
    } catch (e) {
      setErrorMessage('존재하지 않는 아이디입니다.');
    } finally {
      if (res) {
        setErrorMessage('');
        setHint(res.data.hint);
      }
    }
  };

  const onSubmitAnswer = async () => {
    let res;

    try {
      res = await authApi.getResetPasswordTokenByHint({ email, answer });
    } catch (e) {
      setErrorMessage('답변이 일치하지 않습니다.');
    } finally {
      if (res) {
        setErrorMessage('');
        setResetPasswordToken(res.data.token);
      }
    }
  };

  const onSubmitPasswordChange = async () => {
    let res;
    if (password.length < 8) {
      setErrorMessage('비밀번호 8자리 이상 입력해주세요.');
    } else if (password !== rePassword) {
      setErrorMessage('비밀번호가 일치하지 않습니다.');
    } else {
      try {
        res = await authApi.resetPassword(password, resetPasswordToken);
      } catch (e) {
        setErrorMessage('비밀번호 8자리 이상 입력해주세요.');
      } finally {
        if (res) {
          setErrorMessage('');
          navigate('/signin');
        }
      }
    }
  };

  const onClickSignIn = () => {
    navigate('/signup');
  };

  return (
    <LoginPresenter
      email={email}
      password={password}
      rePassword={rePassword}
      resetPasswordToken={resetPasswordToken}
      errorMessage={errorMessage}
      hint={hint}
      answer={answer}
      onClickSignIn={onClickSignIn}
      onSubmitEmail={onSubmitEmail}
      onChangeEmail={onChangeEmail}
      onChangeAnswer={onChangeAnswer}
      onChangePassword={onChangePassword}
      onChangeRePassword={onChangeRePassword}
      onSubmitAnswer={onSubmitAnswer}
      onSubmitPasswordChange={onSubmitPasswordChange}
    />
  );
}
