import { Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import siloIcon from '../../../asset/icon/silo-icon.png';
import * as s from './PasswordFindStlyed';

interface PassworFinddProps {
  email: string;
  errorMessage: string;
  hint: string;
  resetPasswordToken: string;
  answer: string;
  password: string;
  rePassword: string;
  onClickSignIn: () => void;
  onSubmitEmail: () => void;
  onSubmitAnswer: () => void;
  onSubmitPasswordChange: () => void;
  onChangePassword: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeRePassword: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeAnswer: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeEmail: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function PassworFinddPresenter(props: PassworFinddProps) {
  const {
    email,
    errorMessage,
    hint,
    answer,
    resetPasswordToken,
    password,
    rePassword,
    onClickSignIn,
    onSubmitEmail,
    onChangeEmail,
    onSubmitAnswer,
    onChangeAnswer,
    onChangePassword,
    onChangeRePassword,
    onSubmitPasswordChange,
  } = props;

  return (
    <s.StyledPasswordFindPage maxWidth="sm">
      <s.LogoBox>
        <s.WichuIcon src={siloIcon} />
        <s.CenterText variant="h4">oriental silo</s.CenterText>
      </s.LogoBox>

      {
        // 힌트도 못얻어오고, 비밀번호 초기화 토큰도 없는 경우
      }
      {hint === '' && resetPasswordToken === '' ? (
        <>
          <s.StyledTextField
            label="아이디"
            variant="standard"
            value={email}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onChangeEmail(e);
            }}
          />
          <s.CenterText variant="body2" sx={{ marginTop: '20px', color: 'red' }}>
            {errorMessage}
          </s.CenterText>
          <s.StyledButton
            variant="contained"
            sx={{ marginTop: '1rem', marginBottom: '1rem' }}
            onClick={() => onSubmitEmail()}
          >
            비밀번호 초기화
          </s.StyledButton>
          <s.StyledButton variant="contained" sx={{ marginLeft: '0.3rem' }} onClick={() => onClickSignIn()}>
            회원가입
          </s.StyledButton>
        </>
      ) : (
        ''
      )}
      {
        // 힌트는 있고, 비밀번호 초기화 토큰은 아직 없는 경우
      }
      {hint !== '' && resetPasswordToken === '' ? (
        <>
          <s.StyledTextField label="아이디" variant="standard" value={email} disabled sx={{ marginBottom: '1rem' }} />
          <s.StyledTextField label="힌트" variant="standard" value={hint} disabled sx={{ marginBottom: '1rem' }} />
          <s.StyledTextField
            label="답변"
            variant="standard"
            value={answer}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onChangeAnswer(e);
            }}
          />
          <s.CenterText variant="body2" sx={{ marginTop: '20px', color: 'red' }}>
            {errorMessage}
          </s.CenterText>
          <s.StyledButton
            variant="contained"
            sx={{ marginTop: '1rem', marginBottom: '1rem' }}
            onClick={() => onSubmitAnswer()}
          >
            힌트 입력
          </s.StyledButton>
          <s.StyledButton variant="contained" sx={{ marginLeft: '0.3rem' }} onClick={() => onClickSignIn()}>
            회원가입
          </s.StyledButton>
        </>
      ) : (
        ''
      )}
      {hint !== '' && resetPasswordToken !== '' ? (
        <>
          <s.StyledTextField label="아이디" variant="standard" value={email} disabled sx={{ marginBottom: '1rem' }} />
          <s.StyledTextField label="힌트" variant="standard" value={hint} disabled sx={{ marginBottom: '1rem' }} />
          <s.StyledTextField label="답변" variant="standard" value={answer} disabled sx={{ marginBottom: '1rem' }} />
          <s.StyledTextField
            label="새로운 비밀번호"
            variant="standard"
            value={password}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onChangePassword(e);
            }}
          />
          <s.StyledTextField
            label="비밀번호 확인"
            variant="standard"
            value={rePassword}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onChangeRePassword(e);
            }}
          />
          <s.CenterText variant="body2" sx={{ marginTop: '20px', color: 'red' }}>
            {errorMessage}
          </s.CenterText>
          <s.StyledButton
            variant="contained"
            sx={{ marginTop: '1rem', marginBottom: '1rem' }}
            onClick={() => onSubmitPasswordChange()}
          >
            비밀번호 변경
          </s.StyledButton>
          <s.StyledButton variant="contained" sx={{ marginLeft: '0.3rem' }} onClick={() => onClickSignIn()}>
            회원가입
          </s.StyledButton>
        </>
      ) : (
        ''
      )}
    </s.StyledPasswordFindPage>
  );
}
