import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Select, MenuItem, SelectChangeEvent } from '@mui/material';
import siloIcon from '../../../asset/img/logo.png';
import * as s from './LoginStlyed';

interface LoginProps {
  email: string;
  errorMessage: string;
  onSubmit: () => void;
  onChangeEmail: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangePassword: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function LoginPresenter(props: LoginProps) {
  const { email, errorMessage, onSubmit, onChangeEmail, onChangePassword } = props;
  const { t, i18n } = useTranslation();

  const changeLanguage = (event: SelectChangeEvent<string>) => {
    i18n.changeLanguage(event.target.value);
  };

  return (
    <s.StyledSignInPage maxWidth="sm">
      <s.LanguageSelector>
          <Select
            value={i18n.language}
            onChange={changeLanguage}
            defaultValue='ko'
            variant="outlined"
            size="small"
            style={{fontFamily: 'Noto Sans KR'}}
          >
            <MenuItem value="ko" style={{fontFamily: 'Noto Sans KR'}}>한국어</MenuItem>
            <MenuItem value="en" style={{fontFamily: 'Noto Sans KR'}}>English</MenuItem>
          </Select>
        </s.LanguageSelector>
      <s.LogoBox>
        <s.WichuIcon src={siloIcon} />
        <s.CenterText variant="h4">KAIDOC</s.CenterText>
      </s.LogoBox>
      <s.StyledTextField
        label={t('manage.id')}
        variant="standard"
        value={email}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          onChangeEmail(e);
        }}
        sx={{ marginBottom: '1rem' }}
      />
      <s.StyledTextField
        label={t('manage.pw')}
        type="password"
        variant="standard"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          onChangePassword(e);
        }}
      />
      <s.CenterText variant="body2" sx={{ marginTop: '20px', color: 'red' }}>
        {errorMessage}
      </s.CenterText>
      <s.StyledButton variant="contained" sx={{ marginTop: '1rem', marginBottom: '1rem' }} onClick={() => onSubmit()}>
        {t('manage.login')}
      </s.StyledButton>
      <s.LinkBox>
        <s.WithoutDecoLink href="password-find" sx={{ marginRight: '0.3rem' }}>
          <Typography variant="body2" color="text.secondary">
          {t('manage.find_pw')}
          </Typography>
        </s.WithoutDecoLink>
        <Typography variant="body2" color="text.secondary">
          |
        </Typography>
        <s.WithoutDecoLink href="signup" sx={{ marginLeft: '0.3rem' }}>
          <Typography variant="body2" color="text.secondary">
          {t('manage.signup')}
          </Typography>
        </s.WithoutDecoLink>
      </s.LinkBox>
    </s.StyledSignInPage>
  );
}
