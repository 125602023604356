import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useHeaderTextChange, useHeaderToggle } from '../../../../lib/useHeader';
import SurveyMainPresenter from './SurveyMainPresenter';

export default function SurveyMainContainer() {
  useHeaderTextChange('설문시작');

  const navigate = useNavigate();

  const [open, setOpen] = useState<boolean>(false);

  const onClickSurveyStart = async () => {
    navigate('/survey-basic');
    setOpen(false);
  };

  return <SurveyMainPresenter onClickSurveyStart={onClickSurveyStart} open={open} setOpen={setOpen} />;
}
