import React from 'react';
import { useTranslation } from 'react-i18next';
import * as s from './NewSurveyStyled';
import twoBowls from '../../../asset/img/survey/two_bowls.png';
import threeBowls from '../../../asset/img/survey/three_bowls.png';
import fourBowls from '../../../asset/img/survey/four_bowls.png';


interface NumberOfMealsProps {
  onNext: () => void;
  onScore: (key: string, score: number) => void;
  selectedOption: string;
  onOptionSelect: (option: string) => void;
}

const NumberOfMeals: React.FC<NumberOfMealsProps> = ({ onNext, onScore, selectedOption, onOptionSelect }) => {
  const { t } = useTranslation();
  
  return (
    <s.SurveyContainer>
      <s.Question>{t('survey.number_of_meals.question')}</s.Question>
        <s.Options>
          <s.OptionContainer style={{ padding: '0' }}>
            <s.OptionTitle style={{ marginBottom: '1rem' }}>{t('survey.number_of_meals.option1')}</s.OptionTitle>
            <s.OptionButtonWithImage
              type="button"
              variant={selectedOption === '1' ? 'contained' : 'outlined'}
              onClick={() => {
                onOptionSelect('1');
                onScore('ebProduce', -1);
              }}
            >
              <s.OptionImageContainer>
                <s.ThreeOptionImage src={twoBowls} alt="상체" />
              </s.OptionImageContainer>
            </s.OptionButtonWithImage>
          </s.OptionContainer>
          <s.OptionContainer style={{ padding: '0' }}>
            <s.OptionTitle style={{ marginBottom: '1rem' }}>{t('survey.number_of_meals.option2')}</s.OptionTitle>
            <s.OptionButtonWithImage
              type="button"
              variant={selectedOption === '2' ? 'contained' : 'outlined'}
              onClick={() => {
                onOptionSelect('2');
                onNext();
              }}
            >
              <s.OptionImageContainer>
                <s.ThreeOptionImage src={threeBowls} alt="상체" />
              </s.OptionImageContainer>
            </s.OptionButtonWithImage>
          </s.OptionContainer>
          <s.OptionContainer style={{ padding: '0' }}>
            <s.OptionTitle style={{ marginBottom: '1rem' }}>{t('survey.number_of_meals.option3')}</s.OptionTitle>
            <s.OptionButtonWithImage
              type="button"
              variant={selectedOption === '3' ? 'contained' : 'outlined'}
              onClick={() => {
                onOptionSelect('3');
                onNext();
              }}
            >
              <s.OptionImageContainer>
                <s.ThreeOptionImage src={fourBowls} alt="상체" />
              </s.OptionImageContainer>
            </s.OptionButtonWithImage>
          </s.OptionContainer>
        </s.Options>
    </s.SurveyContainer>
  );
};

export default NumberOfMeals;
