import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import SurveyFavFodPresenter from './SurveyDgnsisPresenter';
import { useHeaderTextChange, useHeaderToggle } from '../../../../lib/useHeader';
import { RootState } from '../../../../modules';

export default function SurveyDgnsisContainer() {
  // header text change
  useHeaderTextChange('사전문진');

  // get store object(survey)
  const dictObject = useSelector((state: RootState) => state.survey);

  // validation for survey-dgnsis
  const [validForm, setValidForm] = useState({
    etcDisease: true,
    etcReason: true,
    diseaseGenerateTime: false,
  });

  // message for validation help
  const [validErrMessages, setErrMessages] = useState({
    etcDiseaseErrMessage: '',
    etcReasonErrMessage: '',
  });

  // default Value setting
  const [defaultValueForm, setDefaultValueForm] = useState({
    body_symptom: [
      dictObject.body_symptom_1_1,
      dictObject.body_symptom_1_2,
      dictObject.body_symptom_1_3,
      dictObject.body_symptom_1_4,
      dictObject.body_symptom_1_5,
      dictObject.body_symptom_1_6,
    ],
    autoimmune: [
      dictObject.autoimmune_1,
      dictObject.autoimmune_2,
      dictObject.autoimmune_3,
      dictObject.autoimmune_4,
      dictObject.autoimmune_5,
    ],
    nervous_system: [
      dictObject.nervous_system_1,
      dictObject.nervous_system_2,
      dictObject.nervous_system_3,
      dictObject.nervous_system_4,
      dictObject.nervous_system_5,
      dictObject.nervous_system_6,
      dictObject.nervous_system_7,
    ],
    psychiatry: [dictObject.psychiatry_1, dictObject.psychiatry_2, dictObject.psychiatry_3, dictObject.psychiatry_4],
    gynecological: [
      dictObject.gynecological_1,
      dictObject.gynecological_2,
      dictObject.gynecological_3,
      dictObject.gynecological_4,
      dictObject.gynecological_5,
      dictObject.gynecological_6,
    ],
    pediatric: [
      dictObject.pediatric_1,
      dictObject.pediatric_2,
      dictObject.pediatric_3,
      dictObject.pediatric_4,
      dictObject.pediatric_5,
    ],
    otorhinolaryngology: [
      dictObject.otorhinolaryngology_1,
      dictObject.otorhinolaryngology_2,
      dictObject.otorhinolaryngology_3,
      dictObject.otorhinolaryngology_4,
      dictObject.otorhinolaryngology_5,
      dictObject.otorhinolaryngology_6,
      dictObject.otorhinolaryngology_7,
    ],
    skin: [dictObject.skin_1, dictObject.skin_2, dictObject.skin_3, dictObject.skin_4],
    cancer: [
      dictObject.cancer_1,
      dictObject.cancer_2,
      dictObject.cancer_3,
      dictObject.cancer_4,
      dictObject.cancer_5,
      dictObject.cancer_6,
      dictObject.cancer_7,
      dictObject.cancer_8,
      dictObject.cancer_9,
      dictObject.cancer_10,
      dictObject.cancer_11,
    ],
  });

  const [autoCompleteContent, setAutoCompleteContent] = useState<any>();

  const [expanded, setExpanded] = useState<string>('');

  useEffect(() => {
    if (!autoCompleteContent) return;
    if (autoCompleteContent.category === '전신 증상') setExpanded('panel1');
    else if (
      autoCompleteContent.category === '내과 질환 > 소화기 질환' ||
      autoCompleteContent.category === '내과 질환 > 호흡기 질환' ||
      autoCompleteContent.category === '내과 질환 > 비뇨생식기 질환'
    )
      setExpanded('panel2');
    else if (
      autoCompleteContent.category === '근 골격계 질환' ||
      autoCompleteContent.category === '근 골격계 질환 > 목.허리 척추질환' ||
      autoCompleteContent.category === '근 골격계 질환 > 팔 다리 관절질환'
    )
      setExpanded('panel3');
    else if (autoCompleteContent.category === '자가면역질환') setExpanded('panel4');
    else if (autoCompleteContent.category === '신경계질환') setExpanded('panel5');
    else if (autoCompleteContent.category === '정신과질환') setExpanded('panel6');
    else if (autoCompleteContent.category === '부인과질환') setExpanded('panel7');
    else if (autoCompleteContent.category === '소아과질환') setExpanded('panel8');
    else if (autoCompleteContent.category === '안이비인후과 질환') setExpanded('panel9');
    else if (autoCompleteContent.category === '피부질환') setExpanded('panel10');
    else if (autoCompleteContent.category === '암질환') setExpanded('panel11');
  }, [autoCompleteContent]);

  const handleChange = (panel: any) => (event: any, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  // default value of body_symptom
  useEffect(() => {
    setDefaultValueForm({
      ...defaultValueForm,
      body_symptom: [
        dictObject.body_symptom_1_1,
        dictObject.body_symptom_1_2,
        dictObject.body_symptom_1_3,
        dictObject.body_symptom_1_4,
        dictObject.body_symptom_1_5,
        dictObject.body_symptom_1_6,
      ],
    });
  }, [
    dictObject.body_symptom_1_1,
    dictObject.body_symptom_1_2,
    dictObject.body_symptom_1_3,
    dictObject.body_symptom_1_4,
    dictObject.body_symptom_1_5,
    dictObject.body_symptom_1_6,
  ]);

  // default value of autoimmmue
  useEffect(() => {
    setDefaultValueForm({
      ...defaultValueForm,
      autoimmune: [
        dictObject.autoimmune_1,
        dictObject.autoimmune_2,
        dictObject.autoimmune_3,
        dictObject.autoimmune_4,
        dictObject.autoimmune_5,
      ],
    });
  }, [
    dictObject.autoimmune_1,
    dictObject.autoimmune_2,
    dictObject.autoimmune_3,
    dictObject.autoimmune_4,
    dictObject.autoimmune_5,
  ]);

  // default value of nervous_system
  useEffect(() => {
    setDefaultValueForm({
      ...defaultValueForm,
      nervous_system: [
        dictObject.nervous_system_1,
        dictObject.nervous_system_2,
        dictObject.nervous_system_3,
        dictObject.nervous_system_4,
        dictObject.nervous_system_5,
        dictObject.nervous_system_6,
        dictObject.nervous_system_7,
      ],
    });
  }, [
    dictObject.nervous_system_1,
    dictObject.nervous_system_2,
    dictObject.nervous_system_3,
    dictObject.nervous_system_4,
    dictObject.nervous_system_5,
    dictObject.nervous_system_6,
    dictObject.nervous_system_7,
  ]);

  // default value of psychiatry
  useEffect(() => {
    setDefaultValueForm({
      ...defaultValueForm,
      psychiatry: [dictObject.psychiatry_1, dictObject.psychiatry_2, dictObject.psychiatry_3, dictObject.psychiatry_4],
    });
  }, [dictObject.psychiatry_1, dictObject.psychiatry_2, dictObject.psychiatry_3, dictObject.psychiatry_4]);

  // default value of gynecological
  useEffect(() => {
    setDefaultValueForm({
      ...defaultValueForm,
      gynecological: [
        dictObject.gynecological_1,
        dictObject.gynecological_2,
        dictObject.gynecological_3,
        dictObject.gynecological_4,
        dictObject.gynecological_5,
        dictObject.gynecological_6,
      ],
    });
  }, [
    dictObject.gynecological_1,
    dictObject.gynecological_2,
    dictObject.gynecological_3,
    dictObject.gynecological_4,
    dictObject.gynecological_5,
    dictObject.gynecological_6,
  ]);

  // default value of pediatric
  useEffect(() => {
    setDefaultValueForm({
      ...defaultValueForm,
      pediatric: [
        dictObject.pediatric_1,
        dictObject.pediatric_2,
        dictObject.pediatric_3,
        dictObject.pediatric_4,
        dictObject.pediatric_5,
      ],
    });
  }, [
    dictObject.pediatric_1,
    dictObject.pediatric_2,
    dictObject.pediatric_3,
    dictObject.pediatric_4,
    dictObject.pediatric_5,
  ]);

  // default value of otorhinolaryngology
  useEffect(() => {
    setDefaultValueForm({
      ...defaultValueForm,
      otorhinolaryngology: [
        dictObject.otorhinolaryngology_1,
        dictObject.otorhinolaryngology_2,
        dictObject.otorhinolaryngology_3,
        dictObject.otorhinolaryngology_4,
        dictObject.otorhinolaryngology_5,
        dictObject.otorhinolaryngology_6,
        dictObject.otorhinolaryngology_7,
      ],
    });
  }, [
    dictObject.otorhinolaryngology_1,
    dictObject.otorhinolaryngology_2,
    dictObject.otorhinolaryngology_3,
    dictObject.otorhinolaryngology_4,
    dictObject.otorhinolaryngology_5,
    dictObject.otorhinolaryngology_6,
    dictObject.otorhinolaryngology_7,
  ]);

  // default value of skin
  useEffect(() => {
    setDefaultValueForm({
      ...defaultValueForm,
      skin: [dictObject.skin_1, dictObject.skin_2, dictObject.skin_3, dictObject.skin_4],
    });
  }, [dictObject.skin_1, dictObject.skin_2, dictObject.skin_3, dictObject.skin_4]);

  // default value of cancer
  useEffect(() => {
    setDefaultValueForm({
      ...defaultValueForm,
      cancer: [
        dictObject.cancer_1,
        dictObject.cancer_2,
        dictObject.cancer_3,
        dictObject.cancer_4,
        dictObject.cancer_5,
        dictObject.cancer_6,
        dictObject.cancer_7,
        dictObject.cancer_8,
        dictObject.cancer_9,
        dictObject.cancer_10,
        dictObject.cancer_11,
      ],
    });
  }, [
    dictObject.cancer_1,
    dictObject.cancer_2,
    dictObject.cancer_3,
    dictObject.cancer_4,
    dictObject.cancer_5,
    dictObject.cancer_6,
    dictObject.cancer_7,
    dictObject.cancer_8,
    dictObject.cancer_9,
    dictObject.cancer_10,
    dictObject.cancer_11,
  ]);

  // validation for etcDisease
  useEffect(() => {
    if (dictObject.etc_disease && dictObject.etc_disease_content.length === 0) {
      setValidForm({
        ...validForm,
        etcDisease: false,
      });
      setErrMessages({
        ...validErrMessages,
        etcDiseaseErrMessage: '내용을 입력해주세요.',
      });
    } else {
      setValidForm({
        ...validForm,
        etcDisease: true,
      });
      setErrMessages({
        ...validErrMessages,
        etcDiseaseErrMessage: '',
      });
    }
  }, [dictObject.etc_disease, dictObject.etc_disease_content]);

  // validation for etcReason
  useEffect(() => {
    if (dictObject.etc_reason && dictObject.etc_reason_content.length === 0) {
      setValidForm({
        ...validForm,
        etcReason: false,
      });
      setErrMessages({
        ...validErrMessages,
        etcReasonErrMessage: '내용을 입력해주세요.',
      });
    } else {
      setValidForm({
        ...validForm,
        etcReason: true,
      });
      setErrMessages({
        ...validErrMessages,
        etcReasonErrMessage: '',
      });
    }
  }, [dictObject.etc_reason, dictObject.etc_reason_content]);

  // validation for diseaseGenerateTime
  useEffect(() => {
    if (dictObject.disease_generate_time === -1) {
      setValidForm({ ...validForm, diseaseGenerateTime: false });
    } else {
      setValidForm({ ...validForm, diseaseGenerateTime: true });
    }
  }, [dictObject.disease_generate_time]);

  // checking for button disable
  useEffect(() => {
    if (
      (!dictObject.etc_disease || (dictObject.etc_disease && dictObject.etc_disease_content.length !== 0)) &&
      (!dictObject.etc_reason || (dictObject.etc_reason && dictObject.etc_reason_content.length !== 0)) &&
      dictObject.disease_generate_time !== -1
    ) {
      setValidForm({
        etcReason: true,
        etcDisease: true,
        diseaseGenerateTime: true,
      });
    }
  }, []);

  return (
    <SurveyFavFodPresenter
      dictObject={dictObject}
      validForm={validForm}
      validErrMessages={validErrMessages}
      defaultValueForm={defaultValueForm}
      expanded={expanded}
      handleChange={handleChange}
      autoCompleteContent={autoCompleteContent}
      setAutoCompleteContent={setAutoCompleteContent}
    />
  );
}
