import React, { useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import moment from 'moment';
import {
  SurveyTitle,
  StyledButton,
  CheckBoxFlexDirecRow,
  CheckBoxWithTextField,
  CheckBoxWithBlank,
  BtnPreNext,
  BtnPreEnd,
} from '../common/Survey';
import * as s from '../../surveyResult/SurveyResultStlyed';
import '../common/Survey.scss';
import { ISurveyDictType } from '../common/SurveyTypes';
import siloIcon from '../../../../asset/icon/silo-icon.png';
import circulatorySystemImg from '../../../../asset/img/circulatory-system.png';
import nervousSystemImg from '../../../../asset/img/nervous-system.png';
import stomachSystemImg from '../../../../asset/img/stomach-system.png';

interface IPasGisPageProps {
  dictObject: ISurveyDictType;
  validForm: IValidData;
  validErrMessages: IValidErrMessagesData;
  survey: any;
  onClickSubmit: () => void;
}

interface IValidData {
  name: boolean;
  etc: boolean;
  pasSurgery: boolean;
}

interface IValidErrMessagesData {
  etcErrMessage: string;
  pasSurgeryErrMessage: string;
}

const circleColorByScore = (score: number) => {
  if (score >= 10) {
    return <CircleIcon sx={{ color: '#EB162F' }} />;
  }
  if (score > 7.5) {
    return <CircleIcon sx={{ color: '#FD9433' }} />;
  }
  return <CircleIcon sx={{ color: '#16eb21' }} />;
};

const commentByAScore = (score: number) => {
  if (score > 7.5) {
    return (
      <Typography variant="body2" color="text.secondary">
        기혈의 순환기능에 문제가 있는 것 같습니다. <br />
        의사와 상담후 진료를 받을 필요가 있습니다.
      </Typography>
    );
  }
  return (
    <Typography variant="body2" color="text.secondary">
      기혈의 순환기능이 정상적입니다! <br />
      정상적인 상태를 유지하기 위해 노력해보세요!
    </Typography>
  );
};

const commentByBScore = (score: number) => {
  if (score > 7.5) {
    return (
      <Typography variant="body2" color="text.secondary">
        기혈의 생성기능에 문제가 있는 것 같습니다. <br />
        의사와 상담후 진료를 받을 필요가 있습니다.
      </Typography>
    );
  }
  return (
    <Typography variant="body2" color="text.secondary">
      기혈의 생성기능이 정상적입니다! <br /> 정상적인 상태를 유지하기 위해 노력해보세요!
    </Typography>
  );
};

const commentByCScore = (score: number) => {
  if (score > 7.5) {
    return (
      <Typography variant="body2" color="text.secondary">
        기혈의 균형조절기능에 문제가 있는 것 같습니다. <br />
        의사와 상담후 진료를 받을 필요가 있습니다.
      </Typography>
    );
  }
  return (
    <Typography variant="body2" color="text.secondary">
      기혈의 균형조절기능이 정상적입니다! <br /> 정상적인 상태를 유지하기 위해 노력해보세요!
    </Typography>
  );
};

const commentLifeTherapy = (AScore: number, BScore: number, CScore: number) => {
  const finalA = Math.round(((AScore * 2) / 3) * 10);
  const finalB = Math.round(((BScore * 2) / 3) * 10);
  const finalC = Math.round(((CScore * 2) / 3) * 10);
  const maxScore = Math.max(finalA, finalB, finalC);

  // console.log(finalA, finalB, finalC);

  if (finalB >= 30 && maxScore === finalB) {
    return (
      <Typography variant="body2" color="text.secondary">
        당신은 인체 에너지의 생성기능이 약한 편으로 음식을 통한 에너지 생성이 적어 쉽게 피로감을 느낄수 있습니다. 따라서
        식사를 가장 신경 써야 하며 양질의 음식을 하루 3끼 잘 챙겨 드시고 오전 오후 식사 사이에 찹쌀 : 멥쌀 : 견과류를
        5:3:2로 섞어 떡을 만들어 간식으로 드시는 것도 좋습니다. 소화에 부담이 되는 찬 음료, 탄산음료, 밀가루 음식 등은
        피하고 부드럽고 따뜻한 성질의 음식을 권장하며 과식이나 급하게 식사하는 습관은 바꿔야 합니다. 기혈 생성기능에
        문제가 있으면 근육량이 부족한 경우가 많으므로 식사를 통해 충분한 에너지를 생성하면서 근육량을 늘리는 운동도
        중요합니다. <br />
        <br />
        <b>
          평소 운동은 배를 당기고 몸을 세워 편안한 속도의 걷기 운동이 좋으며 무리하지 않는 범위 내에서 스트레칭, 요가,
          필라테스 같은 근력운동도 권장합니다.
        </b>
        <br />
        <br />
        <b>좀 더 자세한 내용은 상담 신청을 통하여 제공될 예정입니다.</b>
      </Typography>
    );
  }
  if (finalC >= 30 && maxScore === finalC) {
    return (
      <Typography variant="body2" color="text.secondary">
        당신은 인체 에너지의 균형조절에 문제가 생긴 상태로 신체에서 하강하고 수렴하는 에너지에 해당하는 음기가 부족하여
        기운이 위로 솟아서 목 어깨가 뭉치고, 하체가 약해지기 쉬우며 수면에 문제가 있는 경우가 많습니다. 수면은
        생체리듬과 관련되어 몸의 균형을 조절하는데 있어 매우 중요하며 규칙적인 취침시간과 기상시간(밤 10시~아침 5시)을
        유지하는 것이 중요합니다. 숙면을 위한 수면위생은 다음과 같습니다. <br />
        <br />
        <b>
          ● 커피, 녹차 등 카페인 음료는 아침 10시전에 마시기 <br />
          ● 수면공간과 생활공간 분리하기 <br />
          ● 침상 주위 정리정돈하기 <br />
          ● 자기전 TV, 컴퓨터, 스마트폰 멀리하기 <br />
          ● 방안의 온도와 이불 두께는 알맞게 <br />
        </b>
        <br />
        평소 운동은 상기되어 있는 기혈을 아래로 끌어 내리는데 도움이 되는 계단오르기, 실내자전거, 스쿼트등 하체
        강화운동을 권장합니다. <br />
        <br />
        <b>좀 더 자세한 내용은 상담 신청을 통하여 제공될 예정입니다.</b>
      </Typography>
    );
  }
  if (finalA >= 30 && maxScore === finalA) {
    return (
      <Typography variant="body2" color="text.secondary">
        당신은 인체 에너지의 순환기능이 약한 편으로 에너지를 인체 각 기관이나 조직으로 전달이 어려워 아침에 몸이 무겁고
        상쾌하지 못하며 잘 부을 수 있습니다. 순환기능에 문제가 있으면 체내 대사물이 지속적으로 쌓여 조금만 먹어도 살찌기
        쉬워집니다. 따라서 식사량 조절이 필수적이며 저탄수화물 고단백 위주의 식사를 하면서 서서히 식사량을 줄여
        최종적으로는 위장의 크기를 줄여야 한다는 생각이 중요합니다. <br />
        <br />
        <b>권장음식 : 식물성단백질(두부, 콩), 생선, 살코기(지방이 없는 부위)등 단백질위주의 음식</b> <br />
        <b>피해야 할 음식 : 흰쌀밥, 면(국수, 라면), 떡, 튀김음식, 과일 등 탄수화물</b> <br />
        <br />
        평소 운동은 에너지 순환을 원활하게 하기 위해 유산소 운동(스피트 워킹, 조깅, 등산, 수영 등)을 통해 땀을 흘리거나
        사우나, 찜질방, 냉온탕 또한 도움이 됩니다. 다만 비만한 경우 지나친 운동은 관절에 무리를 주므로 체지방을 줄이면서
        서서히 운동량을 늘려나가는 것이 필요합니다. <br />
        <br />
        <b>좀 더 자세한 내용은 상담 신청을 통하여 제공될 예정입니다.</b>
      </Typography>
    );
  }
  return (
    <Typography variant="body2" color="text.secondary">
      당신은 인체 에너지를 각 기관이나 조직으로 전달하는 순환기능, 음식을 통해 에너지를 만드는 생성기능, 신체에서
      에너지의 흐름을 균형 조절하는 기능 모두 큰 문제가 없는 것으로 보여 집니다. <br />
      <br />
      건강한 몸을 위해서는 규칙적인 식사시간과 식사량, 생활 속에서의 움직임과 적절한 운동, 그리고 일정한 취침시간과
      기상시간을 유지하는 것이 중요합니다. <br />
      <br />
      <b>
        하지만 개인이 가지고 있는 질환이나 체질에 따라 건강생활수칙이 다양할 수 있기 때문에 좀 더 자세한 내용은 상담
        신청을 통하여 제공될 예정입니다.
      </b>
    </Typography>
  );
};

export default function SurveyPasHisPresenter(Props: IPasGisPageProps) {
  const { validForm, validErrMessages, survey, dictObject, onClickSubmit } = Props;

  return !survey ? (
    <>
      <SurveyTitle title="※ 과거병력" />
      <div className="title-container">
        <div className="title-text">해당사항에 모두 체크해주세요</div>
      </div>

      <div className="checkBoxFlexDirecRowOuter-container">
        <div className="checkBoxFlexDirecRow-container">
          <div className="items">
            <div className="title"> </div>
            <div className="checkBox">유무</div>
          </div>
        </div>
        <div className="checkBoxFlexDirecRow-container">
          <div className="items">
            <div className="title"> </div>
            <div className="checkBox">유무</div>
          </div>
        </div>
      </div>

      <div className="checkBoxFlexDirecRowOuter-container">
        <CheckBoxFlexDirecRow
          qTitle="past_medical_history_1"
          title="고혈압"
          defaultValue={dictObject.past_medical_history_1}
        />
        <CheckBoxFlexDirecRow
          qTitle="past_medical_history_2"
          title="당뇨"
          defaultValue={dictObject.past_medical_history_2}
        />
        <CheckBoxFlexDirecRow
          qTitle="past_medical_history_3"
          title="고지혈증"
          defaultValue={dictObject.past_medical_history_3}
        />
        <CheckBoxFlexDirecRow
          qTitle="past_medical_history_4"
          title="중풍"
          defaultValue={dictObject.past_medical_history_4}
        />
        <CheckBoxFlexDirecRow
          qTitle="past_medical_history_5"
          title="암"
          defaultValue={dictObject.past_medical_history_5}
        />
        <CheckBoxFlexDirecRow
          qTitle="past_medical_history_6"
          title="심장질환"
          defaultValue={dictObject.past_medical_history_6}
        />
        <CheckBoxFlexDirecRow
          qTitle="past_medical_history_7"
          title="폐질환(결핵)"
          defaultValue={dictObject.past_medical_history_7}
        />
        <CheckBoxFlexDirecRow
          qTitle="past_medical_history_8"
          title="소화기 질환"
          defaultValue={dictObject.past_medical_history_8}
        />
        <CheckBoxFlexDirecRow
          qTitle="past_medical_history_9"
          title="간질환(간염)"
          defaultValue={dictObject.past_medical_history_9}
        />
        <CheckBoxFlexDirecRow
          qTitle="past_medical_history_10"
          title={`신장(콩팥)\n질환`}
          defaultValue={dictObject.past_medical_history_10}
        />
        <CheckBoxFlexDirecRow
          qTitle="past_medical_history_11"
          title="알러지"
          defaultValue={dictObject.past_medical_history_11}
        />
        <CheckBoxFlexDirecRow
          qTitle="past_medical_history_12"
          title="자가면역질환"
          defaultValue={dictObject.past_medical_history_12}
        />
        <CheckBoxWithTextField
          qTitle="past_medical_history_13"
          title="기타"
          defaultCheckValue={dictObject.past_medical_history_13}
          defaultTextValue={dictObject.past_medical_history_13_content}
          placeholder="명칭"
          helperText={validErrMessages.etcErrMessage}
          error={!validForm.etc && validErrMessages.etcErrMessage !== ''}
        />
        <CheckBoxWithTextField
          qTitle="past_medical_history_14"
          title="수술유무/수술부위"
          defaultCheckValue={dictObject.past_medical_history_14}
          defaultTextValue={dictObject.past_medical_history_14_content}
          placeholder="수술 부위"
          helperText={validErrMessages.pasSurgeryErrMessage}
          error={!validForm.pasSurgery && validErrMessages.pasSurgeryErrMessage !== ''}
        />
        <CheckBoxFlexDirecRow
          qTitle="past_medical_history_15"
          title="해당사항 없음"
          defaultValue={dictObject.past_medical_history_15}
        />
        <CheckBoxWithBlank />
      </div>

      {/* <StyledButton
        btnItem={{
          text: '설문 종료',
          color: '',
          disabled: !(validForm.name && validForm.etc && validForm.pasSurgery),
        }}
        onClickSubmit={onClickSubmit}
      /> */}
      <BtnPreEnd
        linkPre="../survey-abdtmp"
        linkNex="../survey-pashis"
        disabled={!(validForm.name && validForm.etc && validForm.pasSurgery)}
        onClickSubmit={onClickSubmit}
      />
    </>
  ) : (
    <s.StyledSurveyResultPage maxWidth="sm">
      <s.LogoBox>
        <s.SiloIcon src={siloIcon} />
        <s.CenterText variant="h4">oriental silo</s.CenterText>
      </s.LogoBox>

      <s.InfoBox>
        <s.LeftText variant="body2">이름 : {survey.basicInfo.name}</s.LeftText>
        <s.LeftText variant="body2">나이 : {survey.basicInfo.age}</s.LeftText>
        <s.LeftText variant="body2">번호 : {survey.basicInfo.phone}</s.LeftText>
      </s.InfoBox>

      <Typography variant="body1" color="text.secondary">
        결과를 추후에도 보고싶다면 캡쳐해주세요.
      </Typography>

      <s.resultItemBox>
        <s.SubTitle variant="h6">A. 심폐순환기능 {'->'} 기혈의 순환기능 평가</s.SubTitle>
        <Grid container spacing={2}>
          <Grid item>
            <s.Img alt="circulatory system" src={circulatorySystemImg} />
          </Grid>
          <Grid item xs container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <s.ScoreBox>
                  <Typography variant="subtitle1" sx={{ width: '70px' }}>
                    {100 - Math.round(((survey.AScore * 2) / 3) * 10)} / 100
                  </Typography>
                  {circleColorByScore(survey.AScore)}
                </s.ScoreBox>
                {commentByAScore(survey.AScore)}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </s.resultItemBox>

      <s.resultItemBox>
        <s.SubTitle variant="h6">B. 비위소화기능 {'->'} 기혈의 생성기능 평가</s.SubTitle>
        <Grid container spacing={2}>
          <Grid item>
            <s.Img alt="circulatory system" src={stomachSystemImg} />
          </Grid>
          <Grid item xs container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <s.ScoreBox>
                  <Typography variant="subtitle1" sx={{ width: '70px' }}>
                    {100 - Math.round(((survey.BScore * 2) / 3) * 10)} / 100
                  </Typography>
                  {circleColorByScore(survey.BScore)}
                </s.ScoreBox>
                {commentByBScore(survey.BScore)}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </s.resultItemBox>

      <s.resultItemBox>
        <s.SubTitle variant="h6">C. 간신 비뇨생식기능 {'->'} 기혈의 균형조절기능 평가</s.SubTitle>
        <Grid container spacing={2}>
          <Grid item>
            <s.Img alt="circulatory system" src={nervousSystemImg} />
          </Grid>
          <Grid item xs container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <s.ScoreBox>
                  <Typography variant="subtitle1" sx={{ width: '70px' }}>
                    {100 - Math.round(((survey.CScore * 2) / 3) * 10)} / 100
                  </Typography>
                  {circleColorByScore(survey.CScore)}
                </s.ScoreBox>
                {commentByCScore(survey.CScore)}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </s.resultItemBox>

      <s.resultItemBox>
        <s.SubTitle variant="h6">생활요법 제시</s.SubTitle>
        <Grid container spacing={2}>
          <Grid item xs container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <Typography variant="body2" color="text.secondary">
                  {commentLifeTherapy(survey.AScore, survey.BScore, survey.CScore)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </s.resultItemBox>
    </s.StyledSurveyResultPage>
  );
}
