import styled, { css, keyframes } from 'styled-components';
import { Container, Typography, Button, Alert, LinearProgress, TextField, IconButton, Box, Paper } from '@mui/material';

export const StyledSurveyResultPage = styled(Container)`
  display: flex;
  flex-direction: column;
  padding: 50px 20px;
  align-items: center;
`;

export const StyledTextField = styled(TextField)`
  width: 80%;
  input {
    padding: 1rem;
  }
`;

export const CenterText = styled(Typography)`
  font-weight: 500;
  text-align: center;
`;
export const LeftText = styled(Typography)`
  text-align: Left;
  font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px;
  color: #393939;
`;


export const LogoBox = styled(Box)`
  display: flex;
  flex-direction: column;
  margin: 0 auto 1rem;
`;

export const InfoBox = styled(Box)`
  display: flex;
  flex-direction: column;
  margin: 0 auto 3rem;
  margin-left: 2rem;
`;

export const SiloIcon = styled.img`
  width: 120px;
  margin: 0 auto;
`;

export const Img = styled.img`
  margin: auto;
  display: block;
  width: 130px;
  height: 130px;
`;

export const StyledButton = styled(Button)`
  && {
    width: 60%;
    height: 30px;
    background-color: #0F67FE;
  }
`;

export const ScoreBox = styled(Box)`
  display: flex;
  flex-direction: row;
`;

export const resultItemBox = styled(Box)`
  width: 100%;
  margin-top: 10px;
`;


export const PageContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  padding: 20px;
  font-family: 'Noto Sans KR', sans-serif;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 40px;
`;


export const BackButton = styled(IconButton)`
  margin-right: 5%;
  color: #090e1d;
`;

export const NaviTitleContainer = styled.div`
  flex-grow: 1;
  text-align: center;
`;

export const NaviTitleText = styled.span`
    padding: 0 8px;
    font-size: 18px;
    font-weight: bold;
    color: #090e1d;
    margin-right: 3rem;
`;

export const EnergyGraphContainer = styled.div`
    height: 14rem;
    margin-top: 1.5rem;
`;

export const MainTitle = styled.span`
  font-weight: 700;
  font-size: 24px;
  text-align: left;
  margin-top: 2rem;
  margin-left: 0.2rem;
  font-family: 'Noto Sans KR';
  padding: 0 8px;
`;

export const SubTitle = styled.span`
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
color: #393939;
`;

export const ContentText = styled.span`
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 15px;
color: #393939;
`;

export const QuestionText = styled.span`
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 20px;
color: #393939;
`;

export const CommentText = styled.span`
font-size: 10px;
font-style: normal;
font-weight: 500;
line-height: 1.2;
color: #B8B8B8;
`;

export const subContainerGeneral = styled.div`
display: flex;
width: auto; 
height: auto; 
margin-left: 0.6rem;
flex-direction: column;
`;

export const subContainerContentGeneral = styled.div`
display: flex;
width: auto; 
height: auto; 
margin-top: 0.4rem;
flex-direction: column;
`;

export const searchContainerWrapper = styled.div`
width: auto;
display: flex;
justify-content: center;
`;

export const searchContainer = styled.div`
margin-top: 1rem;
width: fit-content;
`;

export const searchImgContainer = styled.div`

`;

export const searchImg = styled.img`
height: 3rem;
width: auto;
max-width: -webkit-fill-available;
`;

export const searchLinkContainer = styled.div`
display: flex;
justify-content: flex-end;
place-items: center;
`;

export const searchLinkImg = styled.img`
width: 1.8rem;
height: auto;
`;

export const diseaseContainer = styled.div`
display: flex;
flex-wrap: wrap;
`;

export const diseaseContentWrapper = styled.div`
width: 8rem;
margin-left: 1rem;
margin-bottom : 0.4rem;
align-content: center;
`;

export const searchLink = styled.a`
margin-left: 1.3rem;
width: 2.3rem;
height: auto;
`

// status

export const statusesContainer = styled.div`
display: flex;
width: auto; 
height: auto; 
margin-left: 2rem;
margin-right: 2rem;
margin-top: 2rem;
flex-direction: column;
`;

export const StatusContainer = styled.div`
    display: flex;
    width: auto; 
    height: auto; 
    flex-direction: column;
    margin-top: 1rem;
`;

export const StatusIconContainer = styled.div`
    display: flex;
    width: auto; 
    height: auto; 
    flex-direction: row;
    align-items: end;
`;

export const StatusIconRound = styled.div`
    width: auto; 
    height: 22px; 
    border-radius: 5px; 
    justify-content: center;
    align-items: center;
    text-align: center; 
    padding-left: 12px;
    padding-right: 12px;
    margin-left: 10px;
    border: solid;
    margin-bottom: 4px;
    padding-bottom: 3px;
`;

export const StatusIconText = styled.span`
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`;

export const StatusTitle = styled.span`
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-bottom: 4px;
`;

export const StatusNum = styled.span`
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-left: 8px;
`;

export const StatusNumMother = styled.span`
font-size: 12px;
font-style: normal;
font-weight: 700;
line-height: normal;
color: #B8B8B8;
margin-bottom: 4px;
`;

export const StatusContent = styled.span`
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
color: #B8B8B8;
margin-left: 0.6rem;
`;

export const ManagementPointGraphContainer = styled.div`
display: flex;
width: auto; 
height: auto; 
flex-direction: column;
margin-top: 2rem;
align-items: center;
`;

export const ManagementPointGraphContents = styled.div`
display: flex;
width: auto; 
height: auto; 
flex-direction: row;
margin-top: -0.6rem;
`;

export const StatusDescContainer = styled.div`
display: flex;
width: auto; 
height: auto; 
flex-direction: row;
margin-top: 5px;
`;

export const StatusImg = styled.img`
width: 4.3rem;
height: auto;
`;

export const StautsDesc = styled.span`
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
color: #3D4966;
margin-left: 0.6rem;
align-content: center;
white-space: pre-wrap;
`;


// ---------------guide----------------

interface GuideCardProps {
    clicked: boolean;
}

const slideDown = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const ManagementPointEachContainer = styled.div`
display: flex;
width: 100%; 
height: auto; 
flex-direction: column;
margin-top: 2rem;
`;

export const GuideInfoContainer = styled.div`
display: flex;
flex-direction: row;
margin: 2rem;
margin-bottom: 0.3rem;
`;

export const GuideInfoImage = styled.img`
width: 8.7rem;
height: auto;
`;

export const GuideInfoTextContainer = styled.div`
display: flex;
flex-direction: row;
margin-left: 1rem;
align-items: center;
`;

export const GuideSubtitleContainer = styled.div`
display: flex;
flex-direction: column;
margin-left: 2rem;
`;

export const GuideCardContainer = styled.div<{ heightTo: number }>`
display: flex;
position: absolute;
left: 0;
right: 0;
flex-direction: column;
margin-top: 0.5rem;
width: auto;
height: ${props => `${props.heightTo}px`};
padding: 1rem;
align-items: center;
background-color: #EAF2FF;
transition: height 0.5s ease;
`;

export const GuideCardInnerContainer = styled.div`
width: 100%;
max-width: 1200px;
`;

export const GuideCardContainerWarpper = styled.div<{ heightTo: number }>`
height: ${props => `${props.heightTo}px`};
transition: height 0.5s ease;
`;

export const GuideCardScroller = styled.div`
display: flex;
overflow-x: auto;
white-space: nowrap;
width: 100%;
height: auto;
padding: 1rem;
box-sizing: border-box;
max-width: 1200px;
`;

export const GuideCard = styled.div<GuideCardProps>`
width: 80px;
height: 107px;
border-radius: 10px;
border: 1px solid ${props => props.clicked ? '#0F67FE' : '#B5B5B5'};
background: ${props => props.clicked ? '#0F67FE' : '#FFF'};
display: grid;
grid-template-rows: 3fr 1fr;
place-items: center;
grid-auto-columns: 80px;
margin-right: 10px;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
&:hover {
    background: ${props => props.clicked ? '#0F67FE' : '#C3D9FF'};
  }
  transition: background 0.3s ease, border 0.3s ease;
`

export const GuideCardImage = styled.img`
width: 60px;
height: 60px;
`;

export const GuideCardText = styled.span<GuideCardProps>`
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 15px;
white-space: pre-wrap;
text-align: center;
color: ${props => props.clicked ? '#FFF' : '#393939;'};
transition: color 0.3s ease;
`;

export const GuideDescWrapper = styled.div<{ animate: boolean }>`
${(props) =>
        props.animate &&
        css`
    animation: ${slideDown} 0.7s ease-out;
  `}
`;

export const GuideTitleWrapper = styled.div<GuideCardProps>`
color: ${props => props.clicked ? '#0F67FE' : '#AAA'};
&:hover {
    color: ${props => props.clicked ? '#0F67FE' : '#C3D9FF'};
  }
transition: color 0.3s ease;
text-align: center;
`

export const GuideTitle = styled.span`
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-align: center;
`;

export const GuideTitlePartition = styled.div`
stroke-width: 2px;
border-left: solid #AAA;
height: 90%;
place-self: center;
`;

export const GuideTitleContainer = styled.div<{ nums: number }>`
display: grid;
width: 100%;
height: auto;
padding: 0.5rem;
box-sizing: border-box;
max-width: 1200px;
margin-top: 1rem;
grid-template-columns: ${props => {
        let temp = '9fr';
        for (let i = 1; i < props.nums; i += 1) {
            temp = temp.concat(' 1fr 9fr');
        }
        return temp;
    }};
`;



//  -------------------- question -----------------

export const questionContentContainer = styled.div`
display: flex;
flex-direction: column;
font-size: 1rem;
opacity: .6;
padding-left: 2rem;
padding-right: 2rem;
width: auto; 
height: auto; 
margin-top: 0.5rem;
`;

export const questionSelectionContainer = styled.div`
align-items: center;
display: flex;
justify-content: space-between;
width: 100%
`

export const ContentSelectText = styled.span`
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 15px;
color: #393939;
`;

export const QuestionWrapper = styled.div`
width: 100%;
margin-bottom: 1rem;
`

export const DiseaseContainer = styled.div`
padding: 1rem;
`

export const StyledPaper = styled(Paper)`
  padding: 1rem 1rem 1rem 1rem;
  margin: 1.5rem 0;
  width: 100%;
  box-sizing: border-box;
`;