import React, { useEffect, useRef, useState } from 'react';
import { ArrowBack } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as s from '../info/InfoStyled';

function PrivacyForm({
  title,
  children,
  onNext,
  isValid,
}: {
  title: string;
  children: React.ReactNode;
  onNext: (event?: React.FormEvent<HTMLFormElement>) => void;
  isValid: boolean;
}) {
  const [showError, setShowError] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    setShowError(false);
  }, [location]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isValid) {
      onNext(event);
    } else {
      setShowError(true);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleSkip = () => {
    navigate('/info', {
      state: {
        isHealthCare: false,
      },
    });
  };

  return (
    <s.PageContainer>
      <div style={{ width: '100%', marginTop: '20px' }}>
        <s.PrivacyContainer>
          <s.NavButton onClick={handleBack} aria-label="Go back">
            <ArrowBack />
          </s.NavButton>
          <s.PrivacyTitle>{t('privacy.index')}</s.PrivacyTitle>
        </s.PrivacyContainer>
        <s.InfoTitle>{title}</s.InfoTitle>
      </div>
      <s.Form onSubmit={handleSubmit} style={{ justifyContent: 'space-between' }}>
        <div style={{ width: '100%' }}>
          {children}
          {showError && <s.ErrorMessage severity="error">{t('inputError')}</s.ErrorMessage>}
        </div>
        <div style={{ width: '100%' }}>
          <s.NextButton type="button" onClick={handleSkip} variant="outlined" color="primary">
            {t('button.skip')}
          </s.NextButton>
          <s.NextButton type="submit" variant="contained" color="primary">
            {t('button.next')}
          </s.NextButton>
        </div>
      </s.Form>
    </s.PageContainer>
  );
}

interface PrivacyPresenterProps {
  privacyData: {
    name: string;
    phoneNumber: string;
  };
  handleInputChange: (event: { target: { name: any; value: any } }) => void;
  handleNext: () => void;
  modal: boolean;
  openModal: () => void;
  closeModal: () => void;
  onClickModalNochan: () => void;
  onClickModalChan: () => void;
}

export default function PrivacyPresenter({
  privacyData,
  handleInputChange,
  handleNext,
  modal,
  openModal,
  closeModal,
  onClickModalNochan,
  onClickModalChan,
}: PrivacyPresenterProps) {
  const modalBackground = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  return (
    <main>
      <PrivacyForm
        title={t('privacy.title')}
        onNext={handleNext}
        isValid={privacyData.phoneNumber.trim() !== '' && privacyData.name.trim() !== ''}
      >
        <s.PrivacyText>{t('privacy.text')}</s.PrivacyText>
        <s.InputTitle>{t('privacy.name')}</s.InputTitle>
        <s.InfoTextField
          fullWidth
          name="name"
          value={privacyData.name}
          onChange={handleInputChange}
          placeholder={t('privacy.name_placeholder')}
        />
        <s.InputTitle>{t('privacy.phone')}</s.InputTitle>
        <s.InfoTextField
          fullWidth
          name="phoneNumber"
          value={privacyData.phoneNumber}
          onChange={handleInputChange}
          placeholder="01012345678"
          inputProps={{ pattern: '[0-9]{11}' }}
        />
      </PrivacyForm>
      {modal && (
        <s.InfoModalBackground
          ref={modalBackground}
          onClick={(e) => {
            if (e.target === modalBackground.current) {
              closeModal();
            }
          }}
        >
          <s.InfoModalBox>
            <s.InputTitle>기존 회원정보가 존재합니다.</s.InputTitle>
            <s.PrivacyText>
              회원 정보가 변경되었다면 회원정보 수정을, 변경되지 않았다면 다음을 눌러주세요.
            </s.PrivacyText>
            <s.PrivacyText>대상 정보 : 이름, 성별, 키, 몸무게</s.PrivacyText>
            <s.NextButton type="submit" onClick={onClickModalNochan} variant="contained" color="primary">
              다음
            </s.NextButton>
            <s.NextButton type="button" onClick={onClickModalChan} variant="outlined" color="primary">
              회원정보 수정
            </s.NextButton>
          </s.InfoModalBox>
        </s.InfoModalBackground>
      )}
    </main>
  );
}
