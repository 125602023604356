import styled from '@emotion/styled';
import { Container, TextField, Typography, Box, Button } from '@mui/material';

export const StyledSurveyResultPage = styled(Container)`
  display: flex;
  flex-direction: column;
  padding: 50px 20px;
  align-items: center;
`;

export const StyledTextField = styled(TextField)`
  width: 80%;
  input {
    padding: 1rem;
  }
`;

export const CenterText = styled(Typography)`
  font-weight: 500;
  text-align: center;
`;
export const LeftText = styled(Typography)`
  font-weight: 300;
  text-align: Left;
`;

export const SubTitle = styled(Typography)`
  font-weight: 550;
  margin: 0 auto 1rem;
  border-bottom: 2px solid gray;
`;

export const LogoBox = styled(Box)`
  display: flex;
  flex-direction: column;
  margin: 0 auto 1rem;
`;

export const InfoBox = styled(Box)`
  display: flex;
  flex-direction: column;
  margin: 0 auto 3rem;
`;

export const SiloIcon = styled.img`
  width: 120px;
  margin: 0 auto;
`;

export const Img = styled.img`
  margin: auto;
  display: block;
  width: 130px;
  height: 130px;
`;

export const StyledButton = styled(Button)`
  && {
    width: 60%;
    height: 30px;
    background-color: #2cbc88;
  }
`;

export const ScoreBox = styled(Box)`
  display: flex;
  flex-direction: row;
`;

export const resultItemBox = styled(Box)`
  width: 100%;
  margin-top: 10px;
`;
