import React from 'react';
import { useTranslation } from 'react-i18next';
import * as s from './NewSurveyStyled';
import wakeUp from '../../../asset/img/survey/wakeup.png';
import goToSleep from '../../../asset/img/survey/gotosleep.png';

interface RegularSleepTimeProps {
  onNext: () => void;
  onScore: (key: string, score: number) => void;
  selectedOption: string;
  onOptionSelect: (option: string) => void;
}

const RegularSleepTime: React.FC<RegularSleepTimeProps> = ({ onNext, onScore, selectedOption, onOptionSelect }) => {
  const { t } = useTranslation();
  
  return (
    <s.SurveyContainer>
      <s.Question>{t('survey.regular_sleep_time.question')}</s.Question>
      <s.TwoPictureContainer>
        <s.TwoPicture src={wakeUp} alt="기상" />
        <s.TwoPicture src={goToSleep} alt="취침" />
      </s.TwoPictureContainer>
      <s.OptionContainer>
        <s.Options>
          <s.OptionButton
            type="button"
            variant={selectedOption === '1' ? 'contained' : 'outlined'}
            onClick={() => {
              onOptionSelect('1');
              onScore('ebBalanceControl', -1);
            }}
            style={{ height: '100px' }}
          >
            <s.OptionTitle>{t('survey.regular_sleep_time.option1')}</s.OptionTitle>
            <s.OptionSubtitle>{t('survey.regular_sleep_time.sub_option1')}</s.OptionSubtitle>
          </s.OptionButton>
          <s.OptionButton
            type="button"
            variant={selectedOption === '2' ? 'contained' : 'outlined'}
            onClick={() => {
              onOptionSelect('2');
              onNext();
            }}
            style={{ height: '100px' }}
          >
            <s.OptionTitle>{t('survey.regular_sleep_time.option2')}</s.OptionTitle>
            <s.OptionSubtitle>{t('survey.regular_sleep_time.sub_option2')}</s.OptionSubtitle>
          </s.OptionButton>
        </s.Options>
      </s.OptionContainer>
    </s.SurveyContainer>
  );
};

export default RegularSleepTime;
