import React, { useEffect } from 'react';
import {
  BtnPreNext,
  SurveyMultiSelect,
  SurveySingleSelect,
  SurveyTitle,
  SurveyMultiSelectWithNoProblem,
} from '../common/Survey';
import { ISurveyDictType } from '../common/SurveyTypes';
import '../common/Survey.scss';

interface IHbiPsnPageProps {
  dictObject: ISurveyDictType;
  validForm: IValidData;
}

interface IValidData {
  q61to63: boolean;
  q64: boolean;
  q7: boolean;
}

export default function SurveyHbiPsnPresenter(Props: IHbiPsnPageProps) {
  const { validForm, dictObject } = Props;

  const q61to63 = {
    qTitles: ['q61', 'q62', 'q63'],
    title: '6-1. 아침 기상시 상태 (해당사항을 모두 체크해주세요)',
    questions: ['아침 기상 시 얼굴이 부음', '아침 기상 시 몸이 무거움', '해당 사항 없음'],
  };

  const q64 = {
    qTitle: 'q64',
    title: '6-2. 평소 활동량',
    questions: ['평소 활동량이 적음', '평소 활동량이 보통', `평소 활동량이 많음`],
  };

  const q7 = {
    qTitles: ['q71', 'q72', 'q73', 'q74', 'q75', 'q76'],
    title: '평소 성격 (해당사항을 모두 체크해주세요)',
    questions: ['급함', '소심함', '느긋함', '내성적', '외향적', '해당 사항 없음'],
  };

  return (
    <>
      <SurveyTitle title="6. 평소 생활습관" />
      <SurveyMultiSelectWithNoProblem
        surveyItem={q61to63}
        defaultValues={[dictObject.q61, dictObject.q62, dictObject.q63]}
      />
      <SurveySingleSelect surveyItem={q64} defaultValue={dictObject.q44} />
      <SurveyTitle title="7. 평소 성격" />
      <SurveyMultiSelectWithNoProblem
        surveyItem={q7}
        defaultValues={[dictObject.q71, dictObject.q72, dictObject.q73, dictObject.q74, dictObject.q75, dictObject.q76]}
      />
      <BtnPreNext
        linkPre="../survey-urins"
        linkNex="../survey-abdtmp"
        disabled={!(validForm.q61to63 && validForm.q64 && validForm.q7)}
      />
    </>
  );
}
