import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, To, useLocation } from 'react-router-dom';
import InfoPresenter from './InfoPresenter';
import { IBasicInfo, setBasicInfo } from '../../../modules/newsurvey';
import { RootState } from '../../../modules';
import { patientApi, patientType } from '../../../api/api-patient';

type StepType = '성별' | '나이' | '혈압' | '체중' | '키';

const stepOrder: StepType[] = ['성별', '나이', '혈압', '체중', '키'];

function calculateBMIValues(bmi: number) {
  let ebCirculation = 0;
  let ebProduce = 0;

  if (bmi >= 35) {
    ebCirculation = -8;
  } else if (bmi >= 30) {
    ebCirculation = -6;
  } else if (bmi >= 25) {
    ebCirculation = -4;
  } else if (bmi >= 18) {
    ebProduce = -2;
  } else if (bmi >= 15) {
    ebProduce = -4;
  } else if (bmi >= 14.9) {
    ebProduce = -5;
  }

  return {
    ebCirculation,
    ebProduce,
  };
}

export default function InfoContainer() {
  const location = useLocation();
  const newsurveydata = useSelector((state: RootState)=> state.newsurvey);
  const privacyData = location.state as { name: string; phoneNumber: string; isHealthCare: boolean };
  const [step, setStep] = useState<StepType>('성별');
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    gender: '',
    age: '60',
    systolicBloodPressure: '0',
    diastolicBloodPressure: '0',
    weight: '75',
    height: '100',
    ebCirculation: 0,
    ebProduce: 0,
    ebBalanceControl: 0,
  });
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleInputChange = (event: { target: { name: any; value: any } }) => {
    const { name, value } = event.target;
    setFormData((prevData) => {
      const updatedData = { ...prevData, [name]: value.toString() };
      if (name === 'height') {
        const weight = Number(updatedData.weight);
        const heightInMeters = value / 100;
        const bmi = weight / heightInMeters ** 2;
        const { ebCirculation, ebProduce } = calculateBMIValues(bmi);
        updatedData.ebCirculation = ebCirculation;
        updatedData.ebProduce = ebProduce;
      }
      return updatedData;
    });
  };

  const handleNext = (path: To) => (event: { preventDefault: () => void }) => {
    event.preventDefault();
    navigate(path);
  };

  const handleResults = (path: To) => async (event: { preventDefault: () => void }) => {
    dispatch(setBasicInfo(formData as IBasicInfo));

    const isExist = newsurveydata.privacy_info.exist;
    // console.log(newsurveydata.privacy_info);
    const genderCode:Record<string,number> = {"male": 0, "female": 1};

    const routing = () =>{
      if (privacyData.isHealthCare) {
        navigate(path, {
          state: {
            privacyData: {
              name: privacyData.name,
              phoneNumber: privacyData.phoneNumber,
            },
            isHealthCare: privacyData.isHealthCare,
            gender: formData.gender,
            ebCirculation: formData.ebCirculation,
            ebProduce: formData.ebProduce,
          },
        });
      } else {
        navigate(path, {
          state: {
            isHealthCare: privacyData.isHealthCare,
            gender: formData.gender,
            ebCirculation: formData.ebCirculation,
            ebProduce: formData.ebProduce,
          },
        });
      }
    }
    
    setLoading(true);
    event.preventDefault();

    if(newsurveydata.privacy_info.phoneNumber !== ""){
      const patientData: patientType = {
        name: newsurveydata.privacy_info.name,
        height: Number(formData.height),
        weight: Number(formData.weight),
        birth: new Date().getFullYear() - Number(formData.age) + 1,
        phone: newsurveydata.privacy_info.phoneNumber,
        gender: genderCode[formData.gender],
        systolic: Number(formData.systolicBloodPressure),
        diastolic: Number(formData.diastolicBloodPressure)
      };
      if(!isExist){
        let res;
        try{
          res = await patientApi.createPatient(patientData)
        } catch(e){
          console.log(e);
        }
        finally{
          if (res){
            if(res.status >= 200 && res.status < 300){
              routing();
            }
            else{
              const confirmed = window.confirm("서버와의 연결에 문제가 발생하였습니다.");
            }
          }
        }
      }
      else{
        let res;
        try{
          res = await patientApi.updatePatient(patientData)
        } catch(e){
          console.log(e);
        }
        finally{
          if (res){
            if(res.status >= 200 && res.status < 300){
              routing();
            }
            else{
              const confirmed = window.confirm("서버와의 연결에 문제가 발생하였습니다.");
            }
          }
        }
      }
    }
    else{
      routing();
    }

    // setTimeout(() => {
    //   if (privacyData.isHealthCare) {
    //     navigate(path, {
    //       state: {
    //         privacyData: {
    //           name: privacyData.name,
    //           phoneNumber: privacyData.phoneNumber,
    //         },
    //         isHealthCare: privacyData.isHealthCare,
    //         gender: formData.gender,
    //         ebCirculation: formData.ebCirculation,
    //         ebProduce: formData.ebProduce,
    //       },
    //     });
    //   } else {
    //     navigate(path, {
    //       state: {
    //         isHealthCare: privacyData.isHealthCare,
    //         gender: formData.gender,
    //         ebCirculation: formData.ebCirculation,
    //         ebProduce: formData.ebProduce,
    //       },
    //     });
    //   }
    // }, 1500);
  };

  const totalSteps = stepOrder.length;
  const currentStepIndex = stepOrder.indexOf(step);

  const goToNextStep = () => {
    // console.log(formData );
    if (currentStepIndex < stepOrder.length - 1) {
      setStep(stepOrder[currentStepIndex + 1]);
    }
  };

  const goToPreviousStep = () => {
    if (currentStepIndex > 0) {
      setStep(stepOrder[currentStepIndex - 1]);
    }
  };

  return (
    <InfoPresenter
      formData={formData}
      handleInputChange={handleInputChange}
      handleNext={handleNext}
      handleResults={handleResults}
      setFormData={setFormData}
      step={step}
      totalSteps={totalSteps}
      currentStepIndex={currentStepIndex}
      goToNextStep={goToNextStep}
      goToPreviousStep={goToPreviousStep}
      loading={loading}
    />
  );
}
