import React from 'react';
import { Route, Routes } from 'react-router-dom';
import WelcomePage from './components/pages/welcome';
import InfoPage from './components/pages/info';
import LoginPage from './components/pages/login';
import SignUpPage from './components/pages/signUp';
import MainPage from './components/pages/main';
import UserListPage from './components/pages/userList';
import PassWordFindPage from './components/pages/passwordFind';
import SurveyListPage from './components/pages/surveyList';
import SurveyBasicPage from './components/pages/surveys/basic';
import { localTokenCheck } from './utils/util';
import SurveyMainPage from './components/pages/surveys/surveyMain';
import SurveyBdyShpPage from './components/pages/surveys/bodyShape';
import SurveyDigSlpPage from './components/pages/surveys/digestionSleep';
import SurveyHbiPsnPage from './components/pages/surveys/habitPersonality';
import SurveyUrsPage from './components/pages/surveys/urineStool';
import SurveyAbdTmpPage from './components/pages/surveys/abdominalTemperature';
import SurveyFavFodPage from './components/pages/surveys/favoriteFood';
import SurveyPasHisPage from './components/pages/surveys/pastMedicalHistory';
import SurveyResultPage from './components/pages/surveyResult';
import SurveyUserResultPage from './components/pages/surveys/result';
import SurveyDgnsisPage from './components/pages/surveys/diagnosis';
import NewSurveyPage from './components/pages/new_surveys/NewSurvey';
import PrivacyPage from './components/pages/privacy';
import HealthCarePage from './components/pages/healthCare';
import HomePage from './components/pages/homepage/HomepagePresenter';
import IntroducePage from './components/pages/homepage/IntroducePresenter';
import NobinoHealthPage from './components/pages/homepage/NobinoHealthPresenter';
import OrientalPage from './components/pages/homepage/OrientalPresenter';
import SocialPage from './components/pages/homepage/SocialPresenter'; 

export function MainRouter() {
  const tokenValid = localTokenCheck();
  return (
    <Routes>
      <Route path="/" element={tokenValid ? <MainPage /> : <LoginPage />} />
      <Route path="/welcome" element={<WelcomePage />} />
      <Route path="/info/*" element={<InfoPage />} />
      <Route path="/signin" element={<LoginPage />} />
      <Route path="/signup" element={<SignUpPage />} />
      <Route path="/password-find" element={<PassWordFindPage />} />
      <Route path="main" element={<MainPage />} />
      <Route path="/survey" element={<SurveyMainPage />} />
      <Route path="/survey-basic" element={<SurveyBasicPage />} />
      <Route path="/survey-bdyshp" element={<SurveyBdyShpPage />} />
      <Route path="/survey-digslp" element={<SurveyDigSlpPage />} />
      <Route path="/survey-urins" element={<SurveyUrsPage />} />
      <Route path="/survey-hbipsn" element={<SurveyHbiPsnPage />} />
      <Route path="/survey-abdtmp" element={<SurveyAbdTmpPage />} />
      <Route path="/survey-favfod" element={<SurveyFavFodPage />} />
      <Route path="/survey-pashis" element={<SurveyPasHisPage />} />
      <Route path="/survey-dgnsis" element={<SurveyDgnsisPage />} />
      <Route path="/survey-list" element={<SurveyListPage />} />
      <Route path="/user-list" element={<UserListPage />} />
      <Route path="/survey-result" element={<SurveyResultPage />} />
      <Route path="/survey-user-result" element={<SurveyUserResultPage />} />
      <Route path="/new-surveys/*" element={<NewSurveyPage />} />
      <Route path="/privacy" element={<PrivacyPage />} />
      <Route path="/health-care" element={<HealthCarePage />} />
      <Route path="/home" element={<HomePage />} />
      <Route path="/introduce" element={<IntroducePage />} />
      <Route path="/nobino-health" element={<NobinoHealthPage />} />
      <Route path="/oriental" element={<OrientalPage />} />
      <Route path="/social" element={<SocialPage />} />
    </Routes>
  );
}
