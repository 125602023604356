/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Container, Paper, Typography, FormGroup, FormControlLabel, Checkbox, TextField } from '@mui/material';
import * as s from './NewSurveyStyled';

const StyledPaper = styled(Paper)`
  padding: 0 1.5rem 1.5rem 1.5rem;
  margin: 1.5rem 0;
  overflow-y: auto;
  max-height: 50vh;
  width: 100%;
  box-sizing: border-box;
`;

const StyledFormGroup = styled(FormGroup)`
  margin-top: 16px;
`;

const LabelText = styled(Typography)`
  font-family: 'Noto Sans KR', sans-serif;
`;

const StyledTextField = styled(TextField)`
  margin-top: 8px;
  margin-left: 24px;
  width: 80%;
`;

const AmountText = styled(Typography)`
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 0.75rem;
  width: 4rem;
`;

const FormControlLabelCustom = styled(FormControlLabel)`
  width: 50%;
`;

interface MedicineContent {
  value: boolean;
  month: number;
}

interface Medicine {
  diet: boolean;
  kmedicine: MedicineContent;
  wmedicine: MedicineContent;
}

interface ObeseMedProps {
  med: Medicine
}

const ObeseMedHistory: React.FC<ObeseMedProps> = ({
  med
}) => {
  const { t } = useTranslation();
  return (
    <s.SurveyContainer>
      <Container style={{ padding: '0' }}>
        <s.OptionContainer style={{ padding: '0' }}>
          <StyledPaper>
            <StyledFormGroup>
              <FormControlLabelCustom
                control={<Checkbox checked={!med.diet} name="해당사항없음" />}
                label={<LabelText>{t('survey.obese_med.option1')}</LabelText>}
              />

              <FormControlLabelCustom
                control={<Checkbox checked={med.kmedicine.value} name="한약" />}
                label={t('survey.obese_med.option2')}
              />
              {med.kmedicine.value && (
                <StyledTextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  placeholder={t('survey.obese_med.placeholder')}
                  value={med.kmedicine.month}
                  InputProps={{
                    endAdornment: <AmountText>{t('manage.health.moonth')}</AmountText>,
                  }}
                />
              )}
              <FormControlLabelCustom
                control={<Checkbox checked={med.wmedicine.value} name="양약" />}
                label={t('survey.obese_med.option3')}
              />
              {med.wmedicine.value && (
                <StyledTextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  placeholder={t('survey.obese_med.placeholder')}
                  value={med.wmedicine.month}
                  InputProps={{
                    endAdornment: <AmountText>{t('manage.health.moonth')}</AmountText>,
                  }}
                />
              )}
            </StyledFormGroup>
          </StyledPaper>
        </s.OptionContainer>
      </Container>
    </s.SurveyContainer>
  );
};

export default ObeseMedHistory;
