import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Alert } from '@mui/material';
import * as s from './NewSurveyStyled';
import pee from '../../../asset/img/survey/pee.png';
import peeDream from '../../../asset/img/survey/peeDream.png';
import no from '../../../asset/img/survey/no.png';

const ErrorMessage = styled(Alert)`
  margin-top: 100px;
  font-family: 'Noto Sans KR', sans-serif;
`;

interface PeeProps {
  onNext: () => void;
  onScore: (key: string, score: number) => void;
  selectedOption: string[];
  onOptionSelect: (option: string) => void;
  isSelected: (selectedOptions: string[], option: string) => boolean;
  isAllSelected: () => boolean;
  isHealthCare: boolean;
}

const Pee: React.FC<PeeProps> = ({ onNext, onScore, selectedOption, onOptionSelect, isSelected, isAllSelected, isHealthCare }) => {
  const [showError, setShowError] = useState(false);
  const location = useLocation();
  useEffect(() => {
    setShowError(false);
  }, [location]);
  const calcScore = (selectedOptions: string[]) => {
    if (selectedOptions.includes('1') || selectedOptions.includes('2')) {
      onScore('ebBalanceControl', -1);
    }
  };

  const { t } = useTranslation();

  return (
    <s.SurveyContainer>
      <s.Question>{t('survey.pee.question')}</s.Question>
      <s.InnerOptionContainer style={{ marginTop: '1rem' }}>
        <s.OptionContainer>
          <s.Options>
            <s.OptionButton
              type="button"
              variant={isSelected(selectedOption, '1') ? 'contained' : 'outlined'}
              onClick={() => {
                onOptionSelect('1');
              }}
            >
              <s.ThreeOptionImage src={pee} alt="대체 이미지" />
              <s.OptionSubtitle>{t('survey.pee.option1')}</s.OptionSubtitle>
            </s.OptionButton>
            <s.OptionButton
              type="button"
              variant={isSelected(selectedOption, '2') ? 'contained' : 'outlined'}
              onClick={() => {
                onOptionSelect('2');
              }}
            >
              <s.ThreeOptionImage src={peeDream} alt="대체 이미지" />
              <s.OptionSubtitle>{t('survey.pee.option2')}</s.OptionSubtitle>
            </s.OptionButton>
            <s.OptionButton
              type="button"
              variant={isSelected(selectedOption, '3') ? 'contained' : 'outlined'}
              onClick={() => {
                onOptionSelect('3');
              }}
            >
              <s.ThreeOptionImage src={no} alt="대체 이미지" />
              <s.OptionSubtitle>{t('survey.pee.option3')}</s.OptionSubtitle>
            </s.OptionButton>
          </s.Options>
        </s.OptionContainer>
      </s.InnerOptionContainer>
      {showError && <ErrorMessage severity="error">{t('unfinishedError')}</ErrorMessage>}
      <s.NextButton
        variant="contained"
        color="primary"
        onClick={() => {
          if (isAllSelected()) {
            calcScore(selectedOption);
            onNext();
          } else {
            setShowError(true);
          }
        }}
      >
        {isHealthCare ? t('button.next') : t('button.end')}
      </s.NextButton>
    </s.SurveyContainer>
  );
};

export default Pee;
