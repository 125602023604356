import React, { useState } from 'react';
import styled from 'styled-components';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import mediaBook from '../../../asset/img/media_book.png';
import News2 from '../../../asset/img/news2.png';
import News3 from '../../../asset/img/news3.png';
import News4 from '../../../asset/img/professor.png';
import News5 from '../../../asset/img/news5.png';
import News6 from '../../../asset/img/news6.png';
import News7 from '../../../asset/img/new7.png';
import News8 from '../../../asset/img/news8.png';

function MediaCard({ title, content, link, img }: { title: string; content: string; link: string; img: any }) {
  return (
    <Card sx={{ width: '330px', marginBottom: '20px', '@media (max-width: 600px)': {
          width: '100%',
        }, }}>
      <CardMedia sx={{ height: 160 }} image={img} />
      <CardContent>
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          style={{ fontFamily: "'Noto Sans KR', sans-serif", fontSize: '1rem', height: '2.8rem', overflow: 'hidden' }}
        >
          {title}
        </Typography>
        <Typography
          variant="body2"
          sx={{ color: 'text.secondary', fontFamily: "'Noto Sans KR', sans-serif", height: '4rem', overflow: 'hidden' }}
        >
          {content}
        </Typography>
      </CardContent>
      <CardActions>
        <a href={link}>
          <Button size="small">뉴스 보러가기</Button>
        </a>
      </CardActions>
    </Card>
  );
}

const ContentWrapper = styled.div`
  width: 100%;
  background-color: white;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NewsWrapper = styled.div`
  width: 100%;
  background-color: white;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;
  align-items: stretch;
`;

const SectionTitle = styled.h2`
  width: 100%;
  font-size: 1.5rem;
  margin: 0 0 1.5rem;
  justify-content: left;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
`;

const TabButton = styled(Button)<{ active: boolean }>`
  && {
    font-family: 'Noto Sans KR', sans-serif;
    font-size: 1rem;
    margin: 0 0.5rem;
    background-color: ${(props) => (props.active ? '#0f67fe' : 'white')};
    color: ${(props) => (props.active ? 'white' : '#0f67fe')};
  }

  @media (max-width: 991px) {
    && {
      font-size: 0.8rem;
    }
  }
`;

const YouTubeList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const YouTubeItem = styled.a`
  display: flex;
  align-items: center;
  padding: 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  text-decoration: none;
  color: inherit;
  transition: background-color 0.3s;

  &:hover {
    background-color: #f5f5f5;
  }
`;

const YouTubeThumbnail = styled.img`
  width: 120px;
  height: 90px;
  object-fit: cover;
  margin-right: 1rem;
`;

const YouTubeTitle = styled.h3`
  font-size: 1rem;
  margin: 0;

  @media (max-width: 991px) {
    font-size: 0.8rem;
  }
`;

const BlogList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const BlogItem = styled.a`
  display: flex;
  padding: 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  text-decoration: none;
  color: inherit;
  transition: background-color 0.3s;

  &:hover {
    background-color: #f5f5f5;
  }
`;

const BlogContent = styled.div`
  flex: 1;
`;

const BlogTitle = styled.h3`
  font-size: 1rem;
  margin: 0 0 0.5rem 0;
`;

const BlogDescription = styled.p`
  font-size: 0.9rem;
  color: #666;
  margin: 0;
`;

const BlogDate = styled.span`
  font-size: 0.8rem;
  color: #999;
`;

const OrientalSection: React.FC = () => {
  const [activeTab, setActiveTab] = useState<'youtube' | 'news' | 'blog'>('youtube');

  const mediaCardsData = [
    {
      title: '경희대한방병원 이재동 교수, K.한의학 임상총론 출간',
      content: '기초이론부터 진단, 치료, 양생법 등 실제 진료에 필요한 정보 담아',
      link: 'https://www.whosaeng.com/110523',
      img: mediaBook,
    },
    {
      title: '경희대한방병원 최도영·이재동·남동우 교수, 한의학 세계화 앞장',
      content: '근골격계 질환에 대한 매선요법의 적용 가능성 제시 등 큰 관심 ICMART 2019 학술대회서 우수포스터상 수상',
      link: 'https://www.akomnews.com/bbs/board.php?bo_table=news&wr_id=36996',
      img: News2,
    },
    {
      title: '[이재동 교수의 노비노(NOBINO)건강법] 한방치료, 몸의 에너지 균형 회복으로',
      content:
        '질병을 치료하는 방법에는 증상 위주의 표증치료법과 몸 중심의 본증치료법이 있다. 표증치료법은 아픈 부위의 질병이나 증상 중심으로 치료하는 방법이며..',
      link: 'https://www.pointdaily.co.kr/news/articleView.html?idxno=214740',
      img: News3,
    },
    {
      title: '에너지 맞춤형 NO비만, NO질병 노비노(NOBINO) 다이어트',
      content: '40대 이상의 중년 여성들은 호르몬이 줄어들면서 지방이 분해되지 않고 축적돼 비만으로 이어지기 쉽다.',
      link: 'https://www.pharmstoday.com/news/articleView.html?idxno=326992',
      img: News4,
    },
    {
      title: '한의학의 근본으로 몸의 밸런스를 찾다 | 침구과 이재동 교수',
      content:
        '이재동 교수는 환자의 질병을 고치기 위해서는 질병 그 자체가 아닌 전체적인 몸의 밸런스를 고치는 게 가장 중요하다고 말한다. 이러한 진료 철학을 바탕으로 후학들에게도.. ',
      link: 'https://khmcpropose.com/vol439/2021/09/13/%EB%B9%84%EB%87%A8%EC%9D%98%ED%95%99-a%EB%B6%80%ED%84%B0-z%EA%B9%8C%EC%A7%80-%EC%B5%9C%EC%8B%A0-%EC%A7%84%EB%A3%8C%EB%A5%BC-%EC%84%A0%EB%8F%84%ED%95%98%EB%8B%A4-_-%EB%A1%9C%EB%B4%87-%EC%88%98%EC%88%A0/',
      img: News5,
    },
    {
      title: '"뚱뚱하다고 다 비만?…기혈상태 진단부터"[비만돋보기]',
      content:
        ' 뚱뚱하다고 모두 비만은 아니다. 대한비만학회에 따르면 비만은 지방이 정상보다 많이 쌓여있는 상태로 체지방량을 기준으로 평가한다.',
      link: 'https://www.newsis.com/view/NISX20221129_0002104748',
      img: News8,
    },
    {
      title: '[이재동 교수의 노비노건강법] NO비만 NO노화 위한 내 몸 에너지 맞춤 생활건강법',
      content:
        '음식은 일상생활에 필요한 내 몸의 에너지를 생성하는데 가장 중요하기 때문에 잘 먹어야 한다. 잘 먹는다는 것은 좋은 것을 많이 먹는 것이 아니라..',
      link: 'https://kmc.khu.ac.kr/kmc_kor/user/bbs/BMSR00040/view.do',
      img: News6,
    },
    {
      title: '경희의료원, ‘메타버스 개인별 맞춤 건강상담실’ 오픈',
      content:
        '게더타운 내 ‘한슬림 아카이브’ 구비…비대면 경희카이닥 1:1 맞춤형 상담 진행. ESG 경영 실천의 일환, ‘공상공무원, 소방·경찰공무원’ 대상에서 일반인까지 확대 예정',
      link: 'http://www.bosa.co.kr/news/articleView.html?idxno=2174451',
      img: News7,
    },
  ];

  const youtubeData = [
    {
      id: 'iEsy-H1mzag',
      title: 'NOBINO 다이어트 노비노 다이어트',
      thumbnail: `https://img.youtube.com/vi/iEsy-H1mzag/0.jpg`,
    },
    {
      id: 'tOIE12pl6oQ',
      title: '[GOOD DOCTORS]한의학의 근본으로 몸의 밸런스를 찾다_경희대한방병원 침구과 이재동 교수',
      thumbnail: `https://img.youtube.com/vi/tOIE12pl6oQ/0.jpg`,
    },
    {
      id: 'roOF0MmQs5Y',
      title:
        '경희의료원 교수가 말하는 모든 질병을 치료하는 핵심 비결 "채식, 운동은 답이 아닙니다" l경희의료원 교수 이재동(1부)',
      thumbnail: `https://img.youtube.com/vi/roOF0MmQs5Y/0.jpg`,
    },
    {
      id: 'uQDcozNhOqY',
      title:
        '[경희한슬림, 비만잡고 질병극복] 제10회 대사성질환과 비만(고혈압, 당뇨, 고지혈증, 지방간 등) ｜경희대한방병원 비만센터 이재동 교수',
      thumbnail: `https://img.youtube.com/vi/uQDcozNhOqY/0.jpg`,
    },
    {
      id: 'WqACYFicAJY',
      title: '[경희대한방병원] NO비만 NO질병 NOBINO(No肥老) 다이어트',
      thumbnail: `https://img.youtube.com/vi/WqACYFicAJY/0.jpg`,
    },
  ];

  const blogData = [
    {
      title: '‘류마티스 관절염’ 개선 위해 피 맑게 해야 하는 이유',
      description: '류마티스 관절염의 한의학적 접근과 치료의 핵심인 피를 맑게 하는 치료',
      link: 'https://blog.naver.com/khmcpr/221877483223',
      date: '2020.03.28',
    },
    {
      title: '한의학 근본으로 ‘신체 균형’ 찾으면 건강 회복',
      description:
        '“환자의 질병을 치료하기 위해서는 질병 자체가 아닌 전체적인 신체 균형을 바로잡는 게 가장 중요하다.” 이재동 교수',
      link: 'https://blog.naver.com/khmcpr/222531116789',
      date: '2021.10.08',
    },
    {
      title: '비만이 부르는 전신 척추‧관절 질환 종류 & 심각성',
      description: '비만이 부르는 전신 척추‧관절 질환 특징과 심각성, 증상 개선을 위해 비만 관리.',
      link: 'https://blog.naver.com/example3',
      date: '2022.02.27',
    },
  ];

  return (
    <ContentWrapper>
      <ButtonGroup>
        <TabButton active={activeTab === 'youtube'} onClick={() => setActiveTab('youtube')} variant="contained">
          유튜브
        </TabButton>
        <TabButton active={activeTab === 'news'} onClick={() => setActiveTab('news')} variant="contained">
          관련 기사
        </TabButton>
        <TabButton active={activeTab === 'blog'} onClick={() => setActiveTab('blog')} variant="contained">
          블로그
        </TabButton>
      </ButtonGroup>

      {activeTab === 'youtube' && (
        <>
          <SectionTitle>관련 유튜브</SectionTitle>
          <YouTubeList>
            {youtubeData.map((video) => (
              <YouTubeItem
                key={video.id}
                href={`https://www.youtube.com/watch?v=${video.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <YouTubeThumbnail src={video.thumbnail} alt={video.title} />
                <YouTubeTitle>{video.title}</YouTubeTitle>
              </YouTubeItem>
            ))}
          </YouTubeList>
        </>
        // <>
        //   <SectionTitle>유튜브</SectionTitle>
        //   <YouTube
        //     videoId="iEsy-H1mzag"
        //     opts={{
        //       width: '560',
        //       height: '315',
        //       playerVars: {
        //         autoplay: 0,
        //         rel: 0,
        //         modestbranding: 1,
        //       },
        //     }}
        //   />
        // </>
      )}

      {activeTab === 'news' && (
        <>
          <SectionTitle>관련 기사</SectionTitle>
          <NewsWrapper>
            {mediaCardsData.map((card, index) => (
              <MediaCard
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                title={card.title}
                content={card.content}
                link={card.link}
                img={card.img}
              />
            ))}
          </NewsWrapper>
        </>
      )}

      {activeTab === 'blog' && (
        <>
          <SectionTitle>블로그 글</SectionTitle>
          <BlogList>
            {blogData.map((blog, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <BlogItem key={index} href={blog.link} target="_blank" rel="noopener noreferrer">
                <BlogContent>
                  <BlogTitle>{blog.title}</BlogTitle>
                  <BlogDescription>{blog.description}</BlogDescription>
                  <BlogDate>{blog.date}</BlogDate>
                </BlogContent>
              </BlogItem>
            ))}
          </BlogList>
        </>
      )}
    </ContentWrapper>
  );
};

export default OrientalSection;
