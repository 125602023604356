import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { authApi } from '../../../api/api-auth';
import { surveyApi } from '../../../api/api-survey';
import { useHeaderTextChange } from '../../../lib/useHeader';
import { localTokenCheck } from '../../../utils/util';
import SurveyListPresenter from './SurveyListPresenter';
import { newsurveyApi } from '../../../api/api-newsurvey';

interface stateType {
  name: string;
  phone: string;
  age: string;
}

export default function SurveyListContainer() {
  useHeaderTextChange('사전설문 목록');
  const [userInfo, setUserInfo] = useState({
    name: '',
    age: '',
    phone: '',
  });

  const [role, setRole] = useState('');
  const [surveyList, setSurveyList] = useState<any>([]);
  const [latestSurvey, setLatSurvey] = useState<any>(null);

  const [pageInfo, setPageInfo] = useState({
    nowSeletedPage: 0,
    totalPages: 0,
    totalResults: 0,
  });

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    localTokenCheck();

    const userInfoJSON = localStorage.getItem('userInfo');

    if (userInfoJSON !== null && userInfoJSON !== '') {
      const userRole = JSON.parse(userInfoJSON).role;
      setRole(userRole);

      const state = location.state as stateType;
      setUserInfo({ name: state.name, phone: state.phone, age: state.age });
      getSurveyListByPage(1, state.name, state.phone);
    } else {
      localStorage.clear();
      navigate('/signin');
    }
  }, []);

  const getSurveyListByPage = async (page: number, name: string, phone: string) => {
    let res;
    try {
      // res = await surveyApi.getSurveyList(1, name, phone);
      res = await newsurveyApi.getSurveyListByPhone(phone);
    } catch (e) {
      console.log(e);
    } finally {
      if (res) {
        setPageInfo({
          nowSeletedPage: res.data.page,
          totalPages: res.data.totalPages,
          totalResults: res.data.totalResults,
        });
        // console.log(res.data)
        setSurveyList(res.data.surveys);
        getSurveyById(res.data.surveys[0].surveyId, name, phone);
      }
    }
  };

  const getSurveyById = async (id: string, name: string, phone: string) => {
    let res;
    try {
      // res = await surveyApi.getSurvey(id, name, phone);
      res = await newsurveyApi.getSurveyById(id);
    } catch (e) {
      console.log(e);
    } finally {
      if (res && res.data) {
        // console.log(`출력 : ${res.data}`);
        try{
        setLatSurvey(res.data);
        }
        catch(e){
          console.error(e);
        }
      }
    }
  };

  const onClickDelete = async (surveyId: string) => {
    let res;
    try {
      res = await newsurveyApi.deleteSurveyById(surveyId);
    } catch (e) {
      console.log(e);
    } finally {
      if (res) {
        if (res.status === 204) {
          const newSurveyList = surveyList.reduce((result: any, value: any) => {
            if (value.surveyId !== surveyId) {
              result.push(value);
            }
            return result;
          }, []);
          setSurveyList(newSurveyList);
        }
      }
    }
  };

  const onClickLogout = async () => {
    const tokenJSON = localStorage.getItem('tokens');

    if (tokenJSON !== null && tokenJSON !== '') {
      await authApi.logout(JSON.parse(tokenJSON).refresh.token);
    }
    localStorage.clear();
    navigate('/signin');
  };

  const onClickSurveyCheck = (surveyId: string) => {
    navigate('/survey-result', { state: { surveyId } });
  };

  const handlePageChange = async (event: React.ChangeEvent<unknown>, value: number) => {
    getSurveyListByPage(value, userInfo.name, userInfo.phone);
  };

  return (
    <SurveyListPresenter
      userInfo={userInfo}
      pageInfo={pageInfo}
      onClickLogout={onClickLogout}
      onClickDelete={onClickDelete}
      onClickSurveyCheck={onClickSurveyCheck}
      surveyList={surveyList}
      latestSurvey={latestSurvey}
      handlePageChange={handlePageChange}
    />
  );
}
