import React from 'react';
import { useTranslation } from 'react-i18next';
import * as s from './NewSurveyStyled';
import hand from '../../../asset/img/survey/hand.png';

interface PulseRateProps {
  onNext: () => void;
  onScore: (key: string, score: number) => void;
  selectedOption: string;
  onOptionSelect: (option: string) => void;
}

const PulseRate: React.FC<PulseRateProps> = ({ onNext, onScore, selectedOption, onOptionSelect }) => {
  const { t } = useTranslation();
  
  return (
    <s.SurveyContainer>
      <s.Question>{t('survey.pulse_rate.question')}</s.Question>
      <s.PictureContainer>
        <s.Picture src={hand} alt="맥박" />
      </s.PictureContainer>
      <s.OptionContainer className="OptionContainer">
        <s.Options>
          <s.OptionButton
            type="button"
            variant={selectedOption === '1' ? 'contained' : 'outlined'}
            onClick={() => {
              onOptionSelect('1');
              onScore('ebCirculation', -1);
            }}
            style={{ height: '3rem' }}
          >
            <s.OptionTitle>{t('survey.pulse_rate.option1')}</s.OptionTitle>
          </s.OptionButton>
          <s.OptionButton
            type="button"
            variant={selectedOption === '2' ? 'contained' : 'outlined'}
            onClick={() => {
              onOptionSelect('2');
              onNext();
            }}
            style={{ height: '3rem' }}
          >
            <s.OptionTitle>{t('survey.pulse_rate.option2')}</s.OptionTitle>
          </s.OptionButton>
          <s.OptionButton
            type="button"
            variant={selectedOption === '3' ? 'contained' : 'outlined'}
            onClick={() => {
              onOptionSelect('3');
              onScore('ebBalanceControl', -1);
            }}
            style={{ height: '3rem' }}
          >
            <s.OptionTitle>{t('survey.pulse_rate.option3')}</s.OptionTitle>
          </s.OptionButton>
          <s.OptionButton
            type="button"
            variant={selectedOption === '4' ? 'contained' : 'outlined'}
            onClick={() => {
              onOptionSelect('4');
              onNext();
            }}
            style={{ height: '3rem' }}
          >
            <s.OptionTitle>{t('survey.pulse_rate.option4')}</s.OptionTitle>
          </s.OptionButton>
        </s.Options>
      </s.OptionContainer>
      {/* <s.NextButton type="button" onClick={() => onNext()} variant="contained" color="primary">
        {t('button.skip')}
      </s.NextButton> */}
    </s.SurveyContainer>
  );
};

export default PulseRate;
