import { notLoginedApi, loginedApi } from './api-base';

export const surveyApi = {
  getSurveyTakenUserList: (page: number) => loginedApi.get(`/v1/survey?sortBy=createdAt%3Adesc&page=${page}`),
  getSurveyTakenUserListWithName: (page: number, name: string) =>
    loginedApi.get(`/v1/survey?sortBy=createdAt%3Adesc&name=${name}&page=${page}`),
  getSurveyTakenUserListWithPhone: (page: number, phone: string) =>
    loginedApi.get(`/v1/survey?sortBy=createdAt%3Adesc&phone=${phone}&page=${page}`),

  getSurveyList: (page: number, name: string, phone: string) =>
    loginedApi.get(`/v1/survey/name/${name}/phone/${phone}?sortBy=createdAt%3Adesc&page=${page}`),

  getSurvey: (surveyId: string, name: string, phone: string) =>
    loginedApi.get(`/v1/survey/name/${name}/phone/${phone}/id/${surveyId}`),
  deleteSurvey: (surveyId: string, name: string, phone: string) =>
    loginedApi.delete(`/v1/survey/name/${name}/phone/${phone}/id/${surveyId}`),
  postSurvey: (data: any) => notLoginedApi.post(`/v1/survey`, data),

  getSurveyExistByPhone: (phone: string, name: string) => notLoginedApi.get(`/v1/survey/exist/${name}/${phone}`),
};
