/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Container, Paper, Typography, FormGroup, FormControlLabel, Checkbox, TextField } from '@mui/material';
import * as s from './NewSurveyStyled';

const StyledPaper = styled(Paper)`
  padding: 0 1.5rem 1.5rem 1.5rem;
  margin: 1.5rem 0;

  width: 100%;
  box-sizing: border-box;
`;

const StyledFormGroup = styled(FormGroup)`
  margin-top: 16px;
`;

const CategoryTitle = styled(Typography)`
  margin-top: 16px;
  margin-bottom: 8px;
  font-weight: bold;
  font-family: 'Noto Sans KR', sans-serif;
`;

const SubCategory = styled.div`
  margin-left: 24px;
  font-family: 'Noto Sans KR', sans-serif;
`;

const LabelText = styled(Typography)`
  font-family: 'Noto Sans KR', sans-serif;
`;

const StyledTextField = styled(TextField)`
  margin-top: 8px;
  margin-left: 24px;
`;

interface DiseaseTimeTriggerProps {
  symptoms: number[];
  selectedOption: string;
  otherReason: string;
  otherReasonB: boolean;
}

const DiseaseTimeTriggerHistory: React.FC<DiseaseTimeTriggerProps> = ({
  symptoms,
  selectedOption,
  otherReason,
  otherReasonB
}) => {
  // console.log({symptoms, selectedOption, otherReason})
  const { t } = useTranslation();

  return (
    <s.SurveyContainer>
      <Container style={{ padding: '0' }}>
        {/* <s.Question style={{ padding: ' 0 1.5rem 1rem 1.5rem' }}>발병 시기 (증상 발현 또는 진단 시기)</s.Question> */}
        <s.OptionContainer>
          <s.Options>
            <s.OptionButton
              type="button"
              variant={selectedOption === '1' ? 'contained' : 'outlined'}
            >
              <s.OptionSubtitle>{t('survey.disease_time_trigger.option1')}</s.OptionSubtitle>
            </s.OptionButton>
            <s.OptionButton
              type="button"
              variant={selectedOption === '2' ? 'contained' : 'outlined'}
            >
              <s.OptionSubtitle>{t('survey.disease_time_trigger.option2')}</s.OptionSubtitle>
            </s.OptionButton>
            <s.OptionButton
              type="button"
              variant={selectedOption === '3' ? 'contained' : 'outlined'}
            >
              <s.OptionSubtitle>{t('survey.disease_time_trigger.option3')}</s.OptionSubtitle>
            </s.OptionButton>
          </s.Options>
        </s.OptionContainer>
        {/* <s.Question style={{ padding: ' 1.5rem 1.5rem 0 1.5rem' }}>발병 원인 (몸이 안 좋아진 계기)</s.Question> */}
        <s.OptionContainer style={{padding: '0rem'}}>
          <StyledPaper>
            <StyledFormGroup>
              <FormControlLabel
                control={<Checkbox checked={symptoms.includes(0)}  name="특별한이유없음" />}
                label={<LabelText>{t('survey.disease_time_trigger.form_option1')}</LabelText>}
              />
              <CategoryTitle variant="subtitle1">{t('survey.disease_time_trigger.subtitle1')}</CategoryTitle>
              <SubCategory>
                <FormControlLabel
                  control={<Checkbox checked={symptoms.includes(1)}  name="과식" />}
                  label={<LabelText>{t('survey.disease_time_trigger.form_option2')}</LabelText>}
                />
                <FormControlLabel
                  control={<Checkbox checked={symptoms.includes(2)} name="폭식" />}
                  label={<LabelText>{t('survey.disease_time_trigger.form_option3')}</LabelText>}
                />
                <FormControlLabel
                  control={<Checkbox checked={symptoms.includes(3)} name="급하게먹음" />}
                  label={<LabelText>{t('survey.disease_time_trigger.form_option4')}</LabelText>}
                />
                <FormControlLabel
                  control={<Checkbox checked={symptoms.includes(4)} name="상한음식" />}
                  label={<LabelText>{t('survey.disease_time_trigger.form_option5')}</LabelText>}
                />
              </SubCategory>
              <CategoryTitle variant="subtitle1">{t('survey.disease_time_trigger.subtitle2')}</CategoryTitle>
              <SubCategory>
                <FormControlLabel
                  control={<Checkbox checked={symptoms.includes(5)} name="과로" />}
                  label={<LabelText>{t('survey.disease_time_trigger.form_option6')}</LabelText>}
                />
                <FormControlLabel
                  control={<Checkbox checked={symptoms.includes(6)} name="야간근무" />}
                  label={<LabelText>{t('survey.disease_time_trigger.form_option7')}</LabelText>}
                />
                <FormControlLabel
                  control={<Checkbox checked={symptoms.includes(7)} name="책상근무" />}
                  label={<LabelText>{t('survey.disease_time_trigger.form_option8')}</LabelText>}
                />
                <FormControlLabel
                  control={<Checkbox checked={symptoms.includes(8)} name="잘눕는습관" />}
                  label={<LabelText>{t('survey.disease_time_trigger.form_option9')}</LabelText>}
                />
                <FormControlLabel
                  control={<Checkbox checked={symptoms.includes(9)} name="과도한성생활" />}
                  label={<LabelText>{t('survey.disease_time_trigger.form_option10')}</LabelText>}
                />
              </SubCategory>
              <CategoryTitle variant="subtitle1">{t('survey.disease_time_trigger.subtitle3')}</CategoryTitle>
              <SubCategory>
                <FormControlLabel
                  control={<Checkbox checked={symptoms.includes(10)} name="화냄" />}
                  label={<LabelText>{t('survey.disease_time_trigger.form_option11')}</LabelText>}
                />
                <FormControlLabel
                  control={<Checkbox checked={symptoms.includes(11)} name="슬픔" />}
                  label={<LabelText>{t('survey.disease_time_trigger.form_option12')}</LabelText>}
                />
                <FormControlLabel
                  control={<Checkbox checked={symptoms.includes(12)} name="우울함" />}
                  label={<LabelText>{t('survey.disease_time_trigger.form_option13')}</LabelText>}
                />
                <FormControlLabel
                  control={<Checkbox checked={symptoms.includes(13)} name="일상스트레스" />}
                  label={<LabelText>{t('survey.disease_time_trigger.form_option14')}</LabelText>}
                />
              </SubCategory>
              <CategoryTitle variant="subtitle1">{t('survey.disease_time_trigger.subtitle4')}</CategoryTitle>
              <SubCategory>
                <FormControlLabel
                  control={<Checkbox checked={symptoms.includes(14)} name="찬바람" />}
                  label={<LabelText>{t('survey.disease_time_trigger.form_option15')}</LabelText>}
                />
                <FormControlLabel
                  control={<Checkbox checked={symptoms.includes(15)} name="무더위" />}
                  label={<LabelText>{t('survey.disease_time_trigger.form_option16')}</LabelText>}
                />
                <FormControlLabel
                  control={<Checkbox checked={symptoms.includes(16)} name="습한" />}
                  label={<LabelText>{t('survey.disease_time_trigger.form_option17')}</LabelText>}
                />
                <FormControlLabel
                  control={<Checkbox checked={symptoms.includes(17)} name="건조한" />}
                  label={<LabelText>{t('survey.disease_time_trigger.form_option18')}</LabelText>}
                />
              </SubCategory>
              <CategoryTitle variant="subtitle1">{t('survey.disease_time_trigger.subtitle5')}</CategoryTitle>
              <SubCategory>
                <FormControlLabel
                  control={<Checkbox checked={symptoms.includes(18)} name="교통사고" />}
                  label={<LabelText>{t('survey.disease_time_trigger.form_option19')}</LabelText>}
                />
                <FormControlLabel
                  control={<Checkbox checked={symptoms.includes(19)} name="낙상" />}
                  label={<LabelText>{t('survey.disease_time_trigger.form_option20')}</LabelText>}
                />
                <FormControlLabel
                  control={<Checkbox checked={symptoms.includes(20)} name="외상" />}
                  label={<LabelText>{t('survey.disease_time_trigger.form_option21')}</LabelText>}
                />
                <FormControlLabel
                  control={<Checkbox checked={symptoms.includes(21)} name="세균 감염" />}
                  label={<LabelText>{t('survey.disease_time_trigger.form_option23')}</LabelText>}
                />
              </SubCategory>
              <FormControlLabel
                control={<Checkbox checked={otherReasonB} name="기타" />}
                label={t('manage.health.etc')}
              />
                <StyledTextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  placeholder={t('manage.health.none')}
                  value={otherReason}
                />
            </StyledFormGroup>
          </StyledPaper>
        </s.OptionContainer>
      </Container>
    </s.SurveyContainer>
  );
};

export default DiseaseTimeTriggerHistory;
