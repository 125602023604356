import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CircleIcon from '@mui/icons-material/Circle';
import React, { useEffect } from 'react';
import moment from 'moment';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import siloIcon from '../../../asset/img/logo.png';
import circulatorySystemImg from '../../../asset/img/heart2.png';
import nervousSystemImg from '../../../asset/img/kidney.png';
import stomachSystemImg from '../../../asset/img/stomach2.png';
import * as s from './SurveyResultStyledNew';
import SurveyPastHistoryContainer from '../surveys/pastSurveyHistory';
import { Disease, SurveyResultType } from '../surveys/result/ResultType';
import { EnergyInfo } from '../surveys/result/SurveyResultPresenter';
import EnergyGraph from '../surveys/result/EnergyGraph';
import DiseaseTimeTriggerHistory from './component/DiseaseTimeTrigger';
import ObeseMedHistory from './component/ObeseMed';
import FavoriteFoodHistory from './component/FavoriteFood';
import PastHistoryTriggerHistory from './component/PastHistory';



interface SurveyResultProps {
  survey: SurveyResultType;
  role: string;
  onClickLogout: () => void;
  onClickDelete: (surveyId: string, name: string, phone: string, age: string) => void;
}

export interface QuestionDetail {
  s1: number;
  s2: number;
  s3: number;
  s4?: number;
  s5?: number;
  s6?: number;
}


function QuestionBoxYN({ title, content, result }: { title: string, content: { yes: string, no: string }, result: number }) {
  const check = 1;
  const basicColor = '#393939'
  const checkedColor = 'red'
  return (
    <s.QuestionWrapper>
      <s.subContainerContentGeneral>
        <s.QuestionText>{title}</s.QuestionText>
      </s.subContainerContentGeneral>
      <s.questionContentContainer>
        <s.questionSelectionContainer>
          <s.ContentSelectText style={{ color: result === 1 ? checkedColor : basicColor }}>{content.yes}</s.ContentSelectText>
          <div>
            <input
              type="radio"
              id={`${title} option 1`}
              value="Option 1"
              defaultChecked={result === 1}
              readOnly
            />
          </div>
        </s.questionSelectionContainer>
        <s.questionSelectionContainer>
          <s.ContentSelectText style={{ color: result === 0 ? checkedColor : basicColor }}>{content.no}</s.ContentSelectText>
          <div>
            <input
              type="radio"
              id={`${title} option 2`}
              value="Option 2"
              defaultChecked={result === 0}
              readOnly
            />
          </div>
        </s.questionSelectionContainer>
      </s.questionContentContainer>
    </s.QuestionWrapper>
  );
}

function QuestionBoxSingle({ title, content, result }: { title: string, content: string[], result: number }) {
  const basicColor = '#393939'
  const checkedColor = 'red'
  return (
    <s.QuestionWrapper>
      <s.subContainerContentGeneral>
        <s.QuestionText>{title}</s.QuestionText>
      </s.subContainerContentGeneral>
      <s.questionContentContainer>
        {content.map((elem, idx) => {
          return <s.questionSelectionContainer>
            <s.ContentSelectText style={{ color: result === (idx + 1) ? checkedColor : basicColor }}>{elem}</s.ContentSelectText>
            <div>
              <input
                type="radio"
                id={`${title} option ${idx + 1}`}
                value={idx + 1}
                defaultChecked={result === (idx + 1)}
                readOnly
              />
            </div>
          </s.questionSelectionContainer>
        })}
      </s.questionContentContainer>
    </s.QuestionWrapper>
  )
}

function QuestionBoxMulti({ title, content, result }: { title: string, content: string[], result: QuestionDetail }) {
  const resultAny = result as any
  const basicColor = '#393939'
  const checkedColor = 'red'
  return (
    <s.QuestionWrapper>
      <s.subContainerContentGeneral>
        <s.QuestionText>{title}</s.QuestionText>
      </s.subContainerContentGeneral>
      <s.questionContentContainer>
        {content.map((elem, idx) => {
          const selecnum = `s${idx + 1}`
          const ischecked = resultAny[selecnum] === undefined ? false : resultAny[selecnum] === 1
          return <s.questionSelectionContainer>
            <s.ContentSelectText style={{ color: ischecked ? checkedColor : basicColor }}>{elem}</s.ContentSelectText>
            <div>
              <input
                type="radio"
                id={`${title} option ${idx + 1}`}
                value={idx + 1}
                defaultChecked={ischecked}
                readOnly
              />
            </div>
          </s.questionSelectionContainer>
        })}
      </s.questionContentContainer>
    </s.QuestionWrapper>
  )
}

function DiseaseHistory({ diseases }: { diseases: Disease[] }) {
  const { t, i18n } = useTranslation();
  return (
    <s.DiseaseContainer>
      <s.QuestionText style={{ color: 'blue' }}>{t('manage.health.order')}</s.QuestionText>
      {diseases.map((element, idx) => {
        return (
          <s.StyledPaper>
            <s.QuestionText>{idx + 1}. </s.QuestionText>
            <s.QuestionText>{element.name}</s.QuestionText>
          </s.StyledPaper>
        )
      })}
    </s.DiseaseContainer>
  );
}


export default function SurveyResultPresenter(props: SurveyResultProps) {
  const { onClickLogout, onClickDelete, role, survey } = props;
  const { t, i18n } = useTranslation();
  const genderDecode = ['male', 'female'];

  const navigate = useNavigate();

  const onClickBack = () => {
    const name = survey.basicOld?.name === undefined || survey.basicOld?.name === null ? "" : survey.basicOld?.name
    const phone = survey.basicOld?.phone === undefined || survey.basicOld?.phone === null ? "" : survey.basicOld?.phone
    const age = survey.basicOld?.birth === undefined || survey.basicOld?.birth === null ? "" : String(new Date().getFullYear() - Number(survey.basicOld?.birth) + 1)
    navigate('/survey-list', { state: { name, phone, age } });
  };

  // if(survey.disease !== undefined && survey.pastMedicalHistory !== undefined)
  //   console.log({r: survey.pastMedicalHistory.reason, t: String(survey.diseaseGenerateTime), etc: survey.pastMedicalHistory.reasonEtcDesc})

  return survey ? (
    <s.StyledSurveyResultPage maxWidth="sm">
      <s.HeaderContainer>
        <s.BackButton onClick={onClickBack} aria-label='Go back'>
          <ArrowBack />
        </s.BackButton>
        <s.NaviTitleContainer>
          <s.NaviTitleText>
            {t('result.main.diag_result')}
          </s.NaviTitleText>
        </s.NaviTitleContainer>
      </s.HeaderContainer>
      <s.LogoBox>
        <s.SiloIcon src={siloIcon} />
        <s.CenterText variant="h4">KAIDOC</s.CenterText>
      </s.LogoBox>

      <s.InfoBox>
        <s.LeftText variant="body2">{t('manage.basic_info.name')} : {survey.basicOld?.name}</s.LeftText>
        <s.LeftText variant="body2">{t('manage.basic_info.age')} : {new Date().getFullYear() - Number(survey.basicOld?.birth) + 1}</s.LeftText>
        <s.LeftText variant="body2">{t('manage.basic_info.phone')} : {survey.basicOld?.phone}</s.LeftText>
        <s.LeftText variant="body2">{t('manage.basic_info.gender')} : {survey.basicOld?.gender !== undefined && (Number(survey.basicOld?.gender) < genderDecode.length) ? genderDecode[Number(survey.basicOld?.gender)] : "undefined"}</s.LeftText>
        <s.LeftText variant="body2">{t('manage.basic_info.height')} : {survey.basicOld?.height}</s.LeftText>
        <s.LeftText variant="body2">{t('manage.basic_info.weight')} : {survey.basicOld?.weight}</s.LeftText>
        <s.LeftText variant="body2">{t('manage.basic_info.sys')} : {survey.basicOld?.systolic}</s.LeftText>
        <s.LeftText variant="body2">{t('manage.basic_info.diag')} : {survey.basicOld?.diastolic}</s.LeftText>
      </s.InfoBox>

      <div>
        <s.MainTitle>에너지 평가</s.MainTitle>
      </div>
      <s.statusesContainer style={{ marginTop: '0rem', marginBottom: '2rem' }}>
        <EnergyInfo title={t('result.main.energy_create_feature')} score={survey.BScore} content={t('result.main.energy_create_desc')} imgsrc={stomachSystemImg} />
        <EnergyInfo title={t('result.main.energy_circulation_feature')} score={survey.AScore} content={t('result.main.energy_circulation_desc')} imgsrc={circulatorySystemImg} />
        <EnergyInfo title={t('result.main.energy_balance_feature')} score={survey.CScore} content={t('result.main.energy_balance_desc')} imgsrc={nervousSystemImg} />
      </s.statusesContainer>
      <s.EnergyGraphContainer>
        <EnergyGraph energyBalanceScore={survey.CScore} energyCreateScore={survey.BScore} energyCirculationScore={survey.AScore} />
      </s.EnergyGraphContainer>
      <div style={{ marginTop: '2rem' }}>
        <s.MainTitle>{t('manage.energy_eval')}</s.MainTitle>
      </div>
      {survey.question !== undefined &&
        <div style={{ marginLeft: '2rem', marginRight: '2rem', marginTop: '1rem' }}>
          <s.SubTitle>{t('manage.basic_survey')}</s.SubTitle>
          <s.subContainerContentGeneral style={{ marginLeft: '1rem' }}>
            <QuestionBoxSingle title={t('manage.basic.q1')} content={[t('manage.basic.a11'), t('manage.basic.a12'), t('manage.basic.a13')]} result={survey.question.q1} />
            <QuestionBoxSingle title={t('manage.basic.q2')} content={[t('manage.basic.a21'), t('manage.basic.a22'), t('manage.basic.a23')]} result={survey.question.q2} />
            <QuestionBoxSingle title={t('manage.basic.q3')} content={[t('manage.basic.a31'), t('manage.basic.a32'), t('manage.basic.a33')]} result={survey.question.q3} />
            <QuestionBoxSingle title={t('manage.basic.q4')} content={[t('manage.basic.a41'), t('manage.basic.a42'), t('manage.basic.a43'), t('manage.basic.a44')]} result={survey.question.q4} />
            <QuestionBoxSingle title={t('manage.basic.q5')} content={[t('manage.basic.a51'), t('manage.basic.a52'), t('manage.basic.a53'), t('manage.basic.a54')]} result={survey.question.q5} />
            <QuestionBoxSingle title={t('manage.basic.q6')} content={[t('manage.basic.a61'), t('manage.basic.a62'), t('manage.basic.a63')]} result={survey.question.q6} />
            <QuestionBoxSingle title={t('manage.basic.q7')} content={[t('manage.basic.a71'), t('manage.basic.a72'), t('manage.basic.a73')]} result={survey.question.q7} />
            <QuestionBoxSingle title={t('manage.basic.q8')} content={[t('manage.basic.a81'), t('manage.basic.a82'), t('manage.basic.a83')]} result={survey.question.q8} />
            <QuestionBoxSingle title={t('manage.basic.q9')} content={[t('manage.basic.a91'), t('manage.basic.a92'), t('manage.basic.a93'), t('manage.basic.a94')]} result={survey.question.q9} />
            <QuestionBoxSingle title={t('manage.basic.q10')} content={[t('manage.basic.a101'), t('manage.basic.a102'), t('manage.basic.a103'), t('manage.basic.a104')]} result={survey.question.q10} />
            <QuestionBoxYN title={t('manage.basic.q11')} content={{ yes: t('manage.basic.a111'), no: t('manage.basic.a112') }} result={survey.question.q11} />
            <QuestionBoxYN title={t('manage.basic.q12')} content={{ yes: t('manage.basic.a121'), no: t('manage.basic.a122') }} result={survey.question.q12} />
            <QuestionBoxYN title={t('manage.basic.q13')} content={{ yes: t('manage.basic.a131'), no: t('manage.basic.a132') }} result={survey.question.q13} />
            <QuestionBoxMulti title={t('manage.basic.q14')} content={[t('manage.basic.a141'), t('manage.basic.a142'), t('manage.basic.a143'), t('manage.basic.a144')]} result={survey.question.q14} />
            <QuestionBoxMulti title={t('manage.basic.q15')} content={[t('manage.basic.a151'), t('manage.basic.a152'), t('manage.basic.a153'), t('manage.basic.a154'), t('manage.basic.a155'), t('manage.basic.a156')]} result={survey.question.q15} />
            <QuestionBoxMulti title={t('manage.basic.q16')} content={[t('manage.basic.a161'), t('manage.basic.a162'), t('manage.basic.a163'), t('manage.basic.a164'), t('manage.basic.a165')]} result={survey.question.q16} />
            <QuestionBoxMulti title={t('manage.basic.q17')} content={[t('manage.basic.a171'), t('manage.basic.a172'), t('manage.basic.a173')]} result={survey.question.q17} />
            <QuestionBoxSingle title={t('manage.basic.q18')} content={[t('manage.basic.a181'), t('manage.basic.a182'), t('manage.basic.a183')]} result={survey.question.q18} />
            <QuestionBoxMulti title={t('manage.basic.q19')} content={[t('manage.basic.a191'), t('manage.basic.a192'), t('manage.basic.a193')]} result={survey.question.q19} />
          </s.subContainerContentGeneral>
        </div>
      }
      {
        survey.disease !== undefined && survey.pastMedicalHistory !== undefined && survey.medicine !== undefined && survey.favorateFood !== undefined &&
        <div style={{ marginLeft: '2rem', marginRight: '2rem', marginTop: '1rem' }}>
          <s.SubTitle>{t('manage.symptom')}</s.SubTitle>
          <DiseaseHistory diseases={survey.disease} />
          <s.SubTitle>{t('manage.cause')}</s.SubTitle>
          <s.subContainerContentGeneral style={{ marginLeft: '1rem' }}>
            <DiseaseTimeTriggerHistory
              symptoms={survey.pastMedicalHistory.reason}
              selectedOption={String(survey.diseaseGenerateTime)}
              otherReason={survey.pastMedicalHistory.reasonEtcDesc}
              otherReasonB = {survey.pastMedicalHistory.reasonEtc}
            />
          </s.subContainerContentGeneral>
          <s.SubTitle>{t('manage.history')}</s.SubTitle>
          <s.subContainerContentGeneral style={{ marginLeft: '1rem' }}>
            <PastHistoryTriggerHistory
              pastHistory={survey.pastMedicalHistory.pastHistory !== undefined ? survey.pastMedicalHistory.pastHistory : []}
              otherReason={survey.pastMedicalHistory.pastHistoryEtcDesc !== undefined ? survey.pastMedicalHistory.pastHistoryEtcDesc : ""}
              surgery={survey.pastMedicalHistory.surgeryDesc !== undefined ? survey.pastMedicalHistory.surgeryDesc : ""}
              otherReasonB={survey.pastMedicalHistory.pastHistoryEtc !== undefined ? survey.pastMedicalHistory.pastHistoryEtc : false}
              surgeryB={survey.pastMedicalHistory.surgery !== undefined ? survey.pastMedicalHistory.surgery : false} />
          </s.subContainerContentGeneral>
          <s.SubTitle>{t('manage.med')}</s.SubTitle>
          <s.subContainerContentGeneral style={{ marginLeft: '1rem' }}>
            <ObeseMedHistory med={survey.medicine} />
          </s.subContainerContentGeneral>
          <s.SubTitle>{t('manage.favorite')}</s.SubTitle>
          <s.subContainerContentGeneral style={{ marginLeft: '1rem' }}>
            <FavoriteFoodHistory formData={survey.favorateFood} />
          </s.subContainerContentGeneral>
        </div>
      }{
        survey.basicOld !== undefined && survey.basicOld != null &&
        <div style={{ width: "100%", textAlignLast: "center" }}>
          <s.StyledButton
            variant="contained"
            onClick={() =>
              onClickDelete(survey.id,
                survey.basicOld?.name === undefined || survey.basicOld?.name === null ? "" : survey.basicOld?.name,
                survey.basicOld?.phone === undefined || survey.basicOld?.phone === null ? "" : survey.basicOld?.phone,
                survey.basicOld?.birth === undefined || survey.basicOld?.birth === null ? "" : String(new Date().getFullYear() - Number(survey.basicOld?.birth) + 1))
            }
          >
            {t('manage.delete')}
          </s.StyledButton>
        </div>}
    </s.StyledSurveyResultPage>
  ) : (
    <>no Data</>
  );
}
