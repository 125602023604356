import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import SurveyFavFodPresenter from './SurveyFavFodPresenter';
import { useHeaderTextChange } from '../../../../lib/useHeader';
import { RootState } from '../../../../modules';

export default function SurveyFavFodContainer() {
  // header text change
  useHeaderTextChange('사전문진');

  // get store object(survey)
  const dictObject = useSelector((state: RootState) => state.survey);

  // validation for survey-favfod
  const [validForm, setValidForm] = useState({
    favorite_food_cigarette: false,
    favorite_food_drink: false,
    favorite_food_caffeine: false,
  });

  // validation check for favorite_food_cigarette
  useEffect(() => {
    if (dictObject.favorite_food_cigarette === '') {
      setValidForm({
        ...validForm,
        favorite_food_cigarette: false,
      });
    } else if (dictObject.favorite_food_cigarette === '1' && dictObject.favorite_food_cigarette_1.length === 0) {
      setValidForm({
        ...validForm,
        favorite_food_cigarette: false,
      });
    } else {
      setValidForm({
        ...validForm,
        favorite_food_cigarette: true,
      });
    }
  }, [dictObject.favorite_food_cigarette, dictObject.favorite_food_cigarette_1]);

  // validation check for favorite_food_drink
  useEffect(() => {
    if (dictObject.favorite_food_drink === '') {
      setValidForm({
        ...validForm,
        favorite_food_drink: false,
      });
    } else if (dictObject.favorite_food_drink === '1' && dictObject.favorite_food_drink_1.length === 0) {
      setValidForm({
        ...validForm,
        favorite_food_drink: false,
      });
    } else if (dictObject.favorite_food_drink === '1' && dictObject.favorite_food_drink_2.length === 0) {
      setValidForm({
        ...validForm,
        favorite_food_drink: false,
      });
    } else {
      setValidForm({
        ...validForm,
        favorite_food_drink: true,
      });
    }
  }, [dictObject.favorite_food_drink, dictObject.favorite_food_drink_1, dictObject.favorite_food_drink_2]);

  // validation check for favorite_food_caffeine
  useEffect(() => {
    if (dictObject.favorite_food_caffeine === '') {
      setValidForm({
        ...validForm,
        favorite_food_caffeine: false,
      });
    } else if (dictObject.favorite_food_caffeine === '1' && dictObject.favorite_food_caffeine_1.length === 0) {
      setValidForm({
        ...validForm,
        favorite_food_caffeine: false,
      });
    } else {
      setValidForm({
        ...validForm,
        favorite_food_caffeine: true,
      });
    }
  }, [dictObject.favorite_food_caffeine, dictObject.favorite_food_caffeine_1]);

  // checking for button disable
  useEffect(() => {
    if (
      dictObject.favorite_food_cigarette !== '' &&
      dictObject.favorite_food_drink !== '' &&
      dictObject.favorite_food_caffeine !== ''
    ) {
      setValidForm({
        favorite_food_cigarette: true,
        favorite_food_drink: true,
        favorite_food_caffeine: true,
      });
    }
  }, []);

  return <SurveyFavFodPresenter dictObject={dictObject} validForm={validForm} />;
}
