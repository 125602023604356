import React from 'react';
import { useTranslation } from 'react-i18next';
import * as s from './NewSurveyStyled';
import upperBody from '../../../asset/img/survey/upper_body.png';
import lowerBody from '../../../asset/img/survey/lower_body.png';
import body from '../../../asset/img/survey/body.png';


interface BodyDevelopmentProps {
  onNext: () => void;
  onScore: (key: string, score: number) => void;
  selectedOption: string;
  onOptionSelect: (option: string) => void;
}

const BodyDevelopment: React.FC<BodyDevelopmentProps> = ({
  onNext,
  onScore,
  selectedOption,
  onOptionSelect,
}) => {
  const { t } = useTranslation();

  return (
    <s.SurveyContainer>
      <s.Question>{t('survey.body_development.question')}</s.Question>
      <s.Options>
        <s.OptionButton
          type="button"
          variant={selectedOption === '1' ? 'contained' : 'outlined'}
          onClick={() => {
            onOptionSelect('1');
            onScore('ebBalanceControl', -1);
          }}
        >
          <s.OptionTitle>{t('survey.body_development.upper')}</s.OptionTitle>
          <s.ThreeOptionImage src={upperBody} alt="상체" />
        </s.OptionButton>
        <s.OptionButton
          type="button"
          variant={selectedOption === '2' ? 'contained' : 'outlined'}
          onClick={() => {
            onOptionSelect('2');
            onNext();
          }}
        >
          <s.OptionTitle>{t('survey.body_development.lower')}</s.OptionTitle>
            <s.ThreeOptionImage src={lowerBody} alt="하체" />
        </s.OptionButton>
        <s.OptionButton
          type="button"
          variant={selectedOption === '3' ? 'contained' : 'outlined'}
          onClick={() => {
            onOptionSelect('3');
            onNext();
          }}
        >
          <s.OptionTitle>{t('survey.body_development.similar')}</s.OptionTitle>
            <s.ThreeOptionImage src={body} alt="상하체 비슷함" />
        </s.OptionButton>

      </s.Options>
    </s.SurveyContainer>
  );
};

export default BodyDevelopment;
