import React from 'react';
import styled from 'styled-components';
import professor from '../../../asset/img/professor.png';
import introBG from '../../../asset/img/introbg.png';

const IntroduceSection: React.FC = () => {
  return (
    <SectionWrapper>
      <SectionTitle>Doctor LEE, JAEDONG</SectionTitle>
      <SectionContainer>
        <ImageContainer>
          <SectionImage src={professor} />
          <ImageCaption>
            경희대학교 한의과대학 <span style={{ fontSize: '1.5rem', fontWeight: '700' }}>이재동</span>
          </ImageCaption>
        </ImageContainer>
        <SectionContent>
          <SectionText>
            카이닥은 경희대학교 한의과대학 이재동학장의 35년 임상데이타를 바탕으로 개발된 한의학기반 몸 건강 관리 AI 닥터입니다. 앞으로도 여러분의 건강과 비만관리에 도움을 드릴 수 있도록 더욱 노력하겠습니다.
          </SectionText>
          <SectionSubTitle>Career</SectionSubTitle>
          <SectionSubTitle style={{ fontSize: '1.2rem', color: '#000', margin: '1rem 0 0', fontWeight: '700' }}>
            경희대학교 한의과대학 학장 역임
          </SectionSubTitle>
          <ListContainer>
            <SectionList>
              <SectionListItem style={{fontWeight: '700'}}>현)</SectionListItem>
              <SectionListItem>경희대학교 한방병원 척추관절센터장</SectionListItem>
              <SectionListItem>경희대학교 한방병원 비만센터교수</SectionListItem>
              <SectionListItem>경희대학교 국제한의학 교육원(ISOM)교수</SectionListItem>
              <SectionListItem>대한한의학회 수석부회장</SectionListItem>
              <SectionListItem>한방척추관절학회 자문교수</SectionListItem>
            </SectionList>
            <SectionList>
              <SectionListItem style={{fontWeight: '700'}}>전)</SectionListItem>
              <SectionListItem>경희대학교 한의과대학 학장</SectionListItem>
              <SectionListItem>전국 한의과대학 학장협의회 회장</SectionListItem>
              <SectionListItem>대통령 한방의료 자문의</SectionListItem>
              <SectionListItem>대한침구학회 회장</SectionListItem>
              <SectionListItem>경희의료원 한방병원 진료부원장</SectionListItem>
              <SectionListItem>경희의료원 한방병원 연구부장</SectionListItem>
              <SectionListItem>경희의료원 한방병원 침구과장</SectionListItem>
            </SectionList>
          </ListContainer>
        </SectionContent>
      </SectionContainer>
    </SectionWrapper>
  );
};

const SectionWrapper = styled.section`
  // background-color: rgba(15, 103, 254, 0.05);
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  box-sizing: border-box;

  @media (max-width: 991px) {
  }
`;

const SectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;

  @media (max-width: 991px) {
    flex-direction: column;
    align-items: center;
  }
`;

const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;
  padding: 0 2rem;

  @media (max-width: 991px) {
    width: 100%;
  }
`;

const SectionText = styled.p`
  font-size: 1.1rem;
  font-family: 'Noto Sans KR', sans-serif;
  line-height: 1.8;
  color: #000;

  @media (max-width: 991px) {
    font-size: 1rem;
  }
`;

const SectionSubTitle = styled.h3`
  margin: 2rem 0 0;
  font-size: 2rem;
  font-weight: 400;
  font-family: 'Noto Sans KR', sans-serif;
  color: rgba(15, 103, 254, 1);

  @media (max-width: 991px) {
    margin: 2rem 0 1rem;
    font-size: 1.5rem;
  }
`;

const SectionTitle = styled.h2`
  margin: 0;
  font-size: 2.5rem;
  font-weight: 400;
  font-family: 'Noto Sans KR', sans-serif;
  color: #000;
  text-align: center;

  @media (max-width: 991px) {
    font-size: 1.5rem;
    max-width: 100%;
  }
`;

const ListContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 991px) {
    flex-direction: column;
  }
`;

const SectionList = styled.ul`
  list-style: none;
  padding: 0;

  @media (max-width: 991px) {
    margin: 0;
  }
`;

const SectionListItem = styled.li`
  font-size: 1rem;
  margin: 0.8rem 0;
  font-family: 'Noto Sans KR', sans-serif;
  color: #000;
`;

const ImageContainer = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 991px) {
    width: 100%;
  }
`;

const ImageCaption = styled.p`
  font-size: 1rem;
  font-family: 'Noto Sans KR', sans-serif;
  color: #000;
  margin: 0;
`;

const SectionImage = styled.img`
  width: 90%;
  margin-top: 2rem;

  @media (max-width: 991px) {
    width: 50%;
  }
`;

export default IntroduceSection;
