import React from 'react';
import { useTranslation } from 'react-i18next';
import * as s from './NewSurveyStyled';
import relax from '../../../asset/img/survey/relax.png';
import urgent from '../../../asset/img/survey/urgent.png';
import party from '../../../asset/img/survey/party.png';
import no from '../../../asset/img/survey/no.png';
import read from '../../../asset/img/survey/read.png';
import timid from '../../../asset/img/survey/timid.png';

interface PersonalityProps {
  onNext: () => void;
  onScore: (key: string, score: number) => void;
  selectedOption: string[];
  onOptionSelect: (option: string) => void;
  isSelected: (selectedOptions: string[], option: string) => boolean;
}

const Personality: React.FC<PersonalityProps> = ({ onNext, onScore, selectedOption, onOptionSelect, isSelected }) => {
  const calcScore = (selectedOptions: string[]) => {
    if (selectedOptions.includes('1')) {
      onScore('ebCirculation', -1);
    }
    if (selectedOptions.includes('2')) {
      onScore('ebCirculation', -1);
    }
    if (selectedOptions.includes('3')) {
      onScore('ebProduce', -1);
    }
    if (selectedOptions.includes('4')) {
      onScore('ebProduce', -1);
    }
    if (selectedOptions.includes('5')) {
      onScore('ebBalanceControl', -1);
    }
  }

  const { t } = useTranslation();

  return (
    <s.SurveyContainer>
      <s.Question>{t('survey.personality.question')}</s.Question>
      <s.InnerOptionContainer style={{ margin: '1rem 0' }}>
        <s.OptionContainer>
          <s.Options>
            <s.OptionButton
              type="button"
              variant={isSelected(selectedOption, '1') ? 'contained' : 'outlined'}
              onClick={() => {
                onOptionSelect('1');
              }}
            >
              <s.ThreeOptionImage src={party} alt="대체 이미지" />
              <s.OptionSubtitle>{t('survey.personality.option1')}</s.OptionSubtitle>
            </s.OptionButton>
            <s.OptionButton
              type="button"
              variant={isSelected(selectedOption, '2')  ? 'contained' : 'outlined'}
              onClick={() => {
                onOptionSelect('2');
              }}
            >
              <s.ThreeOptionImage src={urgent} alt="대체 이미지" />
              <s.OptionSubtitle>{t('survey.personality.option2')}</s.OptionSubtitle>
            </s.OptionButton>
            <s.OptionButton
              type="button"
              variant={isSelected(selectedOption, '3')  ? 'contained' : 'outlined'}
              onClick={() => {
                onOptionSelect('3');
              }}
            >
              <s.ThreeOptionImage src={timid} alt="대체 이미지" />
              <s.OptionSubtitle>{t('survey.personality.option3')}</s.OptionSubtitle>
            </s.OptionButton>
          </s.Options>
        </s.OptionContainer>
        <s.OptionContainer>
          <s.Options style={{ margin: '0' }}>
            <s.OptionButton
              type="button"
              variant={isSelected(selectedOption, '4')  ? 'contained' : 'outlined'}
              onClick={() => {
                onOptionSelect('4');
              }}
            >
              <s.ThreeOptionImage src={read} alt="대체 이미지" />
              <s.OptionSubtitle>{t('survey.personality.option4')}</s.OptionSubtitle>
            </s.OptionButton>
            <s.OptionButton
              type="button"
              variant={isSelected(selectedOption, '5') ? 'contained' : 'outlined'}
              onClick={() => {
                onOptionSelect('5');
              }}
            >
              <s.ThreeOptionImage src={relax} alt="대체 이미지" />
              <s.OptionSubtitle>{t('survey.personality.option5')}</s.OptionSubtitle>
            </s.OptionButton>
            <s.OptionButton
              type="button"
              variant={isSelected(selectedOption, '6')  ? 'contained' : 'outlined'}
              onClick={() => {
                onOptionSelect('6');
              }}
            >
              <s.ThreeOptionImage src={no} alt="대체 이미지" />
              <s.OptionSubtitle>{t('survey.personality.option6')}</s.OptionSubtitle>
            </s.OptionButton>
          </s.Options>
        </s.OptionContainer>
      </s.InnerOptionContainer>
      <s.NextButton variant="contained" color="primary" onClick={() => {
        onNext();
        calcScore(selectedOption)}}>
        다음
      </s.NextButton>
    </s.SurveyContainer>
  );
};

export default Personality;
