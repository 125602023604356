import styled from '@emotion/styled';
import { Container, Typography, Button, Alert, LinearProgress, TextField, IconButton } from '@mui/material';

export const PageContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  padding: 20px;
  font-family: 'Noto Sans KR', sans-serif;
`;

export const PageContainerNoPadding = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  font-family: 'Noto Sans KR', sans-serif;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

export const PrivacyContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const NavButton = styled(IconButton)`
  color: #090e1d;
`;

export const ProgressBarContainer = styled.div`
  flex-grow: 1;
  margin: 0 5%;
`;

export const PrivacyTitle = styled(Typography)`
  font-weight: 700;
  font-size: 1.2rem;
  text-align: left;
  font-family: 'Noto Sans KR';
  padding: 0 8px;
`;

export const PrivacyText = styled(Typography)`
  font-weight: 400;
  font-size: 1rem;
  text-align: left;
  font-family: 'Noto Sans KR';
  padding: 4px 8px 20px 8px;
  color: #0f67fe;
`;

export const InputTitle = styled(Typography)`
  font-weight: 700;
  font-size: 1.2rem;
  text-align: left;
  font-family: 'Noto Sans KR';
  padding: 0 8px;
  margin-top: 20px;
`;

export const InfoTitle = styled(Typography)`
  font-weight: 700;
  font-size: 1.5rem;
  text-align: left;
  font-family: 'Noto Sans KR';
  padding: 0 8px;
  margin-top: 30px;
`;

export const InfoSubtitle = styled(Typography)`
  font-weight: 400;
  font-size: 1rem;
  text-align: left;
  font-family: 'Noto Sans KR';
  margin-top: 10px;
  padding: 0 8px;
`;

export const InfoText = styled(Typography)`
  font-weight: 700;
  font-size: 3rem;
  font-family: 'Noto Sans KR';
  padding: 20px 8px;
  margin-top: 20px;
  text-align: center;
`;

export const SliderText = styled(Typography)`
  border-bottom: 4px dashed #bdc3c7;
  text-align: center;
  color: #34495e;
  font-family: 'Noto Sans KR';
  font-weight: 700;
  font-size: 6em;
  width: 300px;
  height: 100px;
  line-height: 60px;
  margin: 40px auto;
  letter-spacing: -0.07em;
  text-shadow: white 2px 2px 2px;
`;

export const InfoTextField = styled(TextField)`
  width: calc(100% - 16px);
  margin: 10px 0 10px 8px;
  textField: {
    fontFamily: 'Noto Sans KR';
  },
`;

export const ProgressBar = styled(LinearProgress)`
  width: 100%;
  &.MuiLinearProgress-root {
    background-color: #e0e0e0;
    border-radius: 6px;
    height: 12px;
  }
  & .MuiLinearProgress-barColorPrimary {
    background-color: #0f67fe;
    border-radius: 6px;
    height: 12px;
  }
`;

export const ProgressText = styled.span`
  padding: 0 8px;
  font-size: 18px;
  font-weight: bold;
  color: #090e1d;
`;

export const Form = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-family: 'Noto Sans KR', sans-serif;
`;

export const NextButton = styled(Button)`
  margin: 10px 0px;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  font-family: 'Noto Sans KR', sans-serif;
`;

export const Option = styled.div`
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 4rem;
  font-family: 'Noto Sans KR', sans-serif;
`;

export const OptionButton = styled(Button)`
  display: flex;
  flex-direction: column;
  width: 150px;
  height: 200px;
  color: primary;
  font-weight: 700;
  font-family: 'Noto Sans KR', sans-serif;
  border: none;
  border-radius: 10px;
  shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &:hover {
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }
`;

export const GenderIcon = styled.div`
  font-size: 50px;
  color: #fff;
`;

export const OptionText = styled.span`
  font-size: 14px;
`;

export const Slider = styled.input`
  display: block;
  -webkit-appearance: none;
  background-color: #bdc3c7;
  width: 300px;
  height: 5px;
  border-radius: 5px;
  margin: 2rem auto;
  outline: 0;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    background-color: #0f67fe;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid white;
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }

  &::-webkit-slider-thumb:hover {
    background-color: white;
    border: 2px solid #0f67fe;
  }

  &::-webkit-slider-thumb:active {
    transform: scale(1.6);
  }
`;

export const ErrorMessage = styled(Alert)`
  margin-top: 10px;
  font-family: 'Noto Sans KR', sans-serif;
`;

export const LoadingContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #0f67fe;
  box-sizing: border-box;
`;

export const LoadingIcon = styled.img`
  width: 50px;
  height: 50px;
`;

export const LoadingText = styled.p`
  font-size: 1.5rem;
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 700;
  color: white;
`;

export const SliderTextContainer = styled.div`
  position: relative;
  width: 300px;
  display: flex;
  margin: 2rem auto;
  justify-content: center;
  align-items: baseline;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
  border-bottom: 4px dashed #bdc3c7;
`;

export const NumericInput = styled.input`
  width: 100%;
  font-size: 6rem;
  font-weight: bold;
  color: #34495e;
  text-align: center;
  border: none;
  background: transparent;
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &:focus {
    outline: none;
  }
`;

export const SliderTextSuffix = styled.span`
  position: absolute;
  right: 0;
  bottom: 0;
  color: #34495e;
`;


export const InfoModalBackground = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`

export const InfoModalBox = styled.div`
  background-color: #ffffff;
  width: 20rem;
  height: auto;
  padding: 15px;
`

export const BpContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8rem;

  @media (max-width: 600px) {
    flex-direction: column;
    gap: 2rem;
  }
`