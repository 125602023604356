import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router, Route, Routes, useNavigate, To, useLocation } from 'react-router-dom';
import { Link, Element, animateScroll as scroll } from 'react-scroll';
import { InputAdornment } from '@mui/material';
import { ArrowBack, Search } from '@mui/icons-material';
import * as s from './ResultStyled';
import EnergyGraph from './EnergyGraph';
import {AllManagementGuidType, GuideCardType, ManagementGuidType, SurveyResultType} from './ResultType';
import stomachImg from '../../../../asset/img/stomach2.png'
import heartImg from '../../../../asset/img/heart2.png'
import brainImg from '../../../../asset/img/kidney.png'
import searchImg from '../../../../asset/svg/search.svg'
import naverImg from '../../../../asset/svg/naver_img.svg'
import kakaoImg from '../../../../asset/svg/kakaotalk_img.svg'
import youtubeImg from '../../../../asset/svg/youtube_img.svg'



const StaticSearchBar = () => {
    const { t } = useTranslation();

    return (
      <s.SearchBarWrapper>
        <s.SearchIconWrapper>
          <Search />
        </s.SearchIconWrapper>
        <s.SearchText>{t('result.nobino')}</s.SearchText>
      </s.SearchBarWrapper>
    );
  };

function FormStep({
    title,
    children,
    progress,
    onNext,
    isValid,
  }: {
    title: string;
    children: React.ReactNode;
    progress: number;
    onNext: React.FormEventHandler<HTMLFormElement>;
    isValid: boolean;
  }) {
    const [showError, setShowError] = useState(false);
    const location = useLocation();
    
    const totalSteps = 5;
  
    // 페이지가 변경될 때마다 에러 메시지 초기화
    React.useEffect(() => {
      setShowError(false);
    }, [location]);
  
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (isValid) {
        onNext(event);
      } else {
        setShowError(true);
      }
    };
  }

export function EnergyInfo({title, content, score, imgsrc}:{
    title: string, content: string, score: number, imgsrc:string
}){
    const { t } = useTranslation();
    const basicColor = '#393939'
    let titleColor = '#000000';
    let levelText = 'null';
    let sumText
    // level 산정 방법 나중에 고쳐야 함
    if(score >= 85){
        titleColor = '#03C5AC';
        levelText = title.concat(t('result.main.energy_info.normal'));
        sumText = t('result.condition.normal');
    }
    else if(score >= 70){
        titleColor = '#FFB800';
        levelText = title.concat(t('result.main.energy_info.caution'));
        sumText = t('result.condition.caution');
    }
    else{
        titleColor = '#FF6E6E';
        levelText = title.concat(t('result.main.energy_info.caution'));
        sumText = t('result.condition.warning');
    }

    return(
        <s.StatusContainer>
            <s.StatusIconContainer>
                <s.StatusTitle style={{color: basicColor}}>{title}</s.StatusTitle>
                <s.StatusNum style={{color: titleColor}}>{score}</s.StatusNum>
                <s.StatusNumMother>/100</s.StatusNumMother>
                <s.StatusIconRound style={{color: titleColor}}>
                    <s.StatusIconText style={{color: titleColor}}>{sumText}</s.StatusIconText>
                </s.StatusIconRound>
            </s.StatusIconContainer>
            <div style={{marginTop: '-6px'}}>
                <s.StatusContent>{content}</s.StatusContent>
            </div>
            <s.StatusDescContainer>
                <s.StatusImg src={imgsrc}/>
                <s.StautsDesc>{levelText}</s.StautsDesc>
            </s.StatusDescContainer>
        </s.StatusContainer>
    );
}

function ManagementPointGraph({dietImportance, ExerciseImportance, sleepImportance}:{
    dietImportance:number, ExerciseImportance:number, sleepImportance:number
}){
    // importance는 0~100
    const calcImportance = (importance : number)=>{
         return importance/3 + 42;
    }
    
    return(
        <s.ManagementPointGraphContainer>
            <s.ManagementPointGraphContents>
                <Link to="dietComponent" smooth duration={500}>
                    <svg width="150" height="150">
                    <circle cx={75} cy={75} r={calcImportance(dietImportance)} fill="#778DFF" />
                    <text x={75} y={80} fill="white" fontSize="16px" textAnchor='middle'>음식</text>
                    </svg>
                </Link>
            </s.ManagementPointGraphContents>
            <s.ManagementPointGraphContents>
                <Link to="exerciseComponent" smooth duration={500}>
                    <svg width="150" height="150" style={{marginRight: '0.3rem'}}>
                    <circle cx={75} cy={75} r={calcImportance(ExerciseImportance)} fill="#FFAFAF" />
                    <text x={75} y={80} fill="white" fontSize="16px" textAnchor='middle'>운동</text>
                    </svg>
                </Link>
                <Link to="sleepComponent" smooth duration={500}>
                    <svg width="150" height="150" style={{marginLeft: '0.3rem'}}>
                    <circle cx={75} cy={75} r={calcImportance(sleepImportance)} fill="#D5A0FF" />
                    <text x={75} y={80} fill="white" fontSize="16px" textAnchor='middle'>수면</text>
                    </svg>
                </Link>
            </s.ManagementPointGraphContents>
        </s.ManagementPointGraphContainer>
    );
}

function EmptyComponent(){
    return null
}

function ManagementGuid({data}:{
    data:ManagementGuidType
}){
    const basicHeight = 30
    const [cards, setCards] = useState<GuideCardType[]>([]);
    const [selectIdx, setSelectIdx] = useState<number>(0);
    const [cardDesc, setCardDesc] = useState<()=> React.ReactNode>(()=>data.cards[0].renderComponent);
    const containerRef = useRef<HTMLDivElement>(null);
    const descRef = useRef<HTMLDivElement>(null);
    const [height, setHeight] = useState(basicHeight);
    const [animate, setAnimate] = useState<boolean>(false);

    useEffect(() => {
        if (data && Array.isArray(data.cards)) {
            const tempCard : GuideCardType[] = [];
            data.cards.forEach((element, index) => {
                if(index === 0){
                    tempCard.push(element);
                }
                else{
                    tempCard.push({
                        title: '', image: '',
                        renderComponent: element.renderComponent
                    });
                    tempCard.push(element);
                }
            });
          setCards(tempCard);
        } else {
          console.error("data.cards is not an array");
        }
      }, [data]);
    const onClickCard = (index: number): React.MouseEventHandler<HTMLDivElement> => {
        return (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          if (index !== selectIdx) {
            setSelectIdx(index); 
            setCardDesc(()=>cards[index].renderComponent);
          }
        }
    };
    
    useLayoutEffect(() => {
        if (descRef.current) {
            if(descRef.current.scrollHeight > basicHeight)
                setHeight(descRef.current.scrollHeight)
            else
                setHeight(basicHeight);
          setAnimate(true);
        }
    }, [cardDesc, selectIdx]);  

    useEffect(() => {
        const timer = setTimeout(() => setAnimate(false), 700);
        return () => clearTimeout(timer);
    }, [animate])

    return(
<s.ManagementPointEachContainer>
    <s.SubTitle style={{marginLeft: '2rem'}}>{data.title}</s.SubTitle>
    <s.GuideInfoContainer>
        <s.GuideInfoImage src={data.image} alt="Description of the specific content"/>
        <s.GuideInfoTextContainer>
            <s.ContentText>{data.content}</s.ContentText>
        </s.GuideInfoTextContainer>
    </s.GuideInfoContainer>
    <s.GuideSubtitleContainer>
        <s.CommentText style={{marginLeft:'0.6rem'}}>{data.subcomment}</s.CommentText>
    </s.GuideSubtitleContainer>
    <s.GuideTitleContainer nums={data.cards.length}>
            {cards.map((item, index) => {
                    if(item.title === ''){
                        return(<s.GuideTitlePartition/>)
                    }
                    return(
                    <s.GuideTitleWrapper clicked={index===selectIdx} onClick={onClickCard(index)}>
                        <s.GuideTitle>{item.title}</s.GuideTitle>
                    </s.GuideTitleWrapper>
                    );
                })}
    </s.GuideTitleContainer>
    <s.GuideCardContainerWarpper heightTo={height+20}>
        <s.GuideCardContainer ref={containerRef} heightTo={height}>
            <s.GuideCardInnerContainer ref={descRef}>
            
            <s.GuideDescWrapper animate={animate} >
            {cardDesc()}
            </s.GuideDescWrapper>
        </s.GuideCardInnerContainer>
        </s.GuideCardContainer>
    </s.GuideCardContainerWarpper>
</s.ManagementPointEachContainer>
    );
}

function KmedicineDescriptor({data}:{
    data:GuideCardType[]
}){
    const basicHeight = 30
    const [cards, setCards] = useState<GuideCardType[]>([]);
    const [selectIdx, setSelectIdx] = useState<number>(0);
    const [cardDesc, setCardDesc] = useState<()=> React.ReactNode>(()=>data[0].renderComponent);
    const containerRef = useRef<HTMLDivElement>(null);
    const descRef = useRef<HTMLDivElement>(null);
    const [height, setHeight] = useState(basicHeight);
    const [animate, setAnimate] = useState<boolean>(false);

    useEffect(() => {
        if (data && Array.isArray(data)) {
            const tempCard : GuideCardType[] = [];
            data.forEach((element, index) => {
                if(index === 0){
                    tempCard.push(element);
                }
                else{
                    tempCard.push({
                        title: '', image: '',
                        renderComponent: element.renderComponent
                    });
                    tempCard.push(element);
                }
            });
          setCards(tempCard);
        } else {
          console.error("data.cards is not an array");
        }
      }, [data]);
    const onClickCard = (index: number): React.MouseEventHandler<HTMLDivElement> => {
        return (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          if (index !== selectIdx) {
            setSelectIdx(index); 
            setCardDesc(()=>cards[index].renderComponent);
          }
        }
    };
    
    useLayoutEffect(() => {
        if (descRef.current) {
            if(descRef.current.scrollHeight > basicHeight)
                setHeight(descRef.current.scrollHeight)
            else
                setHeight(basicHeight);
          setAnimate(true);
        }
    }, [cardDesc, selectIdx]);  

    useEffect(() => {
        const timer = setTimeout(() => setAnimate(false), 700);
        return () => clearTimeout(timer);
    }, [animate])

    return(
<s.ManagementPointEachContainer style={{marginTop: '0.5rem'}}>
    <s.GuideTitleContainer nums={2}>
            {cards.map((item, index) => {
                    if(item.title === ''){
                        return(<s.GuideTitlePartition/>)
                    }
                    return(
                    <s.GuideTitleWrapper clicked={index===selectIdx} onClick={onClickCard(index)}>
                        <s.GuideTitle>{item.title}</s.GuideTitle>
                    </s.GuideTitleWrapper>
                    );
                })}
    </s.GuideTitleContainer>
    <s.GuideCardContainerWarpper heightTo={height+20}>
        <s.GuideCardContainer ref={containerRef} heightTo={height}>
            <s.GuideCardInnerContainer ref={descRef}>
            
            <s.GuideDescWrapper animate={animate} >
            {cardDesc()}
            </s.GuideDescWrapper>
        </s.GuideCardInnerContainer>
        </s.GuideCardContainer>
    </s.GuideCardContainerWarpper>
</s.ManagementPointEachContainer>
    );
}

export default function SurveyResultPresenter({result, guides, loadingState}:{
    result:SurveyResultType
    guides: AllManagementGuidType
    loadingState: number
}){
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const handleBack = () => {
        navigate("/welcome");
    };
    const {name, AScore, BScore, CScore} = result;
    return(
        <s.PageContainer>
            {loadingState === 1 ?
            <div style={{width: '100%', paddingBottom: '3rem'}}>
                <s.HeaderContainer>
                    <s.BackButton onClick={handleBack} aria-label='Go back'>
                        <ArrowBack />
                    </s.BackButton>
                    <s.NaviTitleContainer>
                        <s.NaviTitleText>
                            {t('result.main.diag_result')}
                        </s.NaviTitleText>
                    </s.NaviTitleContainer>
                </s.HeaderContainer>
                <s.MainTitle style={{marginTop: '0rem'}}>
                    {t('result.main.energy_result_of',{name})}
                </s.MainTitle>
                <s.statusesContainer style={{marginTop: '0rem', marginBottom: '2rem'}}>
                    <EnergyInfo title={t('result.main.energy_create_feature')} score={BScore} content={t('result.main.energy_create_desc')} imgsrc={stomachImg}/>
                    <EnergyInfo title={t('result.main.energy_circulation_feature')} score={AScore} content={t('result.main.energy_circulation_desc')} imgsrc={heartImg}/>
                    <EnergyInfo title={t('result.main.energy_balance_feature')} score={CScore} content={t('result.main.energy_balance_desc')} imgsrc={brainImg}/>
                </s.statusesContainer>
                <s.MainTitle>
                    {t('result.main.the_energy_result_of',{name})}
                </s.MainTitle>
                <s.EnergyGraphContainer>
                    <EnergyGraph energyBalanceScore={CScore} energyCreateScore={BScore} energyCirculationScore={AScore}/>
                </s.EnergyGraphContainer>
                <s.statusesContainer>
                    <div style={{marginTop: '1.5rem'}}>
                        <div>
                            <s.SubTitle>{t('result.main.energy_eval')}</s.SubTitle>
                        </div>
                        <s.subContainerGeneral style={{marginTop : '6px'}}>
                            <s.ContentText>{name}{guides.diagSummary}</s.ContentText>
                        </s.subContainerGeneral>
                        <s.subContainerGeneral style={{marginTop : '2px'}}>
                            <s.CommentWText>{t('result.main.diag_summary_caution')}</s.CommentWText>
                        </s.subContainerGeneral>
                        {guides.status !== 0 ? (
                            <div>
                            <div style={{marginTop : '1rem'}}>
                                <s.SubTitle>{t('result.main.disease_watch_out')}</s.SubTitle>
                            </div>
                            <s.diseaseContainer style={{marginTop : '6px'}}>
                                {guides.predictedDisease.map((item, index)=>{
                                    return(
                                        <s.diseaseContentWrapper>
                                            <s.ContentText>• {item}</s.ContentText>
                                        </s.diseaseContentWrapper>
                                    );
                                })}
                            </s.diseaseContainer>
                            </div>
                        ):null}
                    </div>
                </s.statusesContainer>
                {guides.status !== 0 ?(
                    <div>
                        <div style={{marginTop: '2rem'}}>
                        <s.MainTitle >
                        {t('result.main.recommand_way_life')}
                        </s.MainTitle>
                        
                        </div>
                        <Element name="dietComponent" className="element">
                            <ManagementGuid data={guides.diet}/>
                        </Element>
                        <Element name="exerciseComponent" className="element">
                            <ManagementGuid data={guides.exercise}/>
                        </Element>
                        <Element name="sleepComponent" className="element">
                            <ManagementGuid data={guides.sleep}/>
                        </Element>
                    </div>
                ):null}
                
                <div style={{marginTop: '4rem'}}>
                <s.MainTitle>{t('result.main.oriental_medicine_treatment_and_health_counseling')}</s.MainTitle>
                </div>
                <div style={{marginLeft: '2rem', marginRight: '2rem'}}>
                    <div style={{marginTop: '1rem'}}>
                        <s.SubTitle>{t('result.main.oriental_medicine_treatment')}</s.SubTitle>
                    </div>
                    <s.subContainerGeneral style={{marginTop : '6px'}}>
                        <s.ContentText>{t('result.main.oriental_medicine_treatment_diag')}</s.ContentText>
                    </s.subContainerGeneral>
                    <KmedicineDescriptor data={guides.kmedicine}/>
                    <div style={{marginTop: '3rem'}}>
                        <s.SubTitle>{t('result.main.more_info')}</s.SubTitle>
                    </div>
                    <s.searchContainerWrapper>
                        <s.searchContainer>
                            <s.searchImgContainer>
                                {/* <s.searchImg src={searchImg}/> */}
                                <StaticSearchBar />
                            </s.searchImgContainer>
                            <s.searchLinkContainer>
                                <s.searchLink href='https://www.youtube.com/@NOBINO-jw2up'>
                                    <s.searchLinkImg src={youtubeImg} style={{width: '2.6rem'}}/>
                                </s.searchLink>
                                <s.searchLink href='https://search.naver.com/search.naver?where=nexearch&sm=top_hty&fbm=0&ie=utf8&query=%EB%85%B8%EB%B9%84%EB%85%B8+%EB%8B%A4%EC%9D%B4%EC%96%B4%ED%8A%B8'>
                                    <s.searchLinkImg src={naverImg}/>
                                </s.searchLink>
                            </s.searchLinkContainer>
                        </s.searchContainer>
                    </s.searchContainerWrapper>
                    {/* <s.subContainerGeneral style={{marginTop : '1rem'}}>
                        <s.ContentText>제공된 생활요법은 가장 기본사항이며 유튜브나 SNS에서 “노비노건강법” “노비노다이어트”검색으로 더 많은 정보를 확인하실 수 있습니다.
    <br/>
    건강상담은 “노비노건강법”홈페이지 상담코너나 카카오채널 “이재동교수의 노비노건강법”에 내용 남기시면 성실히 답변 드리겠습니다.
    </s.ContentText>
                    </s.subContainerGeneral> */}
                    <s.CopyrightWapper>
                        <a href='https://www.flaticon.com/kr'>
                            <s.ContentText style={{color:'#0F67FE'}}>all icon is illusted by flaticon</s.ContentText>
                        </a>
                    </s.CopyrightWapper>
                </div>
            </div>
            :
            <div style={{width: '100%', paddingBottom: '3rem'}}>
                {loadingState === 0 ?
                <p>{t('result.main.loading')}</p>
                :
                <p>{t('result.main.server_error')}</p>
                }
                
            </div>
            }
        </s.PageContainer>
    );
}