import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import SurveyDigSlpPresenter from './SurveyAbdtmpPresenter';
import { useHeaderTextChange } from '../../../../lib/useHeader';
import { RootState } from '../../../../modules';

export default function SurveyAbdtmpContainer() {
  // header text change
  useHeaderTextChange('사전문진');

  // get store object(survey)
  const dictObject = useSelector((state: RootState) => state.survey);

  // validation for survey-abdtmp
  const [validForm, setValidForm] = useState({
    q81: false,
    q9: false,
  });

  // validation check for q61
  useEffect(() => {
    if (dictObject.q81 === -1) {
      setValidForm({
        ...validForm,
        q81: false,
      });
    } else {
      setValidForm({
        ...validForm,
        q81: true,
      });
    }
  }, [dictObject.q81]);

  // validation check for q7
  useEffect(() => {
    if (
      !(
        dictObject.q91 ||
        dictObject.q92 ||
        dictObject.q93 ||
        dictObject.q94 ||
        dictObject.q95 ||
        dictObject.q96 ||
        dictObject.q97
      )
    ) {
      setValidForm({
        ...validForm,
        q9: false,
      });
    } else {
      setValidForm({
        ...validForm,
        q9: true,
      });
    }
  }, [dictObject.q91, dictObject.q92, dictObject.q93, dictObject.q94, dictObject.q95, dictObject.q96, dictObject.q97]);

  // checking for button disable
  useEffect(() => {
    if (
      dictObject.q81 !== -1 &&
      (dictObject.q91 ||
        dictObject.q92 ||
        dictObject.q93 ||
        dictObject.q94 ||
        dictObject.q95 ||
        dictObject.q96 ||
        dictObject.q97)
    ) {
      setValidForm({
        q81: true,
        q9: true,
      });
    }
  }, []);

  return <SurveyDigSlpPresenter dictObject={dictObject} validForm={validForm} />;
}
