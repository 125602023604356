import styled from 'styled-components';
import { Container, Typography, Button, Alert, LinearProgress, TextField, IconButton } from '@mui/material';

export const PageContainer = styled.div`
width: auto; 
height: auto; 
border-radius: 10px; 
display: flex;
padding: 1rem;
margin: 0.5rem;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
flex-direction: column;
background: #FFF;
`

export const Title = styled.span`
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-top: 1rem;
color: #393939;
`;

export const TitleGood = styled.span`
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-top: 1rem;
color: #03C5AC;
`;

export const TitleBad = styled.span`
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
color: #FF6E6E;
`;

export const TitleWrapper = styled.div`
margin-top: 1rem;
`;

export const ContentCard = styled.div`
width: 80px;
height: 87px;
border-radius: 10px;
display: grid;
grid-template-rows: 4fr 1fr;
place-items: center;
grid-auto-columns: 80px;
margin-right: 10px;
margin-bottom: 10px;
`

export const ContentCardImgOnly = styled.div`
width: 80px;
height: 80px;
border-radius: 10px;
display: grid;
place-items: center;
grid-auto-columns: 80px;
margin-right: 10px;
margin-bottom: 10px;
`

export const ContentCardImage = styled.img`
width: 60px;
height: 60px;
`;

export const ContentCardText = styled.span`
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 15px;
white-space: pre-wrap;
text-align: center;
transition: color 0.3s ease;
`;

export const ContentCardContainer = styled.div`
display: flex;
flex-direction: row;
flex-wrap: wrap;
`;

export const ContentText = styled.span`
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 15px;
color: #393939;
`;

export const ContentTextContainer = styled.div`
display: flex;
width: auto; 
height: auto; 
margin-left: 0.6rem;
margin-top: 0.5rem;
flex-direction: column;
`;

export const ContentList = styled.li`
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 18px;
color: #393939;
`