import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import SurveyDigSlpPresenter from './SurveyDigSlpPresenter';
import { useHeaderTextChange } from '../../../../lib/useHeader';
import { RootState } from '../../../../modules';

export default function SurveyDigSlpContainer() {
  // header text change
  useHeaderTextChange('사전문진');

  // get store object(survey)
  const dictObject = useSelector((state: RootState) => state.survey);

  // validation for survey-digslp
  const [validForm, setValidForm] = useState({
    q21: false,
    q31: false,
  });

  // validation check for q21
  useEffect(() => {
    if (dictObject.q21 === -1) {
      setValidForm({
        ...validForm,
        q21: false,
      });
    } else {
      setValidForm({
        ...validForm,
        q21: true,
      });
    }
  }, [dictObject.q21]);

  // validation check for q31
  useEffect(() => {
    if (dictObject.q31 === -1) {
      setValidForm({
        ...validForm,
        q31: false,
      });
    } else {
      setValidForm({
        ...validForm,
        q31: true,
      });
    }
  }, [dictObject.q31]);

  // checking for button disable
  useEffect(() => {
    if (dictObject.q21 !== -1 && dictObject.q31 !== -1) {
      setValidForm({
        q21: true,
        q31: true,
      });
    }
  }, []);

  return <SurveyDigSlpPresenter dictObject={dictObject} validForm={validForm} />;
}
