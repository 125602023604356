import React from 'react';
import styled from 'styled-components';
import SideMenu from './SideMenu';
import NobinoHealthSection from './NobinoHealthSection';

const HomepagePresenter: React.FC = () => {
  return (
    <PageWrapper>
      <SideMenu />
      <NobinoHealthSection />
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: 100%;
  min-height: 100vh;
`;

export default HomepagePresenter;