import { notLoginedApi, loginedApi } from './api-base';

export interface patientType {
    "name": string,
    "height": number,
    "weight": number,
    "phone": string,
    "birth":number,
    "gender":number,
    "diastolic":number,
    "systolic":number
}

interface pageType {
  "page": number,
  "limit": number
}

export const patientApi = {
  getPatientExistByPhone: (phone: string) => notLoginedApi.get(`/v1/patient/exist/${phone}`),
  createPatient: (data: patientType) => notLoginedApi.post(`/v1/patient/create`, data),
  updatePatient: (data: patientType) => notLoginedApi.post(`/v1/patient/update`, data),
  getAllPatients: (page:number, limit = 10) => loginedApi.get(`/v1/patient/getPatients`, {params: {page, limit}}),
  getPateintsWithName: (page:number, name:string, limit = 10) => loginedApi.get(`/v1/patient/getPatientsByName`, {params: {page, limit, name}}),
  getPatient: (phone:string) => loginedApi.get(`/v1/patient/getPatient/${phone}`),
};
