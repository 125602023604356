import React, { useEffect } from 'react';
import {
  BtnPreNext,
  SurveyMultiSelect,
  SurveySingleSelect,
  SurveyTitle,
  SurveyMultiSelectWithNoProblem,
} from '../common/Survey';
import { ISurveyDictType } from '../common/SurveyTypes';
import '../common/Survey.scss';

interface IUrsPageProps {
  dictObject: ISurveyDictType;
  validForm: IValidData;
}

interface IValidData {
  q41: boolean;
  q51: boolean;
}

export default function SurveyUrsPresenter(Props: IUrsPageProps) {
  const { validForm, dictObject } = Props;

  const q41 = {
    qTitle: 'q41',
    title: '',
    questions: ['1주일에 2회 이하 배변 (변비경향)', '하루 3회 이상 배변 (설사경향)', `해당 사항 없음`],
  };

  const q51 = {
    qTitle: 'q51',
    title: '',
    questions: ['소변을 참지 못하고 하루 2회 이상 실금', '야간에 3회 이상 소변으로 잠에서 깸', `해당 사항 없음`],
  };

  return (
    <>
      <SurveyTitle title="4. 대변상태" />
      <SurveySingleSelect surveyItem={q41} defaultValue={dictObject.q41} />
      <SurveyTitle title="5. 소변상태" />
      <SurveySingleSelect surveyItem={q51} defaultValue={dictObject.q51} />
      <BtnPreNext linkPre="../survey-digslp" linkNex="../survey-hbipsn" disabled={!(validForm.q41 && validForm.q51)} />
    </>
  );
}
