import styled from '@emotion/styled';
import { Container, TextField, Typography, Box, Button, Link, List, ListItem, IconButton } from '@mui/material';

export const StyledSurveyListPage = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px;
`;

export const StyledTextField = styled(TextField)`
  width: 80%;
  input {
    padding: 1rem;
  }
`;

export const CenterText = styled(Typography)`
  font-weight: 500;
  text-align: center;
`;
export const LeftText = styled(Typography)`
  font-weight: 300;
  text-align: Left;
`;

export const LogoBox = styled(Box)`
  display: flex;
  flex-direction: column;
  margin: 0 auto 1rem;
`;

export const InfoBox = styled(Box)`
  display: flex;
  flex-direction: column;
  margin: 10 auto;
`;

export const SiloIcon = styled.img`
  width: 120px;
  margin: 0 auto;
`;

export const StyledButton = styled(Button)`
  margin: 0.2rem;
  && {
    width: 60%;
    height: 25px;
    background-color: #0F67FE;
  }
`;

export const WithoutDecoLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`;

export const SurveyList = styled(List)``;

export const SurveyListItem = styled(ListItem)`
  margin-bottom: 5px;
`;

export const SurveyListItemInfo = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const SurveyListButtonBox = styled(Box)`
  display: flex;
  flex-direction: row;
`;

export const SerachBox = styled(Box)`
  display: flex;
  flex-direction: row;
`;

export const SummeryBox = styled(Box)`
  display: block;
  width: 85%;
  margin: 0.8rem;
`;

export const SummeryTitle = styled(Typography)`
  font-weight: 600;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
`;

export const SummerySubTitle = styled(Typography)`
  display: inline-block;
  font-weight: 600;
`;

export const foodBox = styled(Box)`
  margin-top: 0.5rem;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 40px;
`;


export const BackButton = styled(IconButton)`
  margin-right: 5%;
  color: #090e1d;
`;

export const NaviTitleContainer = styled.div`
  flex-grow: 1;
  text-align: center;
`;

export const NaviTitleText = styled.span`
    padding: 0 8px;
    font-size: 18px;
    font-weight: bold;
    color: #090e1d;
    margin-right: 3rem;
`;