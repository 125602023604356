import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import SurveyBodyShapePresenter from './SurveyBodyShapePresenter';
import { useHeaderTextChange } from '../../../../lib/useHeader';
import { RootState } from '../../../../modules';

export default function SurveyBodyShapeContainer() {
  // header text change
  useHeaderTextChange('사전문진');

  // get store object(survey)
  const dictObject = useSelector((state: RootState) => state.survey);

  // validation for survey-bdyshp
  const [validForm, setValidForm] = useState({
    q11: false,
  });

  // validation check for q11
  useEffect(() => {
    if (dictObject.q11 === -1) {
      setValidForm({
        ...validForm,
        q11: false,
      });
    } else {
      setValidForm({
        ...validForm,
        q11: true,
      });
    }
  }, [dictObject.q11]);

  // checking for button disable
  useEffect(() => {
    if (dictObject.q11 !== -1) {
      setValidForm({
        q11: true,
      });
    }
  }, []);

  return <SurveyBodyShapePresenter dictObject={dictObject} validForm={validForm} />;
}
