import styled from '@emotion/styled';
import { Container, TextField, Typography, Box, Button } from '@mui/material';

export const StyledSignUpPage = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px;
`;

export const StyledTextField = styled(TextField)`
  width: 80%;
  input {
    padding: 1rem;
  }
`;

export const h4Text = styled(Typography)``;

export const LogoBox = styled(Box)`
  display: flex;
  flex-direction: column;
  margin: 0 auto 5rem;
`;

export const SiloIcon = styled.img`
  width: 120px;
  margin: 0 auto;
`;

export const StyledButton = styled(Button)`
  && {
    width: 80%;
    height: 50px;
  }
`;
