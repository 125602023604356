import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import SurveyBasicPresenter from './SurveyBasicPresenter';
import { useHeaderTextChange, useHeaderToggle } from '../../../../lib/useHeader';
import { RootState } from '../../../../modules';
import { surveyApi } from '../../../../api/api-survey';

export default function SurveyBasicContainer() {
  // header text change
  useHeaderTextChange('사전문진');
  useHeaderToggle();

  // get store object(survey)
  const dictObject = useSelector((state: RootState) => state.survey);

  // validation for survey-basic
  const [validForm, setValidForm] = useState({
    nameValid: true,
    genderValid: true,
    ageValid: true,
    phoneValid: true,
  });

  // validation error messages
  const [validErrMessages, setValidErrMessages] = useState({
    nameErrMessage: '',
    genderErrMessage: '',
    ageErrMessage: '',
    phoneErrMessage: '',
  });

  // validation check for name
  useEffect(() => {
    if (dictObject.name.length === 0) {
      setValidForm({
        ...validForm,
        nameValid: false,
      });
      setValidErrMessages({
        ...validErrMessages,
        nameErrMessage: '이름을 바르게 입력해주세요.',
      });
    } else {
      setValidForm({
        ...validForm,
        nameValid: true,
      });
      setValidErrMessages({
        ...validErrMessages,
        nameErrMessage: '',
      });
    }

    if (dictObject.name !== '' && dictObject.phone !== '') {
      checkSurveyExsitByPhoneAndName();
    }
  }, [dictObject.name]);

  // validation check for gender
  useEffect(() => {
    if (dictObject.gender.length === 0) {
      setValidForm({
        ...validForm,
        genderValid: false,
      });
      setValidErrMessages({
        ...validErrMessages,
        genderErrMessage: '성별을 선택 해주세요.',
      });
    } else {
      setValidForm({
        ...validForm,
        genderValid: true,
      });
      setValidErrMessages({
        ...validErrMessages,
        genderErrMessage: '',
      });
    }
  }, [dictObject.gender]);

  // validation check for age
  useEffect(() => {
    if (dictObject.age.length === 0) {
      setValidForm({
        ...validForm,
        ageValid: false,
      });
      setValidErrMessages({
        ...validErrMessages,
        ageErrMessage: '나이를 바르게 입력해주세요(ex 20).',
      });
    } else {
      setValidForm({
        ...validForm,
        ageValid: true,
      });
      setValidErrMessages({
        ...validErrMessages,
        ageErrMessage: '',
      });
    }
  }, [dictObject.age]);

  // validation check for phone
  useEffect(() => {
    if (dictObject.phone.length !== 11) {
      setValidForm({
        ...validForm,
        phoneValid: false,
      });
      setValidErrMessages({
        ...validErrMessages,
        phoneErrMessage: '핸드폰 번호를 바르게 입력해주세요(ex 01092781271).',
      });
    } else if (dictObject.name !== '' && dictObject.phone !== '') {
      checkSurveyExsitByPhoneAndName();
    }
  }, [dictObject.phone]);

  /* 핸드폰 번호 & 이름이용해서 하루안에 검사 2번 확인 */
  const checkSurveyExsitByPhoneAndName = async () => {
    let result;
    try {
      result = await surveyApi.getSurveyExistByPhone(dictObject.phone, dictObject.name);
    } catch (e) {
      console.log(e);
    } finally {
      if (result) {
        if (!result.data.exist) {
          setValidForm({
            ...validForm,
            phoneValid: true,
            nameValid: true,
          });
          setValidErrMessages({
            ...validErrMessages,
            phoneErrMessage: '',
            nameErrMessage: '',
          });
          dictObject.exist_in_day = false;
        } else {
          dictObject.exist_in_day = true;
          setValidForm({
            ...validForm,
            phoneValid: false,
            nameValid: false,
          });
          setValidErrMessages({
            ...validErrMessages,
            phoneErrMessage: '하루 한번만 참여 가능합니다. 관리자에게 문의하시기 바랍니다.',
          });
        }
      }
    }
  };

  // initial message setting
  useEffect(() => {
    setValidErrMessages({
      nameErrMessage: '',
      genderErrMessage: '',
      ageErrMessage: '',
      phoneErrMessage: '',
    });
  }, []);

  useEffect(() => {
    if (dictObject.name === '' || dictObject.phone === ' ' || dictObject.age === ' ' || dictObject.gender === ' ') {
      setValidForm({
        nameValid: false,
        phoneValid: false,
        genderValid: false,
        ageValid: false,
      });
    }
  }, []);

  return <SurveyBasicPresenter dictObject={dictObject} validForm={validForm} validErrMessages={validErrMessages} />;
}
