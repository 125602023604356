import { SurveyResultRawType } from '../components/pages/surveys/result/ResultType';
import { notLoginedApi, loginedApi } from './api-base';

export const newsurveyApi = {
  createSurvey: (surveyData: SurveyResultRawType) => notLoginedApi.post(`/v1/survey/create`, surveyData),
  getAllSurvey: (page: number, limit = 10)=> loginedApi.get(`/v1/survey/all?page=${page}&limit=${limit}`),
  getSurveyListByPhone: (phone: string)=> loginedApi.get(`/v1/survey/phone/${phone}`),
  getSurveyById: (id: string)=> loginedApi.get(`/v1/survey/id/${id}`),
  deleteSurveyById: (id: string)=> loginedApi.delete(`/v1/survey/id/${id}`),
  getSurveyExistByPhone: (phone: string)=> notLoginedApi.get(`/v1/survey/exist/${phone}`),
};
