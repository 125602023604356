import React from 'react';
import * as s from './SignUpStyled';
import siloIcon from '../../../asset/icon/silo-icon.png';

interface SignUpProps {
  submitForm: submitData;
  validForm: validData;
  validErrMessages: validErrMessagesData;
  setSubmitForm: (data: submitData) => void;
  onClickSubmit: () => void;
}

interface submitData {
  email: string;
  name: string;
  hint: string;
  answer: string;
  password: string;
  rePassword: string;
}

interface validData {
  emailValid: boolean;
  nameValid: boolean;
  hintValid: boolean;
  answerValid: boolean;
  passwordValid: boolean;
  rePasswordValid: boolean;
}

interface validErrMessagesData {
  emailErrMessage: string;
  nameErrMessage: string;
  hintErrMessage: string;
  answerErrMessage: string;
  passwordErrMessage: string;
  rePasswordErrMessage: string;
}

export default function SignUpPresenter(props: SignUpProps) {
  const { submitForm, validForm, validErrMessages, setSubmitForm, onClickSubmit } = props;
  // console.log(validForm);
  return (
    <s.StyledSignUpPage maxWidth="sm">
      <s.LogoBox>
        <s.SiloIcon src={siloIcon} />
        <s.h4Text variant="h4" sx={{ fontWeight: 700, textAlign: 'center' }}>
          oriental silo
        </s.h4Text>
      </s.LogoBox>
      <s.StyledTextField
        label="이름"
        placeholder="홍길동"
        variant="standard"
        value={submitForm.name}
        required
        sx={{ marginBottom: '1rem' }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setSubmitForm({ ...submitForm, name: e.target.value });
        }}
        error={!validForm.nameValid && validErrMessages.nameErrMessage !== ''}
        helperText={validErrMessages.nameErrMessage}
      />
      <s.StyledTextField
        label="이메일"
        placeholder="oriental01"
        type="string"
        variant="standard"
        required
        sx={{ marginBottom: '1rem' }}
        value={submitForm.email}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setSubmitForm({ ...submitForm, email: e.target.value });
        }}
        error={!validForm.emailValid && validErrMessages.emailErrMessage !== ''}
        helperText={validErrMessages.emailErrMessage}
      />
      <s.StyledTextField
        label="비밀번호"
        type="password"
        variant="standard"
        required
        sx={{ marginBottom: '1rem' }}
        value={submitForm.password}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setSubmitForm({ ...submitForm, password: e.target.value });
        }}
        error={!validForm.passwordValid && validErrMessages.passwordErrMessage !== ''}
        helperText={validErrMessages.passwordErrMessage}
      />
      <s.StyledTextField
        label="비밀번호 재입력"
        type="password"
        variant="standard"
        required
        sx={{ marginBottom: '1rem' }}
        value={submitForm.rePassword}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setSubmitForm({ ...submitForm, rePassword: e.target.value });
        }}
        error={!validForm.rePasswordValid && validErrMessages.rePasswordErrMessage !== ''}
        helperText={validErrMessages.rePasswordErrMessage}
      />
      <s.StyledTextField
        label="비밀번호 찾기 힌트"
        placeholder="ex) 다니던 초등학교의 이름은?"
        type="string"
        variant="standard"
        required
        sx={{ marginBottom: '1rem' }}
        value={submitForm.hint}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setSubmitForm({ ...submitForm, hint: e.target.value });
        }}
        error={!validForm.hintValid && validErrMessages.hintErrMessage !== ''}
        helperText={validErrMessages.hintErrMessage}
      />
      <s.StyledTextField
        label="비밀번호 찾기 힌트 정답"
        placeholder="ex) 대한초등학교"
        type="string"
        variant="standard"
        required
        sx={{ marginBottom: '1rem' }}
        value={submitForm.answer}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setSubmitForm({ ...submitForm, answer: e.target.value });
        }}
        error={!validForm.answerValid && validErrMessages.answerErrMessage !== ''}
        helperText={validErrMessages.answerErrMessage}
      />
      <s.StyledButton
        variant="contained"
        sx={{ marginTop: '4rem', marginBottom: '1rem' }}
        onClick={() => onClickSubmit()}
        disabled={
          !(
            validForm.answerValid &&
            validForm.emailValid &&
            validForm.hintValid &&
            validForm.nameValid &&
            validForm.rePasswordValid &&
            validForm.passwordValid
          )
        }
      >
        회원가입
      </s.StyledButton>
    </s.StyledSignUpPage>
  );
}
