/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import {
  Container,
  Paper,
  Typography,
  FormGroup,
  FormControlLabel,
  TextField,
  Radio,
  RadioGroup,
  Alert,
} from '@mui/material';
import * as s from './NewSurveyStyled';

const StyledPaper = styled(Paper)`
  padding: 0 1.5rem 1.5rem 1.5rem;
  margin: 1.5rem 0;
  overflow-y: auto;
  max-height: 50vh;
  width: 100%;
  box-sizing: border-box;
`;

const StyledFormGroup = styled(FormGroup)`
  margin-top: 16px;
`;

const LabelText = styled(Typography)`
  font-family: 'Noto Sans KR', sans-serif;
`;

const StyledTextField = styled(TextField)`
  margin-top: 8px;
  margin-left: 24px;
  width: 80%;
`;

const QuestionGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 1rem;
`;

const ConditionalFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 8px;
`;

const AmountText = styled(Typography)`
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 0.75rem;
  width: 4rem;
`;

const ErrorMessage = styled(Alert)`
  margin-top: 10px;
  font-family: 'Noto Sans KR', sans-serif;
`;

interface FavoriteFoodProps {
  onNext: () => void;
  formData: {
    question1: string;
    question1Amount: string;
    question2: string;
    question2Amount1: string;
    question2Amount2: string;
    question3: string;
    question3Amount: string;
  };
  handleRadioChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleTextChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isValid: () => boolean;
  sendDatas: () => void;
  // isAllSelected: () => boolean;
}

const FavoriteFood: React.FC<FavoriteFoodProps> = ({
  onNext,
  formData,
  handleRadioChange,
  handleTextChange,
  isValid,
  sendDatas,
}) => {
  const location = useLocation();
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    setShowError(false);
  }, [location]);

  const { t } = useTranslation();

  return (
    <s.SurveyContainer>
      <Container style={{ padding: '0' }}>
        <s.Question style={{ padding: ' 0 1.5rem 0 1.5rem' }}>{t('survey.favorite_food.question')}</s.Question>
        <s.QuestionSubtitle style={{ padding: '0 1.5rem', color: '#0f67fe' }}>
          {t('survey.favorite_food.subtitle')}
        </s.QuestionSubtitle>
        <s.OptionContainer>
          <StyledPaper>
            <StyledFormGroup>
              <QuestionGroup>
                <s.QuestionSubtitle>{t('survey.favorite_food.option1')}</s.QuestionSubtitle>
                <RadioGroup name="question1" value={formData.question1} onChange={handleRadioChange}>
                  <FormControlLabel value="no" control={<Radio />} label={<LabelText>{t('survey.favorite_food.no')}</LabelText>} />
                  <FormControlLabel value="yes" control={<Radio />} label={<LabelText>{t('survey.favorite_food.do')}</LabelText>} />
                </RadioGroup>
                {formData.question1 === 'yes' && (
                  <ConditionalFields>
                    <StyledTextField
                      name="question1Amount"
                      label={t('survey.favorite_food.option1_placeholder')}
                      value={formData.question1Amount}
                      onChange={handleTextChange}
                      fullWidth
                      size="small"
                      InputProps={{
                        endAdornment: <AmountText>{t('survey.favorite_food.option1_unit')}</AmountText>,
                      }}
                    />
                  </ConditionalFields>
                )}
              </QuestionGroup>
              <QuestionGroup>
                <s.QuestionSubtitle>{t('survey.favorite_food.option2')}</s.QuestionSubtitle>
                <RadioGroup name="question2" value={formData.question2} onChange={handleRadioChange}>
                  <FormControlLabel value="no" control={<Radio />} label={<LabelText>{t('survey.favorite_food.no')}</LabelText>} />
                  <FormControlLabel value="yes" control={<Radio />} label={<LabelText>{t('survey.favorite_food.drink')}</LabelText>} />
                </RadioGroup>
                {formData.question2 === 'yes' && (
                  <ConditionalFields>
                    <StyledTextField
                      name="question2Amount1"
                      label={t('survey.favorite_food.option2_placeholder')}
                      value={formData.question2Amount1}
                      onChange={handleTextChange}
                      fullWidth
                      size="small"
                      InputProps={{
                        endAdornment: <AmountText>{t('survey.favorite_food.option2_unit')}</AmountText>,
                      }}
                    />
                    <StyledTextField
                      name="question2Amount2"
                      label="음주량"
                      value={formData.question2Amount2}
                      onChange={handleTextChange}
                      fullWidth
                      size="small"
                      InputProps={{
                        endAdornment: <AmountText>{t('survey.favorite_food.option2_unit2')}</AmountText>,
                      }}
                    />
                  </ConditionalFields>
                )}
              </QuestionGroup>

              <QuestionGroup>
                <s.QuestionSubtitle>{t('survey.favorite_food.option3')}</s.QuestionSubtitle>
                <RadioGroup name="question3" value={formData.question3} onChange={handleRadioChange}>
                  <FormControlLabel value="no" control={<Radio />} label={<LabelText>{t('survey.favorite_food.no')}</LabelText>} />
                  <FormControlLabel value="yes" control={<Radio />} label={<LabelText>{t('survey.favorite_food.drink')}</LabelText>} />
                </RadioGroup>
                {formData.question3 === 'yes' && (
                  <ConditionalFields>
                    <TextField
                      name="question3Amount"
                      label={t('survey.favorite_food.option3_placeholder')}
                      value={formData.question3Amount}
                      onChange={handleTextChange}
                      fullWidth
                      size="small"
                      InputProps={{
                        endAdornment: <AmountText>{t('survey.favorite_food.option3_unit')}</AmountText>,
                      }}
                    />
                  </ConditionalFields>
                )}
              </QuestionGroup>
            </StyledFormGroup>
          </StyledPaper>
        </s.OptionContainer>
      </Container>
      {showError && <ErrorMessage severity="error">{t('unfinishedError')}</ErrorMessage>}
      <s.NextButton
        variant="contained"
        color="primary"
        onClick={() => {
          sendDatas();
          // if (isAllSelected()) {
          //   onNext();
          // } else {
          //   setShowError(true);
          // }
        }}
        style={{ marginTop: 'auto' }}
        disabled={!isValid()}
      >
        {t('button.end')}
      </s.NextButton>
    </s.SurveyContainer>
  );
};

export default FavoriteFood;
