import React, { PureComponent, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Button, Pagination } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import moment from 'moment';
import siloIcon from '../../../asset/img/logo.png';
import * as s from './SurveyListStlyed';
import { surveyApi } from '../../../api/api-survey';
import { Disease, SurveyResultType } from '../surveys/result/ResultType';


interface MainProps {
  userInfo: UserInfoData;
  pageInfo: PageInfoData;
  surveyList: Array<any>;
  latestSurvey: SurveyResultType;

  onClickLogout: () => void;
  onClickSurveyCheck: (surveyId: string) => void;
  onClickDelete: (surveyId: string) => void;
  handlePageChange: (event: React.ChangeEvent<unknown>, value: number) => void;
}

interface UserInfoData {
  name: string;
  phone: string;
  age: string;
}

interface PageInfoData {
  nowSeletedPage: number;
  totalPages: number;
  totalResults: number;
}

export default function SurveyListPresenter(props: MainProps) {
  const {
    userInfo,
    pageInfo,
    surveyList,
    latestSurvey,
    onClickLogout,
    onClickDelete,
    onClickSurveyCheck,
    handlePageChange,
  } = props;

  const { t, i18n } = useTranslation();
  // console.log(latestSurvey);
  // console.log(surveyList);
  const reverseSurveyList = surveyList;
  const navigate = useNavigate();
  const data = reverseSurveyList
    .slice(0)
    .reverse()
    .map((value) => {
      // const AScore = 100 - Math.round(((value.AScore * 2) / 3) * 10);
      // const BScore = 100 - Math.round(((value.BScore * 2) / 3) * 10);
      // const CScore = 100 - Math.round(((value.CScore * 2) / 3) * 10);

      const {AScore, BScore, CScore} = value;

      return { name: moment(value.createdAt).format('MM.DD'), A: AScore, B: BScore, C: CScore };
    });

  const onClickBack = () => {
    navigate('/user-list');
  };

  const diseaseGenerate = ['1개월 이내', '1개월-6개월', '6개월 이상'];
  const bodySymptonText = ['만성피로', '비만', '다한증', '고혈압', '당뇨', '고지혈증'];
  const internalFirText = ['역류성 식도염', '기능성 소화불량', '위궤양', '과민성 대장 증후군', '만성 변비', '설사'];
  const internalSecText = ['만성기침', '알러지', '천식'];
  const internalThrText = ['만성 전립선염', '소변실금'];
  const musclePhysiqueFirText = ['교통사고 후유증', '턱 관절통', '골다공증'];
  const musclePhysiqueSecText = [
    '목통증(항강)',
    '목 디스크',
    '허리통증',
    '허리디스크',
    '척추관협착증',
    '척추측만증',
    '압박골절',
  ];
  const musclePhysiqueThrText = [
    '어깨통증(오십견)',
    '손목통증',
    '손목터널증후군',
    '손가락 통증',
    '수족냉증',
    '골반통',
    '엉덩이 뼈괴사',
    '무릎 퇴행성 관절염',
    '만성발목염좌',
    '족저근막염',
  ];
  const autoimmuneText = ['류마티스', '강직성척추염', '섬유근통', '루프스', '통풍'];
  const nervousSystemText = [
    '두통(긴장성두통, 편두통)',
    '어지러움',
    '중풍',
    '파킨슨질환',
    '치매',
    '안면신경마비',
    '안면경련',
  ];
  const psychiatryText = ['불면증', '우울증', '공황장애', '외상후스트레스(PTSD)'];
  const gynecologicalText = ['생리통', '기능성 자궁출혈', '난임증', '산후질환', '유산후유증', '자궁질환'];
  const pediatricText = ['소아 성장', '소아비만', '자페스펙트럼', '코피', '야제증(밤에 우는 것)'];
  const otorhinolaryngologyText = [
    '안구건조증',
    '이명(메니에르) 및 난청',
    '만성중이염',
    '만성비염(알러지)',
    '축농증',
    '만성구내염',
    '구강건조(쇼그렌증후군)',
  ];
  const skinText = ['아토피피부염', '건선', '두드러기', '탈모'];
  const cancerText = [
    '유방암',
    '난소암',
    '갑상선암',
    '전립선암',
    '간암',
    '폐암',
    '위암',
    '대장암',
    '췌장암',
    '뇌종양',
    '두경부암',
  ];

  const foodHabbitText = ['과식', '폭식', '급하게 먹음', '상한 음식'];
  const lifeHabbitText = ['과로', '야간 근무', '책상 근무', '잘 눕는 습관', '과도한 성생활'];
  const stressText = ['화냄', '슬픔', '우울함', '일상 스트레스'];
  const weatherText = ['찬바람', '무더위', '습한 곳', '건조한 곳에 노출'];
  const externalText = ['교통사고', '낙상', '외상', '세균 감염'];
  const diseaseDecode = ["해당사항없음", "고혈압", "당뇨", "고지혈증", "중풍","암","심장질환","폐질환","소화기질환","간질환","신장질환","알러지","자가면역질환"];
  const reasonDecode = ["특별한이유없음", "과식", "폭식", "급하게먹음", "상한음식", "과로", "야간근무", "책상근무", "잘눕는습관", "과도한성생활", "화냄","슬픔", "우울함", "일상스트레스", "찬바람", "무더위", "습한", "건조한", "교통사고", "낙상", "외상", "세균감염"];

  let reason = '';
  let disease = '';
  let cigaretteDo = '';
  let drinkDo = '';
  let caffeinDo = '';

  if (latestSurvey) {
    // 사용자 정의 증상 질병명
    const userDisease:Disease[] = latestSurvey.disease;
    // const userDisease:[Disease] = JSON.parse(String(latestSurvey.disease)); // Object.values(latestSurvey.disease);
    // console.log(userDisease)
    // console.log(typeof userDisease)
    const diseaseNames = userDisease
      .map(element => element.name)
      .join("  ");
    disease += `${diseaseNames}  `

    // const pastM = JSON.parse(String(latestSurvey.pastMedicalHistory));
    const pastM = latestSurvey.pastMedicalHistory;

    // 이전 질병 기록
    const pastMedicalHistory = Object.values(pastM.pastHistory);
    for (let i = 0; i < pastMedicalHistory.length; i += 1) {
      const code = Number(pastMedicalHistory[i]);
      if (code < diseaseDecode.length) {
        disease += `${diseaseDecode[code]}  `;
      }
    }
    
    // 발병 원인
    const reasonCode = Object.values(pastM.reason);
    for (let i = 0; i < reasonCode.length; i += 1) {
      const code = Number(reasonCode[i]);
      if (code < reasonDecode.length) {
        reason += `${reasonDecode[code]}  `;
      }
    }

    // 발병 원인 기타
    if (pastM.reasonEtc) {
      reason += pastM.reasonEtcDesc;
    }

    // const ffood = JSON.parse(String(latestSurvey.favorateFood));
    const ffood = latestSurvey.favorateFood;

    // 기호식품
    if (!ffood.cigarette) {
      cigaretteDo = t('manage.list.no');
    } else {
      cigaretteDo = t('manage.list.cigarette_do',{num:ffood.cigaretteAmount === null ? "" :ffood.cigaretteAmount.toString()}); // `일 ${ffood.cigaretteAmount.toString()}개비`;
    }

    if (!ffood.alcohol) {
      drinkDo = t('manage.list.no');
    } else {
      drinkDo = t('manage.list.alcohol_do',{num1:ffood.alcoholAmount1 === null ? "" : ffood.alcoholAmount1.toString(), num2:ffood.alcoholAmount2 === null ? "" :ffood.alcoholAmount2.toString()}); // `주 ${ffood.alcoholAmount1.toString()}회, 회 ${ffood.alcoholAmount2.toString()}잔`;
    }

    if (!ffood.caffeine) {
      caffeinDo = t('manage.list.no');
    } else {
      caffeinDo = t('manage.list.caffeine_do',{num:ffood.caffeineAmount === null ? "" :ffood.caffeineAmount.toString()}); // `일 ${ffood.caffeineAmount.toString()}잔`;
    }
  }

  return (
    <s.StyledSurveyListPage maxWidth="sm">
      <s.HeaderContainer>
        <s.BackButton onClick={onClickBack} aria-label='Go back'>
          <ArrowBack />
        </s.BackButton>
        <s.NaviTitleContainer>
          <s.NaviTitleText>
          {t('manage.list.list')}
          </s.NaviTitleText>
        </s.NaviTitleContainer>
      </s.HeaderContainer>
      <s.LogoBox>
        <s.SiloIcon src={siloIcon} />
        <s.CenterText variant="h4">KAIDOC</s.CenterText>
      </s.LogoBox>
      <s.InfoBox sx={{ marginBottom: 5 }}>
        <s.LeftText variant="body2">{t('manage.list.name')} : {userInfo.name}</s.LeftText>
        <s.LeftText variant="body2">{t('manage.list.age')} : {userInfo.age}</s.LeftText>
        <s.LeftText variant="body2">{t('manage.list.phone')} : {userInfo.phone}</s.LeftText>
      </s.InfoBox>

      {surveyList.length === 0 ? (
        <s.CenterText sx={{ marginTop: 10 }}>{t('manage.list.search_not_found')}</s.CenterText>
      ) : (
        <ResponsiveContainer width="100%" height={400}>
          <LineChart
            data={data}
            margin={{
              top: 5,
              bottom: 10,
            }}
          >
            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
            <XAxis dataKey="name" dy={10} />
            <YAxis dx={-10} />
            <Tooltip />
            <Legend
              verticalAlign="top"
              layout="vertical"
              align="right"
              wrapperStyle={{
                paddingLeft: '15px',
              }}
            />
            <Line
              name={t('manage.list.cycle')}
              type="monotone"
              dataKey="A"
              stroke="#97D7D7"
              activeDot={{ r: 8 }}
              strokeWidth={5}
              strokeDasharray="3 3"
            />
            <Line
              name={t('manage.list.create')}
              type="monotone"
              dataKey="B"
              stroke="#D8D4CB"
              strokeWidth={5}
              strokeDasharray="3 3"
            />
            <Line
              name={t('manage.list.balance')}
              type="monotone"
              dataKey="C"
              stroke="#F9C6C1"
              strokeWidth={5}
              strokeDasharray="3 3"
            />
          </LineChart>
        </ResponsiveContainer>
      )}

      {!latestSurvey ? (
        <div />
      ) : (
        <s.SummeryBox>
          <s.SummeryTitle>{t('manage.list.recent_result')}</s.SummeryTitle>
          <s.SummerySubTitle>{t('manage.list.disease')} : </s.SummerySubTitle>
          <span> {disease}</span>
          <br />
          <s.SummerySubTitle>{t('manage.list.time')} : </s.SummerySubTitle>
          <span> {diseaseGenerate[(latestSurvey as SurveyResultType).diseaseGenerateTime]}</span>
          <br />
          <s.SummerySubTitle>{t('manage.list.cause')} : </s.SummerySubTitle>
          <span> {reason}</span>
          <s.foodBox>
            <s.SummerySubTitle>{t('manage.list.favorite')}</s.SummerySubTitle>
            <br />
            <s.SummerySubTitle>{t('manage.list.cigarette')} : </s.SummerySubTitle>
            <span> {cigaretteDo}</span>
            <br />
            <s.SummerySubTitle>{t('manage.list.alcohol')} : </s.SummerySubTitle>
            <span> {drinkDo}</span>
            <br />
            <s.SummerySubTitle>{t('manage.list.caffeine')} : </s.SummerySubTitle>
            <span> {caffeinDo}</span>
            <br />
          </s.foodBox>
        </s.SummeryBox>
      )}

      <s.SurveyList sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
        {surveyList.length === 0 ? (
          <s.CenterText sx={{ marginTop: 10 }}>{t('manage.list.search_not_found')}</s.CenterText>
        ) : (
          surveyList.map((value: any, index: number) => (
            <s.SurveyListItem
              key={value.surveyId}
              disableGutters
              secondaryAction={
                <s.SurveyListButtonBox>
                  <s.StyledButton
                    variant="contained"
                    onClick={() => onClickSurveyCheck(value.surveyId)}
                  >
                    {t('manage.list.check')}
                  </s.StyledButton>
                  <s.StyledButton
                    variant="contained"
                    onClick={() => onClickDelete(value.surveyId)}
                  >
                    {t('manage.list.delete')}
                  </s.StyledButton>
                </s.SurveyListButtonBox>
              }
            >
              <s.LeftText sx={{ marginRight: '20px' }}>{pageInfo.totalResults - index}</s.LeftText>
              <s.SurveyListItemInfo>
                <s.LeftText>{`${moment(value.createdAt).format('yyyy.MM.DD HH:mm')}`}</s.LeftText>
              </s.SurveyListItemInfo>
            </s.SurveyListItem>
          ))
        )}
      </s.SurveyList>
      <Pagination count={pageInfo.totalPages} page={pageInfo.nowSeletedPage} onChange={handlePageChange} />
    </s.StyledSurveyListPage>
  );
}
