const SELECT = 'survey/SELECT';
const RESET = 'survey/RESET';

export interface IFormValue {
  body_symptom_1_1: number | string | boolean | null;
  body_symptom_1_2: number | string | boolean | null;
  body_symptom_1_3: number | string | boolean | null;
  body_symptom_1_4: number | string | boolean | null;
  body_symptom_1_5: number | string | boolean | null;
  body_symptom_1_6: number | string | boolean | null;
  internal_1_1: number | string | boolean | null;
  internal_1_2: number | string | boolean | null;
  internal_1_3: number | string | boolean | null;
  internal_1_4: number | string | boolean | null;
  internal_1_5: number | string | boolean | null;
  internal_1_6: number | string | boolean | null;
  internal_2_1: number | string | boolean | null;
  internal_2_2: number | string | boolean | null;
  internal_2_3: number | string | boolean | null;
  internal_3_1: number | string | boolean | null;
  internal_3_2: number | string | boolean | null;
  muscle_physique_1_1: number | string | boolean | null;
  muscle_physique_1_2: number | string | boolean | null;
  muscle_physique_1_3: number | string | boolean | null;
  muscle_physique_2_1: number | string | boolean | null;
  muscle_physique_2_2: number | string | boolean | null;
  muscle_physique_2_3: number | string | boolean | null;
  muscle_physique_2_4: number | string | boolean | null;
  muscle_physique_2_5: number | string | boolean | null;
  muscle_physique_2_6: number | string | boolean | null;
  muscle_physique_2_7: number | string | boolean | null;
  muscle_physique_3_1: number | string | boolean | null;
  muscle_physique_3_2: number | string | boolean | null;
  muscle_physique_3_3: number | string | boolean | null;
  muscle_physique_3_4: number | string | boolean | null;
  muscle_physique_3_5: number | string | boolean | null;
  muscle_physique_3_6: number | string | boolean | null;
  muscle_physique_3_7: number | string | boolean | null;
  muscle_physique_3_8: number | string | boolean | null;
  muscle_physique_3_9: number | string | boolean | null;
  muscle_physique_3_10: number | string | boolean | null;
  autoimmune_1: number | string | boolean | null;
  autoimmune_2: number | string | boolean | null;
  autoimmune_3: number | string | boolean | null;
  autoimmune_4: number | string | boolean | null;
  autoimmune_5: number | string | boolean | null;
  nervous_system_1: number | string | boolean | null;
  nervous_system_2: number | string | boolean | null;
  nervous_system_3: number | string | boolean | null;
  nervous_system_4: number | string | boolean | null;
  nervous_system_5: number | string | boolean | null;
  nervous_system_6: number | string | boolean | null;
  nervous_system_7: number | string | boolean | null;
  psychiatry_1: number | string | boolean | null;
  psychiatry_2: number | string | boolean | null;
  psychiatry_3: number | string | boolean | null;
  psychiatry_4: number | string | boolean | null;
  gynecological_1: number | string | boolean | null;
  gynecological_2: number | string | boolean | null;
  gynecological_3: number | string | boolean | null;
  gynecological_4: number | string | boolean | null;
  gynecological_5: number | string | boolean | null;
  gynecological_6: number | string | boolean | null;
  pediatric_1: number | string | boolean | null;
  pediatric_2: number | string | boolean | null;
  pediatric_3: number | string | boolean | null;
  pediatric_4: number | string | boolean | null;
  pediatric_5: number | string | boolean | null;
  otorhinolaryngology_1: number | string | boolean | null;
  otorhinolaryngology_2: number | string | boolean | null;
  otorhinolaryngology_3: number | string | boolean | null;
  otorhinolaryngology_4: number | string | boolean | null;
  otorhinolaryngology_5: number | string | boolean | null;
  otorhinolaryngology_6: number | string | boolean | null;
  otorhinolaryngology_7: number | string | boolean | null;
  skin_1: number | string | boolean | null;
  skin_2: number | string | boolean | null;
  skin_3: number | string | boolean | null;
  skin_4: number | string | boolean | null;
  cancer_1: number | string | boolean | null;
  cancer_2: number | string | boolean | null;
  cancer_3: number | string | boolean | null;
  cancer_4: number | string | boolean | null;
  cancer_5: number | string | boolean | null;
  cancer_6: number | string | boolean | null;
  cancer_7: number | string | boolean | null;
  cancer_8: number | string | boolean | null;
  cancer_9: number | string | boolean | null;
  cancer_10: number | string | boolean | null;
  cancer_11: number | string | boolean | null;
  etc_disease: number | string | boolean | null;
  etc_disease_content: number | string | boolean | null;
  etc_reason: number | string | boolean | null;
  etc_reason_content: number | string | boolean | null;
  disease_generate_time: number | string | boolean | null;
  reason_no_reason: number | string | boolean | null;
  reason_food_habbit_1: number | string | boolean | null;
  reason_food_habbit_2: number | string | boolean | null;
  reason_food_habbit_3: number | string | boolean | null;
  reason_food_habbit_4: number | string | boolean | null;
  reason_life_habbit_1: number | string | boolean | null;
  reason_life_habbit_2: number | string | boolean | null;
  reason_life_habbit_3: number | string | boolean | null;
  reason_life_habbit_4: number | string | boolean | null;
  reason_life_habbit_5: number | string | boolean | null;
  reason_stress_1: number | string | boolean | null;
  reason_stress_2: number | string | boolean | null;
  reason_stress_3: number | string | boolean | null;
  reason_stress_4: number | string | boolean | null;
  reason_weather_1: number | string | boolean | null;
  reason_weather_2: number | string | boolean | null;
  reason_weather_3: number | string | boolean | null;
  reason_weather_4: number | string | boolean | null;
  reason_external_1: number | string | boolean | null;
  reason_external_2: number | string | boolean | null;
  reason_external_3: number | string | boolean | null;
  reason_external_4: number | string | boolean | null;
  name: number | string | boolean | null;
  gender: number | string | boolean | null;
  age: number | string | boolean | null;
  phone: number | string | boolean | null;
  exist_in_day: number | string | boolean | null;
  height: number | string | boolean | null;
  weight: number | string | boolean | null;
  heart_rate: number | string | boolean | null;
  blood_pressure_min: number | string | boolean | null;
  blood_pressure_max: number | string | boolean | null;
  q11: number | string | boolean | null;
  q12_neck: number | string | boolean | null;
  q12_waist: number | string | boolean | null;
  q12_calf: number | string | boolean | null;
  q21: number | string | boolean | null;
  q22: number | string | boolean | null;
  q23: number | string | boolean | null;
  q24: number | string | boolean | null;
  q25: number | string | boolean | null;
  q31: number | string | boolean | null;
  q32: number | string | boolean | null;
  q33: number | string | boolean | null;
  q34: number | string | boolean | null;
  q35: number | string | boolean | null;
  q41: number | string | boolean | null;
  q51: number | string | boolean | null;
  q61: number | string | boolean | null;
  q62: number | string | boolean | null;
  q63: number | string | boolean | null;
  q64: number | string | boolean | null;
  q71: number | string | boolean | null;
  q72: number | string | boolean | null;
  q73: number | string | boolean | null;
  q74: number | string | boolean | null;
  q75: number | string | boolean | null;
  q76: number | string | boolean | null;
  q81: number | string | boolean | null;
  q91: number | string | boolean | null;
  q92: number | string | boolean | null;
  q93: number | string | boolean | null;
  q94: number | string | boolean | null;
  q95: number | string | boolean | null;
  q96: number | string | boolean | null;
  q97: number | string | boolean | null;
  favorite_food_cigarette: number | string | boolean | null;
  favorite_food_cigarette_1: number | string | boolean | null;
  favorite_food_drink: number | string | boolean | null;
  favorite_food_drink_1: number | string | boolean | null;
  favorite_food_drink_2: number | string | boolean | null;
  favorite_food_caffeine: number | string | boolean | null;
  favorite_food_caffeine_1: number | string | boolean | null;
  past_medical_history_1: number | string | boolean | null;
  past_medical_history_2: number | string | boolean | null;
  past_medical_history_3: number | string | boolean | null;
  past_medical_history_4: number | string | boolean | null;
  past_medical_history_5: number | string | boolean | null;
  past_medical_history_6: number | string | boolean | null;
  past_medical_history_7: number | string | boolean | null;
  past_medical_history_8: number | string | boolean | null;
  past_medical_history_9: number | string | boolean | null;
  past_medical_history_10: number | string | boolean | null;
  past_medical_history_11: number | string | boolean | null;
  past_medical_history_12: number | string | boolean | null;
  past_medical_history_13: number | string | boolean | null;
  past_medical_history_13_content: number | string | boolean | null;
  past_medical_history_14: number | string | boolean | null;
  past_medical_history_14_content: number | string | boolean | null;
  past_medical_history_15: number | string | boolean | null;
}

export const selectItem = (qTitle: string, value: number | string | boolean | null) => ({
  type: SELECT,
  qTitle,
  value,
});

export const restSurveyStore = () => ({
  type: RESET,
});

const initialState = {
  body_symptom_1_1: false,
  body_symptom_1_2: false,
  body_symptom_1_3: false,
  body_symptom_1_4: false,
  body_symptom_1_5: false,
  body_symptom_1_6: false,
  internal_1_1: false,
  internal_1_2: false,
  internal_1_3: false,
  internal_1_4: false,
  internal_1_5: false,
  internal_1_6: false,
  internal_2_1: false,
  internal_2_2: false,
  internal_2_3: false,
  internal_3_1: false,
  internal_3_2: false,
  muscle_physique_1_1: false,
  muscle_physique_1_2: false,
  muscle_physique_1_3: false,
  muscle_physique_2_1: false,
  muscle_physique_2_2: false,
  muscle_physique_2_3: false,
  muscle_physique_2_4: false,
  muscle_physique_2_5: false,
  muscle_physique_2_6: false,
  muscle_physique_2_7: false,
  muscle_physique_3_1: false,
  muscle_physique_3_2: false,
  muscle_physique_3_3: false,
  muscle_physique_3_4: false,
  muscle_physique_3_5: false,
  muscle_physique_3_6: false,
  muscle_physique_3_7: false,
  muscle_physique_3_8: false,
  muscle_physique_3_9: false,
  muscle_physique_3_10: false,
  autoimmune_1: false,
  autoimmune_2: false,
  autoimmune_3: false,
  autoimmune_4: false,
  autoimmune_5: false,
  nervous_system_1: false,
  nervous_system_2: false,
  nervous_system_3: false,
  nervous_system_4: false,
  nervous_system_5: false,
  nervous_system_6: false,
  nervous_system_7: false,
  psychiatry_1: false,
  psychiatry_2: false,
  psychiatry_3: false,
  psychiatry_4: false,
  gynecological_1: false,
  gynecological_2: false,
  gynecological_3: false,
  gynecological_4: false,
  gynecological_5: false,
  gynecological_6: false,
  pediatric_1: false,
  pediatric_2: false,
  pediatric_3: false,
  pediatric_4: false,
  pediatric_5: false,
  otorhinolaryngology_1: false,
  otorhinolaryngology_2: false,
  otorhinolaryngology_3: false,
  otorhinolaryngology_4: false,
  otorhinolaryngology_5: false,
  otorhinolaryngology_6: false,
  otorhinolaryngology_7: false,
  skin_1: false,
  skin_2: false,
  skin_3: false,
  skin_4: false,
  cancer_1: false,
  cancer_2: false,
  cancer_3: false,
  cancer_4: false,
  cancer_5: false,
  cancer_6: false,
  cancer_7: false,
  cancer_8: false,
  cancer_9: false,
  cancer_10: false,
  cancer_11: false,
  etc_disease: false,
  etc_disease_content: '',
  etc_reason: false,
  etc_reason_content: '',
  disease_generate_time: -1,
  reason_no_reason: false,
  reason_food_habbit_1: false,
  reason_food_habbit_2: false,
  reason_food_habbit_3: false,
  reason_food_habbit_4: false,
  reason_life_habbit_1: false,
  reason_life_habbit_2: false,
  reason_life_habbit_3: false,
  reason_life_habbit_4: false,
  reason_life_habbit_5: false,
  reason_stress_1: false,
  reason_stress_2: false,
  reason_stress_3: false,
  reason_stress_4: false,
  reason_weather_1: false,
  reason_weather_2: false,
  reason_weather_3: false,
  reason_weather_4: false,
  reason_external_1: false,
  reason_external_2: false,
  reason_external_3: false,
  reason_external_4: false,
  name: '',
  gender: '',
  age: '',
  phone: '',
  exist_in_day: false,
  height: '',
  weight: '',
  heart_rate: '',
  blood_pressure_min: '',
  blood_pressure_max: '',
  q11: -1,
  q12_neck: '',
  q12_waist: '',
  q12_calf: '',
  q21: -1,
  q22: false,
  q23: false,
  q24: false,
  q25: false,
  q31: -1,
  q32: false,
  q33: false,
  q34: false,
  q35: false,
  q41: -1,
  q51: -1,
  q61: false,
  q62: false,
  q63: false,
  q64: -1,
  q71: false,
  q72: false,
  q73: false,
  q74: false,
  q75: false,
  q76: false,
  q81: -1,
  q91: false,
  q92: false,
  q93: false,
  q94: false,
  q95: false,
  q96: false,
  q97: false,
  favorite_food_cigarette: '',
  favorite_food_cigarette_1: '',
  favorite_food_drink: '',
  favorite_food_drink_1: '',
  favorite_food_drink_2: '',
  favorite_food_caffeine: '',
  favorite_food_caffeine_1: '',
  past_medical_history_1: false,
  past_medical_history_2: false,
  past_medical_history_3: false,
  past_medical_history_4: false,
  past_medical_history_5: false,
  past_medical_history_6: false,
  past_medical_history_7: false,
  past_medical_history_8: false,
  past_medical_history_9: false,
  past_medical_history_10: false,
  past_medical_history_11: false,
  past_medical_history_12: false,
  past_medical_history_13: false,
  past_medical_history_13_content: '',
  past_medical_history_14: false,
  past_medical_history_14_content: '',
  past_medical_history_15: false,
};

// eslint-disable-next-line default-param-last
function survey(state = initialState, action: any) {
  switch (action.type) {
    case SELECT:
      return {
        ...state,
        [action.qTitle]: action.value,
      };
    case RESET:
      return {
        body_symptom_1_1: false,
        body_symptom_1_2: false,
        body_symptom_1_3: false,
        body_symptom_1_4: false,
        body_symptom_1_5: false,
        body_symptom_1_6: false,
        internal_1_1: false,
        internal_1_2: false,
        internal_1_3: false,
        internal_1_4: false,
        internal_1_5: false,
        internal_1_6: false,
        internal_2_1: false,
        internal_2_2: false,
        internal_2_3: false,
        internal_3_1: false,
        internal_3_2: false,
        muscle_physique_1_1: false,
        muscle_physique_1_2: false,
        muscle_physique_1_3: false,
        muscle_physique_2_1: false,
        muscle_physique_2_2: false,
        muscle_physique_2_3: false,
        muscle_physique_2_4: false,
        muscle_physique_2_5: false,
        muscle_physique_2_6: false,
        muscle_physique_2_7: false,
        muscle_physique_3_1: false,
        muscle_physique_3_2: false,
        muscle_physique_3_3: false,
        muscle_physique_3_4: false,
        muscle_physique_3_5: false,
        muscle_physique_3_6: false,
        muscle_physique_3_7: false,
        muscle_physique_3_8: false,
        muscle_physique_3_9: false,
        muscle_physique_3_10: false,
        autoimmune_1: false,
        autoimmune_2: false,
        autoimmune_3: false,
        autoimmune_4: false,
        autoimmune_5: false,
        nervous_system_1: false,
        nervous_system_2: false,
        nervous_system_3: false,
        nervous_system_4: false,
        nervous_system_5: false,
        nervous_system_6: false,
        nervous_system_7: false,
        psychiatry_1: false,
        psychiatry_2: false,
        psychiatry_3: false,
        psychiatry_4: false,
        gynecological_1: false,
        gynecological_2: false,
        gynecological_3: false,
        gynecological_4: false,
        gynecological_5: false,
        gynecological_6: false,
        pediatric_1: false,
        pediatric_2: false,
        pediatric_3: false,
        pediatric_4: false,
        pediatric_5: false,
        otorhinolaryngology_1: false,
        otorhinolaryngology_2: false,
        otorhinolaryngology_3: false,
        otorhinolaryngology_4: false,
        otorhinolaryngology_5: false,
        otorhinolaryngology_6: false,
        otorhinolaryngology_7: false,
        skin_1: false,
        skin_2: false,
        skin_3: false,
        skin_4: false,
        cancer_1: false,
        cancer_2: false,
        cancer_3: false,
        cancer_4: false,
        cancer_5: false,
        cancer_6: false,
        cancer_7: false,
        cancer_8: false,
        cancer_9: false,
        cancer_10: false,
        cancer_11: false,
        etc_disease: false,
        etc_disease_content: '',
        etc_reason: false,
        etc_reason_content: '',
        disease_generate_time: -1,
        reason_no_reason: false,
        reason_food_habbit_1: false,
        reason_food_habbit_2: false,
        reason_food_habbit_3: false,
        reason_food_habbit_4: false,
        reason_life_habbit_1: false,
        reason_life_habbit_2: false,
        reason_life_habbit_3: false,
        reason_life_habbit_4: false,
        reason_life_habbit_5: false,
        reason_stress_1: false,
        reason_stress_2: false,
        reason_stress_3: false,
        reason_stress_4: false,
        reason_weather_1: false,
        reason_weather_2: false,
        reason_weather_3: false,
        reason_weather_4: false,
        reason_external_1: false,
        reason_external_2: false,
        reason_external_3: false,
        reason_external_4: false,
        name: '',
        gender: '',
        age: '',
        phone: '',
        exist_in_day: false,
        height: '',
        weight: '',
        heart_rate: '',
        blood_pressure_min: '',
        blood_pressure_max: '',
        q11: -1,
        q12_neck: '',
        q12_waist: '',
        q12_calf: '',
        q21: -1,
        q22: false,
        q23: false,
        q24: false,
        q25: false,
        q31: -1,
        q32: false,
        q33: false,
        q34: false,
        q35: false,
        q41: -1,
        q51: -1,
        q61: false,
        q62: false,
        q63: false,
        q64: -1,
        q71: false,
        q72: false,
        q73: false,
        q74: false,
        q75: false,
        q76: false,
        q81: -1,
        q91: false,
        q92: false,
        q93: false,
        q94: false,
        q95: false,
        q96: false,
        q97: false,
        favorite_food_cigarette: '',
        favorite_food_cigarette_1: '',
        favorite_food_drink: '',
        favorite_food_drink_1: '',
        favorite_food_drink_2: '',
        favorite_food_caffeine: '',
        favorite_food_caffeine_1: '',
        past_medical_history_1: false,
        past_medical_history_2: false,
        past_medical_history_3: false,
        past_medical_history_4: false,
        past_medical_history_5: false,
        past_medical_history_6: false,
        past_medical_history_7: false,
        past_medical_history_8: false,
        past_medical_history_9: false,
        past_medical_history_10: false,
        past_medical_history_11: false,
        past_medical_history_12: false,
        past_medical_history_13: false,
        past_medical_history_13_content: '',
        past_medical_history_14: false,
        past_medical_history_14_content: '',
        past_medical_history_15: false,
      };
    default:
      return state;
  }
}

export default survey;
