/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Container, Paper, Typography, FormGroup, FormControlLabel, Checkbox, TextField } from '@mui/material';
import * as s from './NewSurveyStyled';

const StyledPaper = styled(Paper)`
  padding: 0 1.5rem 1.5rem 1.5rem;
  margin: 1.5rem 0;
  overflow-y: auto;
  max-height: 45vh;
  width: 100%;
  box-sizing: border-box;
`;

const StyledFormGroup = styled(FormGroup)`
  margin-top: 16px;
`;

const CategoryTitle = styled(Typography)`
  margin-top: 16px;
  margin-bottom: 8px;
  font-weight: bold;
  font-family: 'Noto Sans KR', sans-serif;
`;

const SubCategory = styled.div`
  margin-left: 24px;
  font-family: 'Noto Sans KR', sans-serif;
`;

const LabelText = styled(Typography)`
  font-family: 'Noto Sans KR', sans-serif;
`;

const StyledTextField = styled(TextField)`
  margin-top: 8px;
  margin-left: 24px;
`;

interface DiseaseTimeTriggerProps {
  onNext: () => void;
  symptoms: { [key: string]: boolean };
  selectedOption: string;
  otherReason: string;
  isNextDisabled: boolean;
  handleSelect: (option: string) => void;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleOtherReasonChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const DiseaseTimeTrigger: React.FC<DiseaseTimeTriggerProps> = ({
  onNext,
  symptoms,
  selectedOption,
  otherReason,
  isNextDisabled,
  handleSelect,
  handleChange,
  handleOtherReasonChange,
}) => {
  const { t } = useTranslation();

  return (
    <s.SurveyContainer>
      <Container style={{ padding: '0' }}>
        <s.Question style={{ padding: ' 0 1.5rem 1rem 1.5rem', textAlign: 'left', fontSize: 'clamp(1rem, 2vw, 1.2rem)' }}>{t('survey.disease_time_trigger.question1')}</s.Question>
        <s.OptionContainer>
          <s.Options>
            <s.OptionButton
              type="button"
              variant={selectedOption === '1' ? 'contained' : 'outlined'}
              onClick={() => {
                handleSelect('1');
              }}
            >
              <s.OptionSubtitle>{t('survey.disease_time_trigger.option1')}</s.OptionSubtitle>
            </s.OptionButton>
            <s.OptionButton
              type="button"
              variant={selectedOption === '2' ? 'contained' : 'outlined'}
              onClick={() => {
                handleSelect('2');
              }}
            >
              <s.OptionSubtitle>{t('survey.disease_time_trigger.option2')}</s.OptionSubtitle>
            </s.OptionButton>
            <s.OptionButton
              type="button"
              variant={selectedOption === '3' ? 'contained' : 'outlined'}
              onClick={() => {
                handleSelect('3');
              }}
            >
              <s.OptionSubtitle>{t('survey.disease_time_trigger.option3')}</s.OptionSubtitle>
            </s.OptionButton>
          </s.Options>
        </s.OptionContainer>
        <s.Question style={{ padding: ' 3rem 1.5rem 0 1.5rem', textAlign: 'left', fontSize: 'clamp(1rem, 2vw, 1.2rem)' }}>{t('survey.disease_time_trigger.question2')}</s.Question>
        <s.OptionContainer>
          <StyledPaper>
            <StyledFormGroup>
              <FormControlLabel
                control={<Checkbox checked={symptoms.특별한이유없음} onChange={handleChange} name="특별한이유없음" />}
                label={<LabelText>{t('survey.disease_time_trigger.form_option1')}</LabelText>}
              />
              <CategoryTitle variant="subtitle1">{t('survey.disease_time_trigger.subtitle1')}</CategoryTitle>
              <SubCategory>
                <FormControlLabel
                  control={<Checkbox checked={symptoms.과식} onChange={handleChange} name="과식" />}
                  label={<LabelText>{t('survey.disease_time_trigger.form_option2')}</LabelText>}
                />
                <FormControlLabel
                  control={<Checkbox checked={symptoms.폭식} onChange={handleChange} name="폭식" />}
                  label={<LabelText>{t('survey.disease_time_trigger.form_option3')}</LabelText>}
                />
                <FormControlLabel
                  control={<Checkbox checked={symptoms.급하게먹음} onChange={handleChange} name="급하게먹음" />}
                  label={<LabelText>{t('survey.disease_time_trigger.form_option4')}</LabelText>}
                />
                <FormControlLabel
                  control={<Checkbox checked={symptoms.상한음식} onChange={handleChange} name="상한음식" />}
                  label={<LabelText>{t('survey.disease_time_trigger.form_option5')}</LabelText>}
                />
              </SubCategory>
              <CategoryTitle variant="subtitle1">{t('survey.disease_time_trigger.subtitle2')}</CategoryTitle>
              <SubCategory>
                <FormControlLabel
                  control={<Checkbox checked={symptoms.과로} onChange={handleChange} name="과로" />}
                  label={<LabelText>{t('survey.disease_time_trigger.form_option6')}</LabelText>}
                />
                <FormControlLabel
                  control={<Checkbox checked={symptoms.야간근무} onChange={handleChange} name="야간근무" />}
                  label={<LabelText>{t('survey.disease_time_trigger.form_option7')}</LabelText>}
                />
                <FormControlLabel
                  control={<Checkbox checked={symptoms.책상근무} onChange={handleChange} name="책상근무" />}
                  label={<LabelText>{t('survey.disease_time_trigger.form_option8')}</LabelText>}
                />
                <FormControlLabel
                  control={<Checkbox checked={symptoms.잘눕는습관} onChange={handleChange} name="잘눕는습관" />}
                  label={<LabelText>{t('survey.disease_time_trigger.form_option9')}</LabelText>}
                />
                <FormControlLabel
                  control={<Checkbox checked={symptoms.과도한성생활} onChange={handleChange} name="과도한성생활" />}
                  label={<LabelText>{t('survey.disease_time_trigger.form_option10')}</LabelText>}
                />
              </SubCategory>
              <CategoryTitle variant="subtitle1">{t('survey.disease_time_trigger.subtitle3')}</CategoryTitle>
              <SubCategory>
                <FormControlLabel
                  control={<Checkbox checked={symptoms.화냄} onChange={handleChange} name="화냄" />}
                  label={<LabelText>{t('survey.disease_time_trigger.form_option11')}</LabelText>}
                />
                <FormControlLabel
                  control={<Checkbox checked={symptoms.슬픔} onChange={handleChange} name="슬픔" />}
                  label={<LabelText>{t('survey.disease_time_trigger.form_option12')}</LabelText>}
                />
                <FormControlLabel
                  control={<Checkbox checked={symptoms.우울함} onChange={handleChange} name="우울함" />}
                  label={<LabelText>{t('survey.disease_time_trigger.form_option13')}</LabelText>}
                />
                <FormControlLabel
                  control={<Checkbox checked={symptoms.일상스트레스} onChange={handleChange} name="일상스트레스" />}
                  label={<LabelText>일{t('survey.disease_time_trigger.form_option14')}</LabelText>}
                />
              </SubCategory>
              <CategoryTitle variant="subtitle1">{t('survey.disease_time_trigger.subtitle4')}</CategoryTitle>
              <SubCategory>
                <FormControlLabel
                  control={<Checkbox checked={symptoms.찬바람} onChange={handleChange} name="찬바람" />}
                  label={<LabelText>{t('survey.disease_time_trigger.form_option15')}</LabelText>}
                />
                <FormControlLabel
                  control={<Checkbox checked={symptoms.무더위} onChange={handleChange} name="무더위" />}
                  label={<LabelText>{t('survey.disease_time_trigger.form_option16')}</LabelText>}
                />
                <FormControlLabel
                  control={<Checkbox checked={symptoms.습한} onChange={handleChange} name="습한" />}
                  label={<LabelText>{t('survey.disease_time_trigger.form_option17')}</LabelText>}
                />
                <FormControlLabel
                  control={<Checkbox checked={symptoms.건조한} onChange={handleChange} name="건조한" />}
                  label={<LabelText>{t('survey.disease_time_trigger.form_option18')}</LabelText>}
                />
              </SubCategory>
              <CategoryTitle variant="subtitle1">{t('survey.disease_time_trigger.subtitle5')}</CategoryTitle>
              <SubCategory>
                <FormControlLabel
                  control={<Checkbox checked={symptoms.교통사고} onChange={handleChange} name="교통사고" />}
                  label={<LabelText>{t('survey.disease_time_trigger.form_option19')}</LabelText>}
                />
                <FormControlLabel
                  control={<Checkbox checked={symptoms.낙상} onChange={handleChange} name="낙상" />}
                  label={<LabelText>{t('survey.disease_time_trigger.form_option20')}</LabelText>}
                />
                <FormControlLabel
                  control={<Checkbox checked={symptoms.외상} onChange={handleChange} name="외상" />}
                  label={<LabelText>{t('survey.disease_time_trigger.form_option21')}</LabelText>}
                />
                <FormControlLabel
                  control={<Checkbox checked={symptoms.세균감염} onChange={handleChange} name="세균 감염" />}
                  label={<LabelText>{t('survey.disease_time_trigger.form_option22')}</LabelText>}
                />
              </SubCategory>
              <FormControlLabel
                control={<Checkbox checked={symptoms.기타} onChange={handleChange} name="기타" />}
                label={t('survey.disease_time_trigger.form_option23')}
              />
              {symptoms.기타 && (
                <StyledTextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  placeholder={t('survey.disease_time_trigger.other_placeholder')}
                  value={otherReason}
                  onChange={handleOtherReasonChange}
                />
              )}
            </StyledFormGroup>
          </StyledPaper>
        </s.OptionContainer>
      </Container>
      <s.NextButton
        variant="contained"
        color="primary"
        onClick={() => {
          onNext();
        }}
        style={{ marginTop: 'auto' }}
        disabled={isNextDisabled}
      >
        {t('button.next')}
      </s.NextButton>
    </s.SurveyContainer>
  );
};

export default DiseaseTimeTrigger;
