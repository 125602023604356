import React, { useEffect } from 'react';
import { SurveyTitle, BtnPreNext, SurveySingleSelect, SurveyMultiSelectWithNoProblem } from '../common/Survey';
import { ISurveyDictType } from '../common/SurveyTypes';
import '../common/Survey.scss';

interface IDigSlpPageProps {
  dictObject: ISurveyDictType;
  validForm: IValidData;
}

interface IValidData {
  q21: boolean;
  q31: boolean;
}
export default function SurveyDigSlpPresenter(Props: IDigSlpPageProps) {
  const { validForm, dictObject } = Props;

  const q21 = {
    qTitle: 'q21',
    title: `2-1. 평소 소화 상태`,
    questions: ['소화기능 문제 없음', '간헐적 소화불량', '잦은 소화불량', '소화불량 아주 심함'],
  };
  const q22to25 = {
    qTitles: ['q22', 'q23', 'q24', 'q25'],
    title: '2-2. 식욕 및 식사량 평가 (해당사항을 모두 체크해주세요)',
    questions: ['평소 식욕 없음', '1회 식사량이 적음', '하루 식사 횟수가 2회 이하', '해당 사항 없음'],
  };
  const q31 = {
    qTitle: 'q31',
    title: `3-1. 평소 수면 상태`,
    questions: ['수면상태 문제 없음 ', '간헐적 수면장애', '잦은 수면장애', '수면장애 아주 심함'],
  };
  const q32to35 = {
    qTitles: ['q32', 'q33', 'q34', 'q35'],
    title: '3-2. 평소 수면장애 평가 (해당사항을 모두 체크해주세요)',
    questions: [
      '잠자리에 들고 일어나는 시간 불규칙함',
      '총 수면시간이 불규칙함',
      '잠자리에 드는 시간이 밤 12시를 넘김',
      '해당 사항 없음',
    ],
  };

  return (
    <>
      <SurveyTitle title="2. 소화기능" />
      <SurveySingleSelect surveyItem={q21} defaultValue={dictObject.q21} />
      <SurveyMultiSelectWithNoProblem
        surveyItem={q22to25}
        defaultValues={[dictObject.q22, dictObject.q23, dictObject.q24, dictObject.q25]}
      />
      <SurveyTitle title="3. 수면상태" />
      <SurveySingleSelect surveyItem={q31} defaultValue={dictObject.q31} />
      <SurveyMultiSelectWithNoProblem
        surveyItem={q32to35}
        defaultValues={[dictObject.q32, dictObject.q33, dictObject.q34, dictObject.q35]}
      />
      <BtnPreNext linkPre="../survey-bdyshp" linkNex="../survey-urins" disabled={!(validForm.q21 && validForm.q31)} />
    </>
  );
}
