import React from 'react';
import styled from 'styled-components';
import SideMenu from './SideMenu';
import OrientalSection from './OrientalSection';

const OrientalPresenter: React.FC = () => {
  return (
    <PageWrapper>
      <SideMenu />
      <OrientalSection />
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: row;
  height: 100%;
  min-height: 100vh;
`;

export default OrientalPresenter;
