/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import {
  Container,
  Paper,
  Typography,
  FormGroup,
  FormControlLabel,
  TextField,
  Radio,
  RadioGroup,
  Alert,
} from '@mui/material';
import * as s from './NewSurveyStyled';

const StyledPaper = styled(Paper)`
  padding: 0 1.5rem 1.5rem 1.5rem;
  margin: 1.5rem 0;
  width: 100%;
  box-sizing: border-box;
`;

const StyledFormGroup = styled(FormGroup)`
  margin-top: 16px;
`;

const LabelText = styled(Typography)`
  font-family: 'Noto Sans KR', sans-serif;
`;

const StyledTextField = styled(TextField)`
  margin-top: 8px;
  margin-left: 24px;
  width: 80%;
`;

const QuestionGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 1rem;
`;

const ConditionalFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 8px;
`;

const AmountText = styled(Typography)`
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 0.75rem;
  width: 4rem;
`;

const ErrorMessage = styled(Alert)`
  margin-top: 10px;
  font-family: 'Noto Sans KR', sans-serif;
`;

interface FavoriteFoodProps {
  formData: {
    cigarette: boolean;
    cigaretteAmount: number,
    alcohol: boolean,
    alcoholAmount1: number,
    alcoholAmount2: number,
    caffeine: boolean,
    caffeineAmount: number
  };
  // isAllSelected: () => boolean;
}

const FavoriteFoodHistory: React.FC<FavoriteFoodProps> = ({ formData}) => {
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <s.SurveyContainer>
      <Container style={{ padding: '0' }}>
        <s.OptionContainer style={{ padding: '0' }}>
          <StyledPaper>
            <StyledFormGroup>
              <QuestionGroup>
                <s.QuestionSubtitle>{t('survey.favorite_food.option1')}</s.QuestionSubtitle>
                <RadioGroup name="question1" value={formData.cigarette}>
                  <FormControlLabel value={false} control={<Radio />} label={<LabelText>{t('survey.favorite_food.no')}</LabelText>} />
                  <FormControlLabel value control={<Radio />} label={<LabelText>{t('survey.favorite_food.do')}</LabelText>} />
                </RadioGroup>
                {formData.cigarette === true && (
                  <ConditionalFields>
                    <StyledTextField
                      name="question1Amount"
                      label={t('survey.favorite_food.option1_placeholder')}
                      value={formData.cigaretteAmount}
                      fullWidth
                      size="small"
                      InputProps={{
                        endAdornment: <AmountText>{t('survey.favorite_food.option1_unit')}</AmountText>,
                      }}
                    />
                  </ConditionalFields>
                )}
              </QuestionGroup>
              <QuestionGroup>
                <s.QuestionSubtitle>{t('survey.favorite_food.option2')}</s.QuestionSubtitle>
                <RadioGroup name="question2" value={formData.alcohol}>
                  <FormControlLabel value={false} control={<Radio />} label={<LabelText>{t('survey.favorite_food.no')}</LabelText>} />
                  <FormControlLabel value control={<Radio />} label={<LabelText>{t('survey.favorite_food.drink')}</LabelText>} />
                </RadioGroup>
                {formData.alcohol === true && (
                  <ConditionalFields>
                    <StyledTextField
                      name="question2Amount1"
                      label={t('survey.favorite_food.option2_placeholder')}
                      value={formData.alcoholAmount1}
                      fullWidth
                      size="small"
                      InputProps={{
                        endAdornment: <AmountText>{t('survey.favorite_food.option2_unit')}</AmountText>,
                      }}
                    />
                    <StyledTextField
                      name="question2Amount2"
                      label="음주량"
                      value={formData.alcoholAmount2}
                      fullWidth
                      size="small"
                      InputProps={{
                        endAdornment: <AmountText>{t('survey.favorite_food.option2_unit2')}</AmountText>,
                      }}
                    />
                  </ConditionalFields>
                )}
              </QuestionGroup>

              <QuestionGroup>
                <s.QuestionSubtitle>{t('survey.favorite_food.option3')}</s.QuestionSubtitle>
                <RadioGroup name="question3" value={formData.caffeine}>
                  <FormControlLabel value={false} control={<Radio />} label={<LabelText>{t('survey.favorite_food.no')}</LabelText>} />
                  <FormControlLabel value control={<Radio />} label={<LabelText>{t('survey.favorite_food.drink')}</LabelText>} />
                </RadioGroup>
                {formData.caffeine === true && (
                  <ConditionalFields>
                    <TextField
                      name="question3Amount"
                      label={t('survey.favorite_food.option3_placeholder')}
                      value={formData.caffeineAmount}
                      fullWidth
                      size="small"
                      InputProps={{
                        endAdornment: <AmountText>{t('survey.favorite_food.option3_unit')}</AmountText>,
                      }}
                    />
                  </ConditionalFields>
                )}
              </QuestionGroup>
            </StyledFormGroup>
          </StyledPaper>
        </s.OptionContainer>
      </Container>
    </s.SurveyContainer>
  );
};

export default FavoriteFoodHistory;
