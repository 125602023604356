import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguaeDetector from 'i18next-browser-languagedetector';
import { indigo } from '@mui/material/colors';
import { Favorite } from '@mui/icons-material';
import { warning } from 'react-router/lib/router';

i18n
  .use(LanguaeDetector) // 사용자 언어 탐지
  .use(initReactI18next) // i18n 객체를 react-18next에 전달
  .init({
    // for all options read: https://www.i18next.com/overview/configuration-options
    debug: true,
    lng: 'ko',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: {
          // 번역본 쓸 공간
          manage: {
            basic_info: {
              name: 'Name',
              age: 'Age',
              phone: 'Phone Number',
              gender: 'Gender',
              height: 'Height',
              weight: 'Weight',
              sys: 'Systolic Blood Pressure',
              diag: 'Diastolic Blood Pressure',
            },
            admin: 'Admin',
            see_result: 'View User Results',
            logout: 'Logout',
            login: 'Login',
            find_pw: 'Find password',
            signup: 'Sign up',
            id: 'ID',
            pw: 'PW',
            list: {
              id: 'ID',
              name: 'Name',
              list: 'Diagnosis List',
              age: 'Age',
              phone: 'Phone Number',
              create: 'B. Energy Generation',
              cycle: 'A. Energy Circulation',
              balance: 'C. Energy Balance',
              search_not_found: 'No search results found.',
              recent: 'Recent Test',
              recent_result: 'Recent Test Results',
              check: 'Check',
              delete: 'Delete',
              disease: 'Symptoms and Diseases',
              time: 'Onset Time',
              cause: 'Onset Cause',
              favorite: 'Favorite Foods',
              cigarette: 'Smoke',
              cigarette_do: '{{num}} cigarettes/day',
              alcohol: 'Drink alcohol more than once a week',
              alcohol_do: '{{num1}} times a week, {{num2}} glasses each time',
              caffeine: 'Drink coffee or green tea',
              caffeine_do: '{{num}} cups/day',
              no: 'No',
            },
            // 결과페이지 - 헬스케어
            health: {
              etc: 'Others',
              none: 'None',
              moonth: 'Months',
              order: 'Sort by severity of symptoms',
            },
            energy_eval: 'Survey Records',
            basic_survey: 'Basic Survey',
            symptom: 'Disease and Symptoms',
            cause: 'Onset Time and Cause',
            history: 'Medical History',
            med: 'Experience with Weight Loss Medication',
            favorite: 'Favorite Foods',
            delete: 'Delete',
            basic: {
              q1: 'Q1. Is your pants size larger than 35 inches (for men) or 33 inches (for women)',
              q2: 'Q2. Which part of your body is more developed, upper body or lower body?',
              q3: 'Q3. If you wrap your fingers around the thickest part of your calf, how close are your fingers?',
              q4: 'Q4. How do you perceive the spacing between your ribs?',
              q5: 'Q5. What is your pulse rate measured over 10 seconds?',
              q6: 'Q6. How is your appetite in general?',
              q7: 'Q7. How many meals do you eat per day?',
              q8: 'Q8. How much do you usually eat per meal?',
              q9: 'Q9. Do you have digestive issues?',
              q10: 'Q10. Reffering to the symptoms of poor sleep, select how frequently you have difficulty achieving restful sleep?',
              q11: 'Q11. When do you go to bed?',
              q12: 'Q12. Do you go to bed and wake up at set times?',
              q13: 'Q13. Is your total sleep duration consistent each day?',
              q14: 'Q14. How do you feel upon waking up/how active are you normally?',
              q15: 'Q15. Which of the following best describes your usual personality?',
              q16: 'Q16. Body temperature',
              q17: 'Q17. Body temperature(face/knees)',
              q18: 'Q18. Defecation',
              q19: 'Q19. Urination',
              a11: 'Yes (more than)',
              a12: 'No (less than)',
              a13: 'Not sure',
              a21: 'Upper body',
              a22: 'Lower body',
              a23: 'Similar',
              a31: 'Calves are larger',
              a32: 'Fits',
              a33: 'Loose',
              a41: 'Obtuse',
              a42: 'Right',
              a43: 'Acute',
              a44: 'Not sure',
              a51: '10(60bpm) or less',
              a52: '11-16(61-99bpm)',
              a53: '17(100bpm) or more',
              a54: 'Not sure',
              a61: 'No appetite',
              a62: 'Normal',
              a63: 'Strong',
              a71: '2 or less',
              a72: '3',
              a73: 'More than 4',
              a81: 'Less than one serving',
              a82: 'About one serving',
              a83: 'More than one serving',
              a91: 'No issues',
              a92: 'Occasionall',
              a93: 'Frequently',
              a94: 'Constantly',
              a101: 'No issues(do not wake up during sleep)',
              a102: 'Less than 2-3 times a month',
              a103: 'About once or twice a week',
              a104: 'More than three times a week',
              a111: 'After midnight',
              a112: 'Before midnight',
              a121: 'Yes (relatively regular)',
              a122: 'No (varies)',
              a131: 'Yes (relatively consistent)',
              a132: 'No (varies)',
              a141: 'Face is puffy in the morning',
              a142: 'Feel heavy when waking up',
              a143: 'Usually lie down a lot',
              a144: 'None of the above',
              a151: 'Extroverted',
              a152: 'Impatient',
              a153: 'Timid',
              a154: 'Introverted',
              a155: 'Easygoing',
              a156: 'None of the above',
              a161: 'Usually warm hands and feet',
              a162: 'Usually cold hands and feet',
              a163: 'Prefer warm things',
              a164: 'Prefer cold things',
              a165: 'None of the above',
              a171: 'Feel heat in face',
              a172: 'Feel sour below knees',
              a173: 'None of the above',
              a181: 'Healthy (moist stool)',
              a182: 'Constipated (hard stool)',
              a183: 'Frequent loose stools (soft stool)',
              a191: 'Involuntarily urinated',
              a192: 'Wake up frequently to urinate during sleep',
              a193: 'None of the above',
            },
          },
          result: {
            main: {
              diag_result: 'Diagnosis Result',
              energy_result_of: '{{name}}’s Energy Evaluation',
              energy_create_feature: 'Energy Creating Function',
              energy_circulation_feature: 'Energy Circulation Function',
              energy_balance_feature: 'Energy Balance Function',
              energy_create_text: 'Energy Creation',
              energy_circulation_text: 'Energy Circulation',
              energy_balance_text: 'Energy Balance',
              energy_create_desc: 'Function of creating energy required for the body through food',
              energy_circulation_desc: 'Function of delivering generated energy to organs and tissues',
              energy_balance_desc: 'Function of controling the body’s bio-rhythm',
              the_energy_result_of: '{{name}}’s Energy Evaluation Report',
              energy_eval: 'Energy Evaluation',
              diag_summary_caution: '※ Please consult a doctor for detailed health condition analysis.',
              disease_watch_out: 'Diseases to Watch Out For',
              recommand_way_life: 'Recommended Lifestyle',
              oriental_medicine_treatment_and_health_counseling: 'Oriental Medicine Treatment and Health Counseling',
              oriental_medicine_treatment: 'Oriental Medicine Treatment',
              oriental_medicine_treatment_diag:
                'Through controling the body’s energy, we not only treat diseases but also prevent obesity and aging.',
              more_info: 'For more information',
              energy_info: {
                normal: 'is normal.\nStrive to maintain this normal condition!',
                caution: 'has issues.\nLifestyle changes and treatment are needed for normal function.',
              },
              loading: `Loading...`,
              server_error: `An error occurred while connecting to the server.`,
            },
            cont: {
              default_name: `Member`,
              diet_recommend: 'Recommended Foods',
              diet_avoid: 'Foods to Avoid',
              exercise_recommend: 'Recommended Exercises',
              exercise_avoid: 'Precautions',
              sleep_recommend: 'Recommended Sleep Hygiene',
              sleep_avoid: 'Precautions',
              k_medical_therapy: 'Herbal Medicine Therapy',
              bee_venom_therapy: 'Bee Venom Therapy',
              diet_guide_create: `The idea that energy should be generated through food is important. 
      We recommend eating three meals a day and two snacks in between.`,
              diet_guide_circulation: `Controlling portion sizes to reduce stomach capacity is essential. 
      Try to serve your meal on one plate to visually check your total portion.`,
              diet_guide_balance: `You should eat a balanced variety of foods.`,
              diet_guide_normal: ``,
              diet_title: 'Diet',
              diet_subtitle: 'The following lifestyle guidelines are recommended',
              diet_subcomment: 'Refer to the guide below to improve your eating habits!',
              exercise_guide_create: `Start with light exercises that do not strain the body.`,
              exercise_guide_circulation: `Incorporating as much movement into daily life is essential.`,
              exercise_guide_balance: `Focus on lower body exercises to bring energy downward.`,
              exercise_guide_normal: ``,
              exercise_title: 'Exercise',
              exercise_subtitle: 'The following lifestyle guidelines are recommended',
              exercise_subcomment: 'Refer to the guide below to improve your exercise habits!',
              sleep_guide_create: `We recommend sleeping during the golden hours (10 PM to 5 AM). 
      Ensure sufficient sleep time to reduce energy consumption.`,
              sleep_guide_circulation: `We recommend sleeping during the golden hours (10 PM to 5 AM). 
      Going to bed before 11 PM is crucial as it helps maintain hormonal balance.`,
              sleep_guide_balance: `We recommend sleeping during the golden hours (10 PM to 5 AM). 
      Try to ensure deep sleep for proper hormone production.`,
              sleep_guide_normal: ``,
              sleep_title: 'Sleep',
              sleep_subtitle: 'The following lifestyle guidelines are recommended',
              sleep_subcomment: 'Refer to the guide below to improve your sleep patterns!',
              diag_summary_create: `Based on your energy evaluation, the most improvement is needed in the energy generating function. 
      A lack of muscle mass is caused by impaired energy generating function. It can lead to abdominal obesity and the following diseases.`,
              care_create: `To reduce abdominal fat and increase muscle mass, we recommend Nobino B.`,
              disease_create: {
                d1: 'Chronic Fatigue',
                d2: 'Cold Extremities',
                d3: 'Acute and Chronic Digestive Diseases',
                d4: 'Back Pain',
                d5: 'Shoulder Pain',
                d6: 'Low Blood Pressure',
                d7: 'Dementia',
                d8: 'Parkinson’s',
                d9: 'Memory Loss',
                d10: 'Tinnitus',
                d11: 'Vision Loss',
                d12: 'Dizziness',
                d13: 'Headaches',
                d14: 'Hyperlipidemia',
              },
              diag_summary_circulation: `Based on your energy evaluation, the most improvement is needed in the energy circulation function. 
      Due to impaired energy circulation function, toxins could accumulate easily in the body. It can lead to full-body obesity and the following diseases.`,
              care_circulation: `We recommend Nobino A for reducing body fat and improving circulation function.`,
              disease_circulation: {
                d1: 'Obesity',
                d2: 'Metabolic Diseases (Hyperlipidemia, Hypertension, Diabetes)',
                d3: 'Heart Disease',
                d4: 'Stroke',
                d5: 'Respiratory Diseases',
                d6: 'Knee Osteoarthritis',
                d7: 'Back Pain (Disc, Stenosis)',
                d8: 'Autoimmune Diseases (Rheumatoid Arthritis, Fibromyalgia, Ankylosing Spondylitis)',
                d9: 'Various Cancers',
              },
              diag_summary_balance: `Based on your energy evaluation, the most improvement is needed in the energy balance function. 
      When the energy balance function is impaired, energy rises upwards. It can lead to upper body obesity and the following diseases.`,
              care_balance: `We recommend Nobino C for reducing body fat and improving energy balance.`,
              disease_balance: {
                d1: 'Reproductive Diseases (Prostate, Uterine Diseases)',
                d2: 'Infertility',
                d3: 'Urinary Problems',
                d4: 'Hair Loss',
                d5: 'Dry Eyes',
                d6: 'Skin Diseases (Skin Aging)',
                d7: 'Headaches',
                d8: 'Tinnitus',
                d9: 'Mental Disorders (Insomnia, Panic Disorder, Depression)',
                d10: 'Back Pain (Disc, Stenosis)',
                d11: 'Neck and Shoulder Pain',
                d12: 'Arthritis',
                d13: 'Plantar Fasciitis',
              },
              diag_summary_normal: `Your energy creation, circulation, and balance functions are all within the normal range. However, we recommend following the Nobino Health Method to maintain a healthy body.`,
            },
            card: {
              diet_create: {
                g_t1: 'You can eat any easily digestible foods.',
                g_c1: 'Since digestibility varies per person, please consult for more details.',
                b_t1: 'Flour-based Foods',
                b_t2: 'Cool natured Foods',
                b_c2: 'Cold, refrigerated fruits',
                b_t3: 'Carbonated Drinks (Sparkling Water)',
              },
              diet_cycle: {
                g_t1: 'Protein',
                g_c11: 'Tofu',
                g_c12: 'Beans',
                g_c13: 'Fish',
                g_c14: 'Lean Meat',
                b_t1: 'Carbohydrates',
                b_c11: 'White Rice',
                b_c12: 'Noodles (Noodles, Ramen)',
                b_c13: 'Rice Cakes',
                b_c14: 'Fried Foods',
                b_c15: 'Fruits',
              },
              diet_balance: {
                g_t1: 'Cool natured foods or seafood',
                g_c11: 'Pork',
                g_c12: 'Fish',
                g_c13: 'Seaweed',
                b_t1: 'Spicy and Salty Foods',
                b_t2: 'Caffeinated Drinks',
                b_c2: `Try to consume caffeinated drinks before 10 AM.`,
                b_c21: 'Coffee',
                b_c22: 'Green Tea',
              },
              exercise_create: {
                g_t1: 'Proper Posture',
                g_c1: 'Maintaining proper posture is important to keep energy pathways (meridians) open.',
                g_t2: 'Walking Exercise',
                g_c2: 'Walk comfortably while listening to calm music, not too fast or strenuous.',
                g_t3: 'Light Exercise',
                g_c31: 'Walking',
                g_c32: 'Abdominal Breathing (Diaphragmatic Breathing)',
                g_c33: 'Stretching',
                g_c34: 'Yoga',
                g_c35: 'Pilates',
                b_t1: 'Excessive and Energy-consuming Exercises',
                b_c11: `Avoid strenuous exercises that drain energy or heavy aerobic exercises that cause excessive sweating.<br/>
                Be especially cautious with swimming in cold water, which can drain energy.`,
                b_c12: 'Swimming',
              },
              exercise_cycle: {
                g_t1: 'Proper Posture',
                g_c1: 'Maintaining proper posture is important to keep energy pathways (meridians) open.',
                g_t2: 'Speed Walking',
                g_c21: 'It’s important to walk as fast as possible.',
                g_c22: 'Listening to fast-tempo music while walking can help.',
                g_t3: 'Aerobic Exercise',
                g_c31: 'Jogging',
                g_c32: 'Hiking',
                g_c33: 'Swimming',
                b_t1: `Strenuous Exercises that Strain the Joints`,
                b_c1: `When there is excess body fat, strenuous exercise can strain the joints, so it is better to start by reducing body fat with controlled diet before increasing exercise.`,
                b_t2: `Gradually Increasing Exercise Intensity`,
                b_c2: `The intensity of the exercise should be gradually increased depending on your physical condition.`,
              },
              exercise_balance: {
                g_t1: 'Proper Posture',
                g_c1: 'Maintaining proper posture is important to keep energy pathways (meridians) open.',
                g_t2: 'Power Walking',
                g_c21: 'It is important to focus on the lower body while walking.',
                g_c22: 'Listen to classical music while focusing on the hips as you walk.',
                g_t3: 'Lower Body Exercises',
                g_c31: 'Stair Climbing',
                g_c32: 'Indoor Cycling',
                g_c33: 'Barefoot Walking',
                g_c34: 'Toe Tapping',
                g_c35: 'Squats, Lunges',
                b_t1: `Upper Body Exercises and Late-night Exercises`,
                b_c1: `Upper body exercises such as lifting weights or late-night exercises should be avoided as they pull energy upwards.`,
              },
              sleep_create: {
                g_t1: 'Recommended Sleep Hygiene',
                g_c1: 'Consume caffeinated drinks such as coffee and green tea before 10 AM',
                g_c2: 'Avoid TV, computer, or smartphone use before bed',
                g_c3: 'Separate sleep space from living space',
                g_c4: 'Keep the bed area tidy',
                g_c5: 'Adjust room temperature and blanket thickness appropriately',
                g_c6: 'Maintain a consistent wake-up time',
                g_c7: 'Exercise your lower body in sunlight',
                b_t1: `Precautions`,
                b_c1: `Going to bed after 11 PM`,
                b_c2: `Habit of drinking coffee in the afternoon`,
                b_c3: `Using electronic devices late at night`,
                b_c4: `Lack of movement during the day`,
              },
              sleep_cycle: {
                g_t1: 'Recommended Sleep Hygiene',
                g_c1: 'Consume caffeinated drinks such as coffee and green tea before 10 AM',
                g_c2: 'Avoid TV, computer, or smartphone use before bed',
                g_c3: 'Separate sleep space from living space',
                g_c4: 'Keep the bed area tidy',
                g_c5: 'Adjust room temperature and blanket thickness appropriately',
                g_c6: 'Maintain a consistent wake-up time',
                g_c7: 'Exercise your lower body in sunlight',
                b_t1: `Precautions`,
                b_c1: `Going to bed after 11 PM`,
                b_c2: `Habit of drinking coffee in the afternoon`,
                b_c3: `Using electronic devices late at night`,
                b_c4: `Lack of movement during the day`,
              },
              sleep_balance: {
                g_t1: 'Recommended Sleep Hygiene',
                g_c1: 'Consume caffeinated drinks such as coffee and green tea before 10 AM',
                g_c2: 'Avoid TV, computer, or smartphone use before bed',
                g_c3: 'Separate sleep space from living space',
                g_c4: 'Keep the bed area tidy',
                g_c5: 'Adjust room temperature and blanket thickness appropriately',
                g_c6: 'Maintain a consistent wake-up time',
                g_c7: 'Exercise your lower body in sunlight',
                b_t1: `Precautions`,
                b_c1: `Going to bed after 11 PM`,
                b_c2: `Habit of drinking coffee in the afternoon`,
                b_c3: `Using electronic devices late at night`,
                b_c4: `Lack of movement during the day`,
              },
              han: {
                m_t: `Nobino Herbal Decoction`,
                m_c: `'Nobino' is a customized herbal medicine prescribed according to an individual's energy status, developed by Professor Lee Jae-dong’s team from Kyung Hee University after 8 years of research (Patent No. 10-1071294).`,
                b_t: `Bee Venom Therapy`,
                b_c: `Bee venom contains over 40 beneficial components such as melittin, apamin, and phospholipase A2. It has strong anti-inflammatory and pain-relieving effects, effective in treating arthritis, various pain disorders, cancer, and autoimmune diseases such as rheumatoid arthritis and ankylosing spondylitis (Patent No. 10-1146718).`,
              },
            },
            condition: {
              normal: 'Normal',
              caution: 'Caution',
              warning: 'Warning',
            },
            nobino: 'NOBINO Health Method, NOBINO Diet'
          },
          // ---------------------
          welcome: {
            title: 'Healthcare Solution',
            subtitle: 'Kaidoc',
            content: 'Find the perfect health solution for your body',
            button: 'Start diagnosis',
          },
          privacy: {
            title: 'Please enter your personal information if you want to manage your health.',
            index: 'Personal information collection',
            text: 'If you only want a health diagnosis, you can skip your personal information (name, number).',
            name: 'Name',
            name_placeholder: 'Kaidoc Health',
            phone: 'Phone number',
          },
          info: {
            title_gender: 'Gender?',
            male: 'Male',
            female: 'Female',
            title_bp: 'Blood pressure?',
            subtitle_bp: "If you're not sure, please press the skip button.",
            systolic: 'Systolic blood pressure',
            diastolic: 'Diastolic blood pressure',
            title_age: 'Age?',
            age: 'yrs',
            title_weight: 'Weight?',
            title_height: 'Height?',
          },
          survey: {
            title: 'Energy Evaluation Survey',
            waist_circumference: {
              question: 'Girth (waist circumference)?',
              option1: '{{inch}} inches or more',
              option2: 'Less than {{inch}} inches',
              option3: 'Not sure',
            },
            body_development: {
              question: 'Which part of your body is more developed?',
              upper: 'Upper body',
              lower: 'Lower body',
              similar: 'Upper and lower body are similar',
            },
            calf_circumference: {
              question: 'When you wrap your fingers around the thickest part of your calf, which is closest?',
              sub_question: 'Measure by touching the tips of both your thumbs and index fingers together.',
              option1: 'My calf is larger',
              option2: 'It fits perfectly',
              option3: "It's loose",
            },
            rib_angle: {
              question: 'What do you think about the angle of your ribs?',
              sub_question: "If you're not sure, please press the skip button",
              option1: "It's closer to an obtuse angle (greater than 90°)",
              option2: "It's close to a right angle (90°)",
              option3: "It's closer to an acute angle (less than 90°)",
              option4: 'Not sure',
            },
            pulse_rate: {
              question: 'How many times do you feel your pulse in 10 seconds using your index and middle fingers?',
              option1: '10 times or less',
              option2: '11 to 16 times',
              option3: '17 times or more',
              option4: 'Not sure',
            },
            appetite: {
              question: 'How is your appetite usually?',
              option1: 'No appetite',
              option2: 'Normal',
              option3: 'Strong',
            },
            number_of_meals: {
              question: 'How many meals do you eat per day?',
              option1: '2 meals or less',
              option2: '3 meals',
              option3: '4 meals or more',
            },
            amount_per_meal: {
              question: 'How much do you usually eat per meal?',
              option1: 'Less than one serving',
              option2: 'About one serving',
              option3: 'More than one serving',
            },
            indigestion: {
              question: 'Do you have digestive issues?',
              option1: 'No issues',
              option2: 'Occasionally',
              option3: 'Frequently',
              option4: 'Constantly',
            },
            enough_sleep: {
              question:
                "Considering the symptoms when you don't get a good night's sleep, how often do you have difficulty getting a good night's sleep?",
              sub_question1: '- Unable to fall asleep within 30 minutes after going to bed',
              sub_question2: '- Waking up due to various reasons such as dreams or pain',
              option1: 'No sleep problems usually',
              sub_option1: '(I sleep well without waking up)',
              option2: '2-3 times a month or less',
              sub_option2: "I have difficulty getting a good night's sleep",
              option3: 'About 1-2 times a week',
              sub_option3: "I have difficulty getting a good night's sleep",
              option4: '3 or more times a week',
              sub_option4: "I have difficulty getting a good night's sleep",
            },
            sleep_time: {
              question: 'Do you go to bed after midnight?',
              option1: 'No',
              sub_option1: 'I go to bed before midnight',
              option2: 'Yes',
              sub_option2: 'I go to bed after midnight',
            },
            regular_sleep_time: {
              question: 'Do you go to bed and wake up at set times?',
              option1: 'No',
              sub_option1: 'It seems different every time',
              option2: 'Yes',
              sub_option2: "It's somewhat regular",
            },
            sleep_amount: {
              question: 'Is your total sleep duration consistent each day?',
              option1: 'No',
              sub_option1: 'It seems different every time',
              option2: 'Yes',
              sub_option2: "It's somewhat regular",
            },
            condition: {
              question: 'Do any of the following apply to you? (Multiple selections possible)',
              option1: 'My face is swollen in the morning',
              option2: 'I feel heavy when I wake up in the morning',
              option3: 'I tend to lie down often',
              option4: 'None of the above',
            },
            personality: {
              question: 'Please select the personalities similar to yours (Multiple selections possible)',
              option1: "I'm extroverted",
              option2: "I'm impatient",
              option3: "I'm timid",
              option4: "I'm introverted",
              option5: "I'm relaxed",
              option6: 'None of the above',
            },
            heat_condition: {
              question: 'Please select all that apply (Multiple selections possible)',
              option1: 'My hands and feet are usually warm',
              option2: 'My hands and feet are usually cold',
              option3: 'I usually like warm things',
              option4: 'I usually like cold things',
              option5: 'None of the above',
            },
            heat_condition_detail: {
              question: 'If you have any symptoms you usually feel, please select all that apply',
              option1: 'I feel heat rising to my face',
              option2: 'I feel sour below my knees',
              option3: 'None of the above',
            },
            poop: {
              question: 'Do you have any defecation concerns?',
              option1: "I'm healthy",
              sub_option1: 'Moist form',
              option2: 'I have constipation',
              sub_option2: 'Hard form',
              option3: 'I have diarrhea',
              sub_option3: 'Watery form',
            },
            pee: {
              question: 'Please select all that apply',
              option1: "I've had urinary incontinence",
              option2: 'I often wake up to urinate during sleep',
              option3: 'None of the above',
            },
            disease: {
              question: 'Disease and Symptoms (If none, please press the "Skip" button)',
              placeholder: 'Enter disease or symptom',
              subtitle: 'Please drag and sort the items you have written in order from the most severe symptom.',
            },
            disease_time_trigger: {
              question1: 'Onset Time (Symptom onset or diagnosis time)',
              option1: 'Within 3 months',
              option2: '3 to 6 months',
              option3: 'More than 6 months',
              question2: 'Cause of Onset (What triggered your health deterioration)',
              subtitle1: 'Dietary Habits',
              subtitle2: 'Lifestyle Habits',
              subtitle3: 'Mental Stress',
              subtitle4: 'Weather/Climate Influence',
              subtitle5: 'Trauma',
              form_option1: 'No particular reason',
              form_option2: 'Overeating',
              form_option3: 'Binge eating',
              form_option4: 'Eating too quickly',
              form_option5: 'Spoiled food',
              form_option6: 'Overwork',
              form_option7: 'Night shifts',
              form_option8: 'Desk job',
              form_option9: 'Lying down often',
              form_option10: 'Excessive sexual activity',
              form_option11: 'Anger',
              form_option12: 'Sadness',
              form_option13: 'Depression',
              form_option14: 'Daily stress',
              form_option15: 'Cold wind',
              form_option16: 'Heatwave',
              form_option17: 'Humid environment',
              form_option18: 'Exposure to dry environment',
              form_option19: 'Car accident',
              form_option20: 'Fall',
              form_option21: 'Trauma',
              form_option22: 'Bacterial infection',
              form_option23: 'Other',
              other_placeholder: 'Please enter another cause',
            },
            past_history: {
              question: 'Medical History',
              subtitle: 'Please check all the applicable items.',
              option1: 'None',
              option2: 'Hypertension',
              option3: 'Diabetes',
              option4: 'Hyperlipidemia',
              option5: 'Stroke',
              option6: 'Cancer',
              option7: 'Heart disease',
              option8: 'Lung disease (Tuberculosis)',
              option9: 'Digestive disorders',
              option10: 'Liver disease (Hepatitis)',
              option11: 'Kidney disease',
              option12: 'Allergy',
              option13: 'Autoimmune disease',
              option14: 'Other',
              option15: 'History of surgery',
              other_placeholder: 'Please enter another cause',
              surgery_placeholder: 'Please enter the surgery location',
            },
            obese_med: {
              question: 'Experience with Obesity Medications',
              subtitle: 'Please check all the applicable items.',
              option1: 'None',
              option2: 'Herbal medicine',
              option3: 'Western medicine',
              placeholder: 'Enter the duration of medication',
            },
            favorite_food: {
              question: 'Favorite Foods',
              subtitle: 'Check the applicable items, and if applicable, enter the amount.',
              option1: 'Smoke',
              option1_placeholder: 'Smoking quantity',
              option1_unit: 'Cigarettes/day',
              option2: 'Drink alcohol more than once a week.',
              option2_placeholder: 'Alcohol consumption',
              option2_unit: 'Times/week',
              option2_unit2: 'Glasses/time',
              option3: 'Drink coffee or green tea.',
              option3_placeholder: 'Consumption amount',
              option3_unit: 'Cups/day',
              no: 'No',
              do: 'Smoke',
              drink: 'Drink',
            },
          },
          button: {
            next: 'Next',
            skip: 'Skip',
            end: 'End Survey',
            add: 'Add',
          },
          inputError: 'Please enter the required information.',
          unfinishedError: "Please answer the questions you haven't answered before",
          loading: 'Generating the survey...',
        },
      },
      ko: {
        translation: {
          // 번역본 쓸 공간
          manage: {
            basic_info: {
              name: '이름',
              age: '나이',
              phone: '번호',
              gender: '성별',
              height: '키',
              weight: '몸무게',
              sys: '수축기 혈압',
              diag: '이완기 혈압',
            },
            // 로그인 페이지
            admin: '관리자',
            see_result: '사용자 결과 보기',
            logout: '로그아웃',
            login: '로그인',
            find_pw: '비밀번호 찾기',
            signup: '회원가입',
            id: '아이디',
            pw: '비밀번호',
            // 환자, 설문 리스트 페이지
            list: {
              id: '아이디',
              name: '이름',
              list: '진단 목록',
              age: '나이',
              phone: '번호',
              create: 'B. 에너지 생성기능 평가',
              cycle: 'A. 에너지 순환기능 평가',
              balance: 'C. 에너지 균형조절기능 평가',
              search_not_found: '검색 결과가 존재하지 않습니다.',
              recent: '최근 검사',
              recent_result: '최근 검사 결과',
              check: '확인',
              delete: '삭제',
              disease: '증상 및 질병명',
              time: '발병 시기',
              cause: '발병 원인',
              favorite: '기호 식품',
              cigarette: '담배를 피운다',
              cigarette_do: '일 {{num}}개비',
              alcohol: '술을 주 1회 이상 마신다',
              alcohol_do: '주 {{num1}}회, 회 {{num2}}잔',
              caffeine: '커피, 녹차를 마신다',
              caffeine_do: '일 {{num}}잔',
              no: '아니다',
            },
            // 결과페이지 - 헬스케어
            health: {
              etc: '기타',
              none: '없음',
              moonth: '개월',
              order: '증상이 심한 순서대로 정렬',
            },
            // 설문 결과 페이지
            energy_eval: '설문 기록',
            basic_survey: '기본 설문',
            symptom: '질환 및 증상',
            cause: '발병 시기 및 원인',
            history: '과거력',
            med: '비만약 복용 경험',
            favorite: '기호 식품',
            delete: '삭제',
            basic: {
              q1: 'Q1. 바지 사이즈(허리둘레)가 35인치(남성) 또는 33인치(여성) 이상 이신가요?',
              q2: 'Q2. 상체와 하체 중 발달한 부분은?',
              q3: 'Q3. 종아리 중 가장 굵은 부위를 손가락으로 감싸보면 어디에 가장 가까운가요?',
              q4: 'Q4. 본인이 생각하는 나의 갈비뼈 간격은 어떤가요?',
              q5: 'Q5. 10초간 맥박을 재면 몇번이 측정되나요?',
              q6: 'Q6. 평소 식욕은 어떠세요?',
              q7: 'Q7. 하루에 몇 끼를 드시나요?',
              q8: 'Q8. 평소 한 끼 식사량은 어떠세요?',
              q9: 'Q9. 평소 소화는 잘 되시나요?',
              q10: 'Q10. 숙면을 취하지 못했을 때의 증상을 참고하여, 숙면을 취하는데 어려움이 얼마나 자주 있었는지 선택해주세요.',
              q11: 'Q11. 밤 12시 이후에서야 잠자리에 드시나요?',
              q12: 'Q12. 정해진 시간에 잠자리에 들고 정해진 시간에 일어나세요?',
              q13: 'Q13. 하루 총 수면시간은 매번 비슷한가요?',
              q14: 'Q14. 기상시 상태/평소 활동량',
              q15: 'Q15. 다음 중 평소 본인과 비슷한 성격을 선택해주세요',
              q16: 'Q16. 한열 상태',
              q17: 'Q17. 한열 상태(얼굴/무릎)',
              q18: 'Q18. 대변',
              q19: 'Q19. 소변',
              a11: '예(이상)',
              a12: '아니오(미만)',
              a13: '잘 모르겠음',
              a21: '상체',
              a22: '하체',
              a23: '상하체 비슷함',
              a31: '종아리가 더 커요',
              a32: '딱 맞아요',
              a33: '헐렁해요',
              a41: '둔각',
              a42: '직각',
              a43: '예각',
              a44: '잘 모르겠음',
              a51: '10번(60bpm) 이하',
              a52: '11~16번(61-99bpm)',
              a53: '17번(100bpm) 이상',
              a54: '잘 모르겠음',
              a61: '식욕이 없어요',
              a62: '보통이에요',
              a63: '왕성해요',
              a71: '2끼 이하',
              a72: '3끼',
              a73: '4끼 이상',
              a81: '1인분 보다 적게 먹어요',
              a82: '1인분 만큼 먹어요',
              a83: '1인분 보다 더 먹어요',
              a91: '소화에 문제 없어요',
              a92: '가끔 안되요',
              a93: '자주 안되요',
              a94: '늘 소화가 안되요',
              a101: '평소에 수면에 문제 없음',
              a102: '월 2~3회 이하',
              a103: '주 1~2회 정도',
              a104: '주 3회 이상',
              a111: '예(12시 이후에 수면)',
              a112: '아니오(12시 이전에 수면)',
              a121: '예(나름 규칙적)',
              a122: '아니오(매번 다름)',
              a131: '예(나름 규칙적)',
              a132: '아니오(매번 다름)',
              a141: '아침에 얼굴이 부어요',
              a142: '아침에 일어나면 몸이 무거운 느낌이예요',
              a143: '평소에 잘 눕는 편이예요',
              a144: '해당사항 없어요',
              a151: '외향적',
              a152: '급한편',
              a153: '소심한편',
              a154: '내성적',
              a155: '느긋한편',
              a156: '해당사항 없음',
              a161: '평소 손발이 따뜻해요',
              a162: '평소 손발이 차요',
              a163: '평소 따뜻한 것을 좋아해요',
              a164: '평소 차가운 것을 좋아해요',
              a165: '해당사항 없음',
              a171: '얼굴 쪽으로 열이 나는 느낌이 있어요',
              a172: '무릎 아래가 시린 느낌이 있어요',
              a173: '해당사항 없음',
              a181: '건강해요(촉촉한 형태)',
              a182: '변비가 있어요(딱딱한 형태)',
              a183: '무른 변을 자주봐요(묽은 형태)',
              a191: '소변을 참지 못하고 지린 적이 있어요',
              a192: '수면 중 소변을 보기 위해서 자주 깨요',
              a193: '해당사항 없음',
            },
          },
          result: {
            main: {
              diag_result: '진단 결과',
              energy_result_of: '{{name}}님의 에너지 평가',
              energy_create_feature: '에너지 생성 기능',
              energy_circulation_feature: '에너지 순환 기능',
              energy_balance_feature: '에너지 균형 기능',
              energy_create_text: '에너지 생성',
              energy_circulation_text: '에너지 순환',
              energy_balance_text: '에너지 균형',
              energy_create_desc: '음식을 통해 몸에 필요한 에너지를 생성하는 기능',
              energy_circulation_desc: '생성된 에너지를 체내 각 기관, 조직에 전달하는 기능',
              energy_balance_desc: '신체 전반의 생체 리듬을 조절하는 기능',
              the_energy_result_of: '{{name}}님의 에너지 평가 결과지',
              energy_eval: '에너지 평가',
              diag_summary_caution: '※ 구체적인 건강 상태를 확인하기 위해서는 전문의와의 상담이 꼭 필요합니다.',
              disease_watch_out: '주의해야할 질환',
              recommand_way_life: '추천 생활 용법',
              oriental_medicine_treatment_and_health_counseling: '한방 치료와 건강 상담',
              oriental_medicine_treatment: '한방 치료',
              oriental_medicine_treatment_diag:
                '내 몸의 에너지 조절을 통하여 질병치료뿐만 아니라 비만과 노화를 예방합니다.',
              more_info: '추가 정보를 원하시면',
              energy_info: {
                normal: '이 정상적 입니다.\n정상적인 상태를 유지하기위해 노력하세요!',
                caution: '에 문제가 있습니다.\n정상기능을 위한 생활습관 개선과 치료가 필요합니다.',
              },
              loading: `로딩중 입니다.`,
              server_error: `서버와의 연결 중 에러가 발생했습니다.`,
            },
            cont: {
              default_name: `회원`,
              diet_recommend: '권장 음식',
              diet_avoid: '피할 음식',
              exercise_recommend: '권장 운동',
              exercise_avoid: '주의 사항',
              sleep_recommend: '추천 수면위생',
              sleep_avoid: '주의 사항',
              k_medical_therapy: '한약 요법',
              bee_venom_therapy: '봉독 요법',
              diet_guide_create: `음식을 통하여 에너지를 생성해야 한다는 생각이 중요합니다.
하루 3끼 식사와 함께 오전 오후에 2번의 간식을 먹을 것을 추천합니다.`,
              diet_guide_circulation: `식사량을 조절하여 위장의 크기를 줄인다는 생각이 중요합니다.
            하나의 접시에 밥과 반찬을 담아 전체식사량을 눈으로 확인합니다.`,
              diet_guide_balance: `무엇이든 골고루 드시면 됩니다.`,
              diet_guide_normal: ``,
              diet_title: '음식',
              diet_subtitle: '다음과 같은 생활요법을 추천합니다',
              diet_subcomment: '아래 가이드를 참고해 식습관을 개선해 보세요!',
              exercise_guide_create: `몸에 부담이 되지 않는 가벼운 운동부터 시작합니다.`,
              exercise_guide_circulation: `최대한 생활 속에서 많이 움직이는 것이 중요합니다.`,
              exercise_guide_balance: `상기된 에너지를 아래로 끌어 내린다는 생각으로 하체운동이 중요 합니다.`,
              exercise_guide_normal: ``,
              exercise_title: '운동',
              exercise_subtitle: '다음과 같은 생활요법을 추천합니다',
              exercise_subcomment: '아래 가이드를 참고해 운동 습관을 개선해 보세요!',
              sleep_guide_create: `골든타임(밤10-아침 5시)의 수면을 추천합니다.
에너지 소모를 줄이기 위해 잠자는 시간을 충분히 하는 것이 좋습니다.`,
              sleep_guide_circulation: `골든타임(밤10-아침 5시)의 수면을 추천합니다.
밤11시를 전후하여 식욕관련 호르몬 균형이 깨어지기 때문에 11시전 취침이 중요합니다.`,
              sleep_guide_balance: `골든타임(밤10-아침 5시)의 수면을 추천합니다.
호르몬 생성을 위한 딮수면(deep sleep)을 위해 숙면이 되도록 노력하세요.`,
              sleep_guide_normal: ``,
              sleep_title: '수면',
              sleep_subtitle: '다음과 같은 생활요법을 추천합니다',
              sleep_subcomment: '아래 가이드를 참고해 수면 패턴을 개선해 보세요!',
              diag_summary_create: `님의 몸 에너지를 평가한 결과 생성기능 개선이 가장 필요합니다. 
에너지 생성기능에 문제가 있으면 근육량 부족으로 마른 복부비만과 다음과 같은 질병이 발생할 수 있습니다.`,
              care_create: `복부지방 제거와 근육량 증가를 위해 노비노B를 추천합니다.`,
              disease_create: {
                d1: '만성피로',
                d2: '수족냉증',
                d3: '급 만성 소화기질환',
                d4: '허리통증',
                d5: '어깨통증',
                d6: '저혈압',
                d7: '치매',
                d8: '파킨슨',
                d9: '건망증',
                d10: '이명',
                d11: '시력저하',
                d12: '현훈',
                d13: '두통',
                d14: '고지혈증',
              },
              diag_summary_circulation: `님의 몸에너지를 평가한 결과 순환기능 개선이 가장 필요합니다.
에너지 순환기능에 문제가 있으면 몸에 독소가 쉽게 쌓이면서 전신비만과 다음과 같은 질병이 발생할 수 있습니다.`,
              care_circulation: `체지방 감소와 순환기능 개선을 위해 노비노A를 추천합니다.`,
              disease_circulation: {
                d1: '비만',
                d2: '대사성질환(고지혈증, 고혈압, 당뇨)',
                d3: '심장질환',
                d4: '중풍',
                d5: '호흡기질환',
                d6: '무릎관절염',
                d7: '허리통증(디스크, 협착증)',
                d8: '자가면역질환(류마티스, 섬유근통, 강직성척추염)',
                d9: '각종 암질환',
              },
              diag_summary_balance: `님의 몸에너지를 평가한 결과 균형기능 개선이 가장 필요합니다.
에너지의 균형기능에 문제가 있으면 에너지가 위로 상기되면서 상체비만이나 다음과 같은 질병이 발생할 수 있습니다.`,
              care_balance: `체지방감소와 에너지의 균형기능을 개선하는 노비노C 를 추천합니다.`,
              disease_balance: {
                d1: '각종 생식기질환(전립선, 자궁질환)',
                d2: '난임(불임)',
                d3: '소변장애',
                d4: '탈모',
                d5: '안구건조증',
                d6: '피부질환(피부노화)',
                d7: '두통',
                d8: '이명',
                d9: '정신과질환(불면, 공황장애, 우울증)',
                d10: '허리통증(디스크, 협착증)',
                d11: '목 어깨통증',
                d12: '관절염',
                d13: '족저근막염',
              },
              diag_summary_normal: `님은 우리 몸의 생명활동에 필요한 에너지를 만드는 생성기능, 만들어진 에너지를 각 기관이나 조직에 공급하는 순환기능, 몸에서 에너지의 흐름을 조절하는 균형기능 모두 정상범위에 있습니다만 노비노건강법을 통하여 더욱 건강한 몸을 유지하시길 바랍니다.`,
            },
            card: {
              diet_create: {
                g_t1: '소화 잘 되는 음식은 무엇이든 좋습니다.',
                g_c1: '소화가 잘 되는 음식은 개개인마다 차이가 있기 때문에 자세한 정보는 상담을 통해 확인하시길 바랍니다.',
                b_t1: '밀가루 음식',
                b_t2: '찬음식',
                b_c2: '냉장된 차가운 과일',
                b_t3: '탄산 음료(탄산수)',
              },
              diet_cycle: {
                g_t1: '단백질',
                g_c11: '두부',
                g_c12: '콩',
                g_c13: '생선',
                g_c14: '육류(지방이 없는 부위)',
                b_t1: '탄수화물',
                b_c11: '흰 쌀밥',
                b_c12: '면(국수, 라면)',
                b_c13: '떡',
                b_c14: '튀김',
                b_c15: '과일',
              },
              diet_balance: {
                g_t1: '찬 성질의 음식이나 바다에서 나는 음식',
                g_c11: '돼지고기',
                g_c12: '생선',
                g_c13: '해조류',
                b_t1: '맵고 짠 자극적인 음식',
                b_t2: '카페인 음료',
                b_c2: `카페인 음료는 가급적 오전 10시 이전에 섭취해주세요.`,
                b_c21: '커피',
                b_c22: '녹차',
              },
              exercise_create: {
                g_t1: '바른 자세',
                g_c1: '평소 에너지 흐름 통로인 경락이 접히지 않도록 가슴을 펴고 몸을 세우는 바른자세가 중요합니다.',
                g_t2: '걷기 운동',
                g_c2: '너무 빠르거나 힘들지 않게 발라드 음악 등을 들으며 편안한 속도로 걷는것이 좋습니다.',
                g_t3: '가벼운 운동',
                g_c31: '산책',
                g_c32: '단전호흡(복식 호흡)',
                g_c33: '스트레칭',
                g_c34: '요가',
                g_c35: '필라테스',
                b_t1: '에너지를 빼앗기는 과격한 운동',
                b_c11: `에너지 소모가 많은 무리한 운동이나 과격한 운동, 땀을 많이 빼는 유산소 운동 등은 피해야 합니다.<br/>
                특히 수영은 찬물에서 에너지를 뺏길 수 있기 때문에 주의하셔야 합니다.`,
                b_c12: '수영',
              },
              exercise_cycle: {
                g_t1: '바른 자세',
                g_c1: '평소 에너지 흐름 통로인 경락이 접히지 않도록 가슴을 펴고 몸을 세우는 바른자세가 중요합니다.',
                g_t2: '스피드 워킹',
                g_c21: '최대한 빠르게 걷는 것이 중요합니다.',
                g_c22: '빠른 템포의 음악을 들으며 걸으면 더욱 좋습니다.',
                g_t3: '유산소 운동',
                g_c31: '조깅',
                g_c32: '등산',
                g_c33: '수영',
                b_t1: `관절에 무리가 가는 과격한 운동`,
                b_c1: `체지방이 많은 상황에서 과격한 운동은 관절에 부담을 주기 때문에 초기에는 식사량 조절로 체지방을 줄이면서 운동량을 늘립니다.`,
                b_t2: `운동은 점진적으로 강하게`,
                b_c2: `운동은 몸 상황에 따라 조금씩 강도를 높혀야 합니다.`,
              },
              exercise_balance: {
                g_t1: '바른 자세',
                g_c1: '평소 에너지 흐름 통로인 경락이 접히지 않도록 가슴을 펴고 몸을 세우는 바른자세가 중요합니다.',
                g_t2: '파워 워킹',
                g_c21: '하체에 힘을 주고 걷는것이 중요합니다.',
                g_c22: '클래식 음악을 들으며 엉덩이에 의식을 두고 걷습니다.',
                g_t3: '하체운동',
                g_c31: '계단 오르기',
                g_c32: '실내 자전거',
                g_c33: '맨발걷기',
                g_c34: '발끝치기',
                g_c35: '스쿼트, 런지',
                b_t1: `상체 운동 및 늦은밤에 운동`,
                b_c1: `역기 들기 등 상체운동이나 늦은 밤 운동은 에너지를 위로 끌어올리기 때문에 피하는 것이 좋습니다.`,
              },
              sleep_create: {
                g_t1: '권장 수면 위생',
                g_c1: '커피, 녹차 등 카페인 음료는 아침 10시전에 마시기',
                g_c2: '잠자기전 TV, 컴퓨터, 스마트폰 멀리하기',
                g_c3: '수면공간과 생활공간 분리하기',
                g_c4: '침상 주위 정리정돈하기',
                g_c5: '방안의 온도와 이불 두께는 알맞게',
                g_c6: '아침 기상시간 일정하게하기',
                g_c7: '햇볕 보며 하체운동하기',
                b_t1: `주의 사항`,
                b_c1: `밤 11시 이후에 잠자리에 드는 것`,
                b_c2: `오후에 커피를 마시는 습관`,
                b_c3: `밤 늦게 전자기기를 가까이 하는 것`,
                b_c4: `낮에 움직임 등의 활동량이 없는 것`,
              },
              sleep_cycle: {
                g_t1: '권장 수면 위생',
                g_c1: '커피, 녹차 등 카페인 음료는 아침 10시전에 마시기',
                g_c2: '잠자기전 TV, 컴퓨터, 스마트폰 멀리하기',
                g_c3: '수면공간과 생활공간 분리하기',
                g_c4: '침상 주위 정리정돈하기',
                g_c5: '방안의 온도와 이불 두께는 알맞게',
                g_c6: '아침 기상시간 일정하게하기',
                g_c7: '햇볕 보며 하체운동하기',
                b_t1: `주의 사항`,
                b_c1: `밤 11시 이후에 잠자리에 드는 것`,
                b_c2: `오후에 커피를 마시는 습관`,
                b_c3: `밤 늦게 전자기기를 가까이 하는 것`,
                b_c4: `낮에 움직임 등의 활동량이 없는 것`,
              },
              sleep_balance: {
                g_t1: '권장 수면 위생',
                g_c1: '커피, 녹차 등 카페인 음료는 아침 10시전에 마시기',
                g_c2: '잠자기전 TV, 컴퓨터, 스마트폰 멀리하기',
                g_c3: '수면공간과 생활공간 분리하기',
                g_c4: '침상 주위 정리정돈하기',
                g_c5: '방안의 온도와 이불 두께는 알맞게',
                g_c6: '아침 기상시간 일정하게하기',
                g_c7: '햇볕 보며 하체운동하기',
                b_t1: `주의 사항`,
                b_c1: `밤 11시 이후에 잠자리에 드는 것`,
                b_c2: `오후에 커피를 마시는 습관`,
                b_c3: `밤 늦게 전자기기를 가까이 하는 것`,
                b_c4: `낮에 움직임 등의 활동량이 없는 것`,
              },
              han: {
                m_t: `노비노 탕약`,
                m_c: `'노비노'는 경희대 이재동교수팀이 8년간의 연구결과로 개발한 비만치료제 '한슬림'(특허 제10-1071294호)을 개인의 에너지 상태에 따른 맞춤 한약으로 처방한 것입니다.`,
                b_t: `봉독요법`,
                b_c: `봉독은 인체에 유익한 멜리틴, 아파민, 포스포리파제 A2를 비롯한 40여가지의 성분을 함유하며 
강력한 소염진통작용으로 관절염을 비롯한 각종 통증질환과 
암을 비롯한 각종 면역질환에 탁월한 효과를 가진 
천연 소염진통제(특허 제 10-1146718호)입니다.`,
              },
            },
            condition: {
              normal: '정상',
              caution: '주의',
              warning: '경고',
            },
            nobino: '노비노 건강법 or 노비노 다이어트',
          },
          // ---------------------
          welcome: {
            title: '건강관리 솔루션',
            subtitle: '카이닥',
            content: '나의 몸에 딱 맞는 건강 솔루션을 찾아서',
            button: '진단 시작하기',
          },
          privacy: {
            title: '건강관리를 원하시면 개인정보를 입력해주세요.',
            index: '개인정보 수집',
            text: '내 몸 에너지 평가만을 원하시면 개인정보(이름. 번호)는 건너뛰셔도 됩니다.',
            name: '이름',
            name_placeholder: '카이닥스훈트',
            phone: '전화번호',
          },
          info: {
            title_gender: '성별이 어떻게 되시나요?',
            male: '남성',
            female: '여성',
            title_bp: '혈압이 어떻게 되시나요?',
            subtitle_bp: '잘 모르시겠다면 건너뛰기 버튼을 눌러주세요.',
            systolic: '수축기 혈압',
            diastolic: '이완기 혈압',
            title_age: '나이가 어떻게 되시나요?',
            age: '세',
            title_weight: '몸무게가 어떻게 되시나요?',
            title_height: '키가 어떻게 되시나요?',
          },
          survey: {
            title: '에너지 평가 설문',
            waist_circumference: {
              question: '바지 사이즈(허리 둘레)가 어떻게 되시나요?',
              option1: '{{inch}}인치 ({{centimeter}}) 이상이예요',
              option2: '{{inch}}인치 ({{centimeter}}) 미만이예요',
              option3: '잘 모르겠어요',
            },
            body_development: {
              question: '상체와 하체 중 발달한 부분은?',
              upper: '상체',
              lower: '하체',
              similar: '상하체 비슷함',
            },
            calf_circumference: {
              question: '종아리 중 가장 굵은 부위를 손가락으로 감싸 보면 어디에 가장 가까운가요?',
              sub_question: '양쪽 엄지와 검지를 각각 맞대서 측정해보세요.',
              option1: '종아리가 더 커요',
              option2: '딱 맞아요',
              option3: '헐렁해요',
            },
            rib_angle: {
              question: '본인이 생각하는 나의 갈비뼈 간격은 어떤가요?',
              sub_question: '잘 모르시겠다면 건너뛰기 버튼을 눌러주세요',
              option1: '직각(90°)보다 큰 둔각에 가까워요',
              option2: '직각(90°)에 가까워요',
              option3: '직각(90°)보다 작은 예각에 가까워요',
              option4: '잘 모르겠어요',
            },

            pulse_rate: {
              question: '검지와 중지로 10초간 맥박을 재면 몇 번이 측정되나요?',
              option1: '10번(60bpm) 이하',
              option2: '11 ~ 16번(61~99bpm)',
              option3: '17번(100bpm) 이상',
              option4: '잘 모르겠어요',
            },
            appetite: {
              question: '평소 식욕은 어떠세요?',
              option1: '식욕이 없어요',
              option2: '보통이에요',
              option3: '왕성해요',
            },
            number_of_meals: {
              question: '하루에 몇 끼를 드시나요?',
              option1: '2끼 이하',
              option2: '3끼',
              option3: '4끼 이상',
            },
            amount_per_meal: {
              question: '평소 한 끼 식사량은 어떠세요?',
              option1: '1인분 보다 적게 먹어요',
              option2: '1인분 만큼 먹어요',
              option3: '1인분 보다 더 먹어요',
            },
            indigestion: {
              question: '평소 소화는 잘 되시나요?',
              option1: '소화에 문제 없어요',
              option2: '가끔 안돼요',
              option3: '자주 안돼요',
              option4: '늘 소화가 안돼요',
            },
            enough_sleep: {
              question:
                '숙면을 취하지 못했을 때의 증상을 참고하여, 숙면을 취하는데 어려움이 얼마나 자주 있었는지 선택해주세요.',
              sub_question1: '- 취침 후 30분 이내 잠들지 못함',
              sub_question2: '- 꿈이나 통증 등 여러 이유로 잠이 깸',
              option1: '평소에 수면에 문제 없음',
              sub_option1: '(깨지 않고 잘 자는 편)',
              option2: '월 2~3회 이하',
              sub_option2: '숙면에 어려움을 겪음',
              option3: '주 1~2회 정도',
              sub_option3: '숙면에 어려움을 겪음',
              option4: '주 3회 이상',
              sub_option4: '숙면에 어려움을 겪음',
            },
            sleep_time: {
              question: '밤 12시 이후에서야 잠자리에 드시나요?',
              option1: '아니요',
              sub_option1: '12시 이전에 자요',
              option2: '네',
              sub_option2: '12시 이후에 자요',
            },
            regular_sleep_time: {
              question: '정해진 시간에 잠자리에 들고 정해진 시간에 일어나세요?',
              option1: '아니요',
              sub_option1: '매번 다른 것 같아요',
              option2: '네',
              sub_option2: '나름 규칙적입니다',
            },
            sleep_amount: {
              question: '하루 총 수면시간은 매번 비슷한가요?',
              option1: '아니요',
              sub_option1: '매번 다른 것 같아요',
              option2: '네',
              sub_option2: '나름 규칙적입니다',
            },
            condition: {
              question: '다음 중 해당되는 경우가 있으신가요? (중복선택 가능)',
              option1: '아침에 얼굴이 부어요',
              option2: '아침에 일어나면 몸이 무거운 느낌이예요',
              option3: '평소에 잘 눕는 편이예요',
              option4: '해당사항 없어요',
            },
            personality: {
              question: '다음 중 평소 본인과 비슷한 성격을 선택해주세요 (중복선택 가능)',
              option1: '외향적이에요',
              option2: '급한 편이에요',
              option3: '소심한 편이에요',
              option4: '내성적이에요',
              option5: '느긋한 편이에요',
              option6: '해당사항 없어요',
            },
            heat_condition: {
              question: '해당하는 것을 모두 선택해주세요 (중복선택 가능)',
              option1: '평소 손발이 따뜻해요',
              option2: '평소 손발이 차요',
              option3: '평소 따뜻한 것을 좋아해요',
              option4: '평소 차가운 것을 좋아해요',
              option5: '해당사항 없어요',
            },
            heat_condition_detail: {
              question: '평소 느끼는 증상이 있다면 모두 선택해주세요',
              option1: '얼굴 쪽으로 열이 나는 느낌이 있어요',
              option2: '무릎 아래가 시린 느낌이 있어요',
              option3: '해당사항 없어요',
            },
            poop: {
              question: '평소 나의 배변 고민이 있나요?',
              option1: '건강해요',
              sub_option1: '촉촉한 형태',
              option2: '변비가 있어요',
              sub_option2: '딱딱한 형태',
              option3: '무른 변을 자주 봐요',
              sub_option3: '묽은 형태',
            },
            pee: {
              question: '해당하는 것을 모두 선택해주세요',
              option1: '소변을 참지 못하고 지린 적이 있어요',
              option2: '수면 중 소변을 보기 위해 자주 깨요',
              option3: '해당사항 없어요',
            },
            disease: {
              question: "질환 및 증상 (없으시면 바로 '건너뛰기' 버튼을 눌러주세요)",
              placeholder: '질환 또는 증상 명',
              subtitle: '아래 작성하신 항목들을 가장 심한 증상부터 순서대로 드래그하여 정렬해주세요.',
            },
            disease_time_trigger: {
              question1: '발병 시기 (증상 발현 또는 진단 시기)',
              option1: '3개월 이내',
              option2: '3 ~ 6개월',
              option3: '6개월 이상',
              question2: '발병 원인 (몸이 안 좋아진 계기)',
              subtitle1: '음식 습관',
              subtitle2: '생활 습관',
              subtitle3: '정신적 스트레스',
              subtitle4: '날씨 기후 영향',
              subtitle5: '외상',
              form_option1: '특별한 이유 없음',
              form_option2: '과식',
              form_option3: '폭식',
              form_option4: '급하게 먹음',
              form_option5: '상한 음식',
              form_option6: '과로',
              form_option7: '야간 근무',
              form_option8: '책상 근무',
              form_option9: '잘 눕는 습관',
              form_option10: '과도한 성생활',
              form_option11: '화냄',
              form_option12: '슬픔',
              form_option13: '우울함',
              form_option14: '일상 스트레스',
              form_option15: '찬바람',
              form_option16: '무더위',
              form_option17: '습한 곳',
              form_option18: '건조한 곳에 노출',
              form_option19: '교통사고',
              form_option20: '낙상',
              form_option21: '외상',
              form_option22: '세균 감염',
              form_option23: '기타',
              other_placeholder: '기타 원인을 입력해주세요',
            },
            past_history: {
              question: '과거력',
              subtitle: '해당사항에 모두 체크해주세요.',
              option1: '해당사항 없음',
              option2: '고혈압',
              option3: '당뇨',
              option4: '고지혈증',
              option5: '중풍',
              option6: '암',
              option7: '심장질환',
              option8: '폐질환(결핵)',
              option9: '소화기 질환',
              option10: '간질환(간염)',
              option11: '신장(콩팥)질환',
              option12: '알러지',
              option13: '자가면역질환',
              option14: '기타',
              option15: '수술유무',
              other_placeholder: '기타 원인을 입력해주세요',
              surgery_placeholder: '수술 부위를 입력해주세요',
            },
            obese_med: {
              question: '비만약 복용 경험',
              subtitle: '해당사항에 모두 체크해주세요.',
              option1: '해당사항 없음',
              option2: '한약',
              option3: '양약',
              placeholder: '복용기간을 입력해주세요',
            },
            favorite_food: {
              question: '기호식품',
              subtitle: '해당사항을 체크하고, 해당한다면 양을 적어주세요.',
              option1: '담배를 피운다.',
              option1_placeholder: '흡연량',
              option1_unit: '개비/1일',
              option2: '술을 주 1회 이상 마신다.',
              option2_placeholder: '음주량',
              option2_unit: '회/1주',
              option2_unit2: '잔/1회',
              option3: '커피, 녹차를 마신다.',
              option3_placeholder: '섭취량',
              option3_unit: '잔/1일',
              no: '아니다',
              do: '피운다',
              drink: '마신다',
            },
          },
          button: {
            next: '다음',
            skip: '건너뛰기',
            end: '설문 종료',
            add: '추가',
          },
          inputError: '필수 정보를 입력해주세요.',
          unfinishedError: '이전에 답하지 않은 질문들을 답해주세요',
          loading: '설문지를 생성 중입니다...',
        },
      },
    },
  });

export default i18n;
