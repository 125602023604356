import React from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import styled from '@emotion/styled';
import * as s from '../../info/InfoStyled';

const HeaderWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
`;

const SurveyTitle = styled.span`
  font-size: 1rem;
  font-weight: 700;
`;

function SurveyForm({
  children,
  progress,
  totalSteps,
  onNext,
  onPrev,
}: {
  children: React.ReactNode;
  progress: number;
  totalSteps: number;
  onNext: () => void;
  onPrev: () => void;
}) {
  const { t } = useTranslation();

  return (
    <s.PageContainer>
      <div style={{ width: '100%' }}>
        <HeaderWrap>
          <SurveyTitle>{t('survey.title')}</SurveyTitle>
          <s.HeaderContainer>
            <s.NavButton onClick={onPrev} aria-label="Go back">
              <ArrowBack />
            </s.NavButton>
            <s.ProgressBarContainer>
              <s.ProgressBar variant="determinate" value={progress * 100} />
            </s.ProgressBarContainer>
            <s.NavButton onClick={onNext} aria-label="Go front">
              <ArrowForward />
            </s.NavButton>
          </s.HeaderContainer>
          <s.ProgressText>
            {progress * totalSteps}/{totalSteps}
          </s.ProgressText>
        </HeaderWrap>
      </div>
      {children}
    </s.PageContainer>
  );
}

export default SurveyForm;
