import React, { useEffect } from 'react';
import { SurveyTitle, DropBox, TextFieldBox, BtnPreNext, StyledButton } from '../common/Survey';
import { ISurveyDictType } from '../common/SurveyTypes';
import './SurveyBasic.scss';
import '../common/Survey.scss';

interface IBasicPageProps {
  dictObject: ISurveyDictType;
  validForm: IValidData;
  validErrMessages: IValidErrMessagesData;
}

interface IValidData {
  nameValid: boolean;
  genderValid: boolean;
  ageValid: boolean;
  phoneValid: boolean;
}

interface IValidErrMessagesData {
  nameErrMessage: string;
  genderErrMessage: string;
  ageErrMessage: string;
  phoneErrMessage: string;
}

export default function SurveyBasicPresenter(Props: IBasicPageProps) {
  const { validForm, validErrMessages, dictObject } = Props;

  return (
    <>
      <SurveyTitle title="기본설문" />
      <TextFieldBox
        textFieldBoxItem={{
          qTitle: 'name',
          title: '이름',
          placeholder: 'OOO',
          require: true,
          helperText: validErrMessages.nameErrMessage,
          error: !validForm.nameValid && validErrMessages.nameErrMessage !== '',
          defaultValue: dictObject.name,
        }}
      />
      <DropBox
        dropBoxItem={{ qTitle: 'gender', title: '성별', questions: ['남자', '여자'], defaultValue: dictObject.gender }}
      />
      <TextFieldBox
        textFieldBoxItem={{
          qTitle: 'age',
          title: '나이',
          placeholder: '20',
          type: 'number',
          require: true,
          helperText: validErrMessages.ageErrMessage,
          error: !validForm.ageValid && validErrMessages.ageErrMessage !== '',
          defaultValue: dictObject.age,
        }}
      />
      <TextFieldBox
        textFieldBoxItem={{
          qTitle: 'phone',
          title: '전화번호',
          placeholder: '01092781271',
          type: 'number',
          require: true,
          helperText: validErrMessages.phoneErrMessage,
          error: !validForm.phoneValid && validErrMessages.phoneErrMessage !== '',
          defaultValue: dictObject.phone,
        }}
      />
      <TextFieldBox
        textFieldBoxItem={{
          qTitle: 'height',
          title: '신장(cm)',
          placeholder: '174.6',
          type: 'number',
          require: false,
          defaultValue: dictObject.height,
        }}
      />
      <TextFieldBox
        textFieldBoxItem={{
          qTitle: 'weight',
          title: '체중(kg)',
          placeholder: '69.3',
          type: 'number',
          require: false,
          defaultValue: dictObject.weight,
        }}
      />
      <TextFieldBox
        textFieldBoxItem={{
          qTitle: 'blood_pressure_max',
          title: '수축기 혈압',
          placeholder: '127',
          type: 'number',
          require: false,
          defaultValue: dictObject.blood_pressure_max,
        }}
      />
      <TextFieldBox
        textFieldBoxItem={{
          qTitle: 'blood_pressure_min',
          title: '이완기 혈압',
          placeholder: '80',
          type: 'number',
          require: false,
          defaultValue: dictObject.blood_pressure_min,
        }}
      />
      <TextFieldBox
        textFieldBoxItem={{
          qTitle: 'heart_rate',
          title: '맥박(분)',
          placeholder: '87',
          type: 'number',
          require: false,
          defaultValue: dictObject.heart_rate,
        }}
      />

      <StyledButton
        btnItem={{
          text: '다음',
          color: '',
          link: '../survey-dgnsis',
          disabled: !(validForm.ageValid && validForm.genderValid && validForm.nameValid && validForm.phoneValid),
        }}
      />
    </>
  );
}
