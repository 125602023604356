const HEADER_ON = 'header/OF';
const HEADER_OFF = 'header/ON';
const HEADER_TEXT_CHANGE = 'header/CHANGE_TEXT';

interface IHeaderAction {
  type: typeof HEADER_ON | typeof HEADER_OFF | typeof HEADER_TEXT_CHANGE;
  text: string;
}

export const headerON = () => ({ type: HEADER_ON });
export const headerOFF = () => ({ type: HEADER_OFF });
export const headerTextChange = (text: string) => ({ type: HEADER_TEXT_CHANGE, text });

const initialState = {
  toggle: true,
  text: '',
};

// eslint-disable-next-line default-param-last
function header(state = initialState, action: IHeaderAction) {
  switch (action.type) {
    case HEADER_ON:
      return {
        ...state,
        toggle: true,
      };
    case HEADER_OFF:
      return {
        ...state,
        toggle: false,
      };
    case HEADER_TEXT_CHANGE:
      return {
        ...state,
        text: action.text,
      };
    default:
      return state;
  }
}

export default header;
