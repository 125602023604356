import React from 'react';
import jwtDecode from 'jwt-decode';
import { authApi } from '../api/api-auth';

export const requesetTokenCheck = (accessToken: string) => {
  if (accessToken === '' || accessToken === null) {
    localStorage.clear();
    return false;
  }
  // access 토큰 만료시간은 프론트에서도 확인
  const decode: any = jwtDecode(accessToken);
  const { exp } = decode;
  const now = Date.now() / 1000;
  if (exp < now) {
    // refresh 토큰 갱신
    return refreshToken();
  }
  return true;
};

export const localTokenCheck = () => {
  // localStorage.clear();
  const tokens = JSON.parse(localStorage.getItem('tokens') || 'null');
  if (tokens === '' || tokens === null || tokens === undefined) {
    localStorage.clear();
    return false;
  }

  // access 토큰 만료시간은 프론트에서도 확인
  const decode: any = jwtDecode(tokens.access.token);
  const { exp } = decode;
  const now = Date.now() / 1000;
  if (exp < now) {
    // refresh 토큰 갱신
    return refreshToken();
  }
  return true;
};

const refreshToken = async () => {
  const tokens = JSON.parse(localStorage.getItem('tokens') || 'null');
  let res;
  try {
    res = await authApi.refreshToken(tokens.refresh.token);
    if (res) {
      localStorage.setItem('tokens', JSON.stringify(res.data));
      return true;
    }
  } catch (e) {
    // refresh 토큰도 만료
    localStorage.clear();
    return false;
  }
  return false;
};
