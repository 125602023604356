import React from 'react';
import { useTranslation } from 'react-i18next';
import * as s from './NewSurveyStyled';
import insomnia from '../../../asset/img/survey/Insomnia.png';

interface SleepTimeProps {
  onNext: () => void;
  onScore: (key: string, score: number) => void;
  selectedOption: string;
  onOptionSelect: (option: string) => void;
}

const SleepTime: React.FC<SleepTimeProps> = ({ onNext, onScore, selectedOption, onOptionSelect }) => {
  const { t } = useTranslation();

  return (
    <s.SurveyContainer>
      <s.Question>{t('survey.sleep_time.question')}</s.Question>
      <s.Picture src={insomnia} />
      <s.OptionContainer>
        <s.Options>
          <s.OptionButton
            type="button"
            variant={selectedOption === '1' ? 'contained' : 'outlined'}
            onClick={() => {
              onOptionSelect('1');
              onNext();
            }}
            style={{ height: '100px' }}
          >
            <s.OptionTitle>{t('survey.sleep_time.option1')}</s.OptionTitle>
            <s.OptionSubtitle>{t('survey.sleep_time.sub_option1')}</s.OptionSubtitle>
          </s.OptionButton>
          <s.OptionButton
            type="button"
            variant={selectedOption === '2' ? 'contained' : 'outlined'}
            onClick={() => {
              onOptionSelect('2');
              onScore('ebBalanceControl', -1);
            }}
            style={{ height: '100px' }}
          >
            <s.OptionTitle>{t('survey.sleep_time.option2')}</s.OptionTitle>
            <s.OptionSubtitle>{t('survey.sleep_time.sub_option2')}</s.OptionSubtitle>
          </s.OptionButton>
        </s.Options>
      </s.OptionContainer>
    </s.SurveyContainer>
  );
};

export default SleepTime;
