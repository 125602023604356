import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { headerOFF, headerON, headerTextChange } from '../modules/header';

// when access the pecific page, set off the header.
// if page is unmount, then set on the header.
// => use this function if you want to hide header.
export function useHeaderToggle() {
  const dispatch = useDispatch();
  useEffect(() => {
    // console.log('OFF');
    dispatch(headerOFF());
    return () => {
      // console.log('ON');
      dispatch(headerON());
    };
  }, []);
}

// set the header title.
export function useHeaderTextChange(text: string) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(headerTextChange(text));
  }, []);
}
