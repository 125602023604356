import styled from '@emotion/styled';
import { Container, Typography, Button } from '@mui/material';

export const WelcomePage = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: white;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
`;

export const WelcomeContainer = styled(Container)`
  width: 100%;
`;

export const Logo = styled.div`
  background-color: #0f67fe;
  color: white;
  width: 4rem;
  height: 4rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0 auto 1rem;
`;

export const WelcomeLogo = styled.img`
  width: 4rem;
  height: 4rem;
  margin: 0 auto 1rem;
`;

export const WelcomeTitle = styled(Typography)`
  font-weight: 700;
  font-size: 2rem;
  text-align: center;
  font-family: 'Noto Sans KR';
`;

export const WelcomeSubtitle = styled(Typography)`
  font-weight: 700;
  font-size: 2rem;
  text-align: center;
  color: #0f67fe;
  margin-bottom: 0.5rem;
  font-family: 'Noto Sans KR';
`;

export const WelcomeContent = styled(Typography)`
  font-weight: 400;
  text-align: center;
  color: #3d4966;
  font-size: 0.875rem;
  font-family: 'Noto Sans KR';
`;

export const WelcomeImage = styled.img`
  min-width: 300px;
  max-width: 40%;
  margin: 0.5rem 0 0;
`;

export const StartButton = styled(Button)`
  background-color: #0f67fe;
  font-family: 'Noto Sans KR';
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  width: 100%;
  font-size: 1rem;
  text-transform: none;
`;

export const ImageLink = styled.a`
  display: block;
  font-size: 0.7rem;
  text-decoration: none;
  color: #0f67fe;
  opacity: 0.5;
  font-weight: 400;
  margin-top: 1rem;
`;

export const LanguageSelector = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
  background-color: white;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;
