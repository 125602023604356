import React from 'react';
import { useTranslation } from 'react-i18next';
import * as s from './NewSurveyStyled';
import pantsImg from '../../../asset/img/pants.png';

interface WaistCircumferenceProps {
  onNext: () => void;
  gender: string;
  onScore: (key: string, score: number) => void;
  selectedOption: string;
  onOptionSelect: (option: string) => void;
}

const WaistCircumference: React.FC<WaistCircumferenceProps> = ({
  onNext,
  gender,
  onScore,
  selectedOption,
  onOptionSelect,
}) => {
  const inch = gender === 'male' ? '35' : '33';
  const centimeter = gender === 'male' ? '90cm' : '85cm';
  const { t } = useTranslation();

  return (
    <s.SurveyContainer>
      <s.Question>{t('survey.waist_circumference.question')}</s.Question>
      <s.Picture src={pantsImg} />
      <s.Options>
        <s.OptionButton
          type="button"
          variant={selectedOption === '1' ? 'contained' : 'outlined'}
          onClick={() => {
            onOptionSelect('1');
            onScore('ebCirculation', -1);
          }}
          style={{ height: '4rem' }}
        >
          <s.OptionTitle>{t('survey.waist_circumference.option1', {inch, centimeter})}</s.OptionTitle>
        </s.OptionButton>
        <s.OptionButton
          type="button"
          variant={selectedOption === '2' ? 'contained' : 'outlined'}
          onClick={() => {
            onOptionSelect('2');
            onNext();
          }}
          style={{ height: '4rem' }}
        >
          <s.OptionTitle>{t('survey.waist_circumference.option2', {inch, centimeter})}</s.OptionTitle>
        </s.OptionButton>
        <s.OptionButton
          type="button"
          variant={selectedOption === '3' ? 'contained' : 'outlined'}
          onClick={() => {
            onOptionSelect('3');
            onNext();
          }}
          style={{ height: '4rem' }}
        >
          <s.OptionTitle>{t('survey.waist_circumference.option3', {inch, centimeter})}</s.OptionTitle>
        </s.OptionButton>
      </s.Options>
      {/* <s.NextButton type="button" onClick={() => onNext()} variant="contained" color="primary">
        {t('button.skip')}
      </s.NextButton> */}
    </s.SurveyContainer>
  );
};

export default WaistCircumference;
