const SELECTONE = 'newsurvey/SELECTONE';
const SELECTMANY = 'newsurvey/SELECTMANY';
const SELECTBASIC = 'newsurvey/SELECTBASIC';
const SELECTPRIVACY = 'newsurvey/SELECTPRIVACY';
const SELECTHEALTHCARE = 'newsurvey/SELECTHEALTHCARE';
const RESET = 'newsurvey/RESET';

export type StepType =
    | '목둘레'
    | '상하체발달'
    | '종아리둘레'
    | '늑골각'
    | '맥박수'
    | '식욕'
    | '식사횟수'
    | '1회식사량'
    | '소화불량'
    | '충분한수면'
    | '입면시간'
    | '규칙적인입출면시간'
    | '규칙적인총수면시간'
    | '기상시상태와평소활동량'
    | '성격'
    | '한열상태'
    | '한열상태(얼굴무릎)'
    | '대변'
    | '소변';


export const stepOrder: StepType[] = [
    '목둘레',
    '상하체발달',
    '종아리둘레',
    '늑골각',
    '맥박수',
    '식욕',
    '식사횟수',
    '1회식사량',
    '소화불량',
    '충분한수면',
    '입면시간',
    '규칙적인입출면시간',
    '규칙적인총수면시간',
    '기상시상태와평소활동량',
    '성격',
    '한열상태',
    '한열상태(얼굴무릎)',
    '대변',
    '소변',
    // '질환및증상',
    // '발병시기및계기',
    // '과거력',
    // '비만약',
    // '기호식품',
];

export type BasicType = '성별' | '나이' | '체중' | '키';

export const basicOrder: BasicType[] = ['성별', '나이', '체중', '키'];


export interface INewFormValue {
    selected_option: Record<StepType, string[]> | null;
    single_selected_option: Record<StepType, string> | null;
    basic_info: IBasicInfo;
    privacy_info: IPrivacyInfo;
    healthcare_info: IHealthcareInfo;
};

export interface IHealthcareInfo {
    disease: { id: string; name: string }[];
    trigger: {
        특별한이유없음: boolean;
        과식: boolean;
        폭식: boolean;
        급하게먹음: boolean;
        상한음식: boolean;
        과로: boolean;
        야간근무: boolean;
        책상근무: boolean;
        잘눕는습관: boolean;
        과도한성생활: boolean;
        화냄: boolean;
        슬픔: boolean;
        우울함: boolean;
        일상스트레스: boolean;
        찬바람: boolean;
        무더위: boolean;
        습한: boolean;
        건조한: boolean;
        교통사고: boolean;
        낙상: boolean;
        외상: boolean;
        세균감염: boolean;
        기타: boolean;
    };
    disease_generate_time: number | string | boolean | null;
    past_history: {
        해당사항없음: boolean;
        고혈압: boolean;
        당뇨: boolean;
        고지혈증: boolean;
        중풍: boolean;
        암: boolean;
        심장질환: boolean;
        폐질환: boolean;
        소화기질환: boolean;
        간질환: boolean;
        신장질환: boolean;
        알러지: boolean;
        자가면역질환: boolean;
        기타: boolean;
        수술유무: boolean;
    };
    otherReason: number | string | boolean | null;
    otherReasonP: number | string | boolean | null;
    surgery: number | string | boolean | null;
    isNextDisabledP: number | string | boolean | null;
    diet_med: {
        해당사항없음: boolean;
        한약: boolean;
        양약: boolean;
    };
    korMed: number | string | boolean | null;
    westernMed: number | string | boolean | null;
    isNextDisabledM: number | string | boolean | null;
    favorate_food: {
        question1: number | string | boolean | null;
        question1Amount: number | string | boolean | null;
        question2: number | string | boolean | null;
        question2Amount1: number | string | boolean | null;
        question2Amount2: number | string | boolean | null;
        question3: number | string | boolean | null;
        question3Amount: number | string | boolean | null;
    }

}

export interface IBasicInfo {
    gender: number | string | boolean | null;
    age: number | string | boolean | null;
    weight: number | string | boolean | null;
    height: number | string | boolean | null;
    ebCirculation: number | string | boolean | null;
    ebProduce: number | string | boolean | null;
    ebBalanceControl: number | string | boolean | null;
}

export interface IPrivacyInfo {
    name: number | string | boolean | null;
    phoneNumber: number | string | boolean | null;
    exist: boolean | null;
}

export const setSelectedOptionInfo = (value: Record<StepType, string[]>) => ({
    type: SELECTMANY,
    value,
});

export const setSingleSelectedOptionInfo = (value: Record<StepType, string>) => ({
    type: SELECTONE,
    value,
});

export const setBasicInfo = (value: IBasicInfo) => ({
    type: SELECTBASIC,
    value,
});

export const setPrivacyInfo = (value: IPrivacyInfo) => ({
    type: SELECTPRIVACY,
    value,
});

export const setHealthcareInfo = (value: IHealthcareInfo) => ({
    type: SELECTHEALTHCARE,
    value,
});

export const restSurveyStore = () => ({
    type: RESET,
});

const initialState: INewFormValue = {
    selected_option: {} as Record<StepType, string[]>,
    single_selected_option: {} as Record<StepType, string>,
    basic_info: {
        gender: '',
        age: '60',
        weight: '90',
        height: '150',
        ebCirculation: 0,
        ebProduce: 0,
        ebBalanceControl: 0,
    },
    privacy_info: {
        name: '',
        phoneNumber: '',
        exist: false
    },
    healthcare_info: {} as IHealthcareInfo
};

// eslint-disable-next-line default-param-last
function newsurvey(state = initialState, action: any) {
    switch (action.type) {
        case SELECTONE:
            return {
                ...state,
                single_selected_option: action.value,
            };
        case SELECTMANY:
            return {
                ...state,
                selected_option: action.value,
            };
        case SELECTBASIC:
            return {
                ...state,
                basic_info: action.value,
            };
        case SELECTPRIVACY:
            return {
                ...state,
                privacy_info: action.value,
            };
        case SELECTHEALTHCARE:
            return {
                ...state,
                healthcare_info: action.value,
            };
        case RESET:
            return {
                selected_option: {} as Record<StepType, string[]>,
                single_selected_option: {} as Record<StepType, string>,
                basic_info: {
                    gender: '',
                    age: '60',
                    weight: '90',
                    height: '150',
                    ebCirculation: 0,
                    ebProduce: 0,
                    ebBalanceControl: 0,
                },
                privacy_info: {
                    name: '',
                    phoneNumber: '',
                    exist: false
                },
                healthcare_info: {} as IHealthcareInfo
            };
        default:
            return state
    }
};

export default newsurvey;