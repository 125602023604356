import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import SurveyDigSlpPresenter from './SurveyHbiPsnPresenter';
import { useHeaderTextChange } from '../../../../lib/useHeader';
import { RootState } from '../../../../modules';

export default function SurveyHbiPsnContainer() {
  // header text change
  useHeaderTextChange('사전문진');

  // get store object(survey)
  const dictObject = useSelector((state: RootState) => state.survey);

  // validation for survey-hbipsn
  const [validForm, setValidForm] = useState({
    q61to63: false,
    q64: false,
    q7: false,
  });

  // validation check for q61to63
  useEffect(() => {
    if (!(dictObject.q61 || dictObject.q62 || dictObject.q63)) {
      setValidForm({
        ...validForm,
        q61to63: false,
      });
    } else {
      setValidForm({
        ...validForm,
        q61to63: true,
      });
    }
  }, [dictObject.q61, dictObject.q62, dictObject.q63]);

  // validation check for q64
  useEffect(() => {
    if (!dictObject.q64) {
      setValidForm({
        ...validForm,
        q64: false,
      });
    } else {
      setValidForm({
        ...validForm,
        q64: true,
      });
    }
  }, [dictObject.q64]);

  // validation check for q7
  useEffect(() => {
    if (!(dictObject.q71 || dictObject.q72 || dictObject.q73 || dictObject.q74 || dictObject.q75 || dictObject.q76)) {
      setValidForm({
        ...validForm,
        q7: false,
      });
    } else {
      setValidForm({
        ...validForm,
        q7: true,
      });
    }
  }, [dictObject.q71, dictObject.q72, dictObject.q73, dictObject.q74, dictObject.q75, dictObject.q76]);

  // checking for button disable
  useEffect(() => {
    if (
      (dictObject.q61 || dictObject.q62 || dictObject.q63 || dictObject.q64) &&
      (dictObject.q71 || dictObject.q72 || dictObject.q73 || dictObject.q74 || dictObject.q75 || dictObject.q76)
    ) {
      setValidForm({
        q61to63: true,
        q64: true,
        q7: true,
      });
    }
  }, []);

  return <SurveyDigSlpPresenter dictObject={dictObject} validForm={validForm} />;
}
