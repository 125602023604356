import styled from '@emotion/styled';
import { Container, Typography, Button, Radio } from '@mui/material';

export const SurveyContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Question = styled(Typography)`
  font-size: var(--font-size-md);
  font-weight: 700;
  font-family: 'Noto Sans KR', sans-serif;
  justify-content: center;
  text-align: center;
`;

export const QuestionSubtitle = styled(Typography)`
  font-size: 1rem;
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 400;
`;

export const PictureContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: clamp(1rem, 5vw, 3rem);
  margin: 1rem 0;

  @media (max-width: 600px) {
    flex-direction: column;
    gap: 0;
  }
`;

export const TwoPictureContainer = styled(PictureContainer)`
  @media (max-width: 600px) {
    flex-direction: row;
    gap: 0;
  }
`;

export const Picture = styled.img`
  width: clamp(15rem, 25vw, 18rem);
  height: auto;
`;

export const TwoPicture = styled.img`
  width: clamp(12rem, 20vw, 15rem);

  @media (max-width: 600px) {
    width: 8rem%;
  }
`;

export const OptionContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const InnerOptionContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  gap: var(--option-gap);
`;

export const Options = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  // height: 100%;
  justify-content: center;
  align-items: center;
  gap: var(--option-gap);
  margin-top: 1rem;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const OptionImageContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 30%;
`;

export const ThreeOptionImage = styled.img`
  width: clamp(5rem, 15vw, 10rem);
  margin: 0.5rem 0;
`;

export const OptionButton = styled(Button)`
  display: flex;
  flex-direction: column;
  width: 30%;
  color: primary;
  font-weight: 700;
  font-family: 'Noto Sans KR', sans-serif;
  border: none;
  border-radius: 10px;
  shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 0;

  &:hover {
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const OptionButtonWithImage = styled(OptionButton)`
  width: fit-content;
`;

export const OptionTitle = styled.span`
  display: block;
  font-family: 'Noto Sans KR', sans-serif;
  font-size: clamp(1rem, 2vw, 1.2rem);
  font-weight: 700;
`;

export const OptionSubtitle = styled.span`
  display: block;
  font-size: clamp(0.7rem, 2vw, 1rem);
  font-weight: 700;
`;

export const OptionRadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const OptionRadio = styled(Radio)`
  .MuiSvgIcon-root {
    width: 1.5em;
    height: 1.5em;
  }
`;

export const NextButton = styled(Button)`
  margin: 10px 0px;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  background-color: #0f67fe;
  font-family: 'Noto Sans KR', sans-serif;
  margin-top: auto;
`;
